export const MESSAGE = {
  SERVICE_AUTHENTICATED_ONLY: '회원 전용 서비스입니다. 먼저 로그인해주세요.',
  SERVICE_INVALID_TOKEN: '토큰이 유효하지 않습니다. 다시 로그인해주세요.',
  SERVICE_EXPIRED_TOKEN: '로그인 토큰이 만료되었습니다. 다시 로그인해주세요.',
  SERVICE_EXPIRED_REFRESHTOKEN: '로그인이 만료되었습니다. 다시 로그인해주세요.',
  SERVICE_STUDENT_ONLY: '학습자 전용 서비스입니다.',
  SERVICE_ADMIN_ONLY: '관리자 전용 서비스입니다.',
  SERVICE_TRAINER_ONLY: '강사 전용 서비스입니다.',
  SERVICE_OPERATOR_ONLY: '강사 / 관리자 전용 서비스입니다.',
  STATUE_NOT_CONFIRMED: '사용 승인이 필요합니다.',
  SERVICE_STUDENT_EXAM_ONLY: '역량평가 응시 대상이 아닙니다.',
  INVALID_ERROR_OCCUR: '오류가 발생하였습니다. 페이지 관리자에게 문의해주세요.',
  TOAST_NOT_ALLOWED_COURSE: '현재 계정은 수강할 수 없는 강의입니다. 그룹 운영자에게 문의해주세요.',
  UNKNOWN_ERROR: '알 수 없는 에러입니다. 다시 시도해주세요.',
  UNKNOWN_REQUEST: '잘못된 요청입니다.',
  EXPIRE_LOGIN_TOKEN: '로그인 세션이 만료되었습니다. 다시 로그인 해주세요.',
  EXPIRE_COURSE_SESSION: '수강 세션이 만료되었습니다. 학습실로 이동합니다.',
};

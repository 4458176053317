import React, { useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogTitle,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  Select,
  MenuItem,
} from '@mui/material';

import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import { adminConfirmUser, fetchReviewList } from 'api/customer';
import Button from '../../../elements/Button/Button';
import { MESSAGE } from 'utilities';
import { BootstrapInput } from 'style/customerPageStyle';
import { errorToast } from 'utilities/toast';
import { FormWrapper, StyledTableRow, TableRowHover } from '../CustomerCommon';

const ReviewList = ({ history }) => {
  // 로그인 정보 없을 시 홈으로 리다이렉트
  useRedirectWithoutAuth();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [list, setList] = useState([]);
  const [searchedData, setSearched] = useState([]);
  const [search, setSearch] = useState({
    selected: 'postTitle',
    title: '제목',
    content: '',
  });

  const selected = useState([])[0];

  const selectChange = (e) => {
    if (e.target.value === 'postTitle') {
      setSearch({ ...search, selected: e.target.value, title: '제목' });
      setSearched(
        list.filter((l) => {
          return l.postTitle.indexOf(search.content) !== -1;
        })
      );
    } else if (e.target.value === 'postContent') {
      setSearch({ ...search, selected: e.target.value, title: '내용' });
      setSearched(
        list.filter((l) => {
          return l.postContent.indexOf(search.content) !== -1;
        })
      );
    }
  };

  const handleChangeSearch = (e) => {
    if (search.selected === 'postTitle')
      setSearched(
        list.filter((l) => {
          return l.postTitle.indexOf(e.target.value) !== -1;
        })
      );
    else if (search.selected === 'postContent') {
      setSearched(
        list.filter((l) => {
          return l.postContent.indexOf(e.target.value) !== -1;
        })
      );
    }
    setSearch({ ...search, content: e.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchReviewList();
        setList(response.data);
        setSearched(response.data);
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.status === 401 || error.response.status === 403) {
            errorToast(MESSAGE.SERVICE_INVALID_TOKEN);
          }
          history.push('/');
        }
      }
    })();
  }, []);

  const [openUserDialog, setOpenUSerDialog] = useState(false);
  const selectedValue = useState(null)[0];
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseUserDialog = async (value) => {
    if (isLoading) return;

    const { type, userId } = value;
    //type 1 승인, 2거절

    let form = new FormData();
    form.append('userId', userId);
    form.append('oauth', type);

    try {
      setIsLoading(true);

      await adminConfirmUser({ data: form });

      const listUSer = list?.map((user) => (user.userId === userId ? { ...user, isOauth: type } : user));
      setList(listUSer);
      setSearched(listUSer);
      setIsLoading(false);
    } catch (error) {}
    setOpenUSerDialog(false);
  };

  const handleGoToDetail = (id) => history.push(`/customer/review/${id}`);

  return (
    <Fragment>
      <div style={{ marginBottom: '1vh', float: 'left' }}></div>

      <FormWrapper spaceBetween>
        <Button roundSmall to='/customer/review-add' type='outline-color-cl' hoverType='solid-color-tb'>
          <span className='secotext'>
            후기 등록하기<i className='las la-file-code' style={{ marginLeft: '5px' }}></i>
          </span>
        </Button>

        <span>
          <Select
            variant='filled'
            id='select'
            value={search.selected}
            onChange={selectChange}
            input={<BootstrapInput />}
          >
            <MenuItem className='secotext' value='postTitle'>
              제목
            </MenuItem>
            <MenuItem className='secotext' value='postContent'>
              내용
            </MenuItem>
          </Select>
          <BootstrapInput
            placeholder={`${search.title}으로 검색하기`}
            name='content'
            value={search.content}
            onChange={handleChangeSearch}
            style={{ width: '300px' }}
          />
        </span>
      </FormWrapper>

      <div className='mypageContents'>
        <TableContainer component={Paper}>
          <Table aria-label='customized table'>
            <TableHead rowcount={searchedData.length}>
              <StyledTableRow>
                <TableCell width='5vw'>
                  <span className='secotext'>No.</span>
                </TableCell>
                <TableCell align='center'>
                  <span className='secotext'>제목</span>
                </TableCell>
                <TableCell align='center'>
                  <span className='secotext'>작성자</span>
                </TableCell>
                <TableCell align='center'>
                  <span className='secotext'>등록일</span>
                </TableCell>
              </StyledTableRow>
            </TableHead>

            <TableBody>
              {searchedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                const isItemSelected = isSelected(row.postId);
                return (
                  <TableRowHover
                    onClick={() => handleGoToDetail(row.postId)}
                    key={index}
                    hover
                    selected={isItemSelected}
                  >
                    <TableCell>
                      <span className='secotext'>{page * rowsPerPage + index + 1}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span className='secotext'>{row.postTitle}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span className='secotext'>{row.postAuthor}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span className='secotext'>{row.postRegDate}</span>
                    </TableCell>
                  </TableRowHover>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component='div'
        count={searchedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <UserInfoDialog userSelect={selectedValue} open={openUserDialog} onClose={handleCloseUserDialog} />
    </Fragment>
  );
};

const UserInfoDialog = (props) => {
  const { onClose, userSelect, open } = props;

  const handleClose = () => {
    onClose(userSelect);
  };

  const handleAcceptUser = (value) => {
    let params = {
      type: 1,
      userId: value,
    };
    onClose(params);
  };
  const handleRejectUser = (value) => {
    let params = {
      type: 2,
      userId: value,
    };
    onClose(params);
  };

  if (!userSelect) {
    return '';
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby='simple-dialog-title' open={open}>
      <div style={{ padding: '40px' }}>
        <DialogTitle id='simple-dialog-title'>유저 정보</DialogTitle>
        <WrapperDiv>
          <p>{userSelect === '1' ? '학습자' : userSelect === '2' ? '출제자' : '일반자'}</p>
          <br />
          <div>
            <p>No: {userSelect.userId.slice(0, 2)}</p>
            <p>이름: {userSelect.name}</p>
          </div>
          <div>
            <p>이메일: {userSelect.email}</p>
            <p>전화번호: {userSelect.phone}</p>
          </div>
          <div style={{ padding: '10px', textAlign: 'center' }}>
            <Button
              style={{ margin: '5px' }}
              onClick={() => handleAcceptUser(userSelect.userId)}
              variant='contained'
              color='primary'
            >
              승인
            </Button>
            <Button style={{ margin: '5px' }} onClick={() => handleRejectUser(userSelect.userId)} variant='contained'>
              거절
            </Button>
          </div>
        </WrapperDiv>
      </div>
    </Dialog>
  );
};

const WrapperDiv = styled.div`
  div {
    width: 500px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
`;

export default ReviewList;

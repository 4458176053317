import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LinearScale,
  BarController,
  annotationPlugin,
  ChartDataLabels
);

const StudentScoreGraph = ({ userExamResult }) => {
  const [userNames, setUserNames] = useState([]);
  const [firstExamScore, setFirstExamScore] = useState([]);
  const [secondExamScore, setSecondExamScore] = useState([]);
  const [firstAvg, setFirstAvg] = useState(0);
  const [secondAvg, setSecondAvg] = useState(0);

  // 목표점수 80으로 하드코딩
  const [targetScore] = useState(80);

  useEffect(() => {
    if (userExamResult?.length > 0) {
      setUserNames(userExamResult?.map((e) => e.userName));

      setFirstExamScore(userExamResult?.map((e) => e.examTakenResults[0]?.userScore));
      setFirstAvg(userExamResult[0].examTakenResults[0]?.groupAvgScore || 0);

      setSecondExamScore(userExamResult?.map((e) => e.examTakenResults[1]?.userScore || 0) || []);
      setSecondAvg(userExamResult[0].examTakenResults[1]?.groupAvgScore || 0);
    }
  }, [userExamResult]);

  const calculateBarThickness = useCallback(() => {
    let thickness = 0;
    if (userExamResult?.length < 0) {
      return thickness;
    }
    if (userExamResult?.length >= 30) {
      thickness = 15;
    } else if (userExamResult?.length >= 20) {
      thickness = 20;
    } else if (userExamResult?.length <= 10) {
      thickness = 25;
    }

    return thickness;
  }, [userExamResult]);

  const labels = userNames;
  const data = {
    labels,
    datasets: [
      {
        label: '사전 평가',
        data: firstExamScore,
        barThickness: calculateBarThickness(),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: '사후 평가',
        data: secondExamScore,
        barThickness: calculateBarThickness(),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  const options = {
    animation: {
      duration: 0,
    },
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: (tooltipItem) => {
            const { datasetIndex, dataIndex } = tooltipItem;
            const label = data.datasets[datasetIndex].label || '';
            const value = data.datasets[datasetIndex].data[dataIndex] || 0;
            return `${label}: ${value}`;
          },
        },
      },
      datalabels: {
        display: true,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        borderColor: 'rgba(128, 128, 128, 0.7)',
        borderRadius: 4,
        borderWidth: 1,
        align: 'center',
        anchor: 'end',
        formatter: (value, context) => {
          if (context.datasetIndex !== 1) return null;
          const index = context.dataIndex;
          const firstScore = data.datasets[0].data[index];
          const secondScore = data.datasets[1].data[index];
          const difference = secondScore - firstScore;
          const symbol = difference > 0 ? '▲' : difference < 0 ? '▼' : '';

          if ((firstScore || secondScore) === 0) return null;
          return `${symbol}점수 변동률: ${difference}`;
        },
      },
      annotation: {
        annotations: {
          clip: false,
          line1: {
            borderDash: [5],
            type: 'line',
            yMin: firstAvg,
            yMax: firstAvg,
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 1,
            label: {
              backgroundColor: 'rgb(255, 99, 132)',
              content: [`사전 평균 점수`, `${Math.floor(firstAvg)}점`],
              display: true,
              xAdjust: 5,
              yAdjust: 0,
              position: 'end',
            },
          },
          line2: {
            borderDash: [5],
            type: 'line',
            yMin: secondAvg,
            yMax: secondAvg,
            borderColor: 'rgb(53, 162, 235)',
            borderWidth: 1,
            label: {
              backgroundColor: 'rgb(53, 162, 235)',
              content: [`사후 평균 점수`, `${Math.floor(secondAvg)}점`],
              display: true,
              xAdjust: 5,
              yAdjust: 0,
              position: 'end',
            },
          },
        },
      },
    },
    scales: {
      y: {
        border: {
          width: 3,
        },
        grid: {
          color: (ctx) => {
            if (ctx.tick.value === 80) {
              return 'red';
            }
            return '#eee';
          },
        },
        min: 0,
        max: 100,
        ticks: {
          callback: (value) => {
            if (value === targetScore) {
              return [value, '(목표 점수)'];
            }
            return value;
          },
          color: '#000',
          crossAlign: 'center',
          font: {
            size: 12,
            weight: 'bold',
          },
        },
      },
    },
  };

  return (
    <Container>
      <h2>전체 교육생 점수 그래프</h2>

      <GraphBox>
        <Bar options={options} data={data} style={{ width: '85%', height: '100%' }} />
      </GraphBox>
    </Container>
  );
};

const Container = styled.div``;

const GraphBox = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
`;

export default StudentScoreGraph;

import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { Header } from 'components/widgets';
import LanguageList from './LanguageList.js';
import { MESSAGE } from 'utilities';
import './Training.css';
import { AuthAtom } from 'state';
import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import { errorToast } from 'utilities/toast';
import { useFetchLanguages } from '../../../../../hooks/common';

export default ({ history }) => {
  const auth = useRecoilValue(AuthAtom);

  const ref = useRef(null);

  useRedirectWithoutAuth();

  useEffect(() => {
    if (!auth.auth) {
      errorToast(`${MESSAGE.SERVICE_STUDENT_ONLY}`);
      history.push('/');
    }
  }, [auth]);

  const { languages, myCourseId } = useFetchLanguages();

  const isAllowedCourse = (languageId) => !!myCourseId.includes(languageId);

  return (
    <>
      <Header>프로젝트 개발훈련</Header>
      <div ref={ref}>
        <div className='text-center' style={{ marginTop: '50px', marginBottom: '30px' }}>
          <h4 className='pt-2 secosub2' style={{ color: '#a8a9a9' }}>
            학습할 개발언어를 선택하세요.
          </h4>
        </div>

        <CardWrapper className='row'>
          {languages?.map((lan, index) => {
            return (
              lan.languageId !== 0 && (
                <LanguageList
                  key={index}
                  languageType={lan.languageType}
                  languageId={lan.languageId}
                  disable={!isAllowedCourse(lan.languageId)}
                />
              )
            );
          })}
        </CardWrapper>

        <div className='text-center' style={{ margin: '50px' }}></div>
      </div>
    </>
  );
};

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0 10% 60px;
`;

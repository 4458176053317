import React, { Fragment } from 'react';

import { Header, Process } from '../../widgets';
import { useCustomState } from 'state/state';

const About = () => {
  const state = useCustomState()[0];

  return (
    <Fragment>
      <Header>훈련장 이용 안내</Header>
      <Process data={state.data.process} />
    </Fragment>
  );
};

export default About;

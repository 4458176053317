import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { errorToast, warningToast } from 'utilities/toast';
import { fetchCheckUserExam } from 'api/capability';
import { AuthAtom } from 'state';

const LanguageCard = ({ languageType, languageId, disable }) => {
  const history = useHistory();
  const auth = useRecoilValue(AuthAtom);
  const { userId } = auth;

  const imgUrl = `/assets/icons/${languageType}-icon.png`;

  const handleCheckExamIsExist = async (languageId) => {
    try {
      const response = await fetchCheckUserExam({ userId, languageId });

      if (response.data.data === 0) {
        history.push(`/student/capability/${languageId}`);
      }

      if (response.data.data === 1 || response.data.data === 2) {
        history.push('/student/capability/result');
      }

      if (response.data.data === 3) {
        errorToast('응시 가능한 시험이 존재하지 않습니다.');
        history.push('/');
      }
    } catch (error) {
      history.push('/');
    }
  };

  const handleDisableClick = () => {
    warningToast(`${languageType.toUpperCase()} 언어에 해당하는 시험이 존재하지 않습니다.`);
  };

  return (
    <>
      {disable && (
        <div className='mOver'>
          <div onClick={handleDisableClick}>
            <div className='mBox' style={{ background: '#fff', opacity: '0.4' }}>
              <img src={imgUrl} alt='LanguageType' width='120' />
            </div>
          </div>
        </div>
      )}
      {!disable && (
        <div className='mOver'>
          <div onClick={() => handleCheckExamIsExist(languageId)}>
            <div className='mBox'>
              <img src={imgUrl} alt='LanguageType' width='120' />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LanguageCard;

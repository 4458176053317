import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Header } from '../../widgets';
import { Layout } from '../../layouts';
import { useCustomState } from 'state/state';
import styles from './Sitemap.module.scss';

const SiteMap = () => {
  const location = useLocation();
  const state = useCustomState()[0];

  const path = location.pathname;
  const service = state.data.footerMenu.find((s) => s.url === path);

  return (
    <Fragment>
      <Header>{service.title}</Header>
      <Layout col='3' padding mode='light' style={{ background: 'rgb(255,255,255)' }}>
        {state.data.sitemap.map((item, index) => (
          <div className={styles.wrapper} key={index}>
            <div className={styles.step}>
              <p className={`${styles.number} ${styles.wrapper} secosub1 secoblue`}>{item.title}</p>
              <div className={styles.info}>
                {item.subMenu.map((list, idx) => (
                  <Link to={list.link} key={idx}>
                    <p className='secosub2'>{list.title}</p>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        ))}
        <div className={`layout-footer ${styles.footer}`}></div>

        {state.data.sitemap2.map((item, index) => (
          <div className={styles.wrapper} key={index}>
            <div className={styles.step}>
              <p className={`${styles.number} ${styles.wrapper} secosub1 secoblue`}>{item.title}</p>
              <div className={styles.info}>
                {item.subMenu.map((list, idx) => (
                  <Link to={list.link} key={idx}>
                    <p className='secosub2'>{list.title}</p>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        ))}
        <div className={`layout-footer ${styles.footer}`}></div>
      </Layout>
    </Fragment>
  );
};

export default SiteMap;

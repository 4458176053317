import { InputBase } from '@mui/material';
import { withStyles } from '@mui/styles';

const BootstrapInput = withStyles(() => ({
  root: {
    'label + &': {
      marginTop: 3,
    },
  },
  input: {
    marginLeft: '3px',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    fontSize: '15.5px',
    letterSpacing: '-0.175px',
    padding: '10px 26px 10px 12px',
    transition: '0.2s',
    fontFamily: [
      'Noto Sans KR',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    '&::placeholder': {
      fontSize: 14,
    },
  },
}))(InputBase);

export default BootstrapInput;

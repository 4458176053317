import { api } from './config';

/* 오퍼레이터가 소속된 그룹 리스트 조회 */
export const fetchOperatorGroups = async () => {
  return await api.get(`/group/operator/coursesList`);
};

/* 허용된 코스 리스트 조회 */
export const fetchGroupAllowedCourse = async (groupId) => {
  return await api.get(`/group/operator/${groupId}/course`);
};

/* 그룹에 소속된 유저 상태 조회 */
export const fetchGroupUserStatus = async (groupId, selectedCourseId) => {
  return await api.get(`/group/operator/usersList/${groupId}/${selectedCourseId}`);
};

/* 그룹 시험 상태 조회 */
export const fetchGroupExamStatus = async (groupId, selectedCourseId) => {
  return await api.get(`/group/operator/exam/${groupId}/${selectedCourseId}`);
};

/* 그룹 시험 상태 변경 */
// export const changeGroupExamStatus = async ({ examId, examActive }) => {
//   return await api.patch(`/group/operator/exam`, {
//     examId,
//     examActive,
//   });
// };

/* 그룹 시험 정보 조회 */
export const fetchGroupExamSubject = async ({ groupId }) => {
  return await api.get(`/group/operator/exam/subject/${groupId}`);
};

/* 역량평가 시험 결과 */
export const fetchExamResult = async ({ groupId, languageId }) => {
  return await api.get(`/group/operator/exam-group-result/${groupId}/${languageId}`);
};

/* 유저 개인 역량평가 결과 조회 */
export const fetchUserExamResult = async ({ userId }) => {
  return await api.get(`/group/operator/exam-result/${userId}`);
};

/* 유저 개인 역량평가 상세 결과 조회 */
export const fetchUserExamDetailResult = async ({ userId }) => {
  return await api.get(`/group/operator/exam-result/detail/${userId}`);
};

/* 문제별 결과 조회 */
export const fetchExamCategoryResult = async ({ languageId, groupId }) => {
  return await api.get(`/group/operator/exam/analysis/${languageId}/${groupId}`);
};

/* 유저가 소속된 그룹 정보 조회 */
export const fetchUserGroupInfo = async ({ userId, groupId }) => {
  return await api.get(`/group/operator/userDetail/${userId}/${groupId}`);
};

/* 운영자 역량평가 모니터링 */
export const fetchExamProgress = async ({ groupId, languageId, examOrder }) => {
  return await api.get(`/group/operator/${groupId}/exam-progress/${languageId}/${examOrder}`);
};

/* [오퍼레이터 역량평가 개인 보고서]교육생 시험 보고서 조회용 응시자의 결과 상세 조회 */
export const fetchPersonalResultInfo = async ({ userId, languageId }) => {
  return await api.get(`/group/operator/report/${userId}/languages/${languageId}/info`);
};

/* [오퍼레이터 역량평가 개인 보고서]응시자의 응시 결과 상세 */
export const fetchPersonalResultAverage = async ({ userId, languageId }) => {
  return await api.get(`/group/operator/report/${userId}/languages/${languageId}/group-avg`);
};

/* [오퍼레이터 역량평가 개인 보고서]응시자의 정오표 조회 */
export const fetchPersonalResultCorrect = async ({ userId, languageId }) => {
  return await api.get(`/group/operator/report/${userId}/languages/${languageId}/problems`);
};

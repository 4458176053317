import { api } from './config';

/* 자료실 게시글 리스트 조회 */
export const fetchLibraryList = async () => {
  return await api.get(`/users/library`);
};

/* 자료실 게시글 상세 조회 */
export const fetchLibraryDetail = async (id) => {
  return await api.get(`/users/library/${id}`);
};

/* FAQ 리스트 조회 */
export const fetchFaqList = async () => {
  return await api.get(`/users/faq`);
};

/* FAQ 게시글 상세 조회 */
export const fetchFaqDetail = async (id) => {
  return await api.get(`/users/faq/${id}`);
};

/* 사용자 후기 게시글 리스트 조회 */
export const fetchReviewList = async () => {
  return await api.get(`/users/review`);
};

/* 사용자 후기 게시글 상세 조회 */
export const fetchReviewDetail = async (id) => {
  return await api.get(`/users/review/${id}`);
};

/* [관리자]유저 확인 */
export const adminConfirmUser = async ({ data }) => {
  return await api.put(`/admin/users/confirm`, {
    data,
  });
};

/* 문의하기 게시글 등록 */
export const submitContactForm = async ({ postCategoryId, postTitle, postContent, postAuthor, userId }) => {
  return await api.post(
    `/common/question`,
    {
      postCategoryId,
      postTitle,
      postContent,
      postAuthor,
      userId,
    },
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }
  );
};

/* 플랫폼 리스트 조회 */
export const fetchPlatformList = async () => {
  return await api.get(`/common/question/platform`);
};

/* 사용자 게시판 카테고리명 조회 */
export const fetchCategoryName = async (categoryType) => {
  return await api.get(`/common/question/postCategory/${categoryType}`);
};

/* 사용자 후기 등록 */
export const createUserReview = async ({ postTitle, postContent, postAuthor, userId }) => {
  return await api.post(`/users/review`, { postTitle, postContent, postAuthor, userId });
};

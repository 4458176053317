import { useEffect, useState } from 'react';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';

import { fetchFaqData, fetchLanguageData, fetchLearningRate } from 'api/common';

/* 언어 정보 조회 */
export const useFetchLanguages = () => {
  const history = useHistory();

  const [languages, setLanguages] = useState([]);
  const [myCourseId, setMyCourseId] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const languageResponse = await fetchLanguageData();
        const { data } = languageResponse.data;
        setLanguages(data.language);
        setMyCourseId(data.myLanguage);
      } catch (error) {
        if (error.response !== undefined) {
          history.push('/');
        }
      }
    })();
  }, []);

  return { languages, myCourseId };
};

/* JS 파일 HASH 값 읽어서 새로운 버전인지 체크 */
export const useVersionCheck = () => {
  return useQuery(
    ['latest-hash'],
    async () => {
      const response = await fetch('/latest-hash.txt');

      return response.text();
    },
    {
      retry: 3,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 3000),
      refetchInterval: 60 * 1000 * 30,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
    }
  );
};

/* 홈 메인화면에서 사용하는 정보들 동시 조회 */
export const useFetchHomeData = () => {
  const queryReturns = useQueries({
    queries: [
      { queryKey: ['fetchFaqData'], queryFn: fetchFaqData },
      { queryKey: ['fetchLearningRate'], queryFn: fetchLearningRate },
    ],
  });

  const faqData = queryReturns[0].data;
  const rateData = queryReturns[1].data;

  return { faqData, rateData };
};

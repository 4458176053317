import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

import Layout from '../../layouts/Layout/Layout';
import THEME from 'state/theme';
import styles from './PortfolioCourse.module.scss';
import { infoToast, warningToast } from 'utilities/toast';
import { MESSAGE } from 'utilities';
import { useCreateUserVideoTrain } from 'hooks/student.hook';
import { fetchTrainVideoList } from 'api/student';

const PortfolioCourseInst = ({ data = [], currentCourseId = [], midibusCategory = [], accessibleCourseId = [] }) => {
  let [amount] = useState(4);
  let [isLoading, setIsLoading] = useState(false);

  const isAllowedCourse = (courseId) => !!currentCourseId.includes(courseId);
  const isExamAccess = (courseId) => !!accessibleCourseId.includes(courseId);

  const mutation = useCreateUserVideoTrain();

  const isVideoTrain = async (ctype, cid, courseName) => {
    if (isLoading) return;
    if (isAllowedCourse(cid) && !isExamAccess(cid)) return;

    setIsLoading(true);
    const { data } = await fetchTrainVideoList({
      courseId: cid,
      courseType: ctype,
    });
    if (data.data.videoTrainList.length === 0) {
      infoToast(`${courseName} 강의를 수강합니다.`, 2000);

      await mutation.mutate({ courseId: cid });

      setIsLoading(false);
    }
  };

  const getCategoryId = (courseName) => {
    if (midibusCategory.length === 0) return;

    const findCategoty = midibusCategory.find((category) => category.category_name === courseName.replaceAll(' ', ''));

    if (findCategoty) return findCategoty.category_id;
    if (!findCategoty) return null;
  };

  const handleBeforeApply = () => {
    warningToast(MESSAGE.TOAST_NOT_ALLOWED_COURSE);
  };

  const handleBeforeExam = () => {
    warningToast('사전 평가 응시 후 수강이 가능합니다.');
  };

  const portfolioList = data
    ?.map((item, index) => (
      <div key={index} className={styles.wrapper}>
        <div
          className={styles.image}
          style={{
            background: `url("/../../../../courseimage/${item.courseImage}") center/cover`,
            borderColor: THEME.color,
          }}
        >
          {(item.courseMainYn === '2' || item.courseMainYn === '1') && isAllowedCourse(item.courseId) ? (
            <Link
              to={
                isAllowedCourse(item.courseId) &&
                (isExamAccess(item.courseId) || item.courseId === 1 || item.courseId === 11)
                  ? `/student/coursedetail/${item.courseType}/${item.courseId}/${item.instId}/${getCategoryId(
                      item.courseName
                    )}`
                  : '#'
              }
              onClick={() => isVideoTrain(item.courseType, item.courseId, item.courseName)}
            >
              <div className={styles.button}>
                <h3 className='secomin'>과정&nbsp;:&nbsp;총&nbsp;{item.courseCount}강</h3>
                <p className='secomin'>{item.courseObj}</p>
                {isExamAccess(item.courseId) || item.courseId === 1 || item.courseId === 11 ? (
                  <Button variant='contained' color='secondary'>
                    <span className='secomin'>
                      수강하기&nbsp;<i className='fas fa-angle-right'></i>
                    </span>
                  </Button>
                ) : (
                  <Button
                    onClick={handleBeforeExam}
                    variant='contained'
                    color='secondary'
                    style={{ backgroundColor: '#f5bb28c0', color: '#fff' }}
                  >
                    <span className='secomin'>사전평가 미응시</span>
                  </Button>
                )}
              </div>
            </Link>
          ) : item.courseMainYn === '2' || item.courseMainYn === '1' ? (
            <div className={styles.button} onClick={handleBeforeApply}>
              <h3 className='secomin'>과정&nbsp;:&nbsp;총&nbsp;{item.courseCount}강</h3>
              <p className='secomin'>{item.courseObj}</p>
              <Button disabled variant='contained' style={{ backgroundColor: '#808080', color: '#fff' }}>
                <span className='secomin'>신청 후 수강하기</span>
              </Button>
            </div>
          ) : (
            <div className={styles.button}>
              <h3 className='secomin'>과정&nbsp;:&nbsp;총&nbsp;{item.courseCount}강</h3>
              <p className='secomin'>{item.courseObj}</p>
              <Button disabled variant='contained' style={{ backgroundColor: '#808080', color: '#fff' }}>
                <span className='secomin'>오픈 예정</span>
              </Button>
            </div>
          )}
        </div>

        <div className={styles.info}>
          {item.courseType === '1' ? (
            <span className='secotext' style={{ color: THEME.color }}>
              소프트웨어 개발보안 교육
            </span>
          ) : item.courseType === '2' ? (
            <span className='secotext' style={{ color: THEME.color }}>
              정보보안 교육
            </span>
          ) : item.courseType === '3' ? (
            <span className='secotext' style={{ color: THEME.color }}>
              프로그래밍 기술 교육
            </span>
          ) : (
            ''
          )}
          <h3 className='secosub2'>
            {item.courseMainYn === '2' || item.courseMainYn === '1' ? (
              <Link
                to={`/student/coursedetail/${item.courseType}/${item.courseId}/${item.instId}/${getCategoryId(
                  item.courseName
                )}`}
                onClick={function () {
                  isVideoTrain(item.courseType, item.courseId, item.courseName);
                }}
              >
                {item.courseName}
              </Link>
            ) : (
              <div style={{ pointerEvents: 'none' }}>{item.courseName}</div>
            )}
          </h3>

          <p className='secomin'>강사 : {item.courseInst} </p>
        </div>
      </div>
    ))
    .slice(0, amount);

  return (
    <Layout col='1'>
      <div
        className={styles.portfolio}
        style={{
          borderColor: THEME.color,
        }}
      >
        {portfolioList}
      </div>
    </Layout>
  );
};

export default PortfolioCourseInst;

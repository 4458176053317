import { TableCell } from '@mui/material';
import { withStyles } from '@mui/styles';

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#757575',
    color: '#fff',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default StyledTableCell;

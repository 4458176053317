import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from '@mui/material';

import Button from '../../elements/Button/Button';
import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import { fetchMyQuestionDetail } from 'api/mypage';
import { errorToast } from 'utilities/toast';
import './detailStyle.css';

const MyQandADetail = () => {
  const { id } = useParams();
  const history = useHistory();

  const [list, setList] = useState([]);

  useRedirectWithoutAuth();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchMyQuestionDetail({ id });
        setList(response.data);
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.status === 403) {
            errorToast('토큰이 만료되었습니다. 다시 로그인해주세요 :)');
            history.push('/');
          }
        }
      }
    })();
  }, []);

  const handleClick = async (id) => {
    try {
      const response = await fetchMyQuestionDetail({ id });
      setList(response.data);
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status === 403) {
          errorToast('토큰이 만료되었습니다. 다시 로그인해주세요 :)');
          history.push('/');
        }
      }
    }
  };

  return (
    <div>
      <div className='row'>
        <div style={{ marginBottom: '2vh', marginLeft: '25px', width: '200px' }}>
          <Button roundSmall to='/question' type='outline-color-cl' hoverType='solid-color-tb'>
            <span className='secotext'>
              나의 문의 목록<i className='las la-file-code'></i>
            </span>
          </Button>
        </div>
      </div>
      <Container>
        <div className='row detailBox'>
          <div className='col-md-2 detailLeft secotext'>제목</div>
          <div className='col-md-10 detailRight secotext'>{list.postTitle}</div>
        </div>
        <div className='row detailBox'>
          <div className='col-md-2 detailLeft secotext'>등록일자</div>
          <div className='col-md-4 detailRight secotext'>{list.postRegDate}</div>
          <div className='col-md-2 detailLeft secotext'>조회수</div>
          <div className='col-md-4 detailRight secotext'>{list.postHitCount}</div>
        </div>
        <div className='row detailBox'>
          <div className='col-md-2 detailLeft secotext'>작성자</div>
          <div className='col-md-10 detailRight secotext'>{list.postAuthor}</div>
        </div>
        <div className='row detailBox'>
          <div className='col-md-2 detailLeft secotext'>대분류</div>
          <div className='col-md-4 detailRight secotext'>{list.postCategoryId}</div>
          <div className='col-md-2 detailLeft secotext'>소분류</div>
          <div className='col-md-4 detailRight secotext'>{list.postCategoryName}</div>
        </div>
        <div className='row detailcontentBox' style={{ backgroundColor: 'rgba(76,137,229,0.08' }}>
          <div className='col-md-2 detailLeft secotext'>문의 내용</div>
          <div className='col-md-10 detailRight secotext'>{list.postContent}</div>
        </div>
        <div
          className='row detailcontentBox'
          style={{ marginBottom: '50px', backgroundColor: 'rgba(0,0,0,0.5', borderRadius: '0 0 5px 5px' }}
        >
          <div className='col-md-2 detailLeft secotext' style={{ color: 'white' }}>
            답변 내용
          </div>
          <div className='col-md-10 detailRight secotext' style={{ color: 'white' }}>
            {list.replyContent}
          </div>
        </div>
      </Container>
      <div className='row'>
        <div style={{ marginBottom: '2vh', marginLeft: '25px', width: '150px' }}>
          {list.prevId > 0 ? (
            <Button
              roundSmall
              click={() => handleClick(list.prevId)}
              type='outline-color-cl'
              hoverType='solid-color-tb'
            >
              <i className='las la-angle-left'></i>&nbsp;&nbsp;이전글
            </Button>
          ) : (
            <Button roundSmall disabled type='outline-color-cl'>
              <i className='las la-angle-left'></i>&nbsp;&nbsp;이전글
            </Button>
          )}
        </div>
        <div style={{ marginBottom: '2vh', marginLeft: '25px', width: '150px' }}>
          {list.nextId > 0 ? (
            <Button
              roundSmall
              click={() => handleClick(list.nextId)}
              type='outline-color-cl'
              hoverType='solid-color-tb'
            >
              다음글&nbsp;&nbsp;<i className='las la-angle-right'></i>
            </Button>
          ) : (
            <Button roundSmall disabled type='outline-color-cl'>
              다음글&nbsp;&nbsp;<i className='las la-angle-right'></i>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyQandADetail;

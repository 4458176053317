import React from 'react';

import { Layout } from '../../layouts';
import { Button } from '../../elements';
import styles from './Process.module.scss';

const Process = ({ data = [] }) => {
  const processList = data?.map((item, index) => (
    <div key={index} className={styles.wrapper}>
      <Card data={item} index={index} />
    </div>
  ));
  
  return (
    <Layout
      col='5'
      padding
      mode='light'
      style={{
        paddingTop: '5rem',
        paddingBottom: '1rem',
        background: 'rgb(255,255,255)',
        minHeight: '50vh',
      }}
    >
      {processList}
      <div className={`layout-footer ${styles.footer}`}></div>
    </Layout>
  );
};

const Card = ({ data, index }) => {
  return (
    <div className={styles.step}>
      <span className={styles.number}>{index + 1}.</span>
      <div className={styles.info}>
        <h4 className='secosub1'>{data.title}</h4>
        <p className='secotext'>{data.text}</p>
      </div>
      <ul className={styles.list}></ul>
      <div className={styles.btn}>
        <Button hoverType='solid-gray-tb' round after='&#xf101;' />
      </div>
    </div>
  );
};

export default Process;

import React, { useState } from 'react';
import CodeTable from './CodeTable';

import { Accordion, AccordionSummary, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMore from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '96%',
    height: 'auto',
    marginLeft: '3%',
    marginRight: '1%',
    marginTop: '3%',
  },
  heading: {
    flexBasis: '33.33%',
    flexShrink: 0,
    color: 'white',
  },
  heading1: {
    position: 'relative',
    top: '20%',
    fontSize: '16px',
    flexBasis: '33.33%',
    flexShrink: 0,
    color: 'white',
  },
  secondaryHeading: {
    fontSize: '25px',
    flexBasis: '33.33%',
    flexShrink: 0,
    color: 'white',
  },
  accDisable: {
    opacity: 0.6,
    cusor: 'not-allowed',
  },
}));

const DescAccordions = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState('panel1');
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Accordion
        style={{ backgroundColor: '#252526', boxShadow: '0px 0px 0px 0px black' }}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMore style={{ color: 'white' }} />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Typography className={classes.heading}>
            <span className='secotext secowhite'>
              <i className='far fa-file-code'></i> &nbsp;문제 선택 &nbsp;{' '}
            </span>
          </Typography>
        </AccordionSummary>
        <CodeTable
          secId={props.secId}
          problemNumber={props.problemNumber}
          handleSelectProblem={props.handleSelectProblem}
          lists={props.lists}
        />
      </Accordion>
    </div>
  );
};

export default DescAccordions;

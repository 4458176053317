import React from 'react';
import styled from 'styled-components';

const NoResult = () => {
  return (
    <Box>
      <span>검색되는 결과가 없습니다.</span>
    </Box>
  );
};

const Box = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 280px;

  span {
    font-size: 18px;
    font-weight: bold;
    color: #2e94d2;
  }
`;

export default NoResult;

import React, { useState, Fragment, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  Select,
  MenuItem,
} from '@mui/material';

import { fetchLibraryList } from 'api/customer';
import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import { MESSAGE } from 'utilities';
import { BootstrapInput } from 'style/customerPageStyle';
import { AuthAtom } from 'state';
import { errorToast } from 'utilities/toast';
import { FormWrapper, StyledTableRow, TableRowHover } from '../CustomerCommon';

const CustomerLibrary = ({ history }) => {
  const auth = useRecoilValue(AuthAtom);

  // 로그인 정보 없을 시 홈으로 리다이렉트
  useRedirectWithoutAuth();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [list, setList] = useState([]);
  const [searchedData, setSearched] = useState([]);
  const [search, setSearch] = useState({
    selected: 'postTitle',
    title: '제목',
    content: '',
  });

  const [selected] = useState([]);

  const USER_STATE_MAP = {
    대기: 0,
    승인: 1,
    거절: 2,
  };

  const searchByKeyword = (dataList, column, keyword) => {
    // 'state' 필드의 경우 특별한 처리가 필요합니다.
    if (column === 'state') {
      const userState = USER_STATE_MAP[keyword];
      if (userState !== undefined) {
        return dataList.filter((data) => {
          return Number(data.isOauth) === Number(userState) && data.type !== 0;
        });
      } else {
        return dataList;
      }
    } else {
      return dataList.filter((data) => {
        return data[column].indexOf(keyword) !== -1;
      });
    }
  };

  const selectChange = (e) => {
    let selectedColumn = '';
    let title = '';

    if (e.target.value === 'postTitle') {
      selectedColumn = e.target.value;
      title = '제목';
    } else if (e.target.value === 'postCategoryName') {
      selectedColumn = e.target.value;
      title = '카테고리';
    }

    setSearch({ ...search, selected: selectedColumn, title });

    // Use the keyword search function
    setSearched(searchByKeyword(list, selectedColumn, search.content));
  };

  const handleChangeSearch = (e) => {
    const content = e.target.value;

    setSearched(searchByKeyword(list, search.selected, content));

    setSearch({ ...search, content });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    if (!auth.auth) return;

    (async () => {
      try {
        const response = await fetchLibraryList();
        setList(response.data);
        setSearched(response.data);
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.status === 401 || error.response.status === 403) {
            errorToast(MESSAGE.SERVICE_INVALID_TOKEN);
          }
          history.push('/');
        }
      }
    })();
  }, []);

  const handleGoToDetail = (id) => history.push(`/customer/library/${id}`);

  return (
    <Fragment>
      <FormWrapper>
        <Select variant='filled' id='select' value={search.selected} onChange={selectChange} input={<BootstrapInput />}>
          <MenuItem className='secotext' value='postTitle'>
            제목
          </MenuItem>
          <MenuItem className='secotext' value='postCategoryName'>
            카테고리
          </MenuItem>
        </Select>

        <BootstrapInput
          placeholder={`${search.title}으로 검색하기`}
          name='content'
          value={search.content}
          onChange={handleChangeSearch}
          style={{ width: '300px' }}
        />
      </FormWrapper>

      <div className='mypageContents'>
        <TableContainer component={Paper}>
          <Table>
            <TableHead rowcount={searchedData.length}>
              <StyledTableRow>
                <TableCell width='5vw'>
                  <span className='secotext'>No.</span>
                </TableCell>
                <TableCell align='center'>
                  <span className='secotext'>제목</span>
                </TableCell>
                <TableCell align='center'>
                  <span className='secotext'>대분류</span>
                </TableCell>
                <TableCell align='center'>
                  <span className='secotext'>소분류</span>
                </TableCell>
                <TableCell align='center'>
                  <span className='secotext'>작성자</span>
                </TableCell>
                <TableCell align='center'>
                  <span className='secotext'>등록일</span>
                </TableCell>
                <TableCell align='center'>
                  <span className='secotext'>조회수</span>
                </TableCell>
              </StyledTableRow>
            </TableHead>

            <TableBody>
              {searchedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                const isItemSelected = isSelected(row.postId);
                return (
                  <TableRowHover
                    onClick={() => handleGoToDetail(row.postId)}
                    key={index}
                    hover
                    tabIndex={-1}
                    selected={isItemSelected}
                  >
                    <TableCell>
                      <span className='secotext'>{page * rowsPerPage + index + 1}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span className='secotext'>{row.postTitle}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span className='secotext'>{row.postCategoryType}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span className='secotext'>{row.postCategoryName}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span className='secotext'>{row.postAuthor}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span className='secotext'>{row.postRegDate}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span className='secotext'>{row.postHitCount}</span>
                    </TableCell>
                  </TableRowHover>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component='div'
        count={searchedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Fragment>
  );
};

export default CustomerLibrary;

import React from 'react';
import { Link } from 'react-router-dom';
import { TableCell, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#000',
    color: '#fff',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#eee',
    },
  },
}))(TableRow);

const MyQandAList = (props) => {
  return (
    <StyledTableRow key={props.qandalist.no}>
      <StyledTableCell component='th' scope='row'>
        <span className='secotext'>{props.no + 1}</span>
      </StyledTableCell>
      <StyledTableCell align='center' style={{ cursor: 'pointer' }}>
        <span className='secotext'>
          <Link to={`/question/${props.qandalist.postId}`}>{props.qandalist.postTitle}</Link>
        </span>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <span className='secotext'>{props.qandalist.postCategoryType}</span>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <span className='secotext'>{props.qandalist.postCategoryName}</span>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <span className='secotext'>{props.qandalist.postAuthor}</span>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <span className='secotext'>{props.qandalist.postRegDate}</span>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <span className='secotext'>{props.qandalist.postHitCount}</span>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <span className='secotext'>{props.qandalist.replyYn}</span>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default MyQandAList;

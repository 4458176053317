import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useFetchProblem, useRedirectWithoutAuth } from 'hooks';
import TestShortAnswer from './TestShortAnswer';
import TestChoice from './TestChoice';
import TestMultipleChoices from './TestMultipleChoices';
import TestOx from './TestOx';

const TestQuestion = ({ saveAnswerHandler, idx, problemId, onClickEnterKey, nowQNum, questionList }) => {
  const [answer, setAnswer] = useState();

  useRedirectWithoutAuth();

  const setCheckAnswer = (param) => {
    if (param !== '') {
      setAnswer(param);
    }
    if (answer === param && idx !== problemId) {
      saveAnswerHandler(answer);
    }
  };

  const handleClickEnterKey = () => {
    onClickEnterKey();
  };

  const { data: testQuestions } = useFetchProblem({ problemId });

  useEffect(() => {
    saveAnswerHandler(answer);
  }, [answer]);

  useEffect(() => {
    const handleBlockCopy = (event) => {
      const code = event.keyCode;
      let charCode = String.fromCharCode(code).toUpperCase();

      if ((event.ctrlKey || event.metaKey) && charCode === 'C') {
        event.preventDefault();
      }
    };

    const handleBlockRightClick = (e) => {
      e.preventDefault();
    };

    window.addEventListener('keydown', handleBlockCopy);
    window.addEventListener('contextmenu', handleBlockRightClick);

    return () => {
      window.removeEventListener('keydown', handleBlockCopy);
      window.removeEventListener('contextmenu', handleBlockRightClick);
    };
  }, []);

  const formatDifficulty = {
    HIGH: '상',
    MEDIUM: '중',
    LOW: '하',
  };

  const difficultyColor = {
    HIGH: '#fc5656',
    MEDIUM: '#ffa54e',
    LOW: '#486ffd',
  };

  return (
    <div className='questions'>
      <Category>
        <RiskBadge backColor={difficultyColor[testQuestions?.riskLevel]}>
          보안 위험도 : {formatDifficulty[testQuestions?.riskLevel]}
        </RiskBadge>
      </Category>
      <p>문제 {nowQNum}.</p>
      <div className='capaQuestion'>
        <pre className='examQuestion'>
          {testQuestions?.question &&
            testQuestions?.question
              .replaceAll(`\"\"`, `\"`)
              .split('\\$')
              ?.map((line, index) => {
                return (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                );
              })}
        </pre>
      </div>
      {testQuestions?.questionContent && (
        <pre className='capaQuestionContent'>
          {testQuestions?.questionContent
            .replaceAll(`\"\"`, `\"`)
            .split('\\$')
            ?.map((line, index) => {
              return (
                <span key={index}>
                  {line}
                  <br />
                </span>
              );
            })}
        </pre>
      )}
      {testQuestions?.problemImgPath1 && (
        <div className='capaTestImage1'>
          <img
            src={`${process.env.REACT_APP_IP}/examimage/${testQuestions?.examProblemId}/${testQuestions?.examImgPath1}`}
            alt='문제이미지1'
          />
        </div>
      )}
      {testQuestions?.problemImgPath2 && (
        <div className='capaTestImage2'>
          <img
            src={`${process.env.REACT_APP_IP}/examimage/${testQuestions?.examProblemId}/${testQuestions?.examImgPath2}`}
            alt='문제이미지2'
          />
        </div>
      )}
      <div className='capaTestAnswer'>
        {(() => {
          switch (testQuestions?.problemType) {
            case 0:
              return (testQuestions?.problemOptions || []).every((e) => e.length <= 0) ? (
                <TestOx
                  questionList={questionList}
                  setCheckAnswer={setCheckAnswer}
                  languageName={testQuestions?.language}
                  idx={idx}
                />
              ) : (
                <TestChoice
                  setCheckAnswer={setCheckAnswer}
                  languageName={testQuestions?.language}
                  idx={idx}
                  testOptions={testQuestions?.problemOptions}
                  questionList={questionList}
                />
              );
            case 1:
              return (
                <TestMultipleChoices
                  setCheckAnswer={setCheckAnswer}
                  idx={idx}
                  testOptions={testQuestions?.problemOptions}
                  languageName={testQuestions?.language}
                  questionList={questionList}
                />
              );
            case 2:
              return (
                <TestOx
                  setCheckAnswer={setCheckAnswer}
                  questionList={questionList || []}
                  languageName={testQuestions?.language}
                  idx={idx}
                />
              );
            case 3:
              return (
                <TestShortAnswer
                  setCheckAnswer={setCheckAnswer}
                  idx={idx}
                  handleNextButton={handleClickEnterKey}
                  answerCount={testQuestions?.numberOfSubjectiveAnswers}
                  languageName={testQuestions?.language}
                  questionList={questionList}
                />
              );
          }
        })()}
      </div>
    </div>
  );
};

const Category = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: -10px;
  padding-bottom: 5px;
`;

const RiskBadge = styled.span`
  padding: 3px 7px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  background-color: ${(p) => p.backColor};
  text-wrap: nowrap;
`;

export default TestQuestion;

import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import CustomerLayout from './CustomerLayout';
import CustomerLibrary from './CustomerLibrary/index';
import CustomerLibraryDetail from './CustomerLibrary/Detail';
import CustomerFaq from './FaqList/index';
import FaqDetail from './FaqList/Detail';
import CustomerReview from './ReviewList/index';
import ReviewDetail from './ReviewList/Detail';
import { ContactForm, ReviewForm } from '../../widgets';

const Customer = () => {
  const match = useRouteMatch();

  return (
    <CustomerLayout>
      <Switch>
        <Route exact path={`${match.path}`}>
          <Redirect to={`${match.path}/library`} />
        </Route>

        {/* 자료실 */}
        <Route exact path={`${match.url}/library`} component={CustomerLibrary} />
        <Route exact path={`${match.url}/library/:id`} component={CustomerLibraryDetail} />

        {/* FAQ */}
        <Route exact path={`${match.url}/faq`} component={CustomerFaq} />
        <Route exact path={`${match.url}/faq/:id`} component={FaqDetail} />

        {/* 사용자후기 */}
        <Route exact path={`${match.url}/review`} component={CustomerReview} />
        <Route exact path={`${match.url}/review/:id`} component={ReviewDetail} />
        <Route exact path={`${match.url}/review-add`} component={ReviewForm} />

        {/* 문의하기 */}
        <Route exact path={`${match.url}/contact`} component={ContactForm} />
      </Switch>
    </CustomerLayout>
  );
};

export default Customer;

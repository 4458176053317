/* eslint-disable */

import React from 'react';

import TestInfo from './TestInfo';
import { Header } from '../../../../components/widgets';

export default function CapabilityTest({ languageId }) {
  return (
    <>
      <Header>역량평가</Header>
      <TestInfo languageId={languageId} />
    </>
  );
}

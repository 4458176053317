import { useQuery } from '@tanstack/react-query';

import { fetchTestQuestion } from 'api/capability';

const useFetchProblem = ({ problemId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchTestQuestion', { problemId }],
    queryFn: async () => await fetchTestQuestion({ problemId }),
    select: (res) => res.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!problemId,
    keepPreviousData: true,
  });

  return { ...queryReturns };
};

export default useFetchProblem;

import React from 'react';
import { Link } from 'react-router-dom';

import THEME from '../../../state/theme';
import styles from './Logo.module.scss';

const Logo = ({ dark }) => (
  <figure className={`${styles.logo} ${dark ? styles.dark : styles.light}`} style={{ borderColor: THEME.color }}>
    <Link className='navbar-brand' to='/'>
      <img src='/logo-secolab.png' alt='SECOLAB' style={{ width: '150px' }} />
    </Link>
  </figure>
);

export default Logo;

import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import './MypageMyinformation.css';
import AccountEdit from './AccountEdit';
import AccountPasswordChange from './AccountPasswordChange';
import AccountExit from './AccountExit';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
};

const useStyles = makeStyles(() => ({
  form: {
    padding: '0px',
    display: 'flex',
    justifyContent: 'center',
  },
  tab: {
    backgroundColor: 'white',
    borderRaidus: '20px',
    fontWeight: '900',
    color: 'gray',
  },
  tabBtn: {
    width: '33.33% !important',
  },
  tF: {
    marginLeft: '0px !important',
  },
  indicator: {
    backgroundColor: '#0069d9',
  },
  button: {
    color: 'white',
    fontSize: '1.2rem',
    margin: '3px 0 2px',
    backgroundColor: '#343a40',
    cursor: 'pointer',
    border: 'thin solid #343a40 !important',
  },
}));

const InfoEdit = () => {
  useRedirectWithoutAuth();

  const classes = useStyles();
  const [value, setValue] = useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      <AppBar position='static'>
        <Tabs
          className={classes.tab}
          classes={{ indicator: classes.indicator }}
          value={value}
          onChange={handleChange}
          centered
        >
          <Tab
            className={classes.tabBtn}
            value='one'
            label={<span className='secosub2'>회원정보 수정하기</span>}
            wrapped
            fullWidth
            {...a11yProps('one')}
          />
          <Tab
            className={classes.tabBtn}
            value='two'
            label={<span className='secosub2'>비밀번호 변경하기</span>}
            wrapped
            fullWidth
            {...a11yProps('two')}
          />
          <Tab
            className={classes.tabBtn}
            value='three'
            label={<span className='secosub2'>탈퇴하기</span>}
            wrapped
            fullWidth
            {...a11yProps('three')}
          />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index='one'>
        <AccountEdit useStyles={useStyles} />
      </TabPanel>

      <TabPanel value={value} index='two'>
        <AccountPasswordChange useStyles={useStyles} />
      </TabPanel>

      <TabPanel value={value} index='three'>
        <AccountExit useStyles={useStyles} />
      </TabPanel>
    </Fragment>
  );
};

export default InfoEdit;

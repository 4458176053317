import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { TablePagination } from '@mui/material';

import { secondToClock } from '../../../../utilities/number';

const ExamUserTable = ({ userExamResult }) => {
  const history = useHistory();
  const location = useLocation();
  const languageId = location.pathname.split('/').at(-1) ?? location.state.languageId;
  const { groupId } = location.state;

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [searchName, setSearchName] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [userData, setUserData] = useState([]);
  const [groupTargetScore] = useState(80);

  const handleChangeSearchName = (e) => {
    const { value } = e.target;

    setUserData(userExamResult.filter((user) => user.userName.indexOf(value) !== -1));
    setSearchName(value);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const clickToMyPage = () => {
    history.push('/operator');
  };

  const handleGoToCategoryResult = () => {
    history.push({
      pathname: `/operator/category-result/${languageId}`,
      state: {
        groupId: groupId,
        languageId,
      },
    });
  };

  const goToGroupTestResult = (userId) => {
    history.push(`/operator/personal-result/${userId}`);
  };

  useEffect(() => {
    if (searchName) return;

    if (!searchName) {
      setUserData(userExamResult);
      return;
    }

    setUserData(userExamResult);
  }, [userExamResult, searchName]);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <Container>
      <InputBox>
        <div>
          <button onClick={handleGoToCategoryResult} className='toAnalysisBtn'>
            <span className='secotext'>
              <i className='fas fa-chart-bar' />
              카테고리별 정답률
            </span>
          </button>
          <button onClick={clickToMyPage} className='toMyPageBtn'>
            <span className='secotext'>
              <i className='fas fa-list' />
              다른 그룹 보기
            </span>
          </button>
        </div>

        <div>
          <input
            placeholder='이름으로 검색하기'
            name='content'
            value={searchName}
            onChange={handleChangeSearchName}
            style={{ width: '300px' }}
          />
        </div>
      </InputBox>

      <TableBox>
        <StyledTable>
          <thead>
            <tr>
              <th>No.</th>
              <th>이름</th>
              <th>분류</th>
              <th>목표 점수</th>
              <th>그룹 평균</th>
              <th>소요 시간</th>
              <th>개인 점수</th>
              <th>석차</th>
            </tr>
          </thead>

          <tbody>
            {userData?.slice(page * pageSize, page * pageSize + pageSize)?.map((e, index) => (
              <Fragment key={index}>
                <tr
                  className={`separator ${hoveredIndex === index ? 'hovered' : ''}`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => goToGroupTestResult(e.userId)}
                >
                  <td rowSpan='2'>{index + 1}</td>
                  <td rowSpan='2'>
                    <span>
                      {e.userName}
                      {e.userStatus !== 'ACTIVE' && (
                        <b className='exit'>
                          <br />
                          (퇴소)
                        </b>
                      )}
                    </span>
                  </td>
                  <td>사전</td>
                  <td>{groupTargetScore}</td>
                  <td>{Math.floor(e.examTakenResults[0]?.groupAvgScore || 0)}점</td>
                  <td>{secondToClock(e.examTakenResults[0]?.timeRequired || 0)}</td>
                  <td>{e.examTakenResults[0]?.userScore ? `${e.examTakenResults[0].userScore}점` : '-'}</td>
                  <td>{e.examTakenResults[0]?.userRank || '미응시'}</td>
                </tr>
                <tr
                  className={`end ${hoveredIndex === index ? 'hovered' : ''}`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => goToGroupTestResult(e.userId)}
                >
                  <td>사후</td>
                  <td>{groupTargetScore}</td>
                  <td>{Math.floor(e.examTakenResults[1]?.groupAvgScore || 0)}점</td>
                  <td>{secondToClock(e.examTakenResults[1]?.timeRequired || 0)}</td>
                  <td>{e.examTakenResults[1]?.userScore ? `${e.examTakenResults[1].userScore}점` : '-'}</td>
                  <td>{e.examTakenResults[1]?.userRank || '미응시'}</td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </StyledTable>
      </TableBox>

      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        component='div'
        count={userExamResult?.length || 0}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangePageSize}
        labelRowsPerPage='조회 수강생 수'
      />
    </Container>
  );
};

const Container = styled.div`
  margin: 40px 0;

  div.MuiTablePagination-root {
    p.MuiTypography-root {
      font-size: 1.5rem !important;
    }
  }
`;

const InputBox = styled.div`
  display: flex;
  justify-content: space-between;

  i {
    margin-right: 5px;
  }

  button {
    height: 45px;
  }

  input {
    height: 45px;
    border: 2px solid rgb(46, 148, 210);
    font-size: 1.5rem;

    &::placeholder {
      color: rgb(107, 164, 199);
    }
  }
`;

const TableBox = styled.div`
  margin-top: 20px;
  min-height: 300px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 0 1px #7fadc9;

  b.exit {
    font-weight: normal;
    color: #ea7a7a;
    text-align: center;
  }

  tr {
    width: 100%;
    font-size: 1.5rem;
    transition: 0.2s;
    cursor: pointer;

    &.separator {
      border-bottom: 1px solid #97b6c9;
    }

    &.end {
      border-bottom: 2px solid #2e94d2;
    }

    &:last-child {
      border-bottom: none;
    }

    th,
    td {
      padding: 10px 30px;
    }

    th {
      background-color: #2e94d2;
      color: #fff;
      flex: 1;
    }
  }

  .separator,
  .end {
    transition: background-color 0.3s;
  }

  .separator.hovered,
  .end.hovered {
    background-color: #eee;
  }
`;

export default ExamUserTable;

import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Paper, Table, TableContainer, TableHead, TableBody, TableRow } from '@mui/material';
import styled from 'styled-components';

import { useFetchOperatorGroups, useRedirectWithoutOperator } from 'hooks';
import { Badge } from '../../../elements';

const GroupCourseList = () => {
  const history = useHistory();

  useRedirectWithoutOperator();
  
  const { data: groups } = useFetchOperatorGroups();

  const goToGroupUserList = (groupId, examOrder, groupName, defaultCourseId, event) => {
    event.preventDefault();
    history.push({
      pathname: '/operator/group',
      state: {
        groupId: groupId,
        examOrder,
        groupName,
        defaultCourseId,
      },
    });
  };

  return (
    <Fragment>
      <div style={{ fontSize: '2em', textAlign: 'center' }}>
        <TableContainer component={Paper}>
          <Table size='small' aria-label='customized table'>
            <TableHead rowcount={groups?.length}>
              <StyledTableRow className='header'>
                <StyledTd>No.</StyledTd>
                <StyledTd>교육명</StyledTd>
                <StyledTd>시작일</StyledTd>
                <StyledTd>종료일</StyledTd>
                <StyledTd>차수</StyledTd>
                <StyledTd>진행여부</StyledTd>
              </StyledTableRow>
            </TableHead>

            <TableBody>
              {groups?.map((row, index) => {
                return (
                  <StyledTableRow
                    key={index}
                    onClick={(e) =>
                      goToGroupUserList(row.groupId, row.groupCourseCount, row.groupName, row.defaultCourseId, e)
                    }
                  >
                    <StyledTd>{index + 1}</StyledTd>
                    <StyledTd>{row.groupCourse}</StyledTd>
                    <StyledTd>{moment(row.groupStartDate).format('YYYY-MM-DD')}</StyledTd>
                    <StyledTd>{moment(row.groupDueDate).format('YYYY-MM-DD')}</StyledTd>
                    <StyledTd>{row.groupCourseCount}차 교육</StyledTd>
                    <StyledTd>
                      {row.groupStatus === 0 ? (
                        <Badge text='대기' color='#ffd670' />
                      ) : row.groupStatus === 1 ? (
                        <Badge text='진행' color='#2bdb0b' />
                      ) : (
                        <Badge text='종료' color='#fc232b' />
                      )}
                    </StyledTd>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Fragment>
  );
};

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: #f1f1f1;
  }

  &.header {
    background-color: #2e94d2;
    color: #fff;
    font-weight: bold;
  }
`;

const StyledTd = styled.td`
  padding: 20px;
  font-size: 1.6rem;
`;

export default GroupCourseList;

import React, { useEffect, useMemo, useState } from 'react';

import { exam } from 'utilities';

const TestChoice = (props) => {
  const { questionList, languageName, idx, setCheckAnswer, testOptions } = props;

  let answerList = sessionStorage.getItem(`saveAnswer${languageName.toUpperCase()}`);

  const [value, setValue] = useState('');

  const [optionOrder, setOptionOrder] = useState([]);

  useEffect(() => {
    if (answerList) {
      let answer = answerList.split(',')[idx];
      setValue(answer.trim());
    }

    if (!questionList || !!questionList.length) return;

    if (questionList.length !== [...answerList.split(',')].length) {
      sessionStorage.setItem(`saveAnswer${languageName.toUpperCase()}`, new Array(questionList.length));
      let answer = answerList.split(',')[idx];
      setValue(answer.trim());
    }

    if (!answerList) {
      localStorage.removeItem(`questionOrder${languageName}`);
    }
  }, [idx, questionList, testOptions]);

  /* 사지선다 리스트 받아오기 */
  const changeRadio = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    value && setCheckAnswer(value);

    return () => setCheckAnswer('');
  }, [value]);

  useEffect(() => {
    const fetchAndSetQuestion = () => {
      const optionsOrder = exam.getQuestionOrder(languageName, idx, testOptions.length);

      setOptionOrder(optionsOrder);
    };

    fetchAndSetQuestion();
  }, [idx, testOptions]);

  const shuffled = useMemo(() => {
    return optionOrder.map((index) => testOptions[index]);
  }, [optionOrder, testOptions]);

  const mappedShuffle = useMemo(() => {
    return shuffled.map((option) => {
      return {
        index: testOptions.indexOf(option) + 1,
        option: option,
      };
    });
  }, [idx, shuffled, testOptions]);

  return (
    <div className='choices'>
      {/* 사지선다 */}
      <ul>
        {mappedShuffle.length > 0 &&
          mappedShuffle.map((e, index) => (
            <label key={index}>
              <li>
                <input
                  type='radio'
                  name='chosenAnswer'
                  checked={parseInt(value) === parseInt(e.index)}
                  onChange={changeRadio}
                  value={e.index}
                  className='form-control'
                />
                <span>
                  &nbsp;{index + 1}.&nbsp;
                  {e.option &&
                    e.option.split('\\t')?.map((line, index) => {
                      return (
                        <span key={index}>
                          {line}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      );
                    })}
                </span>
              </li>
            </label>
          ))}
      </ul>
    </div>
  );
};

export default TestChoice;

import React, { Fragment, useEffect } from 'react';
import { Header, SignUpForm } from '../../widgets';
import { useCustomState } from 'state/state';

import '../../../assets/css/bootstrap.min.css';

const SignUp = () => {
  const [state, actions] = useCustomState();

  useEffect(() => {
    actions.removeModal();
  }, []);

  return (
    <Fragment>
      <Header img={state.data.header_bgs.signup}>회원가입</Header>
      <SignUpForm />
    </Fragment>
  );
};

export default SignUp;

import { midibusApi } from './config';

/* 미디버스 토큰 조회 */
export const fetchMidibusToken = async () => {
  const now = Date.now();
  const expire = Math.floor((now + 24 * 60 * 60 * 999) / 1000);
  const params = {
    expire,
  };

  return await midibusApi.get('/v2/token', {
    params,
    headers: {
      Authorization: 'Basic ZGV2QG9wZW5lZy5jby5rcjoxODkwNjU0NzQ0MWRiYTBk',
    },
  });
};

/* 미디버스 동영상 카테고리 조회 */
export const fetchMidibusCategories = async (token) => {
  return await midibusApi.get('/v2/media', {
    headers: {
      'X-Mbus-Token': token,
    },
  });
};

/* 미디버스 동영상 상세 정보 조회 */
export const fetchMidibusMediaDetail = async ({ midibusToken, categoryId, mediaId }) => {
  return await midibusApi.get(`/v2/media/${categoryId}/${mediaId}`, {
    headers: {
      'X-Mbus-Token': midibusToken,
    },
  });
};

/* eslint-disable */
import React, { useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import ListProjects from './pages/ListProjects';
import ListProblems from './pages/ListProblems';
import DiagnosisReport from './pages/DiagnosisReport';
import DiagnosisHistory from './pages/DiagnosisHistory';
import DetailReport from './pages/DetailReport';
import ListLanguages from './pages/ListLanguages';

import DiagnosisPageLayout from '../../layouts/DiagnosisPageLayout';

function CodeDiagnosis(props) {
  const match = useRouteMatch();
  const [active, setActive] = useState(1);

  return (
    <Switch>
      <Route exact path={`${match.path}`} component={ListLanguages} />

      <DiagnosisPageLayout active={active} setActive={setActive}>
        <Route exact path={`${match.url}/listprojects`} component={ListProjects} />
        <Route exact path={`${match.url}/diagnosishistory`} component={DiagnosisHistory} />
        <Route exact path={`${match.url}/diagnosisreport`} component={DiagnosisReport} />
        <Route exact path={`${match.url}/reportdetail`} component={DetailReport} />
        <Route exact path={`${match.url}/listproblems`} render={() => <ListProblems setActive={setActive} />} />
      </DiagnosisPageLayout>
    </Switch>
  );
}

export default CodeDiagnosis;

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { MESSAGE } from '../utilities';
import { warningToast } from '../utilities/toast';
import { AuthAtom } from '../state';

// 로그인 정보 없을 시 홈으로 리다이렉트
export const useRedirectWithoutAuth = () => {
  const auth = useRecoilValue(AuthAtom);
  const history = useHistory();

  useEffect(() => {
    if (!auth.auth) {
      warningToast(MESSAGE.SERVICE_AUTHENTICATED_ONLY);
      history.push('/');
    }
  }, [auth, history]);
};

export const useRedirectWithoutOperator = () => {
  const auth = useRecoilValue(AuthAtom);
  const history = useHistory();

  useEffect(() => {
    if (auth.type !== 4) {
      warningToast(MESSAGE.SERVICE_OPERATOR_ONLY);
      history.push('/');
    }
  }, [auth, history]);
};

import React from 'react';
import styled from 'styled-components';

import ReportWrapper from './ReportWrapper';

const ExamCorrectIncorrect = ({ correctIncorrectData }) => {
  const { firstExamReportData, secondExamReportData } = correctIncorrectData || {};

  const processExamData = (firstExamReportData, secondExamReportData) => {
    const processedData = {};

    const processExamReport = (reportData, examType) => {
      if (!Array.isArray(reportData)) return;

      reportData.forEach((item) => {
        if (!processedData[item.middleCategoryId]) {
          processedData[item.middleCategoryId] = {
            middleCategoryName: item.middleCategoryName,
            count: 0,
            subCategories: {},
          };
        }

        if (!processedData[item.middleCategoryId].subCategories[item.subCategoryName]) {
          processedData[item.middleCategoryId].subCategories[item.subCategoryName] = {
            firstExam: '',
            secondExam: '',
          };
        }

        if (examType === 'first') {
          processedData[item.middleCategoryId].subCategories[item.subCategoryName].firstExam += item.isCorrect
            ? 'O'
            : 'X';
        } else {
          processedData[item.middleCategoryId].subCategories[item.subCategoryName].secondExam += item.isCorrect
            ? 'O'
            : 'X';
        }
      });
    };

    processExamReport(firstExamReportData, 'first');
    processExamReport(secondExamReportData, 'second');

    // Calculate counts
    Object.values(processedData).forEach((category) => {
      category.count = Object.keys(category.subCategories).length;
    });

    return processedData;
  };

  const sortTableData = (data) => {
    const sortedData = {};

    /* 화면에 표시해야하는 순서대로 정렬 */
    sortedData['0'] = data['10'];
    sortedData['1'] = data['1'];
    sortedData['2'] = data['2'];
    sortedData['3'] = data['3'];
    sortedData['4'] = data['4'];
    sortedData['5'] = data['5'];
    sortedData['6'] = data['6'];
    sortedData['7'] = data['7'];

    return sortedData;
  };

  const tableData = processExamData(firstExamReportData, secondExamReportData);
  const sortedTableData = sortTableData(tableData);

  return (
    <ReportWrapper title='개인 역량평가 상세 결과'>
      <Container>{renderTable(sortedTableData)}</Container>
    </ReportWrapper>
  );
};

const separateOX = (data = '') => {
  if (data.length <= 0 || !data) return '';

  return [...data].map((item, index) => (
    <span key={index} className={item === 'O' ? 'blue' : 'red'}>
      {item}
    </span>
  ));
};

const renderSubCategoryRows = (subCategories) => {
  if (subCategories?.length < 0) return {};

  const rows = [];
  const subCategoryNames = Object.keys(subCategories || {});

  subCategoryNames.forEach((name, index) => {
    if (index % 3 === 0) {
      rows.push(
        <React.Fragment key={index}>
          <td>{name}</td>
          <td>{separateOX(subCategories[name].firstExam)}</td>
          <td>{separateOX(subCategories[name].secondExam)}</td>

          <td>{subCategoryNames[index + 1]}</td>
          <td>{separateOX(subCategories[subCategoryNames[index + 1]]?.firstExam)}</td>
          <td>{separateOX(subCategories[subCategoryNames[index + 1]]?.secondExam)}</td>

          <td>{subCategoryNames[index + 2]}</td>
          <td>{separateOX(subCategories[subCategoryNames[index + 2]]?.firstExam)}</td>
          <td>{separateOX(subCategories[subCategoryNames[index + 2]]?.secondExam)}</td>
        </React.Fragment>
      );
    }
  });

  return rows;
};

const calculateDifference = (categories) => {
  let totalDifference = 0;
  let totalCount = 0;

  Object.values(categories || {}).forEach((subCategory) => {
    const firstExam = subCategory.firstExam;
    const secondExam = subCategory.secondExam;

    const correctFirstExam = (firstExam.match(/O/g) || []).length;
    const totalFirstExam = firstExam.length;
    const correctSecondExam = (secondExam.match(/O/g) || []).length;
    const totalSecondExam = secondExam.length;

    // 각 시험의 O 비율 계산
    const firstExamRatio = correctFirstExam / totalFirstExam;
    const secondExamRatio = correctSecondExam / totalSecondExam;

    // 변화율 계산
    const difference = (secondExamRatio - firstExamRatio) * 100;
    totalDifference += difference;
    totalCount += 1;
  });

  // 비율을 기반으로 총 변화를 -100에서 +100 사이로 정규화
  const normalizedDifference = Math.max(-100, Math.min(100, totalDifference / totalCount));

  if (!normalizedDifference) return <span className='summary'>-</span>;
  return (
    <span className={`summary ${normalizedDifference < 0 ? 'red' : 'blue'}`}>
      {normalizedDifference > 0 && '+'}
      {Math.round(normalizedDifference)}
    </span>
  );
};

const renderTable = (tableData) => (
  <table>
    <thead>
      <tr>
        <th rowSpan='2'>유형</th>
        <th colSpan='9'>평가결과 정오표</th>
        <th rowSpan='2'>전후 증감</th>
      </tr>
      <tr>
        <th>보안약점명</th>
        <th>사전</th>
        <th>사후</th>
        <th>보안약점명</th>
        <th>사전</th>
        <th>사후</th>
        <th>보안약점명</th>
        <th>사전</th>
        <th>사후</th>
      </tr>
    </thead>
    <tbody>
      {Object.entries(tableData).map(([, category], index) => (
        <React.Fragment key={index}>
          {renderSubCategoryRows(category?.subCategories).map((row, index) => (
            <tr key={index}>
              <React.Fragment>
                {index === 0 && (
                  <td rowSpan={Math.ceil(category.count / 3)} className='middleCategory'>
                    {category.middleCategoryName} ({category.count})
                  </td>
                )}
                {row}
                {index === 0 && (
                  <td rowSpan={Math.ceil(category.count / 3)}>{calculateDifference(category.subCategories)}</td>
                )}
              </React.Fragment>
            </tr>
          ))}
        </React.Fragment>
      ))}
    </tbody>
  </table>
);

const Container = styled.div`
  width: 100%;
  overflow: hidden;

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 1.5rem;
    text-align: center;
  }

  tr {
    width: 100%;
    overflow: hidden;
  }

  th,
  td {
    max-width: 150px;
    padding: 5px 7px;
    border: 1px solid #ddd;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    span {
      font-weight: 500;
    }

    span.summary {
      display: flex;
      justify-content: center;
      align-items: center;
      aspect-ratio: 1;
      width: 30px;
      font-weight: bold;
      padding: 3px;
      margin: 0 auto;
      font-size: 1.4rem;
    }

    span.blue {
      color: blue;
    }

    span.red {
      color: red;
    }
  }

  td.middleCategory {
    text-overflow: unset;
    white-space: normal;
    word-break: keep-all;
  }

  thead {
    background-color: #f2f2f2;
  }
`;

export default ExamCorrectIncorrect;

import React, { useState, Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  FormControl,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  TablePagination,
  MenuItem,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

import { Button } from '../../elements';
import MyQandAList from './MyQandAList.js';
import { MESSAGE } from 'utilities';
import { BootstrapInput } from 'style/customerPageStyle';
import { errorToast } from 'utilities/toast';
import { fetchMyQuestionList } from 'api/mypage';
import styled from 'styled-components';

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#C4C4C4',
    color: '#fff',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles(() => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: '5px',
    flex: 1,
  },
  iconButton: {
    padding: 5,
  },
  table: {
    minWidth: 700,
  },
  title: {
    flex: '1 1 100%',
    marginTop: '5vh',
    fontWeight: 'bolder',
  },
}));

const MypageMyQandAList = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [list, setList] = useState([]);
  const [searchedData, setSearched] = useState([]);
  const [search, setSearch] = useState({
    selected: 'date',
    title: '등록일',
    content: '',
  });

  const selectChange = (e) => {
    if (e.target.value === 'date') {
      setSearch({ ...search, selected: e.target.value, title: '등록일' });
      setSearched(
        list.filter((l) => {
          return l.postRegDate.indexOf(search.content) !== -1;
        })
      );
    } else if (e.target.value === 'secName') {
      setSearch({ ...search, selected: e.target.value, title: '제목' });
      setSearched(
        list.filter((l) => {
          return l.postTitle.indexOf(search.content) !== -1;
        })
      );
    } else if (e.target.value === 'scategoryName') {
      setSearch({ ...search, selected: e.target.value, title: '플랫폼' });
      setSearched(
        list.filter((l) => {
          return l.postCategoryType.indexOf(search.content) !== -1;
        })
      );
    }
  };

  const handleChangeSearch = (e) => {
    if (search.selected === 'date')
      setSearched(
        list.filter((l) => {
          return l.postRegDate.indexOf(e.target.value) !== -1;
        })
      );
    else if (search.selected === 'secName')
      setSearched(
        list.filter((l) => {
          return l.postTitle.indexOf(e.target.value) !== -1;
        })
      );
    else if (search.selected === 'scategoryName') {
      setSearched(
        list.filter((l) => {
          return l.postCategoryType.indexOf(e.target.value) !== -1;
        })
      );
    }
    setSearch({ ...search, content: e.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchMyQuestionList();
        setList(response.data);
        setSearched(response.data);
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.status === 401 || error.response.status === 403) {
            errorToast(MESSAGE.SERVICE_INVALID_TOKEN);
          }
        }
      }
    })();
  }, []);

  return (
    <Fragment>
      <ControlWrapper>
        <div>
          <Button roundSmall to='/customer/contact' type='outline-color-cl' hoverType='solid-color-tb'>
            <span className='secotext'>
              문의하기<i className='las la-file-code' style={{ marginLeft: '10px' }}></i>
            </span>
          </Button>
        </div>

        <div>
          <FormWrapper>
            <Select
              variant='outlined'
              id='select'
              value={search.selected}
              onChange={selectChange}
              input={<BootstrapInput />}
            >
              <MenuItem className='secotext' value='date'>
                등록일
              </MenuItem>
              <MenuItem className='secotext' value='secName'>
                제목
              </MenuItem>
              <MenuItem className='secotext' value='scategoryName'>
                플랫폼
              </MenuItem>
            </Select>

            <BootstrapInput
              id='demo-customized-textbox'
              placeholder={`${search.title}으로 검색하기`}
              name='content'
              value={search.content}
              onChange={handleChangeSearch}
              style={{ width: '300px', fontSize: '15px' }}
            />
          </FormWrapper>
        </div>
      </ControlWrapper>

      <TableContainer component={Paper}>
        <Table className={classes.table} size='small' aria-label='customized table'>
          <TableHead rowcount={searchedData.length}>
            <StyledTableRow>
              <StyledTableCell>
                <span className='secotext'>No.</span>
              </StyledTableCell>
              <StyledTableCell align='center'>
                <span className='secotext'>제목</span>
              </StyledTableCell>
              <StyledTableCell align='center'>
                <span className='secotext'>대분류</span>
              </StyledTableCell>
              <StyledTableCell align='center'>
                <span className='secotext'>소분류</span>
              </StyledTableCell>
              <StyledTableCell align='center'>
                <span className='secotext'>작성자</span>
              </StyledTableCell>
              <StyledTableCell align='center'>
                <span className='secotext'>등록일</span>
              </StyledTableCell>
              <StyledTableCell align='center'>
                <span className='secotext'>조회수</span>
              </StyledTableCell>
              <StyledTableCell align='center'>
                <span className='secotext'>답변여부</span>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {searchedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
              return <MyQandAList qandalist={row} no={index} key={index} date={row.postRegDate.substring(0, 10)} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={searchedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Fragment>
  );
};

const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const FormWrapper = styled.form`
  display: flex;
  column-gap: 5px;

  > div {
    border: 1px solid #eee;
    padding: 5px;
    border-radius: 5px;
  }
`;

const StyledTableRow = styled.tr`
  > th {
    background-color: #2c95d2;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
  }
`;

export default withRouter(MypageMyQandAList);

import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import qs from 'query-string';

import { api } from 'api/config';
import { AuthAtom } from 'state';
import { errorToast } from '../../../../../utilities/toast';
import Loading from '../../../../components/Loading';
import './style.scss';

function DiagnosisHistory(props) {
  const { userId } = useRecoilValue(AuthAtom);
  const [loading, setLoading] = useState(false);
  const [listHistoryDiagnosis, setListHistoryDiagnosis] = useState([]);

  useEffect(() => {
    if (loading) return;

    const projectId = qs.parse(props.location.search).pid;

    api
      .post(`/users/quests/result_dynamic-analyzers`, {
        userId: userId,
        projectId: projectId,
        typereport: 'json',
      })
      .then((response) => {
        const { data } = response;
        setListHistoryDiagnosis(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error) {
          errorToast('프로젝트 진단 실패 잠시후 다시 해주세요');
        }
      });

    return () => {};
  }, []);

  const handleClickDetail = (id, result) => {
    localStorage.setItem('owasp_result', result);
    props.history.push(`diagnosisreport?logid=${id}`);
  };

  if (loading) {
    return <Loading type='spinningBubbles' color='#2F96EB' />;
  }
  return (
    <div className='dianosis-history'>
      <Timeline>
        {listHistoryDiagnosis.length !== 0 &&
          listHistoryDiagnosis?.map((diagnosis, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot variant='outlined' color='primary' />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                {diagnosis.time}
                <div className='wrapper-log'>
                  <p>{diagnosis.path}</p>
                  <button onClick={() => handleClickDetail(diagnosis.path, diagnosis.result)}>제세히 보기</button>
                </div>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
    </div>
  );
}

export default DiagnosisHistory;

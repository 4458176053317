import Swal from 'sweetalert2';

const customSwal = Swal.mixin({
  toast: true,
  position: 'top',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  width: 'auto',
  padding: '1.45em 2em',
});

/**
 * 에러 토스트.
 * 파라미터로 출력할 메시지를 받는다.
 * 사용예시: errorToast('내용을 출력하겠습니다.')
 * */
export const errorToast = (text) => {
  customSwal.fire({
    icon: 'error',
    title: 'Error',
    text,
  });
};

/**
 * 성공 토스트.
 * 파라미터로 출력할 메시지를 받는다.
 * 사용예시: successToast('내용을 출력하겠습니다.')
 * */
export const successToast = (text) => {
  customSwal.fire({
    icon: 'success',
    title: 'Success',
    text,
  });
};

/**
 * 주의 토스트.
 * 파라미터로 출력할 메시지를 받는다.
 * 사용예시: warningToast('내용을 출력하겠습니다.')
 * */
export const warningToast = (text) => {
  customSwal.fire({
    icon: 'warning',
    title: 'Warning',
    text,
  });
};

/**
 * 정보 토스트.
 * 파라미터로 출력할 메시지를 받는다.
 * 사용예시: infoToast('내용을 출력하겠습니다.')
 * */
export const infoToast = (text, timer = 2000) => {
  customSwal.fire({
    icon: 'info',
    title: 'Info',
    text,
    timer: timer,
  });
};

/** 컨펌 확인창.
 *  text : 출력할 본문 내용
 *  awaitFunction : 계속 진행을 눌렀을 때 진행할 내용
 *  사용 예시: sweetAlertConfirm('답안을 제출하시겠습니까?', () => onSubmit(saveAnswer))
 * */
export const sweetAlertConfirm = (text, awaitFunction) => {
  Swal.fire({
    text,
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    reverseButtons: true,
    cancelButtonText: '취소',
    confirmButtonText: '제출',
    customClass: {
      htmlContainer: 'custom-sweet-alert-text-font',
      cancelButton: 'custom-sweet-alert-button-font ',
      confirmButton: 'custom-sweet-alert-button-font',
    },
  }).then((result) => {
    if (result.isConfirmed) {
      awaitFunction();
    }
  });
};

import React, { Fragment } from 'react';

import Layout from '../../layouts/Layout/Layout';
import THEME from '../../../state/theme';
import AnimatedNumber from 'animated-number-react';
import styles from './Counter.module.scss';

const Counter = ({ data = [] }) => {
  const formatValue = (value) => value.toFixed(3);

  const countList = data?.map((item, index) => (
    <Fragment key={index}>
      <div style={{ textAlign: 'center' }}>
        <div className={styles.card}>
          <p className='secosub2' style={{ color: THEME.color, marginBottom: '0' }}>
            {item.secName}
          </p>
          <h3 style={{ fontSize: '3rem', lineHeight: '1.4', marginBottom: '10px' }}>
            <AnimatedNumber value={item.learningRate} duration={3500} delay={1000} formatValue={formatValue} />%
          </h3>
        </div>
      </div>
    </Fragment>
  ));

  return (
    <Layout col='3' style={{ marginTop: '60px', marginBottom: '60px' }}>
      <div className={'layout-header'}>
        <h2 className='secosub1'>가장 많이 푼 문제 TOP 3</h2>
        <span className='secomin subtitle' style={{ color: THEME.color }}>
          TOP 3 TRAINING SUCCESS RESULTS
        </span>
      </div>
      {countList}
    </Layout>
  );
};

export default Counter;

import React, { Fragment } from 'react';
import { useRecoilValue } from 'recoil';

import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import MypageMyQandAIndex from './MypageMyQandAIndex.js';
import MypageMyQandASub from './MypageMyQandASub.js';
import MypageOper from '../Mypage/MypageOper';
import { Header } from '../../widgets';
import { AuthAtom } from 'state';

const MypageMyQandA = () => {
  const auth = useRecoilValue(AuthAtom);

  useRedirectWithoutAuth();

  return (
    <Fragment>
      {auth.type === 1 ? (
        <>
          <Header>나의 문의</Header>
          <MypageMyQandAIndex />
        </>
      ) : auth.type === 4 ? (
        <MypageOper />
      ) : (
        <>
          <Header>나의 문의</Header>
          <MypageMyQandASub />
        </>
      )}
    </Fragment>
  );
};

export default MypageMyQandA;

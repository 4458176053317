import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import { BootstrapInput } from 'style';
import { fetchUserStudyStatus, fetchUserStudyStatusByCourseId } from 'api/mypage';
import '../../MyPage.css';

const StudySecureBoard = () => {
  const useStyles = makeStyles(() => ({
    root: {},
    form: {
      padding: '0px',
    },
    tab: {
      borderRaidus: '20px',
      fontWeight: '900',
      color: 'gray',
      boxShadow: '5px 5px 10px #e7e7e7',
    },
    tabBtn: {
      width: '100%',
    },
    tF: {
      marginLeft: '0px !important',
    },
    indicator: {
      backgroundColor: '#007bff',
    },
  }));

  const classes = useStyles();

  const [trainingVideoData, setTrainingVideoData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [course, setCourse] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [myLearningRate, setMyLearningRate] = useState(0);

  useRedirectWithoutAuth();

  const selectChange = (e) => {
    setSelectedCourse(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await fetchUserStudyStatus();
        setCourse(data.data.myCourseList);
        setSelectedCourse([data.data.myCourseList[0].courseId, data.data.myCourseList[0].courseName]);
      } catch (error) {
        if (error.response !== undefined) {
          // history.push('/');
        }
      }
    })();
  }, []);

  const getCourseData = async () => {
    const selectedCourseId = selectedCourse.toString().split(',')[0];

    try {
      const { data } = await fetchUserStudyStatusByCourseId({ courseId: selectedCourseId });
      const { data: courseResult } = data;
      setTrainingVideoData(courseResult.studyStatusList);
      setMyLearningRate(courseResult.myLearningRate);
    } catch (error) {
      if (error.response !== undefined) {
        // history.push('/');
      }
    }
  };

  useEffect(() => {
    selectedCourse[0] !== undefined && getCourseData();
  }, [selectedCourse]);

  return (
    <Fragment>
      <BoardListWrapper>
        <TrainProgress myLearningRate={myLearningRate}>현재 진행률 : {myLearningRate} %</TrainProgress>
        <FormControl className={classes.margin}>
          {course.length !== 0 ? (
            <Select
              variant='outlined'
              id='select'
              value={selectedCourse}
              onChange={selectChange}
              input={<BootstrapInput />}
            >
              {course?.map((c, index) => {
                return (
                  <MenuItem key={index} className='secotext' value={[c.courseId, c.courseName]}>
                    {c.courseName}
                  </MenuItem>
                );
              })}
            </Select>
          ) : (
            <Select variant='outlined' id='select' input={<BootstrapInput />}>
              <MenuItem className='secotext' selected disabled>
                수강중인 강의가 없습니다.
              </MenuItem>
            </Select>
          )}
        </FormControl>
      </BoardListWrapper>
      <TableContainer component={Paper}>
        <Table className={classes.table} size='small' aria-label='customized table'>
          <TableHead rowcount={trainingVideoData.length}>
            <StyledTableRow>
              <TableCell>No.</TableCell>
              <TableCell align='center'>
                <span className='secotext'>과정명</span>
              </TableCell>
              <TableCell align='center'>
                <span className='secotext'>강의명</span>
              </TableCell>
              <TableCell align='center'>
                <span className='secotext'>학습률</span>
              </TableCell>
              <TableCell align='center'>
                <span className='secotext'>마지막 수강 시간</span>
              </TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {trainingVideoData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell align='center'>
                    <span className='secotext'>{row.courseName}</span>
                  </TableCell>
                  <TableCell align='left'>
                    <span className='secotext'>{row.videoName}</span>
                  </TableCell>
                  <TableCell align='center'>
                    <span className='secotext'>{row.trainProgress}%</span>
                  </TableCell>
                  <TableCell align='center'>
                    <span className='secotext'>
                      {row.trainRegDate !== null ? moment(row.trainRegDate).format('YYYY-MM-DD   HH:mm') : '수강 전'}
                    </span>
                  </TableCell>
                  {/* <TableCell align="center">{row.trainStatus}</TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={trainingVideoData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Fragment>
  );
};

const StyledTableRow = styled.tr`
  > th {
    background-color: #2c95d2;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
  }
`;

const BoardListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1vh;
`;

const TrainProgress = styled.div`
  font-size: 1.6rem;
  color: ${(p) => (p.myLearningRate < 80 ? '' : '#1e88e5')};
`;

export default withRouter(StudySecureBoard);

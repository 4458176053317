import React from 'react';
import styled from 'styled-components';

const Badge = ({ text, color }) => {
  return <BadgeWrapper color={color}>{text}</BadgeWrapper>;
};

const BadgeWrapper = styled.span`
  width: auto;
  height: auto;
  border-radius: 10px;
  padding: 5px 12px;
  color: #fff;
  font-weight: bold;
  background-color: ${(p) => p.color};
  border: 1px solid ${(p) => p.color};
  text-shadow: 0.5px 0.5px rgba(0, 0, 0, 0.32);
`;

export default Badge;

import React, { useEffect, Fragment, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useRecoilState } from 'recoil';
import { Accordion, AccordionSummary } from '@mui/material';
import { withStyles } from '@mui/styles';

import { useRedirectWithoutAuth } from '../hooks';
import { midibusApi } from '../api/config';
import { AuthAtom, useSetMidibusToken } from '../state';
import { Header } from '../components/widgets';
import CourseCard from '../components/widgets/Portfolio/CourseCard';
import Layout from '../components/layouts/Layout/Layout';
import { fetchMidibusCategories, fetchMidibusToken } from '../api/midibus';
import { getItem, setItem } from '../commons/localStorage';
import { useFetchCourseList } from '../hooks/student.hook';

// const AccordionSummary = withStyles({
//   root: {
//     //backgroundColor: 'rgba(0, 0, 0, .03)',
//     border: 'thin solid #e7e7e7 !important',
//     boxShadow: '5px 5px 10px #e7e7e7 !important',
//     borderRadius: '5px',
//     marginBottom: 1,
//     minHeight: 56,
//     '&$expanded': {
//       minHeight: 56,
//     },
//   },
//   content: {
//     '&$expanded': {
//       margin: '0 0',
//     },
//   },
//   expanded: {},
// })(AccordionSummary);

const Course = () => {
  const ref = useRef(null);
  const location = useLocation();
  const { pathname } = location;

  const [authAtom, setAuthAtom] = useRecoilState(AuthAtom);
  const { token } = authAtom;
  const setMidibusToken = useSetMidibusToken();
  const midibusToken = getItem('midibusToken');

  const [courseData, setCourseData] = useState([]);
  const [accessibleCourseId, setAccessibleCourseId] = useState(false);
  const [currentCourseId, setCurrentCourseId] = useState([]);
  const [midibusCategory, setMidibusCategory] = useState([]);

  const courseFilter01 = courseData?.filter((fi) => fi.courseType.includes(1));
  const courseFilter02 = courseData?.filter((fi) => fi.courseType.includes(2));
  const courseFilter03 = courseData?.filter((fi) => fi.courseType.includes(3));

  const [expanded, setExpanded] = useState('panel1');

  const { data: courseListData, isLoading } = useFetchCourseList({ token });

  useRedirectWithoutAuth();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    if (!courseListData) return;

    setCourseData(courseListData.data.courses);
    setAccessibleCourseId(courseListData.data.accessibleCourseId);
    setCurrentCourseId(courseListData.data.myCourseId);
  }, [courseListData, isLoading]);

  // 미디버스 API (토큰 & 카테고리 리스트)
  useEffect(() => {
    const isStudentPage = pathname.split('/').includes('student');

    if ((!authAtom.midibusToken && authAtom.token && isStudentPage) || !midibusToken) {
      (async () => {
        try {
          const midibusResponse = await fetchMidibusToken();
          midibusApi.defaults.headers.common['X-Mbus-Token'] = midibusResponse.data.token;
          setItem('midibusToken', midibusResponse.data.token);
          setAuthAtom((prev) => {
            return {
              ...prev,
              midibusToken: midibusResponse.data.token,
            };
          });
          setMidibusToken(midibusResponse.data.token);
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [authAtom.token, location.pathname, useSetMidibusToken]);

  useEffect(() => {
    if (!midibusToken) return;

    (async () => {
      try {
        const mediaCategoryResponse = await fetchMidibusCategories(midibusToken);
        setMidibusCategory(mediaCategoryResponse.data.category_list);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [authAtom, midibusToken]);

  return (
    <Fragment>
      <Header>학습실</Header>
      <div ref={ref}>
        <Layout
          style={{
            backgroundColor: '#fff',
            marginTop: '80px',
            paddingBottom: '110px',
          }}
          col='1'
        >
          <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
              <p className='secosub1 secodgray'>소프트웨어 개발보안 교육</p>
              <p className='toggleBtn'>
                {expanded === 'panel1' ? <i className='fas fa-angle-up'></i> : <i className='fas fa-angle-down'></i>}
              </p>
            </AccordionSummary>
            <CourseCard
              data={courseFilter01}
              midibusCategory={midibusCategory}
              accessibleCourseId={accessibleCourseId}
              currentCourseId={currentCourseId}
            />
          </Accordion>

          <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
              <p className='secosub1 secodgray'>정보보안 교육</p>
              <p className='toggleBtn'>
                {expanded === 'panel2' ? <i className='fas fa-angle-up'></i> : <i className='fas fa-angle-down'></i>}
              </p>
            </AccordionSummary>
            <CourseCard
              data={courseFilter02}
              midibusCategory={midibusCategory}
              accessibleCourseId={accessibleCourseId}
              currentCourseId={currentCourseId}
            />
          </Accordion>

          <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary aria-controls='panel3d-content' id='panel3d-header'>
              <p className='secosub1 secodgray'>프로그래밍 기술 교육</p>
              <p className='toggleBtn'>
                {expanded === 'panel3' ? <i className='fas fa-angle-up'></i> : <i className='fas fa-angle-down'></i>}
              </p>
            </AccordionSummary>
            <CourseCard
              data={courseFilter03}
              midibusCategory={midibusCategory}
              accessibleCourseId={accessibleCourseId}
              currentCourseId={currentCourseId}
            />
          </Accordion>
        </Layout>
      </div>
    </Fragment>
  );
};

export default Course;

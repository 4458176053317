import React from 'react';
import { Redirect, Route } from 'react-router';
import { useRecoilValue } from 'recoil';

import { errorToast, infoToast, warningToast } from './utilities/toast';
import { MESSAGE } from 'utilities';
import { AuthAtom } from './state';
import { getItem } from './commons/localStorage';

export const OperatorRoute = ({ component, path }) => {
  const auth = useRecoilValue(AuthAtom);

  if (auth.type !== 4) {
    errorToast(MESSAGE.SERVICE_OPERATOR_ONLY);
    return <Redirect to='/' replace />;
  }
  return <Route component={component} path={path} />;
};

export const NoExamGroupRoute = ({ children, ...rest }) => {
  const auth = useRecoilValue(AuthAtom);

  if (!auth.examYn) {
    warningToast('시험에 응시할 수 있는 과정이 아닙니다.');
  }

  return <Route {...rest} render={() => (auth.examYn ? children : <Redirect to='/' replace />)} />;
};

export const NeedMidibusTokenRoute = ({ children, ...rest }) => {
  const auth = useRecoilValue(AuthAtom);
  const midibusToken = getItem('midibusToken');

  if (!(auth.midibusToken || midibusToken)) {
    infoToast('학습실로 이동합니다.');
    return <Redirect to='/student/course' replace />;
  }

  return (
    <Route
      {...rest}
      render={() => (!(auth.midibusToken || midibusToken) ? children : <Redirect to='/student/course' replace />)}
    />
  );
};

export const InstructorRoute = ({ component, path }) => {
  const auth = useRecoilValue(AuthAtom);

  if (auth.type !== 2) {
    errorToast(MESSAGE.SERVICE_TRAINER_ONLY);
    return <Redirect to='/' replace />;
  }
  return <Route component={component} path={path} />;
};

import React, { useState, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { withRouter } from 'react-router';

import Layout from '../../layouts/Layout/Layout';
import THEME from 'state/theme';
import { MESSAGE } from 'utilities';
import { AuthAtom } from 'state';
import { warningToast } from 'utilities/toast';
import styles from './Services3.module.scss';

export default withRouter(({ data = [], history }) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const auth = useRecoilValue(AuthAtom);
  const { type, status } = auth;

  useEffect(() => {
    setHeight(ref.current.clientHeight);
    window.addEventListener('resize', resizeListener);
    return window.removeEventListener('resize', resizeListener);
  }, [auth]);

  const handleSecurePage = (id) => {
    if (auth.auth) {
      if (type !== 1) {
        warningToast(MESSAGE.SERVICE_STUDENT_ONLY);
      } else if (type === 1 && status === 1 && id === 'capability') {
        history.push(`/student/${id}/select`);
      } else if (type === 1 && status !== 1) {
        warningToast(MESSAGE.STATUE_NOT_CONFIRMED);
      } else if (type === 1 && status === 1) {
        history.push(`/student/${id}`);
      }
    } else {
      warningToast(MESSAGE.SERVICE_AUTHENTICATED_ONLY);
    }
  };

  const resizeListener = () => {
    setHeight(ref.current.clientHeight);
  };

  let listStatistic = data
    ?.map((item, index) => (
      <div
        key={index}
        className={styles.wrapper}
        style={{
          borderColor: THEME.color,
        }}
        onClick={() => handleSecurePage(item.id)}
      >
        <div className={styles.card}>
          <div className={styles.icon}>
            <img src={item.icon} alt='card_icon' />
          </div>

          <h3 className='secosub1 secoblue'>{item.subtitle}</h3>
          <p className='secotext secogray'>{item.text.replace(/^(.{60}[^\s]*).*/, '$1')}</p>
        </div>
      </div>
    ))
    .slice(0, 4);

  return (
    <div className={styles.stat_area}>
      <Layout
        style={{
          transform: 'translateY(-50%)',
        }}
        col='1'
      >
        <div className={styles.statistic} ref={ref}>
          {listStatistic}
        </div>
      </Layout>
      <span
        className={styles.negative_space}
        style={{
          height: height / 2 + 20 + 'px',
          marginTop: -height + 'px',
        }}
      />
    </div>
  );
});

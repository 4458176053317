import React, { Fragment } from 'react';

import { useCustomState } from 'state/state';
import './signup.css';

const SignUpConfirm = () => {
  const [state, actions] = useCustomState();

  const hideContent = () => {
    state.sidebar && actions.toggleSidebar();
    state.modal && actions.toggleModal();
    state.request.modal && actions.toggleRequest();
    state.video && actions.toggleVideo();
    state.signconfirm && actions.toggleSignConfirm();
  };

  return (
    <Fragment>
      <div className='signConfirmModal'>
        <div className='modal-body'>
          <div style={{ padding: '10px 30px' }}>
            <div className='d-flex justify-content-center'>
              <p
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  marginTop: '20px',
                  marginBottom: '40px',
                }}
              >
                개인정보 수집 및 이용동의
              </p>
            </div>
            <div>
              <p
                style={{
                  fontSize: '1.2rem',
                  fontWeight: '300',
                  lineHeight: '1.5',
                  color: '#323232',
                }}
              >
                ㈜오픈이지(이하 "회사"라 한다)는 고객님의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호"에
                관한 법률을 준수하고 있습니다.
                <br />
                회사는 개인정보처리방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고
                있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다. 회사는 개인정보처리방침을
                개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
                <br />
                <br />
                ■ 개인정보의 수집 및 이용목적
                <br />
                회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
                <br />
                <br />
                ○ 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
                <br />
                콘텐츠 제공, 구매 및 요금 결제, 금융거래 본인 인증 및 금융 서비스, 요금추심
                <br />
                <br />
                ○ 회원 관리
                <br />
                회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사
                확인, 연령확인, 불만처리 등 민원처리, 고지사항 전달
                <br />
                <br />
                ■ 수집하는 개인정보 항목
                <br />
                회사는 회원가입, 문의, 서비스 신청 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.
                <br />
                <br />
                ○ 수집항목
                <br />
                [시코랩 일반 회원]
                <br />
                - 이용자의 식별을 위한 필수 정보: 아이디, 이름, 비밀번호
                <br />
                - 본인확인을 위한 정보: 이메일, 휴대전화번호
                <br />
                [시코랩 학습자]
                <br />
                - 필수정보: 아이디, 이름, 비밀번호, 이메일, 휴대전화번호, 계좌정보
                <br />
                [시코랩 교육기관 내 교육생]
                <br />
                - 필수정보: 아이디, 이름, 비밀번호, 이메일, 휴대전화번호
                <br />
                [시코랩 교육신청 기업]
                <br />
                - 필수정보: 교육신청 기관, 법인명, 사업자 등록번호, 대표자명, 담당자 이름, 이메일, 휴대전화번호,
                계좌정보
                <br />
                - 선택정보: 교육기관 전화번호
                <br />
                [시코랩 출제자]
                <br />
                - 필수정보: 아이디, 이름, 비밀번호, 이메일, 휴대전화번호, 계좌정보
                <br />
                <br />
                ○ 개인정보 수집방법 : 웹사이트(secolab.kr), 유선전화 또는 메일(jdb@openeg.co.kr)
                <br />
                <br />
                ■ 개인정보의 보유 및 이용기간
                <br />
                원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 관계법령의
                규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를
                보관합니다.
                <br />
                <br />
                ○ 회사 내부 방침에 의한 정보보유 사유
                <br />
                - 보존 이유 : 이용규정 미준수 회원의 이용제한(재가입 제한)
                <br />
                - 보존 기간 : 3년
                <br />
                <br />
                ○ 관련법령에 따른 정보보유
                <br />
                - 표시/광고에 관한 기록 : 6개월 (전자상거래 등에서의 소비자보호에 관한 법률)
                <br />
                - 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률)
                <br />
                - 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률)
                <br />
                - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래 등에서의 소비자보호에 관한 법률)
                <br />
                - 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 (신용정보의 이용 및 보호에 관한 법률)
                <br />
                <br />
                ■ 개인정보의 파기 절차 및 방법
                <br />
                회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기절차
                및 방법은 다음과 같습니다.
                <br />
                <br />
                ○ 파기절차
                <br />
                회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의
                서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된
                후 파기됩니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유 이외의 다른 목적으로
                이용되지 않습니다.
                <br />
                <br />
                ○ 파기방법
                <br />
                - 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
                <br />
                - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                <br />
                <br />
                ■ 동의 거부권 및 불이익
                <br />
                귀하는 위와 같은 본인의 개인정보 수집∙이용에 관한 동의를 거부할 권리가 있습니다. 회원 가입을 위해 필요한
                최소한의 정보인 필수사항의 수집∙이용에 관한 동의를 거부하는 경우에는 회원 가입이 불가합니다.
                <br />
                <br /># 상기 내용이 변경되는 경우 인터넷 홈페이지(secolab.kr)게시 등을 통해 그 내용을 확인하실 수
                있습니다
              </p>
            </div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ paddingTop: '10px', borderBottom: '1px solid #e3e3e3', margin: '25px 0' }}></div>
            <button
              className='btn btn-dark'
              style={{ padding: '10px', width: '145px', fontFamily: 'Noto Sans KR', fontSize: '1.4rem' }}
              type='button'
              data-dismiss='modal'
              onClick={hideContent}
            >
              닫기
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SignUpConfirm;

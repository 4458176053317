import React, { Fragment } from 'react';
import { Header } from '../../widgets';

import MypageMyQandADetailIndex from './MypageMyQandADetailIndex.js';
import { useRedirectWithoutAuth } from '../../../hooks/redirect.hook';

const MypageMyQandADetail = () => {
  // 로그인 정보 없을 때 홈으로 리다이렉트
  useRedirectWithoutAuth();

  return (
    <Fragment>
      <Header>나의 문의</Header>
      <MypageMyQandADetailIndex />
    </Fragment>
  );
};

export default MypageMyQandADetail;

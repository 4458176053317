/* eslint-disable */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Skeleton } from '@mui/lab';

import VideoDetailTop from './VideoDetailTop';
import VideoDetailBottom from './VideoDetailBottom';
import Layout from 'components/layouts/Layout/Layout';
import THEME from 'state/theme';
import styles from './Project.module.scss';
import 'react-circular-progressbar/dist/styles.css';
import styled from 'styled-components';
import { useFetchMidibusMediaDetail } from 'hooks/useFetchMidibusMediaDetail';
import { useFetchTrainVideoList, useUpdateVideoProgress } from 'hooks/student.hook';

export default ({ courseTotal = [] }) => {
  const params = useParams();
  const { instId, courseType, courseId, videoId, mediaId, categoryId } = params;
  const mutation = useUpdateVideoProgress({ courseId, courseType });

  const formData = useRef(null);
  const [isIframeLoading, setIsIframeLoading] = useState(false);

  const [isVideoFinished, setIsVideoFinished] = useState(false);
  const [lastHandledTime, setLastHandledTime] = useState(Date.now());
  const [midibusProgressData, setMidibusProgressData] = useState({
    mediaLength: 0,
    mediaPos: 0,
  });
  const [checkpoints, setCheckPoints] = useState(new Array(100).fill(0));

  const { stableCourseData } = useFetchTrainVideoList({ courseId, courseType });

  const courseCurrent = courseTotal.find((item) => item.courseId.toString() === courseId);
  const currentVideo = stableCourseData && stableCourseData.find((item) => item.videoId === Number(videoId));
  const currentVideoIndex = stableCourseData && stableCourseData.findIndex((item) => item.videoId === Number(videoId));
  const nextVideo = stableCourseData && stableCourseData.find((_, index) => index === currentVideoIndex + 1);

  const { isVideoLoading, mediaContentKey, mediaDuration, mediaCreated } = useFetchMidibusMediaDetail(
    categoryId,
    mediaId
  );

  useEffect(() => {
    setCheckPoints(new Array(100).fill(0));
  }, [mediaContentKey]);

  useEffect(() => {
    setIsIframeLoading(true);
  }, [isVideoLoading, mediaContentKey]);

  const trackPlayback = (currentMediaPos, mediaLength) => {
    let intervalLength = Math.floor((mediaLength * 10) / 100);
    let currentIndex = Math.floor((currentMediaPos * 10) / intervalLength);

    setCheckPoints((prevCheckpoints) => {
      const newCheckpoints = [...prevCheckpoints];
      if (mediaLength < 160) {
        newCheckpoints[currentIndex - 2] = 1;
        newCheckpoints[currentIndex - 1] = 1;
        newCheckpoints[currentIndex] = 1;
        newCheckpoints[currentIndex + 1] = 1;
      } else if (mediaLength < 240) {
        newCheckpoints[currentIndex - 1] = 1;
        newCheckpoints[currentIndex] = 1;
        newCheckpoints[currentIndex + 1] = 1;
      } else if (mediaLength < 700) {
        newCheckpoints[currentIndex - 1] = 1;
        newCheckpoints[currentIndex] = 1;
      } else {
        newCheckpoints[currentIndex] = 1;
      }

      if (newCheckpoints.length > 100) {
        newCheckpoints.splice(100, newCheckpoints.length);
      }

      return newCheckpoints;
    });
  };

  const handleData = (data, midibusProgressData) => {
    const { mediaLength, mediaPos } = { ...midibusProgressData };
    let isUpdated = false;

    if (mediaLength !== data.mediaLength || mediaPos !== data.mediaPos) {
      setMidibusProgressData((prevData) => {
        const updatedData = { ...prevData };

        ['mediaLength', 'mediaPos'].forEach((key) => {
          if (data[key] !== prevData[key]) {
            updatedData[key] = data[key];
            isUpdated = true;
          }
        });
        trackPlayback(updatedData.mediaPos, updatedData.mediaLength);

        return isUpdated ? updatedData : prevData;
      });
      isUpdated = false;
    }
  };

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);

    function receiveMessage(dataFromPlayer) {
      if (typeof dataFromPlayer == 'undefined' || dataFromPlayer == null) {
        return;
      }

      if (dataFromPlayer.data.type === 'finish') {
        setIsVideoFinished(true);
      }

      if (mediaContentKey === dataFromPlayer.data.objectId) {
        formData.current = {
          mediaLength: dataFromPlayer.data.mediaLength,
          mediaPos: dataFromPlayer.data.mediaPos,
        };
        handleData(formData.current, midibusProgressData);
      }
    }

    return () => window.removeEventListener('message', receiveMessage, false);
  }, [params, mediaContentKey, midibusProgressData]);

  useEffect(() => {
    const currentTime = Date.now();
    const elapsedTime = currentTime - lastHandledTime;
    const requestPeriod = 1000 * 30;

    const updateVideoData = async () => {
      setIsVideoFinished(false);
      await mutation.mutate({
        videoId: Number(videoId),
        mediaLastPosition: midibusProgressData.mediaPos,
        viewArray: checkpoints,
      });
    };

    if (elapsedTime >= requestPeriod || isVideoFinished) {
      updateVideoData();
      setLastHandledTime(currentTime);
    }
  }, [midibusProgressData, lastHandledTime, isVideoFinished]);

  return (
    <>
      <Layout col='1'>
        <div className='videoRight'>
          <VideoDetailTop
            data={courseCurrent}
            sDatas={currentVideo}
            mediaDuration={mediaDuration}
            mediaCreated={mediaCreated}
          />
          <div className='video-container'>
            <VideoBox>
              {!isIframeLoading || isVideoLoading ? (
                <Skeleton width='1920px' height='1080px' animation='wave' />
              ) : (
                <iframe
                  onLoad={() => setIsIframeLoading(true)}
                  key={mediaContentKey}
                  width='1920'
                  height='1080'
                  src={
                    currentVideo && currentVideo.mediaLastPosition !== 0
                      ? `https://play.mbus.tv/hls/${mediaContentKey}?&start=${currentVideo.mediaLastPosition}`
                      : `https://play.mbus.tv/hls/${mediaContentKey}?`
                  }
                  allowFullScreen
                />
              )}
            </VideoBox>
          </div>
          <br />
          {nextVideo && currentVideoIndex < stableCourseData.length - 1 && (
            <Link
              to={`/student/videodetail/${instId}/${courseType}/${courseId}/${nextVideo.videoName}/${nextVideo.videoId}/${nextVideo.mediaId}/${categoryId}`}
              className='btn btn-primary'
            >
              <span className='secosub2 secowhite'>
                <i className='fas fa-play-circle'></i> &nbsp; 다음 학습영상 재생
              </span>
            </Link>
          )}
          {stableCourseData && currentVideoIndex >= stableCourseData.length - 1 && (
            <Link
              to={`/student/coursedetail/${courseType}/${courseId}/${instId}/${categoryId}`}
              className='btn btn-primary'
            >
              <span className='secosub2 secowhite'>
                <i className='fas fa-external-link-alt'></i> &nbsp; 과정소개 페이지로 이동
              </span>
            </Link>
          )}
          <br />
          <br />

          <VideoDetailBottom data={courseCurrent} sDatas={currentVideo} />
        </div>
      </Layout>
    </>
  );
};

const VideoBox = styled.div`
  width: 100%;

  .MuiSkeleton-root {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .MuiSkeleton-text {
    transform: unset;
  }
`;

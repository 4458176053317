export const getItem = (key) => {
  let storageValue;
  if (typeof window !== 'undefined') {
    storageValue = localStorage.getItem(key);
  }
  if (!storageValue) return null;
  try {
    return storageValue;
  } catch (error) {
    return null;
  }
};

export const setItem = (key, value) => {
  localStorage.setItem(key, value);
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};

export const clear = () => {
  localStorage.clear();
};

import { atom, useRecoilCallback } from 'recoil';

import { clear, getItem } from '../commons/localStorage';
import { parseJwtToken } from '../utilities/parseToken';
import { api, midibusApi } from '../api/config';

const useSyncAuthStateWithLocalStorage = ({ setSelf, onSet }) => {
  const localToken = getItem('token');
  const refreshToken = getItem('refreshToken');
  const examYn = getItem('examYn');
  const midibusToken = getItem('midibusToken');
  if (localToken !== null) {
    if (midibusToken !== null) {
      setSelf((prevAuthState) => ({
        ...prevAuthState,
        midibusToken,
      }));

      midibusApi.defaults.headers.common['X-Mbus-Token'] = midibusToken;
    }
    const token = localToken.split('Bearer ')[1];
    const { allowLanguages, freeYn, status, userEmail, userName, userType, userId } = parseJwtToken(token);

    setSelf((prevAuthState) => ({
      ...prevAuthState,
      auth: true,
      userId,
      status,
      refreshToken,
      freeYn,
      allowLanguages,
      name: userName,
      type: userType,
      email: userEmail,
      token: `Bearer ${token}`,
      examYn: !!examYn,
    }));

    api.defaults.headers.common['Authorization'] = localToken;
  }

  onSet((newValue, oldValue, isReset) => {
    if (isReset) {
      clear();
      sessionStorage.clear();
    }
  });
};

export const AuthAtom = atom({
  key: 'AuthAtom',
  default: {
    auth: false,
    userId: '',
    name: '',
    type: -1,
    email: '',
    status: '',
    refreshToken: '',
    freeYn: '',
    allowLanguages: '',
    midibusToken: '',
    token: '',
    examYn: false,
  },
  effects: [useSyncAuthStateWithLocalStorage],
});

export const useSetMidibusToken = () => {
  return useRecoilCallback(({ set }) => (midibusToken) => {
    set(AuthAtom, (prevAuthState) => ({
      ...prevAuthState,
      midibusToken,
    }));
  });
};

import React from 'react';
import styled from 'styled-components';
import XLSX from 'xlsx';
import { useLocation, useHistory } from 'react-router-dom';

import { LANGUAGE_ID } from 'constants/common';

const GroupCategoryResultTable = ({ firstExamSubCorrectRates, secondExamSubCorrectRates }) => {
  const history = useHistory();
  const { state } = useLocation();

  const examSubCorrectRates =
    secondExamSubCorrectRates && secondExamSubCorrectRates.length > 0
      ? secondExamSubCorrectRates?.map((item) => {
          const matchedItem = firstExamSubCorrectRates.find((fItem) => fItem.subCategoryId === item.subCategoryId);
          return {
            subCategoryId: item.subCategoryId,
            subCategoryName: item.subCategoryName,
            riskLevel: item.riskLevel,
            firstExamCorrectRate: matchedItem ? matchedItem.correctRate : null,
            secondExamCorrectRate: item.correctRate,
          };
        })
      : firstExamSubCorrectRates?.map((item) => {
          return {
            subCategoryId: item.subCategoryId,
            subCategoryName: item.subCategoryName,
            riskLevel: item.riskLevel,
            firstExamCorrectRate: item.correctRate,
            secondExamCorrectRate: '-',
          };
        });

  const formatDifficulty = {
    HIGH: '상',
    MEDIUM: '중',
    LOW: '하',
  };

  const difficultyColor = {
    HIGH: '#fc5656',
    MEDIUM: '#ffa54e',
    LOW: '#486ffd',
  };

  const GoBack = () => {
    history.goBack();
  };

  const tableToExcel = () => {
    // work_book 생성
    const wb = XLSX.utils.book_new();
    const ws1 = XLSX.utils.table_to_sheet(document.getElementById('category-result-table'), { raw: true });

    // work_book에 work_sheet 추가
    XLSX.utils.book_append_sheet(wb, ws1, LANGUAGE_ID[state.languageId]);

    // work_sheet 컬럼(순서대로) 너비 설정
    ws1['!cols'].push({ width: 10 }, { width: 30 }, { width: 10 }, { width: 10 });

    XLSX.writeFile(wb, `SECOLAB_카테고리별_정답률_report.xlsx`);
  };

  return (
    <Container id='category-result-table'>
      <h2>역량 평가 카테고리별 정답률</h2>

      <TableBox>
        <StyledTable>
          <thead>
            <tr className='header'>
              <td rowSpan='2'>코드</td>
              <td className='subCategoryName' rowSpan='2'>
                <span>서브카테고리명</span>
              </td>
              <td rowSpan='2'>보안위험도</td>
              <td colSpan='2'>정답률</td>
            </tr>
            <tr className='header'>
              <td>사전</td>
              <td className='rates'>사후</td>
            </tr>
          </thead>

          {examSubCorrectRates?.map((e, index) => (
            <tbody key={index}>
              <tr>
                <td className='code'>{e.subCategoryId}</td>
                <td className='subCategoryName'>
                  <span>{e.subCategoryName}</span>
                </td>
                <td className='riskLevel'>
                  <RiskBadge backColor={difficultyColor[e.riskLevel]}>{formatDifficulty[e.riskLevel]}</RiskBadge>
                </td>
                <td className='rates'>{Math.round(e.firstExamCorrectRate ?? e.correctRate)}%</td>
                <td className='rates'>
                  {e.secondExamCorrectRate !== '-' ? `${Math.round(e.secondExamCorrectRate)}%` : '-'}
                </td>
              </tr>
            </tbody>
          ))}
        </StyledTable>
      </TableBox>

      <div className='d-flex justify-content-center'>
        <button onClick={() => GoBack()} className='goBackButton'>
          <i className='fas fa-angle-left'></i> 뒤로가기
        </button>
        <button onClick={() => tableToExcel()} className='excelBtn'>
          <i className='far fa-file-excel'></i> Excel 다운로드
        </button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 0 70px;
  font-size: 1.5rem;
  font-weight: bold;

  > div > button {
    margin-top: 20px !important;
    margin-bottom: 0;
  }
`;

const TableBox = styled.div`
  margin: 30px 0;
  padding-bottom: 30px;
  position: relative;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 0 1px #7fadc9;

  tr {
    color: #333;

    &.header {
      background-color: #2e94d2;
      color: #fff;
      font-weight: bold;
    }
  }

  td {
    text-align: center;
    padding: 20px;
    font-size: 1.6rem;
    border-bottom: 1px solid #eee;

    &.subCategoryName {
      text-align: left;

      > span {
        margin: 0 auto;
      }
    }

    &.code {
      min-width: 200px;
    }

    &.riskLevel {
      min-width: 350px;
    }

    &.rates {
      min-width: 100px;
    }
  }
`;

const RiskBadge = styled.span`
  padding: 3px 7px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  background-color: ${(p) => p.backColor};
  text-wrap: nowrap;
`;

export default GroupCategoryResultTable;

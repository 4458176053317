import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

import styles from './Portfolio.module.scss';
import { warningToast } from 'utilities/toast';
import { MESSAGE } from 'utilities';

const PortfolioLink = (props) => {
  const { item, targetURL, accessibleCourseId, isAllowedCourse, isVideoTrain } = props;

  const isExamAccess = (courseId) => !!accessibleCourseId.includes(courseId);

  const handleBeforeApply = () => {
    warningToast(MESSAGE.TOAST_NOT_ALLOWED_COURSE);
  };

  const handleBeforeExam = () => {
    warningToast('사전 평가 응시 후 수강이 가능합니다.');
  };

  const handleClickButton = () => {
    if (!(isAllowedCourse(item.courseId) && isExamAccess(item.courseId))) return;
    isVideoTrain(item.courseType, item.courseId, item.courseName);
  };

  return (
    <>
      {isAllowedCourse(item.courseId) ? (
        <Link
          item={item}
          to={isAllowedCourse(item.courseId) && isExamAccess(item.courseId) ? targetURL : '#'}
          onClick={handleClickButton}
        >
          <div className={styles.button}>
            <h3 className='secosub2'>과정&nbsp;:&nbsp;총&nbsp;{item.courseCount}강</h3>
            <p className='secosub2'>{item.courseObj}</p>
            {isExamAccess(item.courseId) ? (
              <Button variant='contained' color='secondary'>
                <p className='secosub2'>
                  수강하기 <i className='fas fa-angle-right' />
                </p>
              </Button>
            ) : (
              <Button
                onClick={handleBeforeExam}
                variant='contained'
                color='secondary'
                style={{ backgroundColor: '#f5bb28c0', color: '#fff' }}
              >
                <p className='secosub2'>사전평가 미응시</p>
              </Button>
            )}
          </div>
        </Link>
      ) : (
        <Link item={item} to='#' onClick={handleBeforeApply}>
          <div className={styles.button}>
            <h3 className='secosub2'>과정&nbsp;:&nbsp;총&nbsp;{item.courseCount}강</h3>
            <p className='secosub2'>{item.courseObj}</p>
            <Button disabled color='secondary' style={{ backgroundColor: '#808080', color: '#fff' }}>
              <p className='secosub2'>신청 후 수강하기</p>
            </Button>
          </div>
        </Link>
      )}
    </>
  );
};

export default PortfolioLink;

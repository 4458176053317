import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    //display: 'flex',
    alignItems: 'center',
    //width: 600,
  },
  input: {
    marginLeft: '5px',
    flex: 1,
  },
  iconButton: {
    padding: 5,
  },
  table: {
    minWidth: 700,
  },
  title: {
    flex: '1 1 100%',
    marginTop: '5vh',
    fontWeight: 'bolder',
  },
}));

export default useStyles;

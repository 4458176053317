import styled from 'styled-components';
import { TableRow } from '@mui/material';

export const FormWrapper = styled.form`
  display: flex;
  justify-content: ${(p) => (p.spaceBetween ? 'space-between' : 'flex-end')};
  margin-left: auto;
  margin-bottom: 15px;
  column-gap: 5px;

  > div {
    border-radius: 5px;
    padding: 5px;
  }

  > span {
    > div {
      padding: 5px;
    }

    > div:first-child {
      margin-right: 5px;
    }
  }
`;

export const StyledTableRow = styled(TableRow)`
  > th {
    background-color: #757575;
    color: #fff;
  }
`;

export const TableRowHover = styled(TableRow)`
  transition: 0.2s;
  cursor: pointer;
`;

import React, { useState, Fragment, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { fetchLibraryDetail } from 'api/customer';
import { MESSAGE } from 'utilities';
import { AuthAtom } from 'state';
import { errorToast } from 'utilities/toast';
import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import { Button } from '../../../elements';
import '../detailStyle.css';

export default function Detail({ history }) {
  const { id } = useParams();
  const auth = useRecoilValue(AuthAtom);

  // 로그인 정보 없을 시 홈으로 리다이렉트
  useRedirectWithoutAuth();

  const [list, setList] = useState([]);
  const [fileData, setFileData] = useState([]);

  useEffect(() => {
    if (!auth.auth) return;

    (async () => {
      try {
        const response = await fetchLibraryDetail(id);
        setList(response.data);
        setFileData(response.data.files);
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.status === 401 || error.response.status === 403) {
            errorToast(MESSAGE.SERVICE_INVALID_TOKEN);
          }
          history.push('/');
        }
      }
    })();
  }, []);

  return (
    <div className='detailContainer'>
      <div className='row'>
        <div style={{ marginBottom: '2vh', width: '200px' }}>
          <Button roundSmall to='/customer' type='outline-color-cl' hoverType='solid-color-tb'>
            <span className='secotext'>
              자료실 목록<i className='las la-file-code'></i>
            </span>
          </Button>
        </div>
      </div>
      <div className='row detailBox'>
        <div className='col-md-2 detailLeft secotext'>제목</div>
        <div className='col-md-10 detailRight secotext'>{list.postTitle}</div>
      </div>
      <div className='row detailBox'>
        <div className='col-md-2 detailLeft secotext'>등록일자</div>
        <div className='col-md-4 detailRight secotext'>{list.postRegDate}</div>
        <div className='col-md-2 detailLeft secotext'>조회수</div>
        <div className='col-md-4 detailRight secotext'>{list.postHitCount}</div>
      </div>
      <div className='row detailBox'>
        <div className='col-md-2 detailLeft secotext'>작성자</div>
        <div className='col-md-10 detailRight secotext'>{list.postAuthor}</div>
      </div>
      <div className='row detailBox'>
        <div className='col-md-2 detailLeft secotext'>기술 카테고리</div>
        <div className='col-md-4 detailRight secotext'>{list.postCategoryId}</div>
        <div className='col-md-2 detailLeft secotext'>세부 카테고리</div>
        <div className='col-md-4 detailRight secotext'>{list.postCategoryName}</div>
      </div>
      <div className='row detailcontentBox'>
        <div className='col-md-2 detailLeft secotext'>내용</div>
        <div className='col-md-10 detailRight secotext'>{list.postContent}</div>
      </div>
      <div className='row detailBox' style={{ marginBottom: '30px' }}>
        <div className='col-md-2 detailLeft secotext'>자료</div>
        <div className='col-md-10 detailRight secotext'>
          {fileData?.map((file, index) => {
            return (
              <Fragment key={index}>
                <Link to={'/postFileDownload/' + file.fileOriginalFileName} target='_blank' download>
                  <i className='las la-file-download'></i>&nbsp;{file.fileOriginalFileName}
                </Link>
                <br />
              </Fragment>
            );
          })}
        </div>
      </div>
      {/*
        <div className="row navBox">
            <div className="col-md-2 detailLeft">다음글&nbsp;&nbsp;&nbsp;<i className="las la-angle-up"></i></div>
            <div className="col-md-10 detailRight">{list.postAuthor}</div>
        </div>
        <div className="row navBox">
            <div className="col-md-2 detailLeft">이전글&nbsp;&nbsp;&nbsp;<i className="las la-angle-down"></i></div>
            <div className="col-md-10 detailRight">{list.postAuthor}</div>
        </div>
        */}

      <div className='row'>
        {/* <div style={{ marginBottom: '2vh', width: '150px' }}>
          {
            list.prevId > 0 ?
              <Button
                roundSmall
                click={() => handleClick(list.prevId)}
                type="outline-color-cl"
                hoverType="solid-color-tb"
              >
                <span className='secotext'><i className="las la-angle-left"></i>&nbsp;&nbsp;이전글</span>
              </Button>
              :
              <Button
                roundSmall
                disabled
                //to={"/customer/librarydetail/"+list.prevId}
                type="outline-color-cl"
              >
                <span className='secotext'><i className="las la-angle-left"></i>&nbsp;&nbsp;이전글</span>
              </Button>
          }

        </div> */}
        {/* <div style={{ marginBottom: '2vh', marginLeft: '25px', width: '150px' }}>
          {
            list.nextId > 0 ?
              <Button
                roundSmall
                click={() => handleClick(list.nextId)}
                type="outline-color-cl"
                hoverType="solid-color-tb"
              >
                <span className='secotext'>다음글&nbsp;&nbsp;<i className="las la-angle-right"></i></span>
                
              </Button>
              :
              <Button
                roundSmall
                disabled
                //to={"/customer/librarydetail/"+list.nextId}
                type="outline-color-cl"
              >
                <span className='secotext'>다음글&nbsp;&nbsp;<i className="las la-angle-right"></i></span>
              </Button>
          }

        </div> */}
      </div>
    </div>
  );
}

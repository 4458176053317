/* eslint-disable */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { useRedirectWithoutAuth } from '../hooks/redirect.hook';
import CodeDiagnosis from './pages/CodeDiagnosis';
import DevelopmentEnv from './pages/DevelopmentEnv';
import CapabilityTestPage from './pages/CapabilityTest';
import SecCategory from '../SecureCoding/SecCategory';
import Course from './Course';
import SelectTraining from './SelectTraining';
import CourseDetail from './CourseDetail';
import SecureList from './SecureList';
import VideoDetail from './VideoDetail';
import ScrollToTop from './ScrollToTop';
import { NeedMidibusTokenRoute, NoExamGroupRoute } from '../PrivateRoute';
import { AuthAtom } from '../state';

export default function App({ match, history }) {
  const [authAtom] = useRecoilState(AuthAtom);

  useRedirectWithoutAuth();

  return (
    authAtom.auth && (
      <>
        {/* 로그인했는데, 교육생이 아닌경우 접근불가 */}
        <Route path={`${match.path}/seccategory/:lan_type/:lan_id`}>
          {authAtom.type !== 1 && <Redirect to={'/'} />}
        </Route>
        <Route path={`${match.path}/seccategory/:lan_type/:lan_id`}>{!authAtom.auth && <Redirect to={'/'} />}</Route>
        <Route path={`${match.path}/seccategory/:lan_type/:lan_id`}>
          {authAtom.status !== 1 && <Redirect to={'/'} />}
        </Route>

        <ScrollToTop>
          <Switch>
            <Route exact path={`${match.path}`}>
              <Redirect to={`${match.path}/select`} />
            </Route>
            <Route path={`${match.path}/select`} component={SelectTraining} />

            <Route path={`${match.path}/course`} component={Course} />
            <NeedMidibusTokenRoute
              path={`${match.path}/coursedetail/:courseType/:courseId/:instId/:categoryId`}
              component={CourseDetail}
            />
            <NeedMidibusTokenRoute
              path={`${match.path}/videodetail/:instId/:courseType/:courseId/:videoName/:videoId/:mediaId/:categoryId`}
              component={VideoDetail}
            />

            <Route path={`${match.path}/securelist`} component={SecureList} />
            <Route path={`${match.path}/seccategory/:lan_type/:lan_id`}>
              <SecCategory auth={authAtom} />
            </Route>
            <Route path={`${match.path}/codediagnosis`} component={CodeDiagnosis} />
            <Route path={`${match.path}/developmentenv`} component={DevelopmentEnv} />
            <NoExamGroupRoute path={`${match.path}/capability`}>
              <CapabilityTestPage />
            </NoExamGroupRoute>
          </Switch>
        </ScrollToTop>
      </>
    )
  );
}

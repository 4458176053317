import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment/moment';
import styled from 'styled-components';
import { TextField, Paper } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Header } from '../../widgets';
import Layout from '../../layouts/Layout/Layout';
import { useFetchStudentCountByMonth } from '../../../hooks/trainer.hook';
import TableView from './TableView';
import { errorToast } from '../../../utilities/toast';

const TrainerMonitoring = () => {
  const history = useHistory();

  const currentMonth = moment().format('YYYY-MM');

  const [period, setPeriod] = useState(currentMonth);

  const { data: studentCounts, isError } = useFetchStudentCountByMonth({ period });

  const handleChangePeriod = (e) => {
    setPeriod(e.target.value);
  };

  useEffect(() => {
    if (isError) {
      errorToast('해당 계정에 연결된 강의가 존재하지 않습니다.');
      history.push('/');
    }
  }, [isError]);

  return (
    <Fragment>
      <Header>수강생 조회</Header>
      <div
        style={{
          backgroundColor: '#fff',
          margin: '80px 0 40px',
          paddingBottom: '110px',
        }}
      >
        <Layout col='1'>
          <ContentBox component={Paper}>
            <p className='secosub1 secoblack'>시큐어코딩 in C</p>
            <StyledControlBox>
              <div />
              <div>
                <TextField
                  id='month'
                  label='연월'
                  type='month'
                  value={period}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangePeriod}
                />
                <TotalBox className='total-box'>
                  <span>총 {studentCounts?.length}명</span>
                </TotalBox>
              </div>
            </StyledControlBox>
          </ContentBox>

          <ViewBox>
            <TableView studentCounts={studentCounts} />
          </ViewBox>
        </Layout>
      </div>
    </Fragment>
  );
};

const ContentBox = styled.div`
  width: 100%;
  text-align: center;

  p {
    text-align: center;
    margin-bottom: 30px;
  }
`;

const StyledControlBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;

  .MuiInputBase-root input,
  .MuiInputBase-formControl,
  .MuiInputBase-input.MuiInput-input {
    cursor: pointer !important;
  }

  input[type='month'] {
    position: relative;
  }

  input[type='month']::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 0;
    top: 5px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    padding-left: 100%;
  }
`;

const ViewBox = styled.div`
  width: 100%;
`;

const TotalBox = styled.div`
  span {
    padding: 10px 20px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }
`;

export default TrainerMonitoring;

import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Bar, BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Button } from '@mui/material';

import { fetchDashBoardTimeInfo } from 'api/common';
import { useRedirectWithoutAuth } from 'hooks/redirect.hook';

const DashboardElapsedTimeInfoStat = () => {
  const [elapsedTimeDatas, setElapsedTimeDatas] = useState([]);
  const [elapsedTimeDatasByLanguage, setElapsedTimeDatasByLanguage] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [language, setLanguage] = useState({});

  useRedirectWithoutAuth();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchDashBoardTimeInfo();
        setElapsedTimeDatas(response.data);

        const languageList = response.data.reduce(function (res, cur) {
          if (res.findIndex(({ languageId }) => languageId === cur.languageId) === -1) {
            res.push({ languageId: cur.languageId, languageType: cur.languageType });
          }
          return res;
        }, []);
        setLanguageList(languageList);

        const regeneratedSet = response.data
          .filter((o) => o.languageId === languageList[0].languageId)
          ?.map((o) => {
            return {
              ...o,
              elapsedTimeFirstAvg: o.elapsedTimeFirstAvg / 1000,
              elapsedTimeSecondAvg: o.elapsedTimeSecondAvg / 1000,
              elapsedTimeFirst: o.elapsedTimeFirst / 1000,
              elapsedTimeSecond: o.elapsedTimeSecond / 1000,
            };
          });
        setLanguage(response.data.find((o) => o.languageId === languageList[0].languageId));
        setElapsedTimeDatasByLanguage(regeneratedSet);
      } catch (error) {}
    })();
  }, []);

  const handlerSelectLanguage = (langId) => {
    const regeneratedSet = elapsedTimeDatas
      .filter((o) => o.languageId === langId)
      ?.map((o) => {
        return {
          ...o,
          elapsedTimeFirstAvg: o.elapsedTimeFirstAvg / 1000,
          elapsedTimeSecondAvg: o.elapsedTimeSecondAvg / 1000,
          elapsedTimeFirst: o.elapsedTimeFirst / 1000,
          elapsedTimeSecond: o.elapsedTimeSecond / 1000,
        };
      });
    setLanguage(elapsedTimeDatas.find((o) => o.languageId === langId));
    setElapsedTimeDatasByLanguage(regeneratedSet);
  };

  // x축 라벨 기울여서 출력하기
  const customAxisTick = ({ x, y, value, payload }) => {
    if (payload.value === 'auto') value = 1;
    else value = payload.value;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={13} textAnchor='start' fill='#666' transform='rotate(45)'>
          {value}
        </text>
        ;
      </g>
    );
  };

  const CustomizedLegend = [
    { color: '#0073e6', type: 'rect', value: '1단계' },
    { color: '#0044cc', type: 'rect', value: '2단계' },
  ];

  const CustomizedTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const p = payload[0].payload;
      return (
        <div
          style={{
            background: '#eee',
            border: '1px solid #ccc',
            borderRadius: '5px',
            padding: '10px',
            color: '#000',
          }}
        >
          <p>보안약점 유형: {p.scategoryName}</p>
          <p>보안약점 명: {p.secName}</p>
          <p>문제번호 : {p.secId}</p>
          <p>1단계 문제풀이 시간 : 평균 {p.elapsedTimeFirstAvg}초</p>
          <p>
            2단계 문제풀이 시간 :{' '}
            {p.elapsedTimeSecondAvg === 0 ? `문제를 푼 회원이 없음` : `평균 ${p.elapsedTimeSecondAvg}초`}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <Fragment>
      <div style={{ textAlign: 'center' }}>
        <p className='secosub1 secoblack'>보안약점별 문제풀이에 걸린 평균시간</p>
        <div>
          <div className='secosub2' style={{ color: '#dc3545', marginBottom: '10px' }}>
            {language.languageType && language.languageType.toUpperCase()}
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'right' }}>
        {languageList?.map((item, index) => {
          return (
            <Button
              key={index}
              variant='contained'
              color='primary'
              onClick={() => handlerSelectLanguage(item.languageId)}
              style={{ marginRight: '10px' }}
            >
              <span className='secotext'>{item.languageType}</span>
            </Button>
          );
        })}
      </div>

      {language !== undefined && language.languageType !== undefined && (
        <div style={{ marginBottom: '80px', flex: '1', width: '0' }}>
          <div style={{ textAlign: 'left', fontSize: '1.5rem' }}>문제풀이시간(초)</div>
          <ResponsiveContainer width='100%' height={400} style={{ overflowX: 'scroll' }}>
            <BarChart data={elapsedTimeDatasByLanguage} margin={{ top: 20, right: 20, left: 0, bottom: 0 }}>
              <CartesianGrid stroke='#f5f5f5' />
              <XAxis dataKey='secId' scale='auto' tick={customAxisTick} />
              <YAxis />
              <Tooltip content={<CustomizedTooltip />} />
              <Legend verticalAlign='bottom' height={36} payload={CustomizedLegend} />
              <Bar dataKey='elapsedTimeSecondAvg' stackId='a' barSize={20} fill='#0044cc' />
              <Bar dataKey='elapsedTimeFirstAvg' stackId='a' barSize={20} fill='#0073e6' />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
      {language === undefined ||
        (language.languageType === undefined && (
          <div
            style={{
              height: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '10px 10px 80px 10px',
            }}
          >
            {/* <span style={{ fontSize: "1.5rem" }}>조회할 개발언어를 선택해 주세요.</span> */}
          </div>
        ))}
    </Fragment>
  );
};

export default withRouter(DashboardElapsedTimeInfoStat);

import { useQuery } from '@tanstack/react-query';

import { fetchCheckUserExam } from '../../api/capability';
import { errorToast } from '../../utilities/toast';
import { useHistory } from 'react-router-dom';

const useCheckUserExamStatus = ({ userId, languageId, myCourseId, checkSingle }) => {
  const history = useHistory();

  const isSingleExam = myCourseId.filter((id) => id !== 0).length === 1;

  const queryReturns = useQuery({
    queryKey: ['fetchCheckUserExam', { userId, languageId }],
    queryFn: async () => {
      const response = await fetchCheckUserExam({ userId, languageId });

      if (response.data.data === 0) {
        history.push(`/student/capability/${myCourseId[1]}`);
      }

      if (response.data.data === 1 || response.data.data === 2) {
        history.push('/student/capability/result');
      }

      if (response.data.data === 3) {
        errorToast('응시 가능한 시험이 존재하지 않습니다.');
        history.push('/');
      }

      return response.data;
    },
    retry: 2,
    retryDelay: 1000,
    enabled: checkSingle ? !!isSingleExam : true,
  });

  return { ...queryReturns };
};

export default useCheckUserExamStatus;

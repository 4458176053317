import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { errorToast } from 'utilities/toast';
import { MESSAGE } from 'utilities';
import { fetchCodeDiagnosisExam } from 'api/student';
import './style.scss';

const useStyles = makeStyles(() => ({
  title01: {
    fontWeight: 'bold',
  },
  content01: {
    fontSize: '1.4rem',
    marginLeft: '10px',
  },
}));

function InformCard(props) {
  const history = useHistory();
  const classes = useStyles();
  const [quest, setQuest] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchCodeDiagnosisExam({ questId: props.questId });
        setQuest(response.data);
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            errorToast(MESSAGE.SERVICE_INVALID_TOKEN);
            history.push('/');
          } else if (error.response.status === 403) {
            errorToast(MESSAGE.SERVICE_INVALID_TOKEN);
            history.push('/');
          }
        }
      }
    })();
  }, []);

  return (
    <div className='wrapper-create-project'>
      <div className='create-project'>
        <span className='close' onClick={() => props.closeModal(false)}>
          <i className='fas fa-times'></i>
        </span>
        <div className='create-project__container'>
          <p style={{ textAlign: 'center', fontSize: '30px', fontWeight: '700', marginBottom: '10px' }}>문제 정보</p>
          <div>
            <Typography>
              <h3 className={classes.title01}>문제 명</h3>
              <p className={classes.content01}>{quest.questName}</p>
              <br />
              <h3 className={classes.title01}>문제 요약</h3>
              <p className={classes.content01}>{quest.questSummary}</p>
              <br />
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InformCard;

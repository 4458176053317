/* 화면에 보여주어야 하는 순서 */
export const MIDDLE_CATEGORIES = {
  '000': '개요',
  '001': '입력 데이터 검증 및 표현',
  // '002': '보안 기능',
  '002': '코드 오류',
  // '003': '시간-상태',
  '003': '보안 기능',
  // '004': '에러 처리',
  '004': '캡슐화',
  // '005': '코드 오류',
  '005': '에러 처리',
  // '006': '캡슐화',
  '006': '시간-상태',
  '007': 'API 오용',
};

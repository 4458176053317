import { useQuery } from '@tanstack/react-query';

import { fetchUserExamResult } from 'api/capability';

const useFetchUserExamResult = () => {
  const queryReturns = useQuery({
    queryKey: ['fetchUserExamResult'],
    queryFn: async () => await fetchUserExamResult(),
    select: (res) => res.data.data,
    retry: 2,
    retryDelay: 1000,
  });

  return { ...queryReturns };
};

export default useFetchUserExamResult;

import React from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepLabel, StepConnector } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import Check from '@mui/icons-material/Check';
import clsx from 'clsx';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 13,
  },
  active: {
    '& $line': {
      backgroundColor: 'aqua',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: 'aqua',
    },
  },
  line: {
    height: 1,
    border: 0,
    backgroundColor: 'lightgray',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: 'lightgray',
    zIndex: 1,
    color: 'gray',
    width: 30,
    height: 30,
    fontSize: '10px',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: 'lightgray',
  },
  completed: {
    backgroundColor: 'aqua',
    zIndex: 1,
    color: '#fff',
    width: 30,
    height: 30,
    fontSize: '10px',
    display: 'flex',
    borderRadius: '50%',
    padding: '5px',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <i className='fas fa-check'></i>,
    2: <i className='fas fa-check'></i>,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? <Check className={classes.completed} /> : icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: '5px',
  },
  instructions: {
    marginTop: '5px',
    marginBottom: '5px',
  },
}));

function getSteps() {
  return [
    ['Step 1', '보안 약점 진단'],
    ['Step 2', '보안 대응 코딩'],
  ];
}

export default function CodeStepper(props) {
  const classes = useStyles();
  const steps = getSteps();

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={props.step - 1}
        connector={<ColorlibConnector />}
        style={{ backgroundColor: '#262626' }}
      >
        {steps?.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <div className='steperText'>
                <h3>{label[0]}</h3>
                <br />
                <p>{label[1]}</p>
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div></div>
    </div>
  );
}

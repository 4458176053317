import React from 'react';
import { Link } from 'react-router-dom';

import THEME from '../../../state/theme';
import styles from './Project.module.scss';

const CourseDetailTop = ({ instData, courseCurrent = [] }) => {
  const instImgUrl = `/../../..${instData.instImage}`;

  return (
    <>
      <div className={styles.breadcrumbs} style={{ color: THEME.color }}>
        <Link to='/student/course' className='secosub1 secoblue'>
          학습실
        </Link>
        <span className='secosub1 secoblack'>
          &nbsp; &nbsp; <i className='fas fa-angle-right' />
          &nbsp; &nbsp;{courseCurrent.courseName}
        </span>
      </div>
      <div className={styles.card} style={{ background: '#f1ba28' }}>
        <p className='secosub1 secoblack' style={{ lineHeight: '100%' }}>
          {courseCurrent.courseType === '1'
            ? '소프트웨어 개발보안 교육'
            : courseCurrent.courseType === '2'
            ? '정보보안 교육'
            : '프로그래밍 기술 교육'}
        </p>
        <p className='secotitle secoblack'>
          {courseCurrent.courseName} {courseCurrent.courseCount}차수
        </p>
        <p className='secosub2 secoblack' style={{ paddingTop: '1rem' }}>
          강의 업데이트 : {courseCurrent.courseRegDate}
        </p>

        <div className={styles.client}>
          <img src={instImgUrl} alt='profile_photo' />
          <div>
            <p className={`${styles.name} secosub1 secoblack`}>강사 : {courseCurrent.courseInst}</p>
            <p className={`${styles.job} secotext secoblack`}>{courseCurrent.courseObj}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetailTop;

import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import MypageMyQandAList from './MypageMyQandAList.js';
import '../MyPage.css';
import styles from './MypageMyQandAIndex.module.scss';

const MypageMyQandAIndex = ({ sidebar = 'left' }) => {
  const ref = useRef(null);
  const [active, setActive] = useState(3);

  const handelClick = (e) => {
    setActive(e);
  };

  return (
    <>
      <div className={styles.mypage} ref={ref}>
        <section className={`${styles.wrapper} ${styles.with_sidebar} ${styles.left}`}>
          <aside className={styles.sidebar}>
            <div className='myPageLeftSide'>
              <ul>
                <li className={active === 1 ? 'mypageLeftsideActive' : ''}>
                  <Link to='/mypage' onClick={() => handelClick(1)}>
                    <span className='secotext'>
                      <i className='far fa-chart-bar fa-2x'></i>
                      <br />
                      학습 현황
                    </span>
                  </Link>
                </li>
                <li className={active === 2 ? 'mypageLeftsideActive' : ''}>
                  <Link to='/information' onClick={() => handelClick(2)}>
                    <span className='secotext'>
                      <i className='far fa-address-book fa-2x'></i>
                      <br />내 정보 관리
                    </span>
                  </Link>
                </li>
                <li className={active === 3 ? 'mypageLeftsideActive' : ''}>
                  <Link to='/question' onClick={() => handelClick(3)}>
                    <span className='secotext'>
                      <i className='far fa-envelope-open sideMenuLast fa-2x'></i>
                      <br />
                      나의 문의
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </aside>

          <article className='mypage'>
            <div className='mypageTable'>
              <MypageMyQandAList />
            </div>
          </article>
        </section>
      </div>
    </>
  );
};

export default MypageMyQandAIndex;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import qs from 'query-string';
import moment from 'moment';

import { useFetchExamProgress } from 'hooks';
import { Header } from '../../../widgets';
import { EXAM_ORDER, LANGUAGE_ID } from 'constants/common';

const Monitoring = () => {
  const useQueryParams = () => {
    const location = useLocation();
    return qs.parse(location.search);
  };

  const [resetSecond, setResetSecond] = useState(0);
  const [fetchTime, setFetchTime] = useState(null);

  const { groupId, languageId, examOrder } = useQueryParams();

  const { data: userList, refetch } = useFetchExamProgress({ groupId, languageId, examOrder });

  const handleFetchButton = () => {
    if (resetSecond !== 0) return;

    refetch();
    setResetSecond(10);
    setFetchTime(moment(new Date()).format('HH:mm:ss'));
  };

  useEffect(() => {
    const blockRefetch = setInterval(() => {
      setResetSecond((prev) => prev - 1);
    }, 1000);

    if (resetSecond === 0) clearInterval(blockRefetch);

    return () => clearInterval(blockRefetch);
  }, [resetSecond]);

  return (
    <>
      <Header>역량평가 모니터링</Header>

      <Container>
        <h2>{`${LANGUAGE_ID[languageId]} - ${EXAM_ORDER[examOrder]} 시험`}</h2>

        <ButtonBox>
          <FetchButton className={!resetSecond ? 'able' : 'disabled'} onClick={handleFetchButton}>
            <span>
              <i className='fa-solid fa-rotate' />
              정보 갱신
            </span>
          </FetchButton>
          {resetSecond !== 0 && <p>{resetSecond}초 뒤 갱신이 가능합니다.</p>}
        </ButtonBox>

        <TableBox>
          {fetchTime && <span>마지막 갱신 시간 : {fetchTime}</span>}
          <StyledTable>
            <thead>
              <tr className='header'>
                <td>사용자 ID</td>
                <td>이름</td>
                <td>시험 시작 시간</td>
                <td>시험 제출 시간</td>
                <td>푼 문제 개수</td>
                <td>제출 상태</td>
              </tr>
            </thead>
            {userList?.map((e, index) => (
              <tbody key={index}>
                <tr>
                  <td>{e.userId}</td>
                  <td>{e.userName}</td>
                  <td>{e.startDateTime ? moment(e.startDateTime).format('YYYY-MM-DD HH:mm') : '-'}</td>
                  <td>{e.submitDateTime ? moment(e.submitDateTime).format('YYYY-MM-DD HH:mm') : '-'}</td>
                  <td>{e.solvedProblemsCount}개</td>
                  <td>{e.submitStatus}</td>
                </tr>
              </tbody>
            ))}
          </StyledTable>
        </TableBox>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 1140px;
  min-height: 433px;
  display: flex;
  flex-direction: column;
  margin: 30px auto;

  h2 {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    color: #2e94d2;
    margin-bottom: 20px;
  }
`;

const ButtonBox = styled.div`
  align-self: end;
  position: relative;
  margin-bottom: 20px;

  > p {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
`;

const FetchButton = styled.span`
  display: inline-block;
  width: 160px;
  height: 45px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;
  background-color: #3c7ca4;
  box-sizing: border-box;
  padding: 10px 20px;
  border: 1px solid #3c7ca4;

  > span {
    width: 160px;
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    z-index: 2;

    i {
      margin-right: 5px;
    }
  }

  &:hover {
    filter: brightness(1.05);
  }

  &.disabled {
    cursor: not-allowed;
    position: relative;
    color: #fff;
    background-color: #3c7ca4;
    overflow: hidden;

    > span {
      animation: text 10s ease-out 0s 1 normal forwards;

      @keyframes text {
        0% {
          color: #3c7ca4;
        }

        20% {
          color: #7baecc;
        }

        60% {
          color: #bed6e7;
        }

        100% {
          color: #fff;
        }
      }
    }

    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      animation: slide 10s linear 0s 1 normal forwards;

      @keyframes slide {
        0% {
          left: 0;
        }

        100% {
          left: 100%;
        }
      }
    }
  }
`;

const TableBox = styled.div`
  margin: 30px 0;
  padding-bottom: 100px;
  position: relative;

  > span {
    font-size: 12px;
    position: absolute;
    right: 0;
    bottom: 100%;
    padding-right: 10px;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 0 1px #7fadc9;

  tr {
    &.header {
      background-color: #2e94d2;
      color: #fff;
      font-weight: bold;
    }
  }

  td {
    text-align: center;
    padding: 20px;
    font-size: 1.6rem;
    border-bottom: 1px solid #eee;
  }
`;

export default Monitoring;

import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import { Header } from '../../widgets';
import { Layout } from '../../layouts';
import { useCustomState } from 'state/state';
import styled from 'styled-components';

const Email = () => {
  const location = useLocation();
  const state = useCustomState()[0];

  const path = location.pathname;
  const service = state.data.footerMenu.find((s) => s.url === path);

  return (
    <Fragment>
      <Header>{service.title}</Header>
      <Layout col='5' padding mode='light' style={{ background: 'rgb(255,255,255)' }}>
        <Container>이메일무단수집거부</Container>
      </Layout>
    </Fragment>
  );
};

const Container = styled.div`
  min-height: calc(100vh - 520px);
`;

export default Email;

import React, { Fragment, useRef, useState } from 'react';
import styles from './MypageMyQandAIndex.module.scss';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import MyQandADetail from './MyQandADetail';
import { AuthAtom } from 'state';
import '../MyPage.css';

const MypageMyQandADetailIndex = ({ sidebar = 'left' }) => {
  const auth = useRecoilValue(AuthAtom);
  const ref = useRef(null);
  const [active, setActive] = useState(3);

  const handelClick = (e) => {
    setActive(e);
  };

  return (
    <Fragment>
      <div className={styles.mypage} ref={ref}>
        <section className={`${styles.wrapper} ${styles.with_sidebar} ${styles.left}`}>
          <aside className={styles.sidebar}>
            <div className='myPageLeftSide'>
              <ul>
                {auth.type === 1 && (
                  <li className={active === 1 ? 'mypageLeftsideActive' : ''}>
                    <Link to='/mypage' onClick={() => handelClick(1)}>
                      <span className='secotext'>
                        <i className='far fa-chart-bar fa-2x'></i>
                        <br />
                        학습 현황
                      </span>
                    </Link>
                  </li>
                )}
                <li className={active === 2 ? 'mypageLeftsideActive' : ''}>
                  <Link to='/information' onClick={() => handelClick(2)}>
                    <span className='secotext'>
                      <i className='far fa-address-book fa-2x'></i>
                      <br />내 정보 관리
                    </span>
                  </Link>
                </li>
                <li className={active === 3 ? 'mypageLeftsideActive' : ''}>
                  <Link to='/question' onClick={() => handelClick(3)}>
                    <span className='secotext'>
                      <i className='far fa-envelope-open sideMenuLast fa-2x'></i>
                      <br />
                      나의 문의
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </aside>

          <article className='mypage'>
            <div className='mypageTable'>
              <MyQandADetail />
            </div>
          </article>
        </section>
      </div>
    </Fragment>
  );
};

export default MypageMyQandADetailIndex;

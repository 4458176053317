import React from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';

import { AuthAtom } from '../../../state';
import moment from 'moment';

const ReportHeader = ({
  title = '페이지 제목을 입력해주세요.',
  display = 'block',
  groupName = '',
  timeStamp = new Date(),
  ...rest
}) => {
  const auth = useRecoilValue(AuthAtom);

  const formattedDate = moment(timeStamp).format('YYYY년 MM월 DD일');

  return (
    <Wrapper display={display} id='ReportDetail' {...rest}>
      <Header>
        <img src='/logo-secolab.png' alt='SECOLAB' style={{ width: '150px' }} />

        <h3>{title}</h3>
      </Header>

      <ReportInfo>
        <div className='info-wrapper'>
          <div>
            <span className='title'>이름</span>
            <span>{auth.name}</span>
            <span className='title'>교육명</span>
            <span>{groupName}</span>
          </div>

          <div className='bottom'>
            <span className='title'>ID</span>
            <span>{auth.userId}</span>
            <span className='title'>출력일</span>
            <span>{formattedDate}</span>
          </div>
        </div>
      </ReportInfo>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: ${(p) => p.display};
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: content-box;

  > img {
    justify-self: flex-end;
    margin-left: auto;
    transform: scale(0.8);
  }

  > h3 {
    font-size: 2.5rem;
    margin: 0;
    padding-top: 0px;
  }
`;

const ReportInfo = styled.div`
  width: 100%;
  padding: 20px 0 10px;

  > div.info-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div {
      box-sizing: border-box;
      width: 100%;
      display: flex;

      &.bottom {
        margin-top: -1px;
      }

      > span {
        display: inline-block;
        font-size: 1.5rem;
        padding: 5px 10px;
        flex: 3;
        max-width: 355px;
        text-align: center;
        border: 1px solid #333;
        box-sizing: border-box;
        margin-right: -1px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &.title {
          background-color: #eee;
          flex: 1;
          font-weight: bold;
        }
      }
    }
  }
`;

export default ReportHeader;

import React, { Fragment } from 'react';

import '../../MyPage.css';

const StudySecureBoard = () => {
  return (
    <Fragment>
      <h3 style={{ textAlign: 'center', marginTop: '60px' }}>서비스 준비중입니다.</h3>
    </Fragment>
  );
};

export default StudySecureBoard;

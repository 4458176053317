import React, { Fragment, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Header } from '../../widgets';
import StudySecureBoard from './StudySecureBoard/index.js';
import AccountInfo from '../MypageMyInformation/AccountInfo.js';
import { AuthAtom } from 'state';
import styles from './Mypage.module.scss';
import '../MyPage.css';

const MypageSub = ({ sidebar = 'left' }) => {
  const auth = useRecoilValue(AuthAtom);
  const ref = useRef(null);
  const [active, setActive] = useState(2);

  const handelClick = (e) => {
    setActive(e);
  };

  return (
    <Fragment>
      <Header>마이 페이지</Header>
      <div className={styles.mypage} ref={ref}>
        <section className={`${styles.wrapper} ${styles.with_sidebar} ${styles.left}`}>
          <aside className={styles.sidebar}>
            <div className='myPageLeftSide'>
              <ul>
                <li className={active === 2 ? 'mypageLeftsideActive' : ''}>
                  <Link to='/information' onClick={() => handelClick(2)}>
                    <span className='secotext'>
                      <i className='far fa-address-book fa-2x'></i>
                      <br />내 정보 관리
                    </span>
                  </Link>
                </li>
                <li className={active === 3 ? 'mypageLeftsideActive' : ''}>
                  <Link to='/question' onClick={() => handelClick(3)}>
                    <span className='secotext'>
                      <i className='far fa-envelope-open sideMenuLast fa-2x'></i>
                      <br />
                      나의 문의
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </aside>

          <article className='mypage'>
            <div className='mypageTable'>{auth.auth && auth.type === 1 ? <StudySecureBoard /> : <AccountInfo />}</div>
          </article>
        </section>
      </div>
    </Fragment>
  );
};

export default MypageSub;

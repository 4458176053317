import React from 'react';
import styled from 'styled-components';

const ReportWrapper = ({ title = '제목을 입력해주세요.', children }) => {
  return (
    <Wrapper>
      <h3>{title}</h3>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 10px 0 15px;

  > h3 {
  }
`;

export default ReportWrapper;

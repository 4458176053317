import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { useFetchCategoryResult } from 'hooks';
import ExamInfoTable from '../ExamUserList/ExamInfoTable';
import GroupCategoryResultTable from './GroupCategoryResultTable';
import { Header } from '../../../widgets';

const CategoryResult = () => {
  const { state } = useLocation();

  const { groupId, languageId } = state || {};

  const { data, isLoading } = useFetchCategoryResult({ groupId, languageId });
  const { firstExamSubCorrectRates, secondExamSubCorrectRates } = data || {};

  const examInfo = useMemo(() => {
    const { groupCourse, groupCourseCount, groupDueDate, groupId, groupName, groupStartDate } = data || {};
    return {
      groupCourse,
      groupCourseCount,
      groupDueDate,
      groupId,
      groupName,
      groupStartDate,
    };
  }, [data, isLoading]);

  return (
    <>
      <Header>카테고리별 문제 정답률</Header>

      <Container>
        {/* 시험 정보 테이블 */}
        <ExamInfoTable examInfo={examInfo} />

        {/* 카테고리별 그룹 평균 */}
        <GroupCategoryResultTable
          firstExamSubCorrectRates={firstExamSubCorrectRates}
          secondExamSubCorrectRates={secondExamSubCorrectRates}
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 1140px;
  min-height: 433px;
  display: flex;
  flex-direction: column;
  margin: 30px auto;
`;

export default CategoryResult;

import React, { useState } from 'react';

import { Layout } from '../../layouts';
import { Button } from '../../elements';
import THEME from 'state/theme';
import styles from './ProcessPrice.module.scss';

const ProcessPrice = ({ data = [] }) => {
  const processList = data?.map((item, index) => (
    <div key={index} className={styles.wrapper}>
      <Card data={item} index={index} />
    </div>
  ));

  return (
    <Layout
      col='4'
      padding
      mode='light'
      style={{
        paddingTop: '5rem',
        background: 'rgb(255,255,255)',
      }}
    >
      <div className={'layout-header'}>
        <h2 className='secosub1 secoblack'>기업용 이용권 구매 방법</h2>
        <span className='secomin subtitle' style={{ color: THEME.color }}>
          Overview for Enterprise
        </span>
      </div>

      {processList}

      <div className={`layout-footer ${styles.footer}`}></div>
    </Layout>
  );
};

const Card = ({ data, index }) => {
  const [hover] = useState(false);

  return (
    <div className={styles.step}>
      <span className={styles.number}>{index + 1 + '.'}</span>
      <div
        className={styles.info}
        style={{
          opacity: hover ? '0' : '1',
          transitionDelay: hover ? '.1s' : '.6s',
        }}
      >
        <h4 className='secosub1'>{data.title}</h4>
        <p className='secotext'>{data.text}</p>
      </div>
      <ul
        style={{
          opacity: hover ? '1' : '0',
          transitionDelay: hover ? '.6s' : '.1s',
        }}
        className={styles.list}
      ></ul>
      <div className={styles.btn}>
        <Button hoverType='solid-gray-tb' round after='&#xf101;' />
      </div>
    </div>
  );
};

export default ProcessPrice;

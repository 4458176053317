import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Skeleton } from '@mui/lab';

import { useFetchTrainVideoList } from '../../../hooks/student.hook';

const DetailAsideChapter = ({ courseTotal = [], isCourseDetailAside = false }) => {
  const { instId, courseType, courseId, categoryId, mediaId } = useParams();

  const [list, setList] = useState({
    selected: 0,
  });

  const courseCurrent = courseTotal.find((item) => {
    if (courseTotal.length <= 0) return;
    return item.courseId.toString() === courseId;
  });
  const { data: videoList = [] } = useFetchTrainVideoList({ courseId, courseType });
  const { data: courseVideoList = [] } = useFetchTrainVideoList({ courseId, courseType });

  useEffect(() => {
    if (isCourseDetailAside) return;

    const selectedVideo = courseVideoList.findIndex((e) => e.mediaId === mediaId);

    setList({
      selected: selectedVideo,
    });
  }, [courseVideoList, mediaId]);

  const clickedStyle = (index) => {
    setList({ selected: index });
  };

  return (
    <>
      <div className='vidLeft'>
        {/* <h4>{courseCurrent && courseCurrent.courseName}</h4> */}
        <div className='chapterList'>
          <h2 className='secotitle secoblue'>CHAPTERS</h2>

          {/*CourseDetail에서 사용할 때*/}
          {isCourseDetailAside ? (
            <ul>
              {videoList.length <= 0 && (
                <SkeletonBox>
                  {Array.from({ length: 12 }).map((_, index) => (
                    <Skeleton key={index} animation='wave' width='100%' height='47px' />
                  ))}
                </SkeletonBox>
              )}
              {videoList.map((v, index) => {
                return courseCurrent ? (
                  <Link
                    key={index}
                    to={{
                      pathname: `/student/videodetail/${courseCurrent.instId}/${courseType}/${courseId}/${v.videoName}/${v.videoId}/${v.mediaId}/${categoryId}`,
                    }}
                  >
                    <li className='secodgray secotext' style={{ letterSpacing: '-0.8px' }}>
                      <p>{v.videoName}&nbsp;&nbsp;</p>
                      <span>
                        {`${v.trainProgress}` !== 0 &&
                          (`${v.trainProgress}` < 82 ? (
                            <CircularProgressbar
                              value={v.trainProgress}
                              text={`${v.trainProgress}%`}
                              strokeWidth='15'
                              styles={{
                                path: {
                                  stroke: `rgb(30 216 87)`,
                                },
                                text: {
                                  fill: '#212529',
                                  fontSize: '33px',
                                  dominantBaseline: 'central',
                                },
                              }}
                            />
                          ) : (
                            <span style={{ fontSize: '20px', color: 'lightgreen' }}>
                              <i className='fas fa-check-circle'></i>
                            </span>
                          ))}
                      </span>
                    </li>
                  </Link>
                ) : null;
              })}
            </ul>
          ) : (
            <ul>
              {courseVideoList.length <= 0 && (
                <SkeletonBox>
                  {Array.from({ length: 12 }).map((_, index) => (
                    <Skeleton key={index} animation='wave' width='100%' height='47px' />
                  ))}
                </SkeletonBox>
              )}
              {courseVideoList.map((v, index) => {
                return (
                  <Link
                    key={index}
                    to={`/student/videodetail/${instId}/${courseType}/${courseId}/${v.videoName}/${v.videoId}/${v.mediaId}/${categoryId}`}
                  >
                    <li
                      className='secodgray secotext'
                      onClick={() => clickedStyle(index)}
                      style={
                        list.selected === index
                          ? {
                              backgroundColor: '#f6fcff',
                              color: '#333',
                              fontWeight: 'bold',
                              letterSpacing: '-0.6px',
                            }
                          : { letterSpacing: '-0.8px' }
                      }
                    >
                      <p>{v.videoName}&nbsp;&nbsp;</p>
                      <span>
                        {`${v.trainProgress}` !== 0 &&
                          (`${v.trainProgress}` < 82 ? (
                            <CircularProgressbar
                              value={v.trainProgress}
                              text={`${v.trainProgress}%`}
                              strokeWidth='15'
                              styles={{
                                path: {
                                  stroke: `rgb(30 216 87)`,
                                },
                                text: {
                                  fill: '#212529',
                                  fontSize: '33px',
                                  dominantBaseline: 'central',
                                },
                              }}
                            />
                          ) : (
                            <span style={{ fontSize: '20px', color: 'lightgreen' }}>
                              <i className='fas fa-check-circle'></i>
                            </span>
                          ))}
                      </span>
                    </li>
                  </Link>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

const SkeletonBox = styled.div`
  .MuiSkeleton-text {
    transform: unset;
    margin-bottom: 3px;
  }
`;

export default DetailAsideChapter;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import Button from '../../../elements/Button/Button';
import { MESSAGE } from 'utilities';
import { fetchReviewDetail } from 'api/customer';
import { errorToast } from 'utilities/toast';
import '../detailStyle.css';

export default function Detail({ history }) {
  const { id } = useParams();
  const [list, setList] = useState([]);

  // 로그인 정보 없을 시 홈으로 리다이렉트
  useRedirectWithoutAuth();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchReviewDetail(id);
        setList(response.data);
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.status === 401 || error.response.status === 403) {
            errorToast(MESSAGE.SERVICE_INVALID_TOKEN);
          }
          history.push('/');
        }
      }
    })();
  }, []);

  const handleClick = async (id) => {
    try {
      const response = await fetchReviewDetail(id);
      setList(response.data);
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status === 401 || error.response.status === 403) {
          errorToast(MESSAGE.SERVICE_INVALID_TOKEN);
        }
        history.push('/');
      }
    }
  };

  return (
    <div className='detailContainer'>
      <div className='row'>
        <div style={{ marginBottom: '2vh', width: '250px' }}>
          <Button roundSmall to='/customer/review' type='outline-color-cl' hoverType='solid-color-tb'>
            <span className='secotext'>
              사용자 후기 목록<i className='las la-file-code'></i>
            </span>
          </Button>
        </div>
      </div>
      <div className='row detailBox'>
        <div className='col-md-2 detailLeft secotext'>제목</div>
        <div className='col-md-10 detailRight secotext'>{list.postTitle}</div>
      </div>
      <div className='row detailBox'>
        <div className='col-md-2 detailLeft secotext'>등록일자</div>
        <div className='col-md-10 detailRight secotext'>{list.postRegDate}</div>
        {/*
            <div className="col-md-2 detailLeft">조회수</div>
            <div className="col-md-2 detailRight">{list.postHitCount}</div>
            */}
      </div>
      <div className='row detailBox'>
        <div className='col-md-2 detailLeft secotext'>작성자</div>
        <div className='col-md-10 detailRight secotext'>{list.postAuthor}</div>
      </div>
      <div
        className='row detailcontentBox'
        style={{ marginBottom: '50px', borderRadius: '0 0 5px 5px', backgroundColor: 'rgba(0,0,0,0.5' }}
      >
        <div className='col-md-2 detailLeft secotext' style={{ color: 'white' }}>
          후기 내용
        </div>
        <div className='col-md-10 detailRight secotext' style={{ color: 'white' }}>
          {list.postContent}
        </div>
      </div>

      <div className='row'>
        <div style={{ marginBottom: '2vh', width: '150px' }}>
          {list.prevId > 0 ? (
            <Button
              roundSmall
              click={() => handleClick(list.prevId)}
              //to={"/customer/faqdetail/"+list.prevId}

              type='outline-color-cl'
              hoverType='solid-color-tb'
            >
              <span className='secotext'>
                <i className='las la-angle-left'></i>&nbsp;&nbsp;이전글
              </span>
            </Button>
          ) : (
            <Button
              roundSmall
              disabled
              //to={"/customer/librarydetail/"+list.prevId}
              type='outline-color-cl'
            >
              <span className='secotext'>
                <i className='las la-angle-left'></i>&nbsp;&nbsp;이전글
              </span>
            </Button>
          )}
        </div>
        <div style={{ marginBottom: '2vh', marginLeft: '25px', width: '150px' }}>
          {list.nextId > 0 ? (
            <Button
              roundSmall
              click={() => handleClick(list.nextId)}
              //to={"/customer/faqdetail/"+list.nextId}

              type='outline-color-cl'
              hoverType='solid-color-tb'
            >
              <span className='secotext'>
                다음글&nbsp;&nbsp;<i className='las la-angle-right'></i>
              </span>
            </Button>
          ) : (
            <Button
              roundSmall
              disabled
              //to={"/customer/librarydetail/"+list.nextId}
              type='outline-color-cl'
            >
              <span className='secotext'>
                다음글&nbsp;&nbsp;<i className='las la-angle-right'></i>
              </span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

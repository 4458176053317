import React, { Fragment } from 'react';

import { Header } from '../../widgets';
import DashBoardList from './DashBoardList';
import Layout from '../../layouts/Layout/Layout';
import '../Mypage/MyPage.css';

const DashBoard = () => {
  return (
    <Fragment>
      <Header>대시보드</Header>
      <div style={{ margin: '50px 0 50px 0' }}>
        <Layout col='1'>
          <DashBoardList />
        </Layout>
      </div>
    </Fragment>
  );
};

export default DashBoard;

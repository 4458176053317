import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';

import { useFetchExamReport, useFetchExamResultInfo, useFetchExamReportAvg, useFetchUserReportLanguage } from 'hooks';
import { Header } from 'components/widgets';
import {
  ExamResult,
  ExamSummaryResult,
  ExamRadarChart,
  ExamCorrectIncorrect,
  ExamRiskResult,
  ExamComment,
  ReportFooter,
  ReportHeader,
} from 'components/widgets/CapabilityReport';

const Report = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const initialLanguageId = pathname.split('/').pop() || '';

  const [selectLanguageId, setSelectLanguageId] = useState(initialLanguageId);

  /* 유저 응시 언어 정보 */
  const { data: languageData } = useFetchUserReportLanguage();

  /* 역량평가 응시 상세 정보 */
  const { data: examInfo } = useFetchExamResultInfo({ languageId: selectLanguageId });
  const { examResultInfos } = examInfo || {};

  /* 정오표 */
  const { data: correctIncorrectData, personalScore } = useFetchExamReport({
    languageId: selectLanguageId,
  });

  /* 응시 평균 */
  const { data: avgResult } = useFetchExamReportAvg({ languageId: selectLanguageId });
  const { middleCategoryCorrectRates, riskLevelCorrectRates } = avgResult || {};

  const handlePrint = () => window.print();

  const handleGoback = () => history.goBack();

  const handleChangeLanguageId = (e) => {
    const selectedId = e.target.value;
    history.replace(`/student/capability/report/${selectedId}`);
    setSelectLanguageId(selectedId);
  };

  useEffect(() => {
    if (!initialLanguageId) setSelectLanguageId(languageData?.[0]?.languageId);
  }, [initialLanguageId, languageData]);

  return (
    <>
      <Header>역량평가 결과 보고서</Header>

      {/* 언어 선택 셀렉트 박스 */}
      <SelectBox className='not-print' id='SelectBox'>
        <div>
          <label htmlFor='selectLaguage'>역량평가 목록</label>
          <select id='selectLaguage' onChange={handleChangeLanguageId}>
            {languageData?.map((item, index) => (
              <option key={index} value={item.languageId}>
                {item.languageName}
              </option>
            ))}
          </select>
        </div>
      </SelectBox>

      <Wrapper id='report'>
        {/* 리포트 Header */}
        <ReportHeader title='역량평가 결과 보고서' groupName={examInfo?.groupName} timeStamp={examInfo?.timestamp} />

        <Notice>
          시큐어 코딩 역량평가는 시큐어 코딩 개요와 49개 보안약점에 대한 유형별 총 100개 문항으로 실시되며, 교육 전에
          실시되는 사전 역량평가와 교육 후에 실시되는 사후 역량평가를 통해 시큐어 코딩에 대한 이해도를 평가합니다.
        </Notice>

        {/* 개인 역량평가 종합 결과 */}
        <ExamSummaryResult summaryResult={examResultInfos || []} riskAvg={riskLevelCorrectRates} />

        {/* 기술 요소별 역량평가 결과(레이더 차트) */}
        <ExamRadarChart groupAvg={middleCategoryCorrectRates} correctIncorrectData={correctIncorrectData} />

        {/* 개인 역량평가 결과(테이블, 그래프) */}
        <ExamResult personalScore={personalScore} groupAvg={middleCategoryCorrectRates || {}} />

        {/* 리포트 Footer */}
        <div style={{ height: '50px' }} />
        <ReportFooter page='1' />

        {/* ============ 페이지 구분 ============ */}

        {/* 리포트 Header 2 */}
        <ReportHeader
          title='역량평가 결과 보고서(상세)'
          display='none'
          groupName={examInfo?.groupName}
          timeStamp={examInfo?.timestamp}
        />

        {/* 개인 역량평가 상세 결과(정오표 테이블) */}
        <ExamCorrectIncorrect correctIncorrectData={correctIncorrectData} />

        {/* 보안 위험도별 역량평가 결과 (테이블, 그래프) */}
        <ExamRiskResult riskAvg={riskLevelCorrectRates} correctIncorrectData={correctIncorrectData} />

        {/* 역량평가 총평 */}
        <ExamComment />

        {/* 리포트 Footer */}
        <ReportFooter page='2' />
      </Wrapper>

      <ButtonWrapper id='Button'>
        <button onClick={handleGoback} className='goBackButton'>
          <i className='fas fa-angle-left'></i> 뒤로가기
        </button>

        <button onClick={handlePrint} className='printButton'>
          <i className='fas fa-print'></i> 인쇄하기
        </button>
      </ButtonWrapper>
    </>
  );
};

const SelectBox = styled.div`
  width: 1000px;
  height: 50px;
  margin: 20px auto 0;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  label {
    height: 30px;
    color: #333;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: -11px;
    z-index: 999;
    padding: 3px;
    background-color: #fff;
  }

  select {
    min-width: 120px;
    height: 100%;
    padding: 8px 5px 5px 5px;
    font-size: 1.3rem;
    border-radius: 10px;
    outline: 0;
  }
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 1000px;
  margin: 0 auto;
  min-height: calc(100vh - 520px);
  padding: 20px 0 30px;
`;

const Notice = styled.div`
  padding: 0 20px 10px;
  font-size: 1.35rem;
  word-break: keep-all;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0 50px;
`;

export default Report;

import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

const LoadingSpinner = () => {
  return (
    <LoadingSpinnerWrapper>
      <CircularProgress />
    </LoadingSpinnerWrapper>
  );
};

const LoadingSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 500px;

  .MuiCircularProgress-colorPrimary {
    color: rgb(44, 149, 210);
  }
`;

export default LoadingSpinner;

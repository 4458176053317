/* 소수점 둘째자리까지 반올림하여 반환 */
export const sliceRoundNumber = (number) => {
  if (number === undefined) return;

  return number.toFixed(1);
};

/* 분:초 변환 */
export const secondToClock = (second) => {
  let min = parseInt(second / 60);
  let sec = second % 60;

  if (min === 0) {
    return `${sec}초`;
  } else {
    return `${min}분 ${sec}초`;
  }
};

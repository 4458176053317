import { useState, useEffect } from 'react';
import { errorToast } from '../utilities/toast';
import { fetchMidibusMediaDetail } from '../api/midibus';
import { getItem } from '../commons/localStorage';

export const useFetchMidibusMediaDetail = (categoryId, mediaId) => {
  const midibusToken = getItem('midibusToken');

  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [mediaContentKey, setMediaContentKey] = useState('');
  const [mediaDuration, setMediaDuration] = useState('');
  const [mediaCreated, setMediaCreated] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setIsVideoLoading(true);
        const mediaDetailResponse = await fetchMidibusMediaDetail({ midibusToken, categoryId, mediaId });
        setMediaContentKey(mediaDetailResponse.data.object_simple_list[0].object_id);
        setMediaDuration(mediaDetailResponse.data.duration);
        setMediaCreated(mediaDetailResponse.data.created);
        setIsVideoLoading(false);
      } catch (error) {
        setIsVideoLoading(false);
        errorToast('Error!');
      }
    })();
  }, [mediaId, categoryId]);

  return { isVideoLoading, mediaContentKey, mediaDuration, mediaCreated };
};

/* eslint-disable */

import React, { Fragment, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { errorToast } from 'utilities/toast';
import './TakeTestModal.css';
import { useRecoilValue } from 'recoil';
import { AuthAtom } from '../../../../state';
import { createUserExamStart } from 'api/capability';

export default function TakeTestModal(props) {
  const auth = useRecoilValue(AuthAtom);
  const { userId } = auth;
  const history = useHistory();
  const match = useRouteMatch();

  const [isLoading, setIsLoading] = useState(false);

  const { languageId } = props;

  const handleClickStart = async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      const { data } = await createUserExamStart({
        userId,
        languageId: +languageId,
      });
      if (data.msg === 'success') {
        history.push(`${match.url}/test`);
      } else {
        errorToast('페이지 접근 오류 발생했습니다. 서버가 정상적으로 작동되었는지 확인하세요. ');
      }
      setIsLoading(false);
    } catch (error) {
      if (error.response !== undefined) {
        history.push('/');
      }
      setIsLoading(false);
    }
  };

  const handleClickCancel = () => {
    return props.closeModal();
  };

  const handleClickContinue = () => {
    history.push(`${match.url}/test`);
  };

  return (
    <Fragment>
      <div className='testConfirmModalContainer'>
        {props.testData.isStarted === 0 ? (
          <div className='testConfirmModal'>
            <div className='testConfirmText'>
              <p>
                <i className='fas fa-info-circle'></i>&nbsp;&nbsp;역량평가 테스트를 시작하시겠습니까?
              </p>
            </div>
            <div className='btnGroup'>
              <button onClick={() => handleClickCancel()} className='cancelBtn' title='취소'>
                취소
              </button>
              <button onClick={() => handleClickStart()} className='startBtn' title='시작'>
                시작
              </button>
            </div>
          </div>
        ) : (
          <div className='testConfirmModal'>
            <div className='testConfirmText'>
              <p>
                <i className='fas fa-info-circle'></i>&nbsp;&nbsp;역량평가 테스트를 이어서 하시겠습니까?
              </p>
            </div>
            <div className='btnGroup'>
              <button onClick={() => handleClickCancel()} className='cancelBtn' title='취소'>
                취소
              </button>
              <button onClick={() => handleClickContinue()} className='startBtn' title='계속'>
                계속
              </button>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

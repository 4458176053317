import React from 'react';
import { useRecoilValue } from 'recoil';

import { AuthAtom } from 'state';
import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import MypageMyInformationIndex from './MypageMyInformationIndex';
import MypageMyInformationSub from './MypageMyInformationSub';
import { Header } from '../../widgets';

const Information = () => {
  const { auth, type } = useRecoilValue(AuthAtom);

  useRedirectWithoutAuth();

  return (
    <>
      <Header>내 정보 관리</Header>
      {auth && type === 1 && <MypageMyInformationIndex />}
      {auth && type === 2 && <MypageMyInformationSub />}
    </>
  );
};

export default Information;

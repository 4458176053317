import React from 'react';

import { useCustomState } from 'state/state';
import styles from './Backdrop.module.scss';

const Backdrop = () => {
  const [state, actions] = useCustomState();

  const hideContent = () => {
    state.sidebar && actions.toggleSidebar();
    state.modal && actions.toggleModal();
    state.request.modal && actions.toggleRequest();
    state.signconfirm.modal && actions.toggleSignConfirm();
    state.video && actions.toggleVideo();
  };

  return <div className={styles.backdrop} onClick={hideContent} />;
};

export default Backdrop;

import React, { useState, Fragment, useEffect } from 'react';
import CourseInformation from './CourseInformation';
import PortfolioCourseType from '../Portfolio/PortfolioCourseType';
import PortfolioCourseInst from '../Portfolio/PortfolioCourseInst';
import styles from './Project.module.scss';
import 'react-circular-progressbar/dist/styles.css';

import { useRecoilValue } from 'recoil';
import { AuthAtom } from '../../../state';
import { useFetchCourseList } from '../../../hooks/student.hook';

const CourseDetailContent = ({
  courseTotal = [],
  courseCurrent = [],
  instData,
  introData,
  currentCourseId,
  midibusCategory = [],
}) => {
  const auth = useRecoilValue(AuthAtom);
  const { token } = auth;

  const [accessibleCourseId, setAccessibleCourseId] = useState([]);

  const diffCourse = courseTotal.filter((fi) => fi !== courseCurrent);
  const sameCourseType = diffCourse.filter((fi) => fi.courseType.includes(courseCurrent.courseType));
  const sameCourseInst = diffCourse.filter((fi) => fi.courseInst.includes(courseCurrent.courseInst));

  const { data: courseListData } = useFetchCourseList({ token });

  useEffect(() => {
    if (!courseListData) return;

    setAccessibleCourseId(courseListData.data.accessibleCourseId);
  }, [courseListData]);

  return (
    <Fragment>
      <div className='vidRight'>
        <div className='courseIntroTab'>
          <CourseInformation instData={instData} introData={introData} />
        </div>

        {sameCourseType.length < 1 ? (
          <br />
        ) : (
          <>
            <div className={`${styles.courseH3} secosub1 secoblue`} style={{ marginTop: '40px' }}>
              <i className='far fa-file-alt'></i>&nbsp;&nbsp;과정구분 추천
            </div>
            <div>
              {/* 과정구분추천 */}
              <PortfolioCourseType
                data={sameCourseType}
                courseCurrent={courseCurrent}
                currentCourseId={currentCourseId}
                midibusCategory={midibusCategory}
                accessibleCourseId={accessibleCourseId}
              />
            </div>
          </>
        )}

        {sameCourseInst.length < 1 ? (
          <br />
        ) : (
          <>
            <div className={`${styles.courseH3} secosub1 secoblue`}>
              <i className='far fa-address-book'></i>&nbsp;&nbsp;{courseCurrent.courseInst} 강사님 다른 강의
            </div>
            <div>
              {/* 강사 다른 강의 */}
              <PortfolioCourseInst
                data={sameCourseInst}
                courseCurrent={courseCurrent}
                currentCourseId={currentCourseId}
                midibusCategory={midibusCategory}
                accessibleCourseId={accessibleCourseId}
              />
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default CourseDetailContent;

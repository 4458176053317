import React, { useEffect, useState, Fragment } from 'react';
import { useRecoilValue } from 'recoil';
import { TextField, Grid, InputLabel, MenuItem, FormControl, Select } from '@mui/material';

import { AuthAtom } from 'state';
import { errorToast, infoToast, successToast } from 'utilities/toast';
import './ContactForm.css';
import { fetchCategoryName, fetchPlatformList, submitContactForm } from '../../../api/customer';
import styled from 'styled-components';

const ContactForm = ({ history }) => {
  const auth = useRecoilValue(AuthAtom);
  const { type, status, userId, name } = auth;

  useEffect(() => {
    if (type === null) {
      errorToast('로그인이 필요합니다.');
      history.push('/');
    } else if (status === 0) {
      infoToast('신청 대기중입니다');
      history.push('/');
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const [cType, setCtype] = useState('');
  const [cName, setCName] = useState('');
  const [values, setValues] = useState({
    postCategoryId: '',
    postTitle: '',
    postContent: '',
    postAuthor: '',
    userId: '',
  });
  const [platformDatas, setPlatformDatas] = useState([]);
  const [categoryTypeDatas, setCategoryTypeDatas] = useState([]);

  const handleChange = (event) => {
    setCtype(event.target.value);
    setCName('');
    getCategoryName(event.target.value);
  };

  const handleChangeCategoryName = (event) => {
    setCName(event.target.value);
  };

  const [error, setError] = useState({
    postTitleError: '',
    postCategoryIdError: '',
    postContentError: '',
  });

  const getCategoryName = async (categoryType) => {
    try {
      const response = await fetchCategoryName(categoryType);
      setCategoryTypeDatas(response.data);
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status === 401) {
          errorToast('토큰이 유효하지 않습니다! 로그아웃됩니다.');
          history.push('/');
        } else if (error.response.status === 403) {
          errorToast('토큰이 만료되었습니다. 다시 로그인해주세요 :)');
          history.push('/');
        }
      }
    }
  };

  const onTextValidation = () => {
    let postTitleError = '';
    let postCategoryIdError = '';
    let postContentError = '';

    if (values.postTitle.length === 0) postTitleError = '제목을 입력해주세요.';
    if (values.cName === 0) postCategoryIdError = '카테고리를 선택해 주세요.';
    if (values.postContent.length === 0) postContentError = '문의내용을 입력해 주세요.';

    setError({
      postTitleError,
      postCategoryIdError,
      postContentError,
    });

    if (postTitleError || postCategoryIdError || postContentError) return false;
    return true;
  };

  const handleChangeForm = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isLoading) return;

    const valid = onTextValidation();

    if (!valid) console.error('invalid');
    else {
      try {
        const categoryId = cName;
        setIsLoading(true);
        await submitContactForm({
          postCategoryId: categoryId,
          postTitle: values.postTitle,
          postContent: values.postContent,
          postAuthor: name,
          userId,
        });
        successToast('문의글이 정상 등록되었습니다.');
        setIsLoading(false);
        history.push('/question');
      } catch (error) {
        errorToast('죄송합니다. 문의글이 정상 등록되지 못했습니다.');
        setValues({ postCategoryId: '', postTitle: '', postContent: '', postAuthor: '' });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchPlatformList();
        let data = response.data;
        data.sort((a, b) => {
          if (a.categoryType === '일반') return -1;
          if (b.categoryType === '일반') return 1;
          return 0;
        });

        setPlatformDatas(data);
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            errorToast('토큰이 유효하지 않습니다! 로그아웃됩니다.');
            history.push('/');
          } else if (error.response.status === 403) {
            errorToast('토큰이 만료되었습니다. 다시 로그인해주세요 :)');
            history.push('/');
          }
        }
      }
    })();
  }, []);

  return (
    <Fragment>
      <StyledForm onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              autoComplete='postTitle'
              fullWidth
              label='제목을 입력하세요.'
              name='postTitle'
              value={values.postTitle}
              onChange={handleChangeForm}
            />
            <div
              style={{
                color: 'red',
                fontSize: '12px',
                margin: '-5px 0 10px 15px',
                fontFamily: 'Noto Sans KR',
              }}
            >
              {error.postTitleError}
            </div>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant='outlined' margin='normal' fullWidth required>
              <InputLabel id='outlined-age-native-required'>플랫폼 타입을 선택해 주세요.</InputLabel>
              <Select
                variant='outlined'
                id='outlined-age-native'
                value={cType}
                onChange={handleChange}
                name=''
                label='플랫폼 타입을 선택해 주세요.'
              >
                {platformDatas?.map((s, idx) => {
                  return (
                    <MenuItem key={idx} value={s.categoryType}>
                      {s.categoryType}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          {cType === '' ? (
            <Grid item xs={12}>
              <FormControl variant='outlined' margin='normal' fullWidth required disabled>
                <InputLabel id='outlined-category-native-required'>카테고리를 선택해 주세요.</InputLabel>
                <Select
                  variant='outlined'
                  id='outlined-category-native'
                  value={cName}
                  onChange={handleChangeCategoryName}
                  label='카테고리를 선택해 주세요.'
                ></Select>
              </FormControl>
              <div
                style={{
                  color: 'red',
                  fontSize: '12px',
                  margin: '-5px 0 10px 15px',
                  fontFamily: 'Noto Sans KR',
                }}
              >
                {error.postCategoryIdError}
              </div>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <FormControl variant='outlined' margin='normal' fullWidth required>
                <InputLabel id='outlined-category-native-required'>카테고리를 선택해 주세요.</InputLabel>
                <Select
                  variant='outlined'
                  value={cName}
                  onChange={handleChangeCategoryName}
                  label='카테고리를 선택해 주세요.'
                >
                  {categoryTypeDatas?.map((s, idx) => {
                    return (
                      <MenuItem key={idx} value={s.categoryId}>
                        {s.categoryName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <div
                style={{
                  color: 'red',
                  fontSize: '12px',
                  margin: '-5px 0 10px 15px',
                  fontFamily: 'Noto Sans KR',
                }}
              >
                {error.categoryNameError}
              </div>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              multiline
              minRows={10}
              required
              autoComplete='postContent'
              fullWidth
              label='문의사항을 입력해주세요.'
              name='postContent'
              value={values.postContent}
              onChange={handleChangeForm}
            />
            <div
              style={{
                color: 'red',
                fontSize: '12px',
                margin: '-5px 0 10px 15px',
                fontFamily: 'Noto Sans KR',
              }}
            >
              {error.postContentError}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                paddingTop: '10px',
                borderBottom: '1px solid #e3e3e3',
                margin: '15px 0',
                fontFamily: 'Noto Sans KR',
              }}
            ></div>
          </Grid>
        </Grid>

        <button
          type='submit'
          color='primary'
          style={{
            margin: 'auto',
            marginTop: '20px',
            padding: '10px 30px',
            borderRadius: '50px',
            fontFamily: 'Noto Sans KR',
          }}
        >
          <span className='secosub2'>문의 등록</span>
        </button>
      </StyledForm>
    </Fragment>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export default ContactForm;

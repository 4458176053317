import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button } from '@mui/material';

import Layout from '../../layouts/Layout/Layout';
import THEME from 'state/theme';
import { infoToast } from 'utilities/toast';
import PortfolioLink from './PortfolioLink';
import { useCreateUserVideoTrain } from '../../../hooks/student.hook';
import { fetchTrainVideoList } from '../../../api/student';
import styles from './Portfolio.module.scss';

// 교육 종류별 data
const CourseCard = ({ data = [], midibusCategory = [], accessibleCourseId = [], currentCourseId }) => {
  const [amount, setAmount] = useState(4);
  const [isLoading, setIsLoading] = useState(false);

  const mutation = useCreateUserVideoTrain();

  const isAllowedCourse = (courseId) => !!currentCourseId.includes(courseId);

  const getCategoryId = (courseName) => {
    if (midibusCategory.length === 0) return;

    const findCategoty = midibusCategory.find((category) => category.category_name === courseName.replaceAll(' ', ''));

    if (findCategoty) return findCategoty.category_id;
    if (!findCategoty) return null;
  };

  const isVideoTrain = async (ctype, cid, courseName) => {
    if (isLoading || !isAllowedCourse(cid)) return;

    setIsLoading(true);
    const { data } = await fetchTrainVideoList({
      courseId: cid,
      courseType: ctype,
    });
    if (data.data.videoTrainList.length === 0) {
      infoToast(`${courseName} 강의를 수강합니다.`, 2000);

      await mutation.mutate({ courseId: cid });

      setIsLoading(false);
    }
  };

  const portfolioList = data
    ?.map((item, index) => (
      <div key={index} className={styles.wrapper}>
        <div
          className={styles.image}
          style={{
            backgroundImage: `url("../../courseimage/${item.courseImage}")`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            borderColor: THEME.color,
          }}
        >
          {item.courseMainYn === '2' || item.courseMainYn === '1' ? (
            <PortfolioLink
              item={item}
              targetURL={`/student/coursedetail/${item.courseType}/${item.courseId}/${item.instId}/${getCategoryId(
                item.courseName
              )}`}
              accessibleCourseId={accessibleCourseId}
              isAllowedCourse={isAllowedCourse}
              isVideoTrain={isVideoTrain}
            />
          ) : (
            <Link to='#' style={{ pointerEvents: 'none' }}>
              <div className={styles.button}>
                <h3 className='secosub2'>과정&nbsp;:&nbsp;총&nbsp;{item.courseCount}강</h3>
                <p className='secosub2'>{item.courseObj}</p>
                <Button variant='contained' style={{ backgroundColor: '#808080', color: '#fff' }}>
                  <p className='secosub2'>오픈 예정</p>
                </Button>
              </div>
            </Link>
          )}
        </div>

        <div className={styles.info}>
          {item.courseType === '1' ? (
            <span style={{ color: THEME.color }}>
              <p className='secosub2 secoblue'>소프트웨어 개발보안 교육</p>
            </span>
          ) : item.courseType === '2' ? (
            <span style={{ color: THEME.color }}>
              <p className='secosub2 secoblue'>정보보안 교육</p>
            </span>
          ) : (
            <span style={{ color: THEME.color }}>
              <p className='secosub2 secoblue'>프로그래밍 기술 교육</p>
            </span>
          )}

          <h3 className='secosub2'>
            {item.courseMainYn === 2 || item.courseMainYn === 1 ? (
              <Link
                to={`/student/coursedetail/${item.courseType}/${item.courseId}/${item.instId}`}
                onClick={function () {
                  isVideoTrain(item.courseType, item.courseId, item.courseName);
                }}
              >
                {item.courseName}
              </Link>
            ) : (
              <Link to='#' style={{ pointerEvents: 'none', textTransform: 'none' }}>
                {item.courseName}
              </Link>
            )}
          </h3>

          <p className='secotext secodgray'>강사 : {item.courseInst} </p>
        </div>
      </div>
    ))
    .slice(0, amount);

  const toggleAmount = () => {
    setAmount(amount === data.length ? 4 : data.length);
  };

  return (
    <Grid container col='1'>
      <Grid item xs={11}>
        <div
          className={styles.portfolio}
          style={{
            borderColor: THEME.color,
            transform: 'translateX(15px)',
          }}
        >
          {portfolioList}
        </div>
      </Grid>
      <Grid item xs={1}>
        {data.length > 4 ? (
          <Button
            onClick={toggleAmount}
            variant='outlined'
            color='primary'
            style={{
              height: '22rem',
              width: '20px',
              margin: '50px 0 0 10px',
              border: 'thin solid #e7e7e7 !important',
            }}
          >
            {amount === data.length ? <i className='fas fa-minus'></i> : <i className='fas fa-plus'></i>}
          </Button>
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  );
};

export default CourseCard;

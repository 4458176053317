import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import { useCheckUserExamStatus } from 'hooks';
import { Header } from 'components/widgets';
import { useFetchLanguages } from 'hooks/common';
import { LoadingSpinner } from '../../../../components/ui';
import { AuthAtom } from 'state';
import LanguageCard from './LanguageCard';
import styled from 'styled-components';

const SelectLanguage = () => {
  const history = useHistory();
  const auth = useRecoilValue(AuthAtom);
  const { userId } = auth;

  useRedirectWithoutAuth();

  const { languages, myCourseId } = useFetchLanguages();

  const { isFetching } = useCheckUserExamStatus({
    userId,
    myCourseId,
    languageId: myCourseId[1],
    checkSingle: true,
  });

  const isAllowedCourse = (languageId) => !!myCourseId.includes(languageId);

  return (
    <>
      <Header>역량평가 언어선택</Header>

      <div>
        <div className='text-center' style={{ marginTop: '50px', marginBottom: '30px' }}>
          <h4 className='pt-2 secosub2' style={{ color: '#a8a9a9' }}>
            평가를 진행할 언어를 선택하세요.
          </h4>
        </div>

        {isFetching && <LoadingSpinner />}
        {!isFetching && (
          <CardWrapper className='row'>
            {languages?.map((lan, index) => {
              return (
                lan.languageId !== 0 && (
                  <LanguageCard
                    key={index}
                    languageType={lan.languageType}
                    languageId={lan.languageId}
                    disable={!isAllowedCourse(lan.languageId)}
                  />
                )
              );
            })}
          </CardWrapper>
        )}

        <div className='text-center' style={{ margin: '50px' }}>
          <button onClick={() => history.push('/student/capability/result')} className='printButton'>
            <i className='fa-solid fa-magnifying-glass'></i> 결과보기
          </button>
        </div>
      </div>
    </>
  );
};

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0 10% 60px;
`;

export default SelectLanguage;

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { Ranking } from '../../../widgets';
import { errorToast } from 'utilities/toast';
import { MESSAGE } from 'utilities';
import MySolvedInfoStat from './MySolvedInfoStat';
import MyElapsedTimeInfoStat from './MyElapsedTimeInfoStat';
import { AuthAtom } from 'state';
import { useStyles } from 'style';
import { fetchProblemSolvingBest, fetchProblemSolvingList, fetchProblemSolvingWorst } from '../../../../api/common';
import '../../MyPage.css';

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#a8a9a9',
    color: '#fff',
    paddingLeft: '0px',
    paddingRight: '0px',
    textAlign: 'center',
  },
  body: {
    fontSize: 14,
    paddingLeft: '0px',
    paddingRight: '0px',
  },
}))(TableCell);

const StudySecureBoard = ({ history }) => {
  const classes = useStyles();
  const auth = useRecoilValue(AuthAtom);
  const { userId } = auth;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [list, setList] = useState([]);
  const [listGood, setListGood] = useState([]);
  const [listBad, setListBad] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    Promise.all([
      fetchProblemSolvingList({ userId }),
      fetchProblemSolvingBest({ userId }),
      fetchProblemSolvingWorst({ userId }),
    ])
      .then((responses) => {
        const [response1, response2, response3] = responses;

        const data = response1.data?.map((item, index) => {
          return { ...item, no: index + 1 };
        });
        setList(data);
        setListGood(response2.data);
        setListBad(response3.data);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401 || error.response.status === 403) {
            errorToast(MESSAGE.SERVICE_INVALID_TOKEN);
          }
          history.push('/');
        }
      });
  }, [auth]);

  return (
    <>
      <div style={{ marginTop: '30px' }}>&nbsp;</div>

      <MySolvedInfoStat />

      <MyElapsedTimeInfoStat />

      <div style={{ width: '900px', marginLeft: '50px' }}>
        <Ranking data={listGood} title={'내가 잘하는 유형'} title_eng={'Top 3 types that I am good at solving'} />
        <Ranking data={listBad} title={'내가 못하는 유형'} title_eng={'Top 3 types that I am bad at solving'} />

        <TableContainer component={Paper}>
          <Table className={classes.table} size='small' aria-label='customized dency table'>
            <TableHead rowcount={list.length}>
              <TableRow>
                <StyledTableCell rowSpan='2'>
                  <span className='secomin'>No.</span>
                </StyledTableCell>
                <StyledTableCell rowSpan='2' align='left'>
                  <span className='secomin'>보안약점명</span>
                </StyledTableCell>
                <StyledTableCell rowSpan='2' align='left'>
                  <span className='secomin'>문제</span>
                </StyledTableCell>
                <StyledTableCell colSpan='2' align='center'>
                  <span className='secomin'>1단계</span>
                </StyledTableCell>
                <StyledTableCell colSpan='2' align='center'>
                  <span className='secomin'>2단계</span>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell align='center'>
                  <span className='secomin'>시도</span>
                </StyledTableCell>
                <StyledTableCell align='center'>
                  <span className='secomin'>완료</span>
                </StyledTableCell>
                <StyledTableCell align='center'>
                  <span className='secomin'>시도</span>
                </StyledTableCell>
                <StyledTableCell align='center'>
                  <span className='secomin'>완료</span>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align='center'>
                    <span className='secomin'>{row.no}</span>
                  </TableCell>
                  <TableCell align='left'>
                    <span className='secomin' style={{ letterSpacing: '-0.40px' }}>
                      {row.secName}
                    </span>
                  </TableCell>
                  <TableCell align='left'>
                    <span className='secomin' style={{ letterSpacing: '-0.40px' }}>
                      {row.scodeVulFile}
                    </span>
                  </TableCell>
                  <TableCell align='center'>
                    <span className='secomin'>{row.tryNumFirst}</span>
                  </TableCell>
                  <TableCell align='center'>
                    <span className='secomin'>
                      {row.solvedDateFirst !== null ? (
                        <i className='fas fa-check-circle' />
                      ) : (
                        <i className='far fa-times-circle' />
                      )}
                    </span>
                  </TableCell>
                  <TableCell align='center'>
                    <span className='secomin'>{row.tryNumSecond}</span>
                  </TableCell>
                  <TableCell align='center'>
                    <span className='secomin'>
                      {row.solvedDateSecond !== null ? (
                        <i className='fas fa-check-circle' />
                      ) : (
                        <i className='far fa-times-circle' />
                      )}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default withRouter(StudySecureBoard);

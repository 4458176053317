import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import moment from 'moment';

import TakeTestModal from './TakeTestModal';
import { AuthAtom } from 'state';
import { useFetchUserExamInfo } from 'hooks';
import { removeItem } from 'commons/localStorage';
import styles from '../Capatest.module.scss';
import './TestInfo.css';

const TestInfo = ({ sidebar = 'left', languageId }) => {
  const ref = useRef(null);
  const auth = useRecoilValue(AuthAtom);
  const { userId } = auth;

  const [openModal, setOpenModal] = useState(false);
  const [checked, setChecked] = useState(false);

  const { data: testInfo, isLoading } = useFetchUserExamInfo({ userId, languageId });

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleCheck = () => {
    setChecked((prev) => !prev);
  };

  const examCountFormat = {
    1: '사전 평가',
    2: '사후 평가',
    3: '재평가',
  };

  useEffect(() => {
    if (!testInfo) return;

    if (testInfo.isStarted === 0) {
      removeItem(`questionOrder${testInfo.language}`);
      sessionStorage.removeItem(`saveAnswer${testInfo.language.toUpperCase()}`);
    }
  }, [isLoading]);

  return (
    <Fragment>
      <div className={styles.mypage} ref={ref}>
        <section className={`${styles.wrapper} ${styles.with_sidebar} ${styles.left}`}>
          <article className='capaTestInfo'>
            <div className='capaTestInfo1'>
              <div className='capaTestInfoBox1'>
                <h3>역량평가 응시 정보</h3>
                <p>- 과목명: {(testInfo?.language || '').toUpperCase()} 시큐어코딩</p>
                <p>- 차수: {examCountFormat[testInfo?.examCount]}</p>
                <p>- 응시자: {auth.name}</p>
                <p>- 응시일자: {moment(new Date()).format('YYYY년 MM월 DD일')}</p>
                <p>- 제한시간: {testInfo?.examTimeLimit / 60} 분</p>
                <p>- 전체 문항 수: {testInfo?.examProblemCount} 문항</p>
              </div>

              <div className='capaTestInfoBox2'>
                <h2>[필독!]응시 화면 안내</h2>
                <p>- 1개의 화면당 1문제씩 보여집니다.</p>
                <p>
                  - 웹 브라우저의 <b className='font-red'>[앞으로 가기], [뒤로 가기] 버튼</b>과{' '}
                  <b className='font-red'>브라우저 닫기, 새 창/새 탭 띄우기, 로그아웃</b> 등의{' '}
                  <b className='font-red'>사용을 금지합니다!</b>
                </p>
                <p>
                  - 해당 화면의 답을 입력한 후 <b>화면 아래의 [다음] 버튼</b>을 클릭하여 다음 문제로 이동합니다.
                </p>
                <p>
                  - 앞 문제를 다시 검토하려면 <b>화면 아래의 [이전] 버튼</b>을 클릭해주세요.
                </p>
                <p>- 화면 오른쪽 문제 현황판에서 숫자번호 부분을 클릭하면 해당 번호의 문제로 이동합니다.</p>
                <p>
                  - 최종 제출하기 전에 다시 확인하고 싶은 문제들은 문제 현황판 숫자번호 아래부분을 클릭하여 표시해둘 수
                  있습니다.
                </p>
                <p>
                  - 응시 화면을 이탈하지 마세요! 표기했던 답안이 모두 사라질 수 있으며, 제한시간도 초기화되지 않습니다.
                </p>
                <p>
                  - 모든 문제 답안을 기입하였다면 반드시 <b>[제출] 버튼</b>을 눌러주세요. 제출한 후에는 답안을 수정할 수
                  없습니다.
                </p>
                <p>- 시험시간이 종료되면 자동으로 제출되니 시간에 유의하여 시험에 응시해 주시기 바랍니다.</p>
                <p>- 시험에 한 번 응시한 수험자는 동일한 시험에 다시 응시할 수 없습니다.</p>

                <div>
                  <p className='test-info-alert'>
                    - 안내된 평가 기간 내에 시험 제출을 하지 않을 경우 불이익이 발생할 수 있습니다.
                  </p>
                  <p className='test-info-alert'>- 위 사항을 위반한 경우 발생되는 문제는 본인의 책임으로 인정합니다.</p>
                </div>
              </div>

              {testInfo?.examCount === 2 && !testInfo?.isExamAccess ? (
                <>
                  <CheckBoxBackground className='checkBoxBackground'>
                    <CheckBoxWrapper
                      className='checkBoxWrapper'
                      isExamAccess={testInfo?.isExamAccess}
                      examCount={testInfo?.examCount}
                    >
                      <label htmlFor='consent-check' className='check'>
                        <span>해당 수강 과정의 진행률이 80% 이상일 때 응시가 가능합니다.</span>
                      </label>
                    </CheckBoxWrapper>
                    <TrainProgressWrapper>
                      <p>현재 진행률 : {Math.round(testInfo?.trainProgress || 0)} %</p>
                    </TrainProgressWrapper>
                  </CheckBoxBackground>
                </>
              ) : (
                <>
                  <CheckBoxBackground className='checkBoxBackground'>
                    <CheckBoxWrapper className='checkBoxWrapper' onClick={handleCheck}>
                      <input type='checkbox' id='consent-check' checked={checked} readOnly />
                      <label htmlFor='consent-check' className='check' onClick={handleCheck}>
                        <span>해당 내용을 확인하였습니다</span>
                      </label>
                    </CheckBoxWrapper>
                  </CheckBoxBackground>

                  {checked ? (
                    <div className='btnContainer'>
                      <button onClick={() => handleModalOpen()} className='testStartBtn'>
                        {!testInfo?.isStarted ? '응시하기' : '이어하기'}
                      </button>
                      {openModal && (
                        <TakeTestModal
                          history
                          languageId={languageId}
                          testData={testInfo || {}}
                          openModal={openModal}
                          closeModal={() => handleModalClose()}
                        />
                      )}
                    </div>
                  ) : (
                    <div className='btnContainer'>
                      <button disabled className='notCheck'>
                        안내문 확인이 필요합니다.
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </article>
        </section>
      </div>
    </Fragment>
  );
};

const CheckBoxBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(44, 149, 210);
  padding: 0 20px;
  background-color: white;
  width: auto;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;

  label {
    display: flex;
    align-items: center;
  }

  .check span {
    color: #000;
    font-size: 16px;
    margin-left: 8px;
    font-weight: 500;
  }

  .input,
  .check {
    cursor: pointer;
  }

  ${(props) =>
    !props.isExamAccess &&
    props.examCount === 2 &&
    css`
      border: 2px solid #f62d27;
      cursor: not-allowed;

      .check span {
        color: #f62d27;
      }

      .input,
      .check {
        cursor: not-allowed;
      }
    `}
`;

const TrainProgressWrapper = styled.div`
  padding-top: 20px;

  p {
    font-size: 1.4rem;
    color: #f62d27;
  }
`;

export default TestInfo;

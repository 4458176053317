import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box, Grid, AppBar } from '@mui/material';
import { makeStyles } from '@mui/styles';

import MovieBoardList from './MovieBoardList';
import SecureBoardList from './SecureBoardList';
import ProjectBoardList from './ProjectBoardList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  form: {
    padding: '0px',
    display: 'flex',
    justifyContent: 'center',
  },
  tab: {
    backgroundColor: 'white',
    borderRaidus: '20px',
    fontWeight: '900',
    color: 'gray',
  },
  tabBtn: {
    width: '33.33% !important',
  },
  tF: {
    marginLeft: '0px !important',
  },
  indicator: {
    backgroundColor: '#0069d9',
  },
  button: {
    color: 'white',
    fontSize: '1.2rem',
    margin: '3px 0 2px',
    backgroundColor: '#343a40',
    cursor: 'pointer',
    border: 'thin solid #343a40 !important',
  },
}));

const StudySecureBoard = () => {
  const classes = useStyles();
  const [value, setValue] = useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      <AppBar position='static'>
        <Tabs
          className={classes.tab}
          classes={{ indicator: classes.indicator }}
          value={value}
          onChange={handleChange}
          centered
        >
          <Tab
            className={classes.tabBtn}
            value='one'
            label={<span className='secosub2'>학습실</span>}
            wrapped
            fullWidth
            {...a11yProps('one')}
          />
          <Tab
            className={classes.tabBtn}
            value='two'
            label={<span className='secosub2'>보안약점 진단훈련</span>}
            wrapped
            fullWidth
            {...a11yProps('two')}
          />
          <Tab
            className={classes.tabBtn}
            value='three'
            label={<span className='secosub2'>프로젝트 개발훈련</span>}
            wrapped
            fullWidth
            {...a11yProps('three')}
          />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index='one'>
        <Grid item xs={12}>
          <MovieBoardList />
        </Grid>
      </TabPanel>
      <TabPanel value={value} index='two'>
        <Grid item xs={12}>
          <SecureBoardList />
        </Grid>
      </TabPanel>
      <TabPanel value={value} index='three'>
        <Grid item xs={12}>
          <ProjectBoardList />
        </Grid>
      </TabPanel>
    </Fragment>
  );
};

export default StudySecureBoard;

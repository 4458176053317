import React, { Fragment } from 'react';
import './coursestyle.css';

export default ({ sDatas = [] }) => {
  return (
    <Fragment>
      <div className='videoDetailBottom'>
        <div className='detailcontentBox'>
          <div className='secotext secodgray col-md-2 detailLeft' style={{ textAlign: 'right' }}>
            <i className='far fa-bookmark'></i>&nbsp;&nbsp;학습 목표
          </div>
          <div className='secotext secoblack col-md-10 detailRight'>
            {(sDatas.videoObject || '').split('\n')?.map((line, index) => {
              return (
                <Fragment key={index}>
                  {line}
                  <br></br>
                </Fragment>
              );
            })}
          </div>
        </div>
        <div className='detailcontentBox'>
          <div className='secotext secodgray col-md-2 detailLeft' style={{ textAlign: 'right' }}>
            <i className='far fa-bell'></i>&nbsp;&nbsp;내용 요약
          </div>
          <div className='secotext secoblack col-md-10 detailRight'>
            {(sDatas.videoIntro || '').split('\n')?.map((line, index) => {
              return (
                <Fragment key={index}>
                  {line}
                  <br></br>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

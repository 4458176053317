import React, { useEffect, useState, Fragment } from 'react';
import { useRecoilValue } from 'recoil';
import { TextField, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { AuthAtom } from 'state';
import { errorToast, successToast } from 'utilities/toast';
import { createUserReview } from 'api/customer';
import './ReviewForm.css';

const useStyles = makeStyles(() => ({
  selectEmpty: {
    marginTop: '10px',
  },
  button: {
    color: 'white',
    fontSize: '1.4rem',
    margin: '15px 0 10px',
    backgroundColor: '#343a40',
    cursor: 'pointer',
    border: 'thin solid #343a40 !important',
  },
  TextField: {
    label: {
      fontSize: '10px',
    },
  },
}));

const ReviewForm = ({ history }) => {
  const classes = useStyles();
  const auth = useRecoilValue(AuthAtom);
  const { type, status, name, userId } = auth;

  useEffect(() => {
    if (type === null) {
      errorToast('로그인이 필요합니다.');
      history.push('/');
    } else if (status === 0) {
      errorToast('신청 대기중입니다');
      history.push('/');
    }
  }, []);

  const [values, setValues] = useState({
    postCategoryId: '',
    postTitle: '',
    postContent: '',
    postAuthor: '',
    userId: '',
  });

  const [error, setError] = useState({
    postTitleError: '',
    postCategoryIdError: '',
    postContentError: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const onTextValidation = () => {
    let postTitleError = '';
    let postContentError = '';

    if (values.postTitle.length === 0) postTitleError = '제목을 입력해주세요.';
    if (values.postContent.length === 0) postContentError = '문의내용을 입력해 주세요.';

    setError({
      postTitleError,
      postContentError,
    });

    if (postTitleError || postContentError) return false;
    return true;
  };

  const handleChangeForm = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;

    const valid = onTextValidation();

    if (!valid) console.error('invalid');
    else {
      try {
        setIsLoading(true);
        await createUserReview({
          postTitle: values.postTitle,
          postContent: values.postContent,
          postAuthor: name,
          userId,
        });
        successToast('후기가 정상 등록되었습니다.');
        setIsLoading(false);
        history.push('/customer/review');
      } catch (error) {
        errorToast('죄송합니다. 정상 등록되지 못했습니다.');
        setValues({ postCategoryId: '', postTitle: '', postContent: '', postAuthor: '' });
        setIsLoading(false);
      }
    }
  };

  return (
    <Fragment>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              autoComplete='postTitle'
              fullWidth
              label='제목을 입력하세요.'
              name='postTitle'
              value={values.postTitle}
              onChange={handleChangeForm}
            />
            <div
              style={{
                color: 'red',
                fontSize: '12px',
                margin: '-5px 0 10px 15px',
                fontFamily: 'Noto Sans KR',
              }}
            >
              {error.postTitleError}
            </div>
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              multiline
              required
              autoComplete='postContent'
              fullWidth
              label='내용을 입력해주세요.'
              name='postContent'
              value={values.postContent}
              onChange={handleChangeForm}
            />
            <div
              style={{
                color: 'red',
                fontSize: '12px',
                margin: '-5px 0 10px 15px',
                fontFamily: 'Noto Sans KR',
              }}
            >
              {error.postContentError}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                paddingTop: '10px',
                borderBottom: '1px solid #e3e3e3',
                margin: '15px 0',
                fontFamily: 'Noto Sans KR',
              }}
            ></div>
          </Grid>
        </Grid>

        <button
          type='submit'
          color='primary'
          className={classes.button}
          style={{
            margin: 'auto',
            marginTop: '20px',
            padding: '10px 30px',
            borderRadius: '50px',
            fontFamily: 'Noto Sans KR',
          }}
        >
          후기 등록하기
        </button>
      </form>
    </Fragment>
  );
};

export default ReviewForm;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {},
  form: {
    padding: '0px',
  },
  tab: {
    borderRaidus: '20px',
    fontWeight: '900',
    color: 'gray',
    boxShadow: '5px 5px 10px #e7e7e7',
  },
  tabBtn: {
    width: '100%',
  },
  tF: {
    marginLeft: '0px !important',
  },
  indicator: {
    backgroundColor: '#007bff',
  },
}));

export default useStyles;

import { api } from './config';

/* [강사]월별 수강생 동영상 시청 횟수 조회 */
export const fetchStudentCountByMonth = async ({ period }) => {
  const params = {};
  if (period) params.period = period;

  const { data } = await api.get(`/trainers/monitor`, { params });
  return data;
};

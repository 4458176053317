import React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';

import {
  useFetchGroupResultAverage,
  useFetchPersonalResultCorrect,
  useFetchPersonalResultInfo,
  useFetchStudentExamInfo,
} from 'hooks';
import { Header } from '../../../widgets';
import PersonalResultSummary from './PersonalResultSummary';
import PersonalResultTechnicalChart from './PersonalResultTechnicalChart';
import PersonalResultTableChart from './PersonalResultTableChart';
import PersonalResultCorrect from './PersonalResultCorrect';
import PersonalResultRiskLevel from './PersonalResultRiskLevel';
import { PersonalResultComment, PersonalResultFooter, PersonalResultHeader } from './PersonalResultCommon';

const PersonalTestResult = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const userId = pathname.split('/').at(-1) || '-';

  /* 학생 시험 응시 정보 조회 */
  const { data: userExamInfo } = useFetchStudentExamInfo({ userId });
  const { languageId } = userExamInfo || {};

  /* 학생 시험 응시 상세 결과 조회 */
  const { data: personalExamInfo } = useFetchPersonalResultInfo({ userId, languageId });
  const { examResultInfos } = personalExamInfo || {};

  /* 해당 시험 그룹 평균 점수 */
  const { data: groupAverage } = useFetchGroupResultAverage({ userId, languageId });
  const { middleCategoryCorrectRates, riskLevelCorrectRates } = groupAverage || {};

  /* 해당 유저 시험 정오표 */
  const { data: correctIncorrectData, personalScore } = useFetchPersonalResultCorrect({ userId, languageId });

  const handlePrint = () => window.print();

  const handleGoback = () => history.goBack();
  
  return (
    <>
      <Header>개인별 역량평과 결과 - {userId}</Header>

      <Container>
        {/* 학생 개인 시함 보고서 Header */}
        <PersonalResultHeader
          title='역량평가 결과 보고서'
          groupName={personalExamInfo?.groupName}
          timeStamp={personalExamInfo?.timestamp}
          userName={personalExamInfo?.userName}
        />
        {/* 개인별 역량평가 종합 */}
        <PersonalResultSummary summaryResult={examResultInfos || []} riskAvg={riskLevelCorrectRates} />

        {/*개인별 기술 요소 역량평가 결과(레이더 차트)*/}
        <PersonalResultTechnicalChart
          groupAvg={middleCategoryCorrectRates}
          correctIncorrectData={correctIncorrectData}
        />

        {/* 개인별 역량평가 결과 */}
        <PersonalResultTableChart personalScore={personalScore} groupAvg={middleCategoryCorrectRates} />

        {/* 리포트 Footer */}
        <div style={{ height: '50px' }} />
        <PersonalResultFooter page='1' />

        {/* ================== 페이지 구분선 ================== */}
        {/* 숨김처리 2페이지 헤더 */}
        <PersonalResultHeader
          title='역량평가 결과 보고서(상세)'
          display='none'
          groupName={personalExamInfo?.groupName}
          timeStamp={personalExamInfo?.timestamp}
          userName={personalExamInfo?.userName}
        />

        {/* 정오표 테이블 */}
        <PersonalResultCorrect correctIncorrectData={correctIncorrectData} />

        {/* 보안 위험도별 역량평가 결과 (테이블, 그래프) */}
        <PersonalResultRiskLevel riskAvg={riskLevelCorrectRates} correctIncorrectData={correctIncorrectData} />

        {/* 총평 */}
        <PersonalResultComment />

        {/* 리포트 Footer */}
        <div style={{ height: '10px' }} />
        <PersonalResultFooter page='2' />
      </Container>

      <ButtonWrapper id='Button'>
        <button onClick={handleGoback} className='goBackButton'>
          <i className='fas fa-angle-left'></i> 뒤로가기
        </button>

        <button onClick={handlePrint} className='printButton'>
          <i className='fas fa-print'></i> 인쇄하기
        </button>
      </ButtonWrapper>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1000px;
  margin: 0 auto;
  min-height: calc(100vh - 520px);
  padding: 20px 0 30px;
  row-gap: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0 50px;

  button.goBackButton {
    margin-right: 15px;
    margin-top: 30px;
    width: 110px;
    height: 40px;
    outline: none;
    border: 0;
    border-radius: 5px;
    font-size: 1.4rem;
    background-color: #a9a9a9;
    color: #fff;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: #868686;
    }
  }
`;
export default PersonalTestResult;

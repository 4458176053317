import React from 'react';
import styled from 'styled-components';
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LinearScale,
  BarController,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import ReportWrapper from './ReportWrapper';

ChartJS.register(CategoryScale, BarElement, PointElement, Title, Tooltip, Legend, LinearScale, BarController);

const ExamResult = ({ personalScore = [], groupAvg }) => {
  const { firstExamMiddleCorrectRates = {}, secondExamMiddleCorrectRates = {} } = groupAvg || {};

  const addGroupAvg = (data = [], firstAvg, secondAvg) => {
    const codeToRateMap = {
      '000': 10,
      '001': 1,
      '002': 2,
      '003': 3,
      '004': 4,
      '005': 5,
      '006': 6,
      '007': 7,
    };

    if (!Array.isArray(data) || data.length === 0) return [];

    return data.map((item) => {
      const rateKey = codeToRateMap[item.code];
      const beforeAvg = firstAvg[rateKey];
      const afterAvg = secondAvg[rateKey];

      return {
        ...item,
        beforeAvg: beforeAvg !== undefined ? beforeAvg : 0,
        afterAvg: afterAvg !== undefined ? afterAvg : 0,
      };
    });
  };

  const tableData = addGroupAvg(personalScore, firstExamMiddleCorrectRates, secondExamMiddleCorrectRates);

  const beforeCorrectRates = Array.isArray(personalScore)
    ? personalScore.map((item) => parseFloat(item.beforeCorrectRate))
    : [];

  const afterCorrectRates = Array.isArray(personalScore)
    ? personalScore.map((item) => parseFloat(item.afterCorrectRate))
    : [];

  const options = {
    indexAxis: 'y',
    animation: {
      duration: 0,
    },
    elements: {
      bar: {
        borderWidth: 1,
        borderRadius: 2,
      },
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        position: 'top',
        align: 'end',
        labels: {
          color: '#000',
          font: {
            weight: 'bold',
          },
        },
      },
    },
    scales: {
      x: {
        display: true,
        min: 0,
        max: 100,
        ticks: {
          color: '#000',
          font: {
            size: 12,
            weight: 'bold',
          },
        },
      },
      y: {
        ticks: {
          color: '#000',
          font: {
            size: 12,
            weight: 'bold',
          },
        },
      },
    },
  };

  const labels = ['000', '001', '002', '003', '004', '005', '006', '007'];

  const data = {
    labels,
    datasets: [
      {
        barThickness: 10,
        label: '사전(%)',
        data: beforeCorrectRates,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        barThickness: 10,
        label: '사후(%)',
        data: afterCorrectRates,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  return (
    <ReportWrapper title='개인 역량평가 결과'>
      <Container>
        <TableWrapper>
          <table className='styled-table'>
            <thead>
              <tr>
                <th rowSpan='2'>코드</th>
                <th rowSpan='2'>유형</th>
                <th colSpan='2'>사전</th>
                <th colSpan='2'>사후</th>
              </tr>
              <tr>
                <th>정답률(%)</th>
                <th>평균(%)</th>
                <th>정답률(%)</th>
                <th>평균(%)</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr key={index}>
                  <td>{item.code}</td>
                  <td>{item.type}</td>
                  <td>{Math.round(item.beforeCorrectRate) ?? '0'}</td>
                  <td>{Math.round(item.beforeAvg) || '0'}</td>
                  <td>{Math.round(item.afterCorrectRate) || '0'}</td>
                  <td>{Math.round(item.afterAvg) || '0'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableWrapper>

        <GraphWrapper>
          <Bar options={options} data={data} width='35%' height='22%' />
        </GraphWrapper>
      </Container>
    </ReportWrapper>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  column-gap: 20px;

  > div {
    flex: 1;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.2rem;

  table {
    width: 100%;
    height: 100%;
  }

  tr,
  th,
  td {
    padding: 5px 10px;
    border-collapse: collapse;
    border: 1px solid #a8a7a7;
  }

  th {
    background-color: #eee;
  }
`;

const GraphWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export default ExamResult;

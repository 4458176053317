import { api } from './config';
import axios from 'axios';

/* 유저 로그인 */
export const login = async ({ userId, password }) => {
  return await axios.post(`${process.env.REACT_APP_IP}/users/login`, { userId, password });
};

/* 유저 로그아웃 */
export const logout = async () => {
  return await api.post('/users/logout', {});
};

/* 유저 회원가입 */
export const signup = async ({ userId, name, email, password, phone, type, ruleCheck }) => {
  return await api.post('/users/sign/signup', {
    userId,
    name,
    email,
    password,
    phone,
    type,
    ruleCheck,
  });
};

/* 회원가입 중복 ID 체크 */
export const checkSignupUserId = async ({ userId }) => {
  return await api.get(`/users/sign/checkId/${userId}`);
};

/* 회원가입 중복 EMAIL 체크 */
export const checkSignupUserEmail = async ({ email }) => {
  return await api.get(`/users/email/checkEmail/${email}`);
};

/* 아이디 검사 */
export const userSubmitId = async ({ name, email }) => {
  return await api.post(`/users/sign/authId`, { name, email });
};

/* 이메일 인증 코드 발송 */
export const userEmailConfirm = async (email) => {
  return await api.get(`/users/email/findPwEmailConfirm/${email}`);
};

/* 이메일 인증 코드 확인 */
export const userConfirmCode = async ({ email, confirmCode }) => {
  return await api.get(`/users/email/confirm/${email}/${confirmCode}`);
};

/* 비밀번호 확인 */
export const userSubmitPassword = async ({ userId, name, email }) => {
  return await api.post(`/users/sign/authPw`, {
    userId,
    name,
    email,
  });
};

/* 비밀번호 변경 */
export const userChangePassword = async ({ userId, password }) => {
  return await api.put(`/users/sign/pw`, { userId, password });
};

/* 회원 탈퇴 */
export const userAccountExit = async ({ userId, password }) => {
  return await api.put(`/users/secession/${userId}/${password}`, {
    userId,
    password,
  });
};

import React, { useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { AuthAtom } from 'state';
import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import { errorToast, successToast } from 'utilities/toast';
import { userAccountExit } from 'api/auth';

const AccountExit = ({ useStyles }) => {
  const history = useHistory();
  const auth = useRecoilValue(AuthAtom);
  const { userId } = auth;

  useRedirectWithoutAuth();

  const classes = useStyles();

  const [valuesPassExit, setvaluesPassExit] = useState({
    userId,
    password: '',
  });

  const [error] = useState({
    userIdError: '',
    emailError: '',
    emailCodeError: '',
    nameError: '',
    phoneError: '',
    pwdError: '',
    confirmPwd: '',
  });

  const [isSecessionLoading, setIsSecessionLoading] = useState(false);

  const handleChangeFormExit = (e) => {
    setvaluesPassExit({ ...valuesPassExit, [e.target.name]: e.target.value });
  };

  const handleSubmitExit = async (e) => {
    e.preventDefault();
    if (isSecessionLoading) return;

    try {
      setIsSecessionLoading(true);
      const response = await userAccountExit({
        userId: userId,
        password: valuesPassExit.password,
      });
      if (response.data === 'success') {
        successToast('회원탈퇴 되었습니다.. 감사합니다.');
        history.push('/');
      } else errorToast('비밀번호가 틀렸습니다.');
      setIsSecessionLoading(false);
    } catch (error) {
      errorToast('서버와의 연결이 원할 하지 않습니다. 잠시 후에 다시 시도 해주세요.');
      setIsSecessionLoading(false);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div>
            <p className='secotext'>* 회원 탈퇴를 하더라도 결제하신 날짜부터 한 달 간은 학습이 가능합니다.</p>
            <p className='secotext'>* 회원 탈퇴는 전월 결제하신 일로부터 1달 뒤 완료됩니다.</p>
            <p className='secotext'>
              * 회원 탈퇴 시 3개월간 개인정보 저장 후 3개월이 지나도 탈퇴해지가 없을 시 자동으로 개인정보가 삭제됩니다.
            </p>
          </div>
          <StyledForm className={classes.form} onSubmit={handleSubmitExit}>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <div
                style={{
                  paddingTop: '10px',
                  borderBottom: '1px solid #e3e3e3',
                  margin: '10px 0',
                }}
              ></div>
            </div>
            <TextField
              variant='outlined'
              margin='normal'
              required
              autoFocus
              autoComplete=''
              fullWidth
              label='비밀번호를 입력하세요.'
              helperText='비밀번호 입력 후 탈퇴하기 버튼을 누르면 탈퇴처리가 완료됩니다.'
              name='password'
              type='password'
              value={valuesPassExit.password}
              onChange={handleChangeFormExit}
            />
            <div
              style={{
                color: 'red',
                fontSize: '12px',
                margin: '-5px 0 10px 15px',
                fontFamily: 'Noto Sans KR',
              }}
            >
              {error.confirmPwd}
            </div>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <div
                style={{
                  paddingTop: '10px',
                  borderBottom: '1px solid #e3e3e3',
                  margin: '10px 0',
                }}
              />
            </div>
            <StyledButton type='submit' color='primary' className={classes.button}>
              <span className='secosub1'>탈퇴하기</span>
            </StyledButton>
          </StyledForm>
        </Grid>
      </Grid>
    </>
  );
};

const StyledForm = styled.form`
  .MuiFormHelperText-root {
    font-size: 14px !important;
  }
`;

const StyledButton = styled.button`
  margin: 20px auto 0;
  padding: 10px 30px;
  border-radius: 50px;
`;

export default AccountExit;

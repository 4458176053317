import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { operator } from '../api';
import { MIDDLE_CATEGORIES } from '../constants/ExamReport';

/* 오퍼레이터가 소속된 그룹 리스트 조회 hook */
export const useFetchOperatorGroups = (groupId) => {
  const queryReturns = useQuery({
    queryKey: ['fetchGroupDetail', groupId],
    queryFn: () => operator.fetchOperatorGroups(groupId),
    select: (res) => res.data,
    cacheTime: 30000,
    staleTime: 30000,
  });

  return { ...queryReturns };
};

/* [역량평가]모니터링(시험 진행률) 조회 hook */
export const useFetchExamProgress = ({ groupId, languageId, examOrder }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchExamProgress', { groupId, languageId, examOrder }],
    queryFn: () => operator.fetchExamProgress({ groupId, languageId, examOrder }),
    select: (res) => res.data.data,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!(groupId || languageId || examOrder),
  });

  return { ...queryReturns };
};

/* [역량평가]그룹 시험 정보 조회 hook */
// export const useFetchExamInfo = ({ groupId }) => {
//   const queryReturns = useQuery({
//     queryKey: ['fetchGroupExamSubject', { groupId }],
//     queryFn: () => operator.fetchGroupExamSubject({ groupId }),
//     select: (res) => res.data,
//   });
//
//   return { ...queryReturns };
// };

/* [역량평가]그룹 유저 시험 정보 조회 hook */
export const useFetchGroupUsersExamResult = ({ groupId, languageId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchExamResult', { groupId, languageId }],
    queryFn: () => operator.fetchExamResult({ groupId, languageId }),
    select: (res) => res.data.data,
    enabled: !!(languageId || groupId),
  });

  return { ...queryReturns };
};

/* [역량평가] 오퍼레이터 전용 유저 시험 응시 정보 조회 hook */
export const useFetchStudentExamInfo = ({ userId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchUserExamResultByOperator', { userId }],
    queryFn: () => operator.fetchUserExamResult({ userId }),
    select: (res) => {
      if (res.data.data.length >= 0) {
        return res.data.data[0];
      }
    },
    enabled: !!userId,
  });

  return { ...queryReturns };
};

/* [교육생 시험 보고서] 응시자의 결과 상세 조회 hook */
export const useFetchPersonalResultInfo = ({ userId, languageId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchPersonalResultInfo', { userId, languageId }],
    queryFn: () => operator.fetchPersonalResultInfo({ userId, languageId }),
    select: (res) => res.data.data,
    retry: false,
    enabled: !!(userId && languageId),
  });

  return { ...queryReturns };
};

/* [교육생 시험 보고서] 해당 그룹 응시 평균 점수 hook */
export const useFetchGroupResultAverage = ({ userId, languageId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchPersonalResultAverage', { userId, languageId }],
    queryFn: () => operator.fetchPersonalResultAverage({ userId, languageId }),
    select: (res) => res.data.data,
    retry: false,
    enabled: !!(userId && languageId),
  });

  return { ...queryReturns };
};

/* [교육생 시험 보고서] 해당 유저 시험 정오표 hook */
export const useFetchPersonalResultCorrect = ({ userId, languageId }) => {
  const [personalScore, setPersonalScore] = useState({});

  const queryReturns = useQuery({
    queryKey: ['fetchPersonalResultCorrect', { userId, languageId }],
    queryFn: async () => {
      const response = await operator.fetchPersonalResultCorrect({ userId, languageId });
      const data = response.data.data;

      if (response.status === 200) {
        const { firstExamReportData = {}, secondExamReportData = {} } = data;

        const groupDataByCategory = (data) => {
          const groupedData = {};

          data.forEach((item) => {
            const categoryName = item.middleCategoryName;
            if (!groupedData[categoryName]) {
              groupedData[categoryName] = { correct: 0, total: 0 };
            }
            groupedData[categoryName].total += 1;
            if (item.isCorrect) {
              groupedData[categoryName].correct += 1;
            }
          });

          return groupedData;
        };

        const preGroupedData = groupDataByCategory(firstExamReportData);
        const postGroupedData = groupDataByCategory(secondExamReportData);

        const calculateCorrectRate = (groupedData) => {
          const calculatedData = {};

          for (const category in groupedData) {
            const data = groupedData[category];
            calculatedData[category] = (data.correct / data.total) * 100;
          }

          return calculatedData;
        };

        const preCorrectRates = calculateCorrectRate(preGroupedData);
        const postCorrectRates = calculateCorrectRate(postGroupedData);

        const resultData = Object.keys(MIDDLE_CATEGORIES).map((code) => {
          const type = MIDDLE_CATEGORIES[code];
          const preCorrectRate = preCorrectRates[type] || 0;
          const postCorrectRate = postCorrectRates[type] || 0;

          return {
            code: code,
            type: type,
            beforeCorrectRate: `${preCorrectRate === 0 ? 0 : preCorrectRate}`,
            afterCorrectRate: `${postCorrectRate === 0 ? 0 : postCorrectRate}`,
          };
        });

        setPersonalScore(resultData);
      }

      return response;
    },
    select: (res) => res.data.data,
    retry: false,
    enabled: !!(userId && languageId),
  });

  return { ...queryReturns, personalScore };
};

export const useFetchUserGroupInfo = ({ userId, groupId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchUserGroupInfo', { userId, groupId }],
    queryFn: () => operator.fetchUserGroupInfo({ userId, groupId }),
    select: (res) => res.data,
    retry: false,
    enabled: !!(userId || groupId),
  });

  return { ...queryReturns };
};

/* 시험 결과 조회 hook */
export const useFetchExamResult = ({ groupId, languageId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchExamResult', { groupId, languageId }],
    queryFn: () => operator.fetchExamResult({ groupId, languageId }),
    select: (res) => res.data.data,
    retry: false,
    enabled: !!(groupId || languageId),
  });

  return { ...queryReturns };
};

/* 카테고리별 시험 결과 조회 hook */
export const useFetchCategoryResult = ({ languageId, groupId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchExamCategoryResult', { languageId, groupId }],
    queryFn: async () => await operator.fetchExamCategoryResult({ languageId, groupId }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!(languageId || groupId),
  });

  return { ...queryReturns };
};

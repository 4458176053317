import React, { useEffect, useRef } from "react";
import styles from "./Services4.module.scss";
import Layout from "../../layouts/Layout/Layout";
import THEME from "../../../state/theme";

import { Button } from "../../elements";

export default ({ data = [] }) => {
  const ref = useRef(null);
  //const [height, setHeight] = useState(0);

  useEffect(() => {
    //setHeight(ref.current.clientHeight);
    window.addEventListener("resize", resizeListener);
    return window.removeEventListener("resize", resizeListener);
  }, []);

  const resizeListener = () => {
    //setHeight(ref.current.clientHeight);
  };

  let listStatistic = data
    ?.map((item, index) => (
      <div
        key={index}
        className={styles.wrapper}
        style={{
          borderColor: THEME.color,
        }}
      >
        {/*
        <div className={styles.extra} style={{ background: THEME.color }}>
          <p>
            Progressively synthesize metrics and multimedia based action items.
            Globally benchmark process-centric experiences without technologies.
          </p>
        </div>
        */}

        <div className={styles.card}>
          <div className={styles.icon}>
            <img src={item.icon} alt="card_icon" />
          </div>

          <h3
            className='secomain'
            style={{
              color: THEME.color,
              fontFamily: 'Noto Sans KR',
              textAlign: 'center',
            }}
          >
            {item.subtitle}
          </h3>
          <p className='secotext'>{item.text.replace(/^(.{60}[^\s]*).*/, "$1")}</p>
          <div className={styles.button}>
            <Button
              to={"/student/" + item.id}
              type="outline-color-cl"
              hoverType = "solid-color-tw"
              roundSmall
              before="&#xf105;"
            >
              <span className='secosub2'>시작하기</span>
            </Button>
          </div>
        </div>
      </div>
    ))
    .slice(0, 3);

  return (
    <div ref={ref}>
      
      <Layout
        style={{
          backgroundColor: "#f0f5f9",
          marginTop: "110px",
          paddingBottom: "110px",
        }}
        col="1"
      >
        <div className="text-center" style={{marginTop:"65px"}}>
            
				    <h2 style={{fontSize:"3rem", color:"#4c94d2"}}>시큐어코딩 훈련시스템</h2>
            {/* <h4 className="pt-2" style={{color:"#a8a9a9"}}>SECOLAB</h4> */}
            {/*<img src="/assets/icons/securecoding.png" alt="securecoding" width="100px"/>*/}
        </div>
        <div className={styles.statistic} >
          {listStatistic}
        </div>
      </Layout>
      
    </div>
  );
};

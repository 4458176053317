import React, { Fragment, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import styled from 'styled-components';

import { useFetchUserExamResult } from 'hooks';
import { LoadingSpinner } from 'components/ui';
import { sliceRoundNumber } from 'utilities/number';
import { StyledTableCellWithHead, ColorTableRow } from 'style/StyledTableCellWithHead';
import { Header } from '../../../../components/widgets';
import styles from '../Capatest.module.scss';
import './TestResult.css';

const TestResult = ({ sidebar = 'left' }) => {
  const ref = useRef(null);

  const history = useHistory();

  const { data, isLoading } = useFetchUserExamResult();
  const { examScore, groupName } = data || {};

  const initialLanguageId = examScore?.[0]?.languageId ?? 2;

  const handlePrint = () => {
    const html = document.querySelector('html');
    // 출력할 내용 가져오기
    const printContents = document.querySelector('#content').innerHTML;

    const printDiv = document.createElement('DIV');
    printDiv.className = 'print-div';

    html.appendChild(printDiv);

    // 출력할 내용 인쇄 화면 속 태그에 넣어주기
    printDiv.innerHTML = printContents;
    document.body.style.display = 'none';

    window.print();
    document.body.style.display = 'block';
    printDiv.style.display = 'none';
  };

  const handleGoCapabilityReport = () => history.push(`/student/capability/report/${initialLanguageId}`);

  return (
    <Fragment>
      <Header>역량평가 결과</Header>
      <div className={styles.mypage} ref={ref}>
        <section className={`${styles.wrapper} ${styles.with_sidebar} ${styles.left}`}>
          <article className='capaTestResult' id='content'>
            {isLoading && <LoadingSpinner />}
            {!isLoading && (examScore || []).length <= 0 && <NoExamResult />}
            {examScore?.map((courseExam, option) => (
              <Fragment key={option}>
                <h2 style={{ color: '#2e94d2' }}>{`${groupName} [${courseExam.languageName.toUpperCase()}]`}</h2>

                <hr />

                {courseExam.firstExam.examStartDate && courseExam.firstExam.examSubmitDate && (
                  <div className='capaTestScore'>
                    <h3>사전 역량평가 결과</h3>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCellWithHead style={{ width: '28%' }}>응시일자</StyledTableCellWithHead>
                            <StyledTableCellWithHead style={{ width: '24%' }}>목표 점수</StyledTableCellWithHead>
                            <StyledTableCellWithHead style={{ width: '24%' }}>전체 평균 점수</StyledTableCellWithHead>
                            <StyledTableCellWithHead style={{ width: '24%' }}>내 점수</StyledTableCellWithHead>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <ColorTableRow>
                            <TableCell style={{ fontSize: '1.4rem', textAlign: 'center' }}>
                              {courseExam.firstExam?.examStartDate?.slice(0, 10) || 0}
                            </TableCell>
                            <TableCell style={{ fontSize: '1.4rem', textAlign: 'center' }}>80</TableCell>
                            <TableCell style={{ fontSize: '1.4rem', textAlign: 'center' }}>
                              {!courseExam.secondExam.examStartDate && courseExam.firstExam.examActive === '진행 중'
                                ? '평가 진행 중'
                                : courseExam.firstExam.examGroupScoreAvg <= 0
                                ? '-'
                                : sliceRoundNumber(courseExam.firstExam.examGroupScoreAvg)}
                            </TableCell>
                            {courseExam.firstExam.examGroupScoreAvg <= courseExam.firstExam.examScoreResult ? (
                              <TableCell style={{ fontSize: '1.4rem', textAlign: 'center', color: '#FA7F42' }}>
                                <strong>{courseExam.firstExam.examScoreResult}</strong>
                              </TableCell>
                            ) : (
                              <TableCell style={{ fontSize: '1.4rem', textAlign: 'center', color: '#2C95D2' }}>
                                <strong>{courseExam.firstExam.examScoreResult}</strong>
                              </TableCell>
                            )}
                          </ColorTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}

                {courseExam.secondExam.examStartDate && courseExam.secondExam.examSubmitDate && (
                  <>
                    <div className='capaTestScore'>
                      <h3>사후 역량평가 결과</h3>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCellWithHead style={{ width: '28%' }}>응시일자</StyledTableCellWithHead>
                              <StyledTableCellWithHead style={{ width: '24%' }}>목표 점수</StyledTableCellWithHead>
                              <StyledTableCellWithHead style={{ width: '24%' }}>전체 평균 점수</StyledTableCellWithHead>
                              <StyledTableCellWithHead style={{ width: '24%' }}>내 점수</StyledTableCellWithHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <ColorTableRow>
                              <TableCell style={{ fontSize: '1.4rem', textAlign: 'center' }}>
                                {courseExam.secondExam?.examStartDate?.slice(0, 10) || 0}
                              </TableCell>
                              <TableCell style={{ fontSize: '1.4rem', textAlign: 'center' }}>80</TableCell>
                              <TableCell style={{ fontSize: '1.4rem', textAlign: 'center' }}>
                                {!courseExam.retakeExam.examStartDate && courseExam.secondExam.examActive === '진행 중'
                                  ? '평가 진행 중'
                                  : courseExam.secondExam.examGroupScoreAvg <= 0
                                  ? '-'
                                  : sliceRoundNumber(courseExam.secondExam.examGroupScoreAvg)}
                              </TableCell>
                              {courseExam.secondExam.examGroupScoreAvg <= courseExam.secondExam.examScoreResult ? (
                                <TableCell style={{ fontSize: '1.4rem', textAlign: 'center', color: '#FA7F42' }}>
                                  <strong>{courseExam.secondExam.examScoreResult}</strong>
                                </TableCell>
                              ) : (
                                <TableCell style={{ fontSize: '1.4rem', textAlign: 'center', color: '#2C95D2' }}>
                                  <strong>{courseExam.secondExam.examScoreResult}</strong>
                                </TableCell>
                              )}
                            </ColorTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </>
                )}

                {courseExam.retakeExam.examStartDate && courseExam.retakeExam.examSubmitDate && (
                  <>
                    <div className='capaTestScore'>
                      <h3>재평가 결과</h3>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCellWithHead style={{ width: '28%' }}>응시일자</StyledTableCellWithHead>
                              <StyledTableCellWithHead style={{ width: '24%' }}>목표 점수</StyledTableCellWithHead>
                              <StyledTableCellWithHead style={{ width: '24%' }}>전체 평균 점수</StyledTableCellWithHead>
                              <StyledTableCellWithHead style={{ width: '24%' }}>내 점수</StyledTableCellWithHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <ColorTableRow>
                              <TableCell style={{ fontSize: '1.4rem', textAlign: 'center' }}>
                                {courseExam.retakeExam?.examStartDate?.slice(0, 10)}
                              </TableCell>
                              <TableCell style={{ fontSize: '1.4rem', textAlign: 'center' }}>80</TableCell>
                              <TableCell style={{ fontSize: '1.4rem', textAlign: 'center' }}>
                                {!courseExam.secondExam.examStartDate && courseExam.retakeExam.examActive === '진행 중'
                                  ? '평가 진행 중'
                                  : courseExam.retakeExam.examGroupScoreAvg <= 0
                                  ? '-'
                                  : sliceRoundNumber(courseExam.retakeExam.examGroupScoreAvg)}
                              </TableCell>
                              {courseExam.retakeExam.examGroupScoreAvg <= courseExam.retakeExam.examScoreResult ? (
                                <TableCell style={{ fontSize: '1.4rem', textAlign: 'center', color: '#FA7F42' }}>
                                  <strong>{courseExam.retakeExam.examScoreResult}</strong>
                                </TableCell>
                              ) : (
                                <TableCell style={{ fontSize: '1.4rem', textAlign: 'center', color: '#2C95D2' }}>
                                  <strong>{courseExam.retakeExam.examScoreResult}</strong>
                                </TableCell>
                              )}
                            </ColorTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </>
                )}
              </Fragment>
            ))}
          </article>
        </section>

        <div className='d-flex justify-content-center'>
          {examScore?.length <= 0 ? (
            <button onClick={() => history.goBack()} className='printButton' style={{ marginRight: '10px' }}>
              <i className='fa-solid fa-circle-left'></i> 뒤로가기
            </button>
          ) : (
            <>
              {/*<button onClick={() => history.goBack()} className='printButton' style={{ marginRight: '10px' }}>*/}
              {/*  <i className='fa-solid fa-circle-left'></i> 뒤로가기*/}
              {/*</button>*/}

              <button onClick={() => handlePrint()} className='printButton' style={{ marginRight: '10px' }}>
                <i className='fas fa-print'></i> 인쇄하기
              </button>

              <button onClick={handleGoCapabilityReport} className='printButton'>
                <i className='fa-solid fa-file-contract'></i> 보고서 조회
              </button>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const NoExamResult = () => {
  return (
    <Wrapper>
      <ImageBox>
        <img src='/assets/images/no-result.jpeg' alt='no result' />
      </ImageBox>
      <NoExamBox>응시한 시험이 존재하지 않습니다. </NoExamBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const NoExamBox = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
  padding: 30px 0 100px;
  text-shadow: 1px 1px 2px rgba(190, 187, 187, 0.65);
`;

const ImageBox = styled.div`
  width: 200px;
  animation: gelatine 1s infinite;
  animation-delay: 2s;

  img {
    width: 100%;
  }

  @keyframes gelatine {
    from,
    to {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.95, 1.05);
    }
    50% {
      transform: scale(1.05, 0.95);
    }
    75% {
      transform: scale(0.95, 1.05);
    }
    100% {
      transform: scale(1, 1);
    }
  }
`;

export default TestResult;

import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Card, CardActions, CardContent, IconButton, Typography, Grid, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import moment from 'moment';

import nodejs from './images/nodejs.png';
import spring from './images/spring.png';
import django from './images/django.png';
import Alert from '../../../../components/Alert';
import InformCard from '../InformCard/InformCard';
import ContainerButton from '../ContainerButton/ContainerButton';
import { api } from 'api/config';
import { AuthAtom } from 'state';
import { errorToast, successToast } from 'utilities/toast';

function getURLImageProject(type) {
  let result;
  switch (type) {
    case 'node':
      result = nodejs;
      break;
    case 'java':
      result = spring;
      break;
    case 'python':
      result = django;
      break;
    default:
      result = '';
      break;
  }
  return result;
}

const ProjectContainer = (props) => {
  let useStyles = (useStyles = makeStyles((theme) => {
    const border = props.container.project_id === props.project.questId ? '3px solid lightgreen' : 'thin dotted aqua';
    return {
      root: {
        width: 280,
        height: 280,
        border: border,
        textAlign: 'center',
        position: 'relative',
        '&:hover': {
          //backgroundColor: 'aqua',
        },
      },
      projectImage: {
        width: '130px',
        height: 'auto',
      },
    };
  }));
  const classes = useStyles();
  const auth = useRecoilValue(AuthAtom);
  const { userId } = auth;

  const [project, setProject] = useState(props.project);
  const [modalInform, setModalInform] = useState(false);
  const container = props.container;
  const [informButton, setInformButton] = useState(false);

  const closeModal = () => {
    setModalInform(false);
  };

  const handleClickViewProject = (questId) => {
    // 컨테이너 중지되어 있을때 시작하는지 확인
    if (container.state && container.project_id === questId) {
      api
        .get(`/users/quests/editor/${questId}`)
        .then((response) => {
          const { data } = response;
          if (data !== null) {
            window.open(`${process.env.REACT_APP_HTTP_IP}:${data.vscode_port}/?folder=/home/coder/projects/`, '_blank');
          } else {
            errorToast('에디터 서버 접근 실패합니다. 다시 확인해주세요');
          }
        })
        .catch((error) => {});
    } else {
      Alert({
        title: '컨테이너를 새로 시작 하시겠습니까??',
        handleClickAccept: () => {
          //accept
          props.setLoading(true);

          api
            .post(`/users/quests/containers`, {
              questId,
            })
            .then((response) => {
              const { data } = response;
              props.setLoading(false);
              if (data === 'success') {
                props.handleUpdateContainer();
              } else {
                errorToast('컨테이너 재시작 오류 발생하니 다시 시도 해주세요.');
              }
            })
            .catch((error) => {
              errorToast('컨테이너 재시작 오류 발생하니 다시 시도 해주세요.');
              props.setLoading(false);
            });
        },
        handleClickReject: () => {}, //reject
      });
    }
  };
  const handleDeleteProject = (projectId) => {
    const { container } = props;
    let titleDiag = '';
    if (container.project_id === projectId) {
      titleDiag = '해당하는 프로젝트가 컨테이너에서 동작하고 있습니다 그래도 삭제하시겠습니까?';
    } else {
      titleDiag = '프로젝트를 삭제하시겠습니까?';
    }
    Alert({
      title: titleDiag,
      handleClickAccept: () => {
        //accept

        api
          .delete(`/users/quests/projects/${projectId}`)
          .then((response) => {
            if (response.data === 'success') props.handleDeleteProject(projectId);
          })
          .catch((error) => {
            errorToast('프로젝트가 삭제 되지 않았습나다.');
          });
      },
      handleClickReject: () => {}, //reject
    });
  };
  // 웹 서비스 실행
  const handleMoveWebService = () => {
    api
      .get(`/users/quests/webservice`)
      .then((response) => {
        const { data } = response;
        if (data !== 'fail') {
          window.open(`${process.env.REACT_APP_HTTP_IP}:${data}`, '_blank');
        } else {
          errorToast('페이지 접근 오류 발생했습니다. 서버가 정상적으로 작동되었는지 확인하세요. ');
        }
      })
      .catch((error) => {});
  };

  // 컨테이너 중지
  const handleSleepContainer = () => {
    Alert({
      title: '컨테이너를 중지하시겠습니까?',
      handleClickAccept: () => {
        api
          .post(`/users/quests/stopcontainers`, {})
          .then((response) => {
            const { data } = response;
            if (data === 'success') {
              props.handleUpdateContainer();
            }
          })
          .catch((error) => {});
      },
      handleClickReject: () => {}, //reject
    });
  };
  // 훈련 결과 제출
  const handleSubmit = (questId) => {
    Alert({
      title: '제출하시면 더 이상 수정하실 수 없습니다.',
      handleClickAccept: () => {
        //accept

        api
          .get(`/users/quests/questSubmit/${questId}`)
          .then((response) => {
            const { data } = response;
            if (data !== 'P') {
              successToast('제출 완료');
            } else {
              successToast('제출 완료');
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              errorToast('이미 제출하셨습니다.');
            } else {
              errorToast('제출 실패\n로그파일이 있는지 확인해주세요');
            }
          });
      },
      handleClickReject: () => {},
    });
  };

  return (
    <>
      <Card className={classes.root} style={{}}>
        <div style={{ textAlign: 'right' }}>
          {/*<Tooltip title="문제 정보">
          <IconButton onClick={() => setModalInform(true)} style={{ margin: '10px 0 0 0' }}>
            <i className="fas fa-info" style={{ fontSize: '16px', color: '#2F96EB', padding: '0px' }}></i>
          </IconButton>
        </Tooltip>*/}
          <Tooltip title='프로젝트 삭제'>
            <IconButton onClick={() => handleDeleteProject(project.questId)} style={{ margin: '10px 10px 0 0' }}>
              <i className='far fa-trash-alt' style={{ fontSize: '16px', color: '#2F96EB', padding: '0px' }}></i>
            </IconButton>
          </Tooltip>
        </div>
        <CardContent style={{ padding: '0px' }}>
          <Typography variant='h5'>{project.questName}</Typography>
          <br />
          <div
            onMouseEnter={() => {
              setInformButton(true);
            }}
            onMouseLeave={() => {
              setInformButton(false);
            }}
          >
            {informButton ? (
              <button
                style={{
                  width: '130px',
                  cursor: 'pointer',
                  padding: '20px 20px',
                  marginBottom: '20px',
                  backgroundColor: '#3498db',
                  color: 'white',
                  border: 'thin solid white',
                  fontSize: '1.2rem',
                  fontWeight: '500',
                  fontFamily: 'Noto Sans KR',
                }}
                onClick={() => {
                  setModalInform(true);
                }}
              >
                <i className='fas fa-search-plus'></i>&nbsp;&nbsp;문제 정보
              </button>
            ) : (
              <img
                src={getURLImageProject(project.questLanguageType)}
                className={classes.projectImage}
                alt='projectImage'
              />
            )}
          </div>
        </CardContent>
        <CardActions style={{ position: 'absolute', bottom: '15px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant='button' display='block' style={{ color: '#2F96EB' }}>
                {moment(project.questCreatedDate).format('YYYY/MM/DD HH:mm:ss')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ContainerButton
                project={project}
                container={props.container}
                handleSubmit={handleSubmit}
                handleSleepContainer={handleSleepContainer}
                handleMoveWebService={handleMoveWebService}
                handleClickViewProject={handleClickViewProject}
              />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      {/*문제정보 띄우는 modal*/}
      {modalInform && <InformCard closeModal={closeModal} questId={project.questId} />}
    </>
  );
};

export default ProjectContainer;

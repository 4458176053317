import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const NotFound = () => {
  const history = useHistory();
  const [second, setSecond] = useState(3);

  const handleGoHome = () => history.push('/');

  useEffect(() => {
    const reduceSecond = setInterval(() => {
      setSecond((prev) => --prev);
    }, 1000);

    const timer = setTimeout(() => {
      history.push('/');
    }, 3000);

    return () => {
      clearTimeout(timer);
      clearTimeout(reduceSecond);
    };
  }, [history]);

  return (
    <Wrapper>
      <img src='/assets/images/404.jpg' alt='404' />
      <h3>
        비정상적인 루트로 접근하셨습니다.
        <br />
        {second}초 뒤 홈 화면으로 이동합니다.
      </h3>

      <span onClick={handleGoHome}>홈으로</span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  padding: 160px 0 70px;
  min-height: 800px;

  h3 {
    text-align: center;
  }

  > span {
    padding: 15px 40px;
    border: 1px solid #007bff;
    border-radius: 15px;
    margin-top: 30px;
    background-color: #007bff;
    font-size: 1.6rem;
    color: #fff;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: #006fe5;
    }
  }
`;

export default NotFound;

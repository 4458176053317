import React from 'react';

import Layout from '../Layout/Layout';
import { Logo, Link } from '../../elements';
import THEME from '../../../state/theme';
import { useCustomState } from 'state/state';
import styles from './Footer.module.scss';

const Footer = () => {
  const state = useCustomState()[0];

  // 하단 Services 링크 출력
  const servicesList = state.data.footerMenu
    ?.map((item, index) => (
      <Link key={index} url={item.url} hoverStyle={{ color: THEME.color }}>
        <i className='las la-angle-right' />
        <span className='secomin'>{item.title}</span>
      </Link>
    ))
    .slice(0, 5);

  // 하단 Links 링크 출력
  const fastLinkList = state.data.fast_links?.map((item, index) => (
    <Link key={index} url={item.url} hoverStyle={{ color: THEME.color }}>
      <i className='las la-angle-right' />
      <span className='secomin'>{item.title}</span>
    </Link>
  ));

  const year = new Date().getFullYear();

  return (
    <div id='mainFooter'>
      <Layout padding style={{ background: '#e6e6e6' }}>
        <div className={styles.footer}>
          <div className={styles.logo_area}>
            <Logo dark />
            <span>
              <p>대표 : 김영숙</p>
              <p>사업자등록번호 : 737 - 81 - 00396</p>
              <p>주소 : 서울 중구 퇴계로36길 2 충무로 영상센터 704호</p>
            </span>
          </div>

          <div className={styles.link_area}>
            <div className={styles.links}>
              <h4>Services</h4>
              {servicesList}
            </div>
            <div className={styles.links}>
              <h4>Links</h4>
              {fastLinkList}
            </div>
          </div>

          <div className={styles.subscribe}>
            <h4>고객센터</h4>
            <span>
              <p>전화번호 : 02 - 6011 - 9633</p>
              <p>운영시간 : 09 : 30 ~ 17 : 00</p>
              <p>이메일 : hello@openeg.co.kr</p>
              <p>팩스번호 : 050 - 8090 - 9633</p>
            </span>
            <p>(C)2021 ~ {year} All rights are reserved</p>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Footer;

import React, { Fragment, useRef } from 'react';

import { useRedirectWithoutAuth, useRedirectWithoutOperator } from 'hooks/redirect.hook';
import { Header } from '../../../widgets';
import GroupCourseList from './GroupCourseList';
import styles from '../../Mypage/Mypage.module.scss';
import '../../MyPage.css';

const OperatorGroupList = () => {
  const ref = useRef(null);

  useRedirectWithoutAuth();
  useRedirectWithoutOperator();

  return (
    <Fragment>
      <Header>오퍼레이터 그룹 정보 조회</Header>
      <div className={styles.mypage} ref={ref}>
        <section className={styles.wrapper}>
          <article className='mypage'>
            <GroupCourseList />
          </article>
        </section>
      </div>
    </Fragment>
  );
};

export default OperatorGroupList;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  createUserTrainVideo,
  fetchCourseIntro,
  fetchCourseList,
  fetchInstructorData,
  fetchTrainVideoList,
  updateVideoProgress,
} from '../api/student';

export const useFetchTrainVideoList = ({ courseId, courseType }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchTrainVideoList', courseId, courseType],
    queryFn: () => fetchTrainVideoList({ courseId, courseType }),
    initialData: [],
    select: (res) => res.data && res.data.data.videoTrainList,
  });

  const { data: stableCourseData } = useQuery({
    queryKey: ['fetchNextCourseData', courseId, courseType],
    queryFn: () => fetchTrainVideoList({ courseId, courseType }),
    initialData: [],
    select: (res) => res.data && res.data.data.videoTrainList,
  });

  return { ...queryReturns, stableCourseData };
};

export const useCreateUserVideoTrain = () => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: ({ courseId }) =>
      createUserTrainVideo({
        courseId,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['fetchTrainVideoList'] });
    },
  });

  return { ...queryReturns };
};

export const useUpdateVideoProgress = () => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: ({ videoId, mediaLastPosition, viewArray }) =>
      updateVideoProgress({
        videoId,
        mediaLastPosition,
        viewArray,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['fetchTrainVideoList'] });
    },
  });

  return { ...queryReturns };
};

export const useFetchCourseList = ({ token }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchCourseList', { token }],
    queryFn: fetchCourseList,
  });

  return { ...queryReturns };
};

export const useFetchCourseIntro = ({ courseId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchCourseIntro'],
    queryFn: () => fetchCourseIntro({ courseId }),
  });

  return { ...queryReturns };
};

export const useFetchInstructorData = ({ instructorId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchInstructorData'],
    queryFn: () => fetchInstructorData({ instructorId }),
  });

  return { ...queryReturns };
};

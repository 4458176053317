import { useMutation, useQuery } from '@tanstack/react-query';

import { capability } from 'api';

const useUpdateTestProgress = () => {
  const mutation = useMutation({
    mutationFn: ({ examId, solvedProblemsCount }) =>
      capability.updateTestProgress({
        examId,
        solvedProblemsCount,
      }),
  });

  return { ...mutation };
};

const usePeriodicUpdate = ({ examId, solvedProblemsCount }) => {
  const { mutateAsync } = useUpdateTestProgress();

  useQuery({
    queryKey: ['updateTestProgress'],
    queryFn: () => mutateAsync({ examId, solvedProblemsCount }),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: 3 * 60 * 1000,
  });
};

export default usePeriodicUpdate;

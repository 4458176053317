import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';

const ContainerButton = (props) => {
  const project = props.project;
  const container = props.container;
  if (project.questWebYn === 'Y') {
    return (
      <>
        {container.project_id === props.project.questId ? (
          <>
            <Tooltip title='에디터 실행'>
              <IconButton onClick={() => props.handleClickViewProject(project.questId)}>
                <i className='fas fa-file-code' style={{ fontSize: '16px', color: '#2F96EB', padding: '5px' }}></i>
              </IconButton>
            </Tooltip>
            <Tooltip title='웹 서비스 실행'>
              <IconButton onClick={() => props.handleMoveWebService(project.questId)}>
                <i className='far fa-window-restore' style={{ fontSize: '16px', color: '#2F96EB', padding: '5px' }}></i>
              </IconButton>
            </Tooltip>
            <Tooltip title='프로젝트 진단'>
              <IconButton aria-label='enter'>
                <Link to={`diagnosisreport?pid=${project.questId}`}>
                  <i className='fas fa-tasks' style={{ fontSize: '16px', color: '#2F96EB', padding: '5px' }}></i>
                </Link>
              </IconButton>
            </Tooltip>
            {/*{ project.questSubmitTime ?
                <>
                <Tooltip title="제출 완료">
                <IconButton onClick={() => toast.error("이미 제출 하셨습니다.")} aria-label="enter">
                    <i className="fas fa-check-circle" style={{ fontSize: '16px', color: '#90ee90', padding: '5px' }}></i>
                </IconButton>
                </Tooltip>
                </>
                :
                <>
                <Tooltip title="최종 제출">
                <IconButton onClick={() => props.handleSubmit(project.questId)} aria-label="enter">
                    <i className="fas fa-check-circle" style={{ fontSize: '16px', color: '#2F96EB', padding: '5px' }}></i>
                </IconButton>
                </Tooltip>
                </>
            }*/}
            <Tooltip title='컨테이너 중지'>
              <IconButton onClick={() => props.handleSleepContainer()}>
                <i className='fas fa-stop' style={{ fontSize: '16px', color: '#dc143c', padding: '5px' }}></i>
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip title='콘테이너 만들기'>
              <IconButton onClick={() => props.handleClickViewProject(project.questId)}>
                <i className='fas fa-cube' style={{ fontSize: '16px', color: '#2F96EB', padding: '5px' }}></i>
              </IconButton>
            </Tooltip>
            <Tooltip title='웹 서비스 실행'>
              <IconButton>
                <i
                  className='far fa-window-restore'
                  style={{ opacity: '0.3', fontSize: '16px', color: '#808080', padding: '5px' }}
                ></i>
              </IconButton>
            </Tooltip>
            <Tooltip title='프로젝트 진단'>
              <IconButton aria-label='enter'>
                <i
                  className='fas fa-tasks'
                  style={{ opacity: '0.3', fontSize: '16px', color: '#808080', padding: '5px' }}
                ></i>
              </IconButton>
            </Tooltip>
            {/*{ project.questSubmitTime ?
                <>
                <Tooltip title="제출 완료">
                <IconButton onClick={() => toast.error("이미 제출 하셨습니다.")} aria-label="enter">
                    <i className="fas fa-check-circle" style={{ fontSize: '16px', color: '#90ee90', padding: '5px' }}></i>
                </IconButton>
                </Tooltip>
                </>
                :
                <>
                <Tooltip title="최종 제출">
                <IconButton onClick={() => props.handleSubmit(project.questId)} aria-label="enter">
                    <i className="fas fa-check-circle" style={{ fontSize: '16px', color: '#2F96EB', padding: '5px' }}></i>
                </IconButton>
                </Tooltip>
                </>
            }*/}
            <Tooltip title='컨테이너 중지'>
              <IconButton>
                <i
                  className='fas fa-stop'
                  style={{ opacity: '0.3', fontSize: '16px', color: '#808080', padding: '5px' }}
                ></i>
              </IconButton>
            </Tooltip>
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        {container.project_id === props.project.questId ? (
          <>
            <Tooltip title='에디터 실행'>
              <IconButton onClick={() => props.handleClickViewProject(project.questId)}>
                <i className='fas fa-file-code' style={{ fontSize: '16px', color: '#2F96EB', padding: '5px' }}></i>
              </IconButton>
            </Tooltip>
            {/*{ project.questSubmitTime ?
                    <>
                    <Tooltip title="제출 완료">
                    <IconButton onClick={() => toast.error("이미 제출 하셨습니다.")} aria-label="enter">
                        <i className="fas fa-check-circle" style={{ fontSize: '16px', color: '#90ee90', padding: '5px' }}></i>
                    </IconButton>
                    </Tooltip>
                    </>
                    :
                    <>
                    <Tooltip title="최종 제출">
                    <IconButton onClick={() => props.handleSubmit(project.questId)} aria-label="enter">
                        <i className="fas fa-check-circle" style={{ fontSize: '16px', color: '#2F96EB', padding: '5px' }}></i>
                    </IconButton>
                    </Tooltip>
                    </>
                }*/}
            <Tooltip title='컨테이너 중지'>
              <IconButton onClick={() => props.handleSleepContainer()}>
                <i className='fas fa-stop' style={{ fontSize: '16px', color: '#dc143c', padding: '5px' }}></i>
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip title='콘테이너 만들기'>
              <IconButton onClick={() => props.handleClickViewProject(project.questId)}>
                <i className='fas fa-cube' style={{ fontSize: '16px', color: '#2F96EB', padding: '5px' }}></i>
              </IconButton>
            </Tooltip>
            {/*{ project.questSubmitTime ?
                    <>
                    <Tooltip title="제출 완료">
                    <IconButton onClick={() => toast.error("이미 제출 하셨습니다.")} aria-label="enter">
                        <i className="fas fa-check-circle" style={{ fontSize: '16px', color: '#90ee90', padding: '5px' }}></i>
                    </IconButton>
                    </Tooltip>
                    </>
                    :
                    <>
                    <Tooltip title="최종 제출">
                    <IconButton onClick={() => props.handleSubmit(project.questId)} aria-label="enter">
                        <i className="fas fa-check-circle" style={{ fontSize: '16px', color: '#2F96EB', padding: '5px' }}></i>
                    </IconButton>
                    </Tooltip>
                    </>
                }*/}
            <Tooltip title='컨테이너 중지'>
              <IconButton>
                <i
                  className='fas fa-stop'
                  style={{ opacity: '0.3', fontSize: '16px', color: '#808080', padding: '5px' }}
                ></i>
              </IconButton>
            </Tooltip>
          </>
        )}
      </>
    );
  }
};

export default ContainerButton;

import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { isArray } from 'chart.js/helpers';

import { useUpdateTestProgress } from 'hooks';
import { fetchCheckUserExam, fetchUserExamData, submitUserExam } from 'api/capability';
import { AuthAtom } from 'state';
import { errorToast, successToast, sweetAlertConfirm, warningToast } from 'utilities/toast';
import { Header } from 'components/widgets';
import TestQuestion from './TestQuestion';
import TestTimer from './TestTimer';
import styles from '../Capatest.module.scss';
import './TestPage.css';

const TestPage = ({ languageId }) => {
  const ref = useRef(null);
  const auth = useRecoilValue(AuthAtom);
  const { userId } = auth;
  const history = useHistory();

  const [language, setLanguage] = useState('');
  const [examId, setExamId] = useState(0);
  const [questionData, setQuestionData] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [idx, setIdx] = useState(0);
  const [nowQNum, setNowQNum] = useState(1);
  const [saveAnswer, setSaveAnswer] = useState([]);
  const [timeOut, setTimeOut] = useState(false);

  const [isExamDone, setIsExamDone] = useState(true);

  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAlertOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleAlertClose = () => {
    setOpen(false);
  };

  /* 브라우저 뒤로가기 버튼 이벤트  */
  useEffect(() => {
    window.addEventListener(
      'popstate',
      () => {
        setOpen(true);
      },
      false
    );
    window.history.pushState(null, null);

    return () => {
      window.removeEventListener('popstate', () => {
        setOpen(false);
      });
    };
  }, [history, history.location.pathname, open]);

  useEffect(() => {
    if (!auth.auth) return;

    (async () => {
      try {
        const examStateResponse = await fetchCheckUserExam({ userId, languageId });

        const examState = examStateResponse.data.data;
        if (examState === 1 || examState === 2) {
          setIsExamDone(true);
          history.push('/');
          return;
        }
        setIsExamDone(false);

        const response = await fetchUserExamData({ languageId });

        const { language, examProblemIdList, examProblemCount, examId } = response.data;

        const storedAnswers = sessionStorage.getItem(`saveAnswer${language.toUpperCase()}`);
        const answers = storedAnswers ? storedAnswers : new Array(examProblemCount);
        if (!storedAnswers) {
          sessionStorage.setItem(`saveAnswer${language.toUpperCase()}`, answers.join(','));
        }

        setExamId(examId);
        setLanguage(language);
        setSaveAnswer(isArray(answers) ? answers : [...answers.split(',')]);
        setQuestionData(response.data);
        setQuestionList(examProblemIdList);
      } catch (error) {
        if (error.response !== undefined) {
          history.push('/');
        }
      }
    })();
  }, []);

  /* 유저 진행률 업데이트 */
  useUpdateTestProgress({
    examId: examId,
    solvedProblemsCount: saveAnswer.filter((e) => !!e).length,
  });

  const handlePrevButton = () => {
    if (nowQNum > 1) {
      setNowQNum(nowQNum - 1);
      setIdx(idx - 1);
      window.scrollTo(0, 0);
    } else {
      alert('첫 번째 문제입니다.');
    }
  };

  const handleNextButton = () => {
    setNowQNum(nowQNum + 1);
    setIdx(idx + 1);
    window.scrollTo(0, 0);
  };

  const handleSubmitButton = () => {
    const storedAnswers = sessionStorage.getItem(`saveAnswer${language.toUpperCase()}`);
    const submitAnswer = storedAnswers.split(',');

    sweetAlertConfirm('답안을 제출하시겠습니까?', () => onSubmit(submitAnswer));
  };

  const timeOutSubmit = () => {
    const storedAnswers = sessionStorage.getItem(`saveAnswer${language.toUpperCase()}`);
    const submitAnswer = storedAnswers.split(',');

    const timeOver = new Promise((resolve) => {
      if (saveAnswer.length !== 0) {
        resolve(submitAnswer);
      }
    });
    warningToast('역량평가 응시 가능 시간이 끝났습니다.');

    timeOver.then((submitAnswer) => {
      setTimeOut(() => {
        onSubmit(submitAnswer);
      }, 1700);
    });
  };

  useEffect(() => {
    timeOut && timeOutSubmit();
  });

  const goToThisQuestion = (idx) => {
    setNowQNum(idx + 1);
    setIdx(idx);
  };

  const saveAnswerHandler = (checkedAnswer) => {
    if (saveAnswer && checkedAnswer !== '' && saveAnswer.length !== 0) {
      if (!checkedAnswer) {
        saveAnswer[idx] = '';
      } else {
        saveAnswer[idx] = checkedAnswer;
      }
      sessionStorage.setItem(`saveAnswer${language.toUpperCase()}`, saveAnswer);
    }
  };

  const onClickEnterKey = () => {
    if (nowQNum === questionData.examProblemCount) {
      handleSubmitButton();
    } else {
      handleNextButton();
    }
  };

  const onSubmit = async (saveAnswer) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    const examStateResponse = await fetchCheckUserExam({ userId, languageId });

    if (examStateResponse.data.data === 2) {
      errorToast('시험 기간 내에 시험을 정상적으로 제출하지 않으셨습니다.');
      setIsSubmitting(false);
      history.push('/');
    } else {
      try {
        const response = await submitUserExam({
          examId: questionData.examId,
          userId,
          examProblemIdList: questionList,
          examSubmitAnswer: saveAnswer,
        });
        if (response.data === 'success') {
          successToast(timeOut ? '제출 시간이 마감되어 자동으로 제출되었습니다.' : '제출 완료');
          sessionStorage.removeItem(`saveAnswer${language.toUpperCase()}`);
          localStorage.removeItem(`questionOrder${language}`);
          setSaveAnswer([]);
          history.push('/student/capability/result');
        } else {
          errorToast('제출되지 않았습니다. 다시 시도해주세요');
          history.push(`/`);
        }
        setIsSubmitting(false);
      } catch (error) {
        if (error.response.data.msg === 'size mismatch') {
          errorToast('제출된 답안의 개수가 일치하지 않습니다. \n 다시 시도해주세요.');
          sessionStorage.removeItem(`saveAnswer${language.toUpperCase()}`);
          localStorage.removeItem(`questionOrder${language}`);
          return;
        }

        errorToast('에러가 발생하였습니다. \n 다시 시도해주세요.');
        setIsSubmitting(false);
      }
    }
  };

  if (isExamDone) return <div style={{ padding: '40% 0' }}></div>;
  return (
    <Fragment>
      <Header>역량평가</Header>
      <div className={styles.mypage} ref={ref}>
        <section className={`${styles.wrapper} ${styles.with_sidebar} ${styles.left}`}>
          <article className='capaTest'>
            <div className='capaTestBox'>
              <div className='capaTestBox2'>
                <div className='capaTestTimer'>
                  <TestTimer questionData={questionData} setTimeOut={setTimeOut} />
                </div>
                <div className='capaTestQuestion1'>
                  <h2>{questionData.language?.toUpperCase()} 시큐어코딩</h2>
                </div>
                <div className='capaTestQuestion2'>
                  <TestQuestion
                    nowQNum={nowQNum}
                    saveAnswerHandler={saveAnswerHandler}
                    questionList={questionList}
                    idx={idx}
                    problemId={parseInt(questionList[idx])}
                    onClickEnterKey={onClickEnterKey}
                  />
                </div>
                <div className='capaTestFooter'>
                  <div className='capaQuestionNums'>
                    <p style={{ color: '#868686' }}>
                      <strong style={{ color: '#2C95D2' }}>{nowQNum}</strong> / {questionData.examProblemCount}
                    </p>
                  </div>
                  <div className='buttonsBox'>
                    {nowQNum === 1 ? null : (
                      <button onClick={() => handlePrevButton()} className='prevButton'>
                        <i className='fas fa-arrow-left'></i>&nbsp;이전
                      </button>
                    )}
                    {nowQNum === questionData.examProblemCount ? (
                      <button onClick={(e) => handleSubmitButton(e)} className='submitButton'>
                        제출
                      </button>
                    ) : (
                      <button onClick={() => handleNextButton()} className='nextButton'>
                        다음&nbsp;<i className='fas fa-arrow-right'></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <div className='capaTestTable'>
                  {questionList &&
                    questionList?.map((nums, index) => (
                      <li key={index}>
                        <p
                          style={{
                            color:
                              idx === index
                                ? '#FE9E6E'
                                : saveAnswer.length && !!saveAnswer[index]
                                ? '#2e94d2'
                                : '#b5b5b5',
                          }}
                          onClick={(e) => goToThisQuestion(index, e)}
                        >
                          {index + 1}
                        </p>
                        <input type='checkbox' value={nums} id={'q' + index} className='form-control' />
                      </li>
                    ))}
                </div>
                <button onClick={(e) => handleSubmitButton(e)} className='submitButton2'>
                  제출
                </button>
              </div>
            </div>
          </article>
        </section>
      </div>

      <Dialog
        open={open}
        onClose={handleAlertOpen}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <AlertWrapper>
          <h2>안내</h2>
          <DialogContent>
            <p>
              페이지를 벗어날 시 시험 제출이 정상적으로 되지 않을 수 있습니다. <br />
              또한, 페이지를 이동하더라도 시간 제한은 지난 시간 만큼 차감됩니다.
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAlertClose}>시험 진행하기</Button>
            <Button onClick={() => history.go(-2)}>뒤로가기</Button>
          </DialogActions>
        </AlertWrapper>
      </Dialog>
    </Fragment>
  );
};

const AlertWrapper = styled.div`
  padding: 18px 14px;

  h2 {
    font-size: 1.8rem;
    font-weight: 500;
  }

  p {
    font-size: 1.4rem;
    color: #333;
  }

  button {
    border: 1px solid rgb(44, 149, 210);
    color: rgb(44, 149, 210);
    border-radius: 3px;
    font-size: 0.9rem;
  }

  button:last-child {
    color: #e74c3c;
    border: 1px solid #e74c3c;
  }
`;
export default TestPage;

/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function TestMultipleChoices(props) {
  const location = useLocation();
  const [value, setValue] = useState([]);
  const [tmpValue, setTmpValue] = useState(true);

  const sortValue = (target) => {
    if (tmpValue !== undefined && target !== '') {
      /* 값이 하나도 없을 경우 첫번째로 추가 */
      if (!value.length) {
        value[0] = target;
        setTmpValue(!tmpValue);
      } /* 값이 있고 동일 값이 없을 경우 추가 후 정렬 */ else if (
        value &&
        value.indexOf(target) === -1 &&
        value.length < 4 &&
        typeof value == 'object'
      ) {
        value[value.length] = target;

        var length = value.length;
        var i, j, temp;
        for (i = 0; i < length - 1; i++) {
          for (j = 0; j < length - 1 - i; j++) {
            if (value[j] > value[j + 1]) {
              temp = value[j];
              value[j] = value[j + 1];
              value[j + 1] = temp;
            }
          }
        }
        setTmpValue(!tmpValue);
      } /* 이미 같은 값이 있을 경우 제거 */ else if (value && value.indexOf(target) !== -1) {
        value.splice(value.indexOf(target), 1);
        setTmpValue(!tmpValue);
      }
    }
  };

  const { questionList, languageName } = props;

  useEffect(() => {
    let answerList = sessionStorage.getItem(`saveAnswer${props.languageName.toUpperCase()}`);

    if (answerList) {
      let answer = answerList.split(',')[props.idx];
      setValue(answer.trim());
      setTmpValue(!tmpValue);
    }

    if (!questionList || !!questionList.length) return;

    if (questionList.length !== [...answerList.split(',')].length) {
      sessionStorage.setItem(`saveAnswer${languageName.toUpperCase()}`, new Array(questionList.length));
      let answer = answerList.split(',')[props.idx];
      setValue(answer.trim());
      setTmpValue(!tmpValue);
    }
  }, [props.idx, questionList]);

  const changeCheckBox = (e) => {
    sortValue(e.target.value);
  };

  useEffect(() => {
    if (value.length) {
      props.setCheckAnswer(value.join('/'));
    } else {
      props.setCheckAnswer(null);
    }
    return function cleanup() {
      props.setCheckAnswer('');
    };
  }, [tmpValue]);

  return (
    <div className='choices'>
      {/* 멀티플초이스 */}
      {props.testOptions &&
        props.testOptions?.map((choice, option) => (
          <li key={'multi' + option}>
            <input
              type='checkbox'
              checked={value.indexOf(String(option + 1)) !== -1 ? true : false}
              onChange={(e) => changeCheckBox(e)}
              value={option + 1}
              id={option + 1}
              className='form-control'
            />
            &nbsp;{option + 1}.&nbsp;
            {choice &&
              choice.split('\\t')?.map((line, idx) => {
                return (
                  <span key={'line' + idx}>
                    {line}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                );
              })}
          </li>
        ))}
    </div>
  );
}

/* eslint-disable */

import React, { useEffect, useState } from 'react';

export default function TestOx(props) {
  const [value, setValue] = useState('');
  const [tmpValue, setTmpValue] = useState(true);

  const { questionList, languageName, idx } = props;

  useEffect(() => {
    let answerList = sessionStorage.getItem(`saveAnswer${languageName.toUpperCase()}`);

    if (answerList) {
      let answer = answerList.split(',')[idx];
      setValue(answer.trim());
      setTmpValue(!tmpValue);
    }

    if (!questionList || !!questionList.length) return;

    if (questionList.length !== [...answerList.split(',')].length) {
      sessionStorage.setItem(`saveAnswer${languageName.toUpperCase()}`, new Array(questionList.length));
      let answer = answerList.split(',')[props.idx];
      setValue(answer.trim());
      setTmpValue(!tmpValue);

      if (!answerList) {
        localStorage.removeItem(`questionOrder${languageName}`);
      }
    }
  }, [idx, questionList]);

  // const choiceAnswer;
  const changeRadio = (e) => {
    setValue(e.target.value);
    setTmpValue(!tmpValue);
  };

  useEffect(() => {
    value && props.setCheckAnswer(value);
    return function cleanup() {
      props.setCheckAnswer('');
    };
  }, [tmpValue]);

  return (
    <div className='choices'>
      {/* OX 선택 */}
      <label>
        <input
          type='radio'
          name='chosenAnswer'
          checked={value === 'O'}
          onChange={changeRadio}
          value='O'
          className='form-control'
        />
        <span>&nbsp;O</span>
      </label>
      <label>
        <input
          type='radio'
          name='chosenAnswer'
          checked={value === 'X'}
          onChange={changeRadio}
          value='X'
          className='form-control'
        />
        <span>&nbsp;X</span>
      </label>
    </div>
  );
}

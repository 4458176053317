import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import AnimatedNumber from 'animated-number-react';
import { Paper, Table, TableCell, TableContainer, TableHead, TableBody, TableRow } from '@mui/material';

import THEME from 'state/theme';
import DashboardElapsedTimeInfoStat from './DashboardElapsedTimeInfoStat';
import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import { fetchDashBoard } from 'api/common';
import styles from '../../widgets/Ranking/Ranking.module.scss';
import styled from 'styled-components';

const StudySecureBoard = () => {
  const [listRank, setListRank] = useState([]);

  const formatValue = (value) => value.toFixed(0);

  const colors = ['#D6AF36', '#A7A7AD', '#A77044'];

  const top3 = listRank
    ?.map((item, index) => (
      <div className='col-md-4' key={index} style={{ width: '30%' }}>
        <div className={styles.card} style={{ borderColor: THEME.color }}>
          <i className='las la-crown' style={{ color: colors[index] }}></i>
          <p className='secomain secoblue'>{item.rankNo}등</p>
          <p className='secosub1 secodgray'>{item.userId}</p>
          <p className='secosub2' style={{ color: 'red' }}>
            총 <AnimatedNumber value={item.solvedCount} duration={3000} delay={1000} formatValue={formatValue} /> 문제
            해결
          </p>
        </div>
      </div>
    ))
    .slice(0, 3);

  useRedirectWithoutAuth();

  useEffect(() => {
    (async () => {
      const response = await fetchDashBoard();
      setListRank(response.data);
    })();
  }, []);

  return (
    <Fragment>
      {/* 개발언어별 보안약점 문제풀이 현황 */}
      {/*<DashboardSolvedInfoStat />*/}

      {/* 보안약점별 문제풀이에 걸린 평균시간 */}
      <DashboardElapsedTimeInfoStat />

      {/* 1위 ~ 10위 */}
      <div style={{ textAlign: 'center' }}>
        <p className='secosub1 secoblack'>가장 많은 문제를 푼 사용자</p>
        <div style={{ display: 'flex', margin: '30px 0px' }}>{top3}</div>
        <TableContainer component={Paper}>
          <Table size='medium' aria-label='customized table'>
            <TableHead rowcount={listRank.length}>
              <StyledTableRow>
                <TableCell align='center'>순위</TableCell>
                <TableCell align='center'>아이디</TableCell>
                <TableCell align='center'>해결한 문제 개수</TableCell>
              </StyledTableRow>
            </TableHead>

            <TableBody>
              {listRank
                ?.map((row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell scope='row' align='center'>
                        {row.rankNo}
                      </TableCell>
                      <TableCell align='center'>{row.userId}</TableCell>
                      <TableCell align='center'>{row.solvedCount}</TableCell>
                    </StyledTableRow>
                  );
                })
                .slice(3, 17)}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Fragment>
  );
};

const StyledTableRow = styled(TableRow)`
  > th {
    background-color: #000;
    color: #fff;
    font-size: 16px;
  }

  > td {
    font-size: 12px;
  }
`;

export default withRouter(StudySecureBoard);

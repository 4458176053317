/* eslint-disable */
import React, { Fragment, useRef } from 'react';
import { Header } from '../components/widgets';
import styled from 'styled-components';

import { useRedirectWithoutAuth } from '../hooks/redirect.hook';
import LanguageList from '../Student/LanguageList.js';
import { useFetchLanguages } from '../hooks/common';
import '../Student/Training.css';

const SecureList = () => {
  const ref = useRef(null);

  useRedirectWithoutAuth();

  const { languages, myCourseId } = useFetchLanguages();

  const isAllowedCourse = (languageId) => !!myCourseId.includes(languageId);

  return (
    <Fragment>
      <Header>보안약점 진단훈련</Header>
      <div ref={ref}>
        <div className='text-center' style={{ marginTop: '50px', marginBottom: '30px' }}>
          <h4 className='pt-2 secosub2' style={{ color: '#a8a9a9' }}>
            학습할 개발언어를 선택하세요.
          </h4>
        </div>

        <CardWrapper className='row'>
          {languages?.map((lan, index) => {
            return (
              lan.languageId !== 0 && (
                <LanguageList
                  key={index}
                  languageType={lan.languageType}
                  languageId={lan.languageId}
                  disable={!isAllowedCourse(lan.languageId)}
                />
              )
            );
          })}
        </CardWrapper>
      </div>
    </Fragment>
  );
};

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0 10% 60px;
`;

export default SecureList;

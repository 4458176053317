import { api } from './config';

/* [수강생]수강생 시험 응시 결과 상태 조회 */
export const fetchCheckUserExam = async ({ userId, languageId }) => {
  return await api.get(`/users/exam/taken/${userId}/${languageId}`);
};

/* [수강생]수강생 시험 응시 상태 조회 */
export const fetchUserExamStatus = async ({ userId, languageId }) => {
  return await api.get(`/users/exam/started/${userId}/${languageId}`);
};

/* [수강생]수강생 시험 응시 시작 */
export const createUserExamStart = async ({ userId, languageId }) => {
  return await api.post(`/users/exam/start`, {
    userId,
    languageId,
  });
};

/* [수강생]시험 정보 조회 */
export const fetchUserExamData = async ({ languageId }) => {
  return await api.get(`/users/exam/test/${languageId}`);
};

/* [수강생]시험 답안지 제출 */
export const submitUserExam = async ({ examId, userId, examProblemIdList, examSubmitAnswer }) => {
  return await api.post(`/users/exam`, {
    examId,
    userId,
    examProblemIdList,
    examSubmitAnswer,
  });
};

export const fetchUserExamResult = async () => {
  return await api.get(`/users/exam/result`);
};

/* [역량평가] 역량평가 문제 정보 조회 */
export const fetchTestQuestion = async ({ problemId }) => {
  return await api.get(`/users/exam/${problemId}`);
};

/* [역량평가 보고서]응시자의 역량평가 문제 정오표 */
export const fetchExamReport = async ({ languageId }) => {
  return await api.get(`/users/exam/report/languages/${languageId}/problems`);
};

/* [역량평가 보고서]응시자의 응시 결과 상세 */
export const fetchExamResultInfo = async ({ languageId }) => {
  return await api.get(`/users/exam/report/languages/${languageId}/info`);
};

/* [역량평가 보고서]그룹 역량평가 응시 평균 */
export const fetchExamReportAvg = async ({ languageId }) => {
  return await api.get(`/users/exam/report/languages/${languageId}/group-avg`);
};

/* [역량평가 보고서]수강생의 역량평가 응시 언어 */
export const fetchUserReportLanguage = async () => {
  return await api.get(`/users/exam/languages`);
};

/* [역량평가]학생 역량평가 진행률 서버에 업데이트 */
export const updateTestProgress = async ({ examId, solvedProblemsCount }) => {
  return await api.patch(`/users/exam/progress`, { examId, solvedProblemsCount });
};

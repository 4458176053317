/* eslint-disable */
import React, { Fragment, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import './style.css';
import { AuthAtom } from 'state';
import Header from '../../components/widgets/Header/Header';

function DiagnosisPageLayout(props) {
  const auth = useRecoilValue(AuthAtom);
  const ref = useRef(null);

  const active = props.active;
  const setActive = props.setActive;

  const { children } = props;

  const handelClick = (e) => {
    setActive(e);
  };

  useState(() => {
    if (window.location.href.indexOf('listprojects') > 0) setActive(2);
  });

  return (
    <Fragment>
      <Header>프로젝트 개발훈련</Header>
      <div className='codedigagnosisMain' ref={ref}>
        <div className='container'>
          {auth.freeYn === 'Y' && (
            <div className='row' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <div className='col-md-20'>
                <div className='secosub2' style={{ color: 'red' }}>
                  프로젝트 진단훈련은 유료회원 전용 서비스입니다.
                </div>
                <div className='secotext secoblack' style={{ lineHeight: '150%' }}>
                  <br />
                  시코랩에 유료회원으로 가입하시면
                  <br />
                  개인별로 웹 애플리케이션을 개발, 빌드, 테스트, 실행할 수 있는 독립된 웹 IDE 환경을 제공받을 수 있으며,
                  <br />
                  시코랩 진단 프로세스를 이용하여 웹 IDE로 생성한 프로젝트에 내재한 보안약점을 진단하고 진단 리포트에
                  따라 수정해 볼 수도 있습니다.
                  <br />
                  <br />
                  더불어, 회원가입, 로그인, 게시판 등록, 수정, 삭제, 조회 등 웹 애플리케이션을 구성하는 주요 기능에서
                  발생할 수 있는 보안약점을
                  <br />
                  확인하고 수정해 볼 수 있도록 실습 샘플 프로젝트를 함께 제공합니다.
                  <br />
                  <br />
                  유료회원 가입 또는 전환과 관련한 자세한 내용은 고객센터로 문의해 주십시오.
                </div>
              </div>
            </div>
          )}

          {auth.freeYn !== 'Y' && (
            <div className='row'>
              <div className='col-md-2'>
                <div className='projectLeftside'>
                  <ul>
                    <li className={active === 1 ? 'projectLeftsideActive' : ''}>
                      <Link to={`/student/codediagnosis/listproblems`} onClick={() => handelClick(1)}>
                        <span className='secotext'>
                          <i className='far fa-file-alt fa-2x'></i>
                          <br />
                          문제 목록
                        </span>
                      </Link>
                    </li>
                    <li className={active === 2 ? 'projectLeftsideActive' : ''}>
                      <Link to={`/student/codediagnosis/listprojects`} onClick={() => handelClick(2)}>
                        <span className='secotext'>
                          <i className='far fa-file-powerpoint fa-2x'></i>
                          <br />내 프로젝트
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-md-10'>
                <div style={{ width: '100%', transition: 'all 0.4s' }}>{children}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default DiagnosisPageLayout;

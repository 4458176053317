import React, { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import * as Sentry from '@sentry/react';

import { api, midibusApi } from '../api/config';
import { getItem, removeItem, setItem } from '../commons/localStorage';
import { useCustomState, AuthAtom } from '../state';
import { errorToast, warningToast } from './toast';
import { MESSAGE } from 'utilities';
import { useHistory } from 'react-router';

let refreshSubscribers = [];

export default function AxiosConfig() {
  let isTokenRefreshing = false;
  const auth = useRecoilValue(AuthAtom);
  const resetAuthAtom = useResetRecoilState(AuthAtom);
  const actions = useCustomState()[1];
  const history = useHistory();

  const onTokenRefreshed = async (accessToken) => {
    refreshSubscribers?.map((callback) => {
      callback(accessToken);
    });
    refreshSubscribers = [];
  };

  const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
  };

  useEffect(() => {
    if (auth.auth) {
      if (JSON.stringify(refreshSubscribers) !== '[]') {
        onTokenRefreshed(getItem('token'));
      }
    }
  }, [auth]);

  api.interceptors.request.use((response) => {
    const localToken = getItem('token');

    if (localToken != null) {
      const token = localToken.split('Bearer ')[1];

      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    return response;
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorStatusArray = [400, 401, 405, 404, 403, 500];
      let accessToken = '';

      const { config, response } = error;
      const originalRequest = config;

      if (error.response && errorStatusArray.includes(error.response.status)) {
        Sentry.captureException(error);
      }

      if (response && response.status === 404) {
        history.push('/');
        errorToast(MESSAGE.UNKNOWN_REQUEST);
      }

      if (
        response.status === 401 &&
        error.config.url !== `${process.env.REACT_APP_IP}/users/login` &&
        error.config.url !== `${process.env.REACT_APP_IP}/users/refreshAccessToken`
      ) {
        if (!isTokenRefreshing) {
          // isTokenRefreshing이 false인 경우에만 token refresh 요청
          isTokenRefreshing = true;
          const refreshToken = localStorage.getItem('refreshToken');
          removeItem('token');

          api
            .post(
              `${process.env.REACT_APP_IP}/users/refreshAccessToken`,
              {}, // token refresh api
              {
                transformRequest: (data, headers) => {
                  delete headers.common.Authorization;
                  return data;
                },
                headers: {
                  RefreshToken: `${refreshToken}`,
                },
              }
            )
            .then((res) => {
              // 새로운 토큰 저장
              isTokenRefreshing = false;
              accessToken = res.headers.authorization;
              api.defaults.headers.common['Authorization'] = res.headers.authorization;
              setItem('token', accessToken);
              onTokenRefreshed(accessToken);
            })
            .catch(() => {
              isTokenRefreshing = false;
              localStorage.clear();
              resetAuthAtom();
              // modal이 여러번 toggle되지 않기 위해 오직 pop만 하는 함수 사용
              actions.popModal();
              history.push('/');
              errorToast(MESSAGE.SERVICE_EXPIRED_REFRESHTOKEN);
            });
        } else {
          isTokenRefreshing = false;
          if (error.config.url === `${process.env.REACT_APP_IP}/users/refreshAccessToken`) {
            localStorage.clear();
            resetAuthAtom();
            history.push('/');
            // modal이 여러번 toggle되지 않기 위해 오직 pop만 하는 함수 사용
            actions.popModal();
            errorToast(MESSAGE.SERVICE_EXPIRED_REFRESHTOKEN);
          }
        }

        //   token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
        const retryOriginalRequest = new Promise((resolve, reject) => {
          addRefreshSubscriber(function (accessToken) {
            originalRequest.headers.Authorization = accessToken;
            api(originalRequest)
              .then((res) => resolve(res))
              .catch((error) => reject(error));
          });
        });
        return retryOriginalRequest;
      }
      return Promise.reject(error);
    }
  );

  midibusApi.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response } = error;

      if (response && (response.status === 401 || response.status === 404)) {
        removeItem('midibusToken');
        history.push('/student/course');
        warningToast(MESSAGE.EXPIRE_COURSE_SESSION);
      }
    }
  );

  return <></>;
}

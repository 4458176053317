import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, Box, Grid, Container, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Button } from '../../elements';
import { errorToast, successToast } from 'utilities/toast';
import { userChangePassword, userConfirmCode, userEmailConfirm, userSubmitId, userSubmitPassword } from 'api/auth';
import styles from './InfoFind.module.scss';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: '15px',
    margin: '50px 0 100px 0',
  },
  paper: {
    padding: '15px',
  },
  tab: {
    borderRaidus: '10px',
    fontWeight: '900',
    color: 'gray',
    backgroundColor: 'white !important',
  },
  tabBtn: {
    width: '50% !important',
    fontSize: '1.4rem !important',
  },
  tF: {
    marginLeft: '0px !important',
  },
  indicator: {
    backgroundColor: '#007bff !important',
  },
  button: {
    color: 'white',
    fontSize: '1.2rem',
    margin: '15px 0 10px',
    backgroundColor: '#343a40',
    cursor: 'pointer',
    border: 'thin solid #343a40 !important',
  },
}));

export default function InfoFind() {
  const classes = useStyles();
  const [value, setValue] = useState('one');
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCheckedUserId('no');
    setCheckedPass('no');
  };

  const [checkedUserId, setCheckedUserId] = useState('no');
  const [checkedPass, setCheckedPass] = useState('no');
  const [confirmStatusEmail, setConfirmStatusEmail] = useState('no');
  const [confirmStatusConfirmCode, setConfirmStatusConfirmCode] = useState('no');

  const [isIdLoading, setIsIdLoading] = useState(false);
  const [isPasswordLoading, setIsPasswordLoading] = useState(false);
  const [isPwLoading, setIsPwLoading] = useState(false);

  const [values, setValues] = useState({
    name: '',
    email: '',
  });

  const [valuesPass, setValuesPass] = useState({
    userId: '',
    name: '',
    email: '',
    confirmCode: '',
  });

  const [valuesPassConfirm, setValuesPassConfirm] = useState({
    password: '',
    confirmPassword: '',
  });

  const [error, setError] = useState({
    userIdError: '',
    emailError: '',
    emailCodeError: '',
    pwdError: '',
    confirmPwd: '',
    nameError: '',
  });

  const isEmail = (email) => {
    const emailRegex =
      /^(([^<>()\].,;:\s@"]+(\.[^<>()\].,;:\s@"]+)*)|(".+"))@(([^<>()¥[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return emailRegex.test(email);
  };

  const isPwd = (pass) => {
    const pwdRegex = /^.*(?=.{6,20})(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@$!%*#?&]).*$/;

    return pwdRegex.test(pass);
  };

  const confirmPassword = (pass, confirmPass) => {
    return pass === confirmPass;
  };
  // TODO:인증번호 확인 검증 로직 추가
  const onTextValidationPass = () => {
    let userIdError = '';
    let nameError = '';
    let emailError = '';
    let emailCodeError = '';
    if (valuesPass.userId.length === 0) userIdError = '아이디를 입력해주세요.';
    if (valuesPass.name.length === 0) nameError = '이름을 입력해주세요.';
    if (!isEmail(valuesPass.email)) emailError = 'email 형식이 아닙니다.';
    if (!confirmStatusEmail || confirmStatusEmail === 'no') emailError = '이메일 인증을 진행해주세요';
    if (!confirmStatusConfirmCode || confirmStatusConfirmCode === 'no') emailCodeError = '인증 번호를 확인 해주세요';

    setError({
      userIdError,
      nameError,
      emailError,
      emailCodeError,
    });

    if ((userIdError || nameError || emailError, emailCodeError)) return false;
    return true;
  };

  const onTextValidationPassConfirm = () => {
    let pwdError = '';
    let confirmPwd = '';

    if (!isPwd(valuesPassConfirm.password)) pwdError = '비밀번호 조건을 만족 할 수 없습니다.';
    if (!confirmPassword(valuesPassConfirm.password, valuesPassConfirm.confirmPassword))
      confirmPwd = '비밀번호가 일치하지 않습니다.';
    if (valuesPass.userId === valuesPassConfirm.password) pwdError = '아이디를 비밀번호로 사용 할 수 없습니다.';

    setError({
      pwdError,
      confirmPwd,
    });

    if (pwdError || confirmPwd) return false;
    return true;
  };

  const handleChangeForm = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleChangeFormPass = (e) => {
    setValuesPass({ ...valuesPass, [e.target.name]: e.target.value });
  };

  const handleChangeFormPassConfirm = (e) => {
    setValuesPassConfirm({ ...valuesPassConfirm, [e.target.name]: e.target.value });
  };

  const handleSubmitID = async (e) => {
    e.preventDefault();
    if (isIdLoading) return;

    try {
      setIsIdLoading(true);
      const response = await userSubmitId({
        name: values.name,
        email: values.email,
      });
      if (response.data === 0) errorToast('해당하는 아이디가 없습니다.');
      else setCheckedUserId(response.data);
      setIsIdLoading(false);
    } catch (error) {
      errorToast('아이디를 찾을 수 없습니다.');
      setValues({ name: '', email: '' });
      setIsIdLoading(false);
    }
  };

  const handleConfirmEmail = async () => {
    if (!valuesPass.email) {
      errorToast('이메일을 입력하세요.');
    } else if (!isEmail(valuesPass.email)) {
      errorToast(valuesPass.email + '는 사용할 수 없는 형식입니다.');
    } else {
      try {
        setConfirmStatusEmail(false);
        const response = await userEmailConfirm(valuesPass.email);
        const { data } = response;
        if (data === 'success') {
          setConfirmStatusEmail(true);
          successToast(
            '입력하신 이메일 주소로 인증번호를 발송했습니다. 받은 편지함을 확인하셔서 전달된 인증번호를 입력해 주세요.'
          );
        } else if (data === 'sendMailErrror') {
          setConfirmStatusEmail(false);
          errorToast('인증번호를 발송할 수 없습니다. 이메일 주소를 확인하신 후 다시 시도해 주세요.');
        } else {
          errorToast('가입된 이메일이 아닙니다.');
        }
      } catch (error) {
        errorToast('서버와의 연결이 원할하지 않습니다. 잠시 후 다시 시도해 주세요.');
      }
    }
  };

  //  이메일 인증코드 확인
  const handleConfirmCode = async () => {
    if (confirmStatusEmail !== true) {
      errorToast('이메일 인증을 먼저 시도해 주세요.');
    } else if (!valuesPass.confirmCode) {
      errorToast('인증번호를 입력하세요.');
    } else {
      try {
        const response = await userConfirmCode({
          email: valuesPass.email,
          confirmCode: valuesPass.confirmCode,
        });
        const { data } = response;
        if (data === 'success') {
          setConfirmStatusConfirmCode(true);
          errorToast('인증되었습니다. 계속해서 진행해 주세요.');
        } else {
          setConfirmStatusConfirmCode(false);
          errorToast(
            '인증번호 확인에 실패했습니다. 인증번호를 확인하신 후 다시 시도해 주세요. 인증제한시간(5분)이 초과한 경우 이메일 인증부터 다시 시도해 주세요.'
          );
        }
      } catch (error) {
        errorToast('서버와의 연결이 원할하지 않습니다. 잠시 후 다시 시도해 주세요.');
      }
      setConfirmStatusConfirmCode(false);
    }
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    const valid = onTextValidationPass();
    if (isPasswordLoading) return;

    if (!valid) console.error('invalid');
    else {
      try {
        const response = await userSubmitPassword({
          userId: valuesPass.userId,
          name: valuesPass.name,
          email: valuesPass.email,
        });
        if (response.data === 0) errorToast('인증에 실패 했습니다.');
        else {
          successToast('인증되었습니다. 패스워드를 재설정하세요.');
          setCheckedPass(response.data);
          setValuesPassConfirm({ password: '', confirmPassword: '' });
        }
        setIsPasswordLoading(false);
      } catch (error) {
        errorToast('인증에 실패 했습니다.');
        setValuesPass({ userId: '', name: '', email: '' });
        setIsPasswordLoading(false);
      }
      setIsPasswordLoading(true);
    }
  };

  const handleSubmitPasswordEdit = async (e) => {
    e.preventDefault();

    if (isPwLoading) return;

    const valid = onTextValidationPassConfirm();

    if (!valid) console.error('invalid');
    else {
      try {
        setIsPwLoading(true);
        const response = await userChangePassword({
          userId: valuesPass.userId,
          password: valuesPassConfirm.password,
        });
        if (response.data === 0) errorToast('인증에 실패 했습니다.');
        else if (response.data === 1) {
          successToast('비밀번호가 변경되었습니다.');
          setTimeout(() => {
            history.push('/');
          }, 1000);
        } else {
          errorToast('비밀번호 변경에 실패 했습니다.');
          setIsPwLoading(false);
        }
      } catch (error) {
        errorToast('인증에 실패 했습니다.');
        setValuesPassConfirm({ password: '', confirmPassword: '' });
        setIsPwLoading(false);
      }
    }
  };

  return (
    <Container maxWidth='sm'>
      <div className='mypage'>
        <div className={classes.root}>
          <AppBar position='static'>
            <Tabs
              className={classes.tab}
              classes={{ indicator: classes.indicator }}
              value={value}
              onChange={handleChange}
              aria-label='wrapped label tabs example'
            >
              <Tab className={classes.tabBtn} value='one' label='아이디 찾기' wrapped fullWidth {...a11yProps('one')} />
              <Tab
                className={classes.tabBtn}
                value='two'
                label='비밀번호 찾기'
                wrapped
                fullWidth
                {...a11yProps('two')}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index='one'>
            {checkedUserId === 'no' ? (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <form className={classes.form} onSubmit={handleSubmitID}>
                    <TextField
                      className={classes.tF}
                      variant='outlined'
                      margin='normal'
                      required
                      autoFocus
                      autoComplete='name'
                      fullWidth
                      label='이름을 입력하세요.'
                      name='name'
                      value={values.name}
                      onChange={handleChangeForm}
                    />
                    <div
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        margin: '-5px 0 10px 15px',
                      }}
                    >
                      {error.nameError}
                    </div>
                    <TextField
                      className={classes.tF}
                      variant='outlined'
                      margin='normal'
                      required
                      autoComplete='email'
                      fullWidth
                      label='이메일을 입력하세요.'
                      name='email'
                      value={values.email}
                      onChange={handleChangeForm}
                    />
                    <div
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        margin: '-5px 0 10px 15px',
                      }}
                    >
                      {error.emailError}
                    </div>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <div
                        style={{
                          paddingTop: '10px',
                          borderBottom: '1px solid #e3e3e3',
                          margin: '10px 0',
                        }}
                      ></div>
                    </div>
                    <button
                      type='submit'
                      size='large'
                      color='primary'
                      className={classes.button}
                      style={{
                        margin: 'auto',
                        marginTop: '20px',
                        padding: '10px 30px',
                        borderRadius: '50px',
                      }}
                    >
                      아이디 찾기
                    </button>
                  </form>
                </Grid>
              </Grid>
            ) : (
              <div>
                <div style={{ width: '90%', margin: 'auto', marginTop: '50px' }}>
                  <p style={{ fontSize: '1.5rem' }}>
                    {values.name}님, <span style={{ fontSize: '1.0rem' }}>반갑습니다.</span>
                  </p>
                  <p style={{ fontSize: '1.2rem' }}>
                    회원님의 아이디는 [ <span style={{ fontSize: '2.0rem' }}>{checkedUserId}</span> ] 입니다.
                  </p>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div
                    style={{
                      paddingTop: '10px',
                      borderBottom: '1px solid #e3e3e3',
                      margin: '25px 0',
                    }}
                  ></div>
                  <Button
                    size='large'
                    variant='contained'
                    color='primary'
                    data-toggle='modal'
                    data-target='#modal-signup'
                    className={classes.button}
                    style={{
                      margin: 'auto',
                      marginTop: '20px',
                      padding: '10px 30px',
                      borderRadius: '50px',
                    }}
                  >
                    로그인
                  </Button>
                </div>
              </div>
            )}
          </TabPanel>
          
          {/* 비밀번호 재설정 부분 시작*/}
          <TabPanel value={value} index='two'>
            {checkedPass === 'no' ? (
              <Grid container spacing={2}>
                <form className={classes.form} onSubmit={handleSubmitPassword}>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      required
                      autoComplete='userId'
                      autoFocus
                      label='아이디를 입력하세요.'
                      name='userId'
                      fullWidth
                      value={valuesPass.userId}
                      onChange={handleChangeFormPass}
                    />
                    <div style={{ color: 'red', fontSize: '12px', margin: '-5px 0 10px 15px' }}>
                      {error.userIdError}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.tF}
                      variant='outlined'
                      margin='normal'
                      required
                      autoComplete='name'
                      fullWidth
                      label='이름을 입력하세요.'
                      name='name'
                      value={valuesPass.name}
                      onChange={handleChangeFormPass}
                    />
                    <div
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        margin: '-5px 0 10px 15px',
                      }}
                    >
                      {error.nameError}
                    </div>
                  </Grid>
                  {/* 이메일 인증 로직 추가 시작*/}
                  <Grid item xs={7}>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      required
                      autoComplete='email'
                      fullWidth
                      label='이메일을 입력하세요.'
                      name='email'
                      value={valuesPass.email}
                      onChange={handleChangeFormPass}
                    />
                    <div
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        margin: '-5px 0 10px 15px',
                        fontFamily: 'Noto Sans KR',
                      }}
                    >
                      {error.emailError}
                    </div>
                  </Grid>
                  {/* 스페이싱 대체 */}
                  <Grid item xs={1}>
                    <div></div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className={styles.btn_findup}>
                      <Button roundSmall click={() => handleConfirmEmail()}>
                        이메일 인증
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      required
                      autoComplete='code'
                      fullWidth
                      label='인증번호를 입력하세요.'
                      name='confirmCode'
                      value={valuesPass.confirmCode}
                      onChange={handleChangeFormPass}
                    />
                    <div
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        margin: '-5px 0 10px 15px',
                        fontFamily: 'Noto Sans KR',
                      }}
                    >
                      {error.emailCodeError}
                    </div>
                  </Grid>
                  {/* 스페이싱 대체 */}
                  <Grid item xs={1}>
                    <div></div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className={styles.btn_findup}>
                      <Button roundSmall click={() => handleConfirmCode()}>
                        인증번호 확인
                      </Button>
                    </div>
                  </Grid>
                  {/* 이메일 인증 로직 추가 끝*/}
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <div
                      style={{
                        paddingTop: '10px',
                        borderBottom: '1px solid #e3e3e3',
                        margin: '10px 0',
                      }}
                    ></div>
                  </div>

                  <button
                    type='submit'
                    size='large'
                    color='primary'
                    className={classes.button}
                    style={{
                      margin: 'auto',
                      marginTop: '20px',
                      padding: '10px 30px',
                      borderRadius: '50px',
                      fontFamily: 'Noto Sans KR',
                    }}
                  >
                    비밀번호 재설정
                  </button>
                </form>
                {/* 비밀번호 재설정 부분 끝*/}
              </Grid>
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <form className={classes.form} onSubmit={handleSubmitPasswordEdit}>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      required
                      autoFocus
                      autoComplete=''
                      fullWidth
                      helperText='영문과 숫자, 특수문자(@$!%*#?&)를 조합하여 8-15자 사이로 입력해 주세요.'
                      label='신규 비밀번호를 입력하세요.'
                      name='password'
                      type='password'
                      value={valuesPassConfirm.password}
                      onChange={handleChangeFormPassConfirm}
                    />
                    <div
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        margin: '-5px 0 10px 15px',
                        fontFamily: 'Noto Sans KR',
                      }}
                    >
                      {error.pwdError}
                    </div>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      required
                      autoComplete=''
                      fullWidth
                      label='비밀번호 다시한번 입력하세요.'
                      name='confirmPassword'
                      type='password'
                      value={valuesPassConfirm.confirmPassword}
                      onChange={handleChangeFormPassConfirm}
                    />
                    <div
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        margin: '-5px 0 10px 15px',
                        fontFamily: 'Noto Sans KR',
                      }}
                    >
                      {error.confirmPwd}
                    </div>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <div
                        style={{
                          paddingTop: '10px',
                          borderBottom: '1px solid #e3e3e3',
                          margin: '10px 0',
                        }}
                      ></div>
                    </div>
                    <button
                      type='submit'
                      size='large'
                      color='primary'
                      className={classes.button}
                      style={{
                        margin: 'auto',
                        marginTop: '20px',
                        padding: '10px 30px',
                        borderRadius: '50px',
                      }}
                    >
                      비밀번호 재설정
                    </button>
                  </form>
                </Grid>
              </Grid>
            )}
          </TabPanel>
        </div>
      </div>
    </Container>
  );
}

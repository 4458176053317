import React, { Fragment } from 'react';

import { Header } from '../../widgets';
import { useRedirectWithoutAuth } from '../../../hooks/redirect.hook';

const Contact = () => {
  useRedirectWithoutAuth();

  return (
    <Fragment>
      <Header>문의하기</Header>
    </Fragment>
  );
};

export default Contact;

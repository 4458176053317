import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Button } from '@mui/material';

import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import { fetchElapsedTimeInfoStat } from 'api/common';

const MyElapsedTimeInfoStat = () => {
  const [elapsedTimeDatas, setElapsedTimeDatas] = useState([]);
  const [elapsedTimeDatasByLanguage, setElapsedTimeDatasByLanguage] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [language, setLanguage] = useState({});

  useRedirectWithoutAuth();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchElapsedTimeInfoStat();
        setElapsedTimeDatas(response.data);

        const languageList = response.data.reduce(function (res, cur) {
          if (res.findIndex(({ languageId }) => languageId === cur.languageId) === -1) {
            res.push({ languageId: cur.languageId, languageType: cur.languageType });
          }
          return res;
        }, []);
        setLanguageList(languageList);

        const langId = languageList[1].languageId;
        const regeneratedSet = response.data
          .filter((o) => o.languageId === langId)
          ?.map((o) => {
            return {
              ...o,
              elapsedTimeFirstAvg: o.elapsedTimeFirstAvg / 1000,
              elapsedTimeSecondAvg: o.elapsedTimeSecondAvg / 1000,
              elapsedTimeFirst: o.elapsedTimeFirst / 1000,
              elapsedTimeSecond: o.elapsedTimeSecond / 1000,
            };
          });
        setLanguage(response.data.find((o) => o.languageId === langId));
        setElapsedTimeDatasByLanguage(regeneratedSet);
      } catch (error) {}
    })();
  }, []);

  const handlerSelectLanguage = (langId) => {
    const regeneratedSet = elapsedTimeDatas
      .filter((o) => o.languageId === langId)
      ?.map((o) => {
        return {
          ...o,
          elapsedTimeFirstAvg: o.elapsedTimeFirstAvg / 1000,
          elapsedTimeSecondAvg: o.elapsedTimeSecondAvg / 1000,
          elapsedTimeFirst: o.elapsedTimeFirst / 1000,
          elapsedTimeSecond: o.elapsedTimeSecond / 1000,
        };
      });
    setLanguage(elapsedTimeDatas.find((o) => o.languageId === langId));
    setElapsedTimeDatasByLanguage(regeneratedSet);
  };

  // x축 라벨 기울여서 출력하기
  const customAxisTick = ({ x, y, value, payload }) => {
    if (payload.value === 'auto') value = 1;
    else value = payload.value;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={13} textAnchor='start' fill='#666' transform='rotate(45)'>
          {value}
        </text>
        ;
      </g>
    );
  };

  const CustomTooltipFirst = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const p = payload[0].payload;
      return (
        <div
          style={{
            background: '#eee',
            border: '1px solid #ccc',
            borderRadius: '5px',
            padding: '10px',
            color: '#000',
          }}
        >
          <p>보안약점 유형: {p.scategoryName}</p>
          <p>보안약점 명: {p.secName}</p>
          <p>
            {p.scodeId}번의 STEP1 문제를 해결하는데 다른 사용자는 평균 {p.elapsedTimeFirstAvg}초가 걸렸고, 나는{' '}
            {p.elapsedTimeFirst}초가 걸렸습니다.
          </p>
        </div>
      );
    }
    return null;
  };

  const CustomTooltipSecond = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const p = payload[1].payload;
      return (
        <div
          style={{
            background: '#eee',
            border: '1px solid #ccc',
            borderRadius: '5px',
            padding: '10px',
            color: '#000',
          }}
        >
          <p>보안약점 유형: {p.scategoryName}</p>
          <p>보안약점 명: {p.secName}</p>
          <p>
            {p.scodeId}번의 STEP2 문제를 해결하는데 다른 사용자는 평균 {p.elapsedTimeSecondAvg}초가, 나는{' '}
            {p.elapsedTimeSecond}초가 걸렸습니다.
          </p>
        </div>
      );
    }
    return null;
  };

  const legendElapsedTime = [
    { color: '#0044cc', type: 'rect', value: '다른 사용자의 문제해결 평균시간' },
    { color: '#c70003', type: 'circle', value: '나의 문제해결 시간' },
  ];

  return (
    <Fragment>
      <div style={{ textAlign: 'center', marginTop: '20px', fontSize: '1.5rem' }}>
        <p className='secosub1 secoblack'>보안약점별 문제해결에 걸린 시간</p>
        <div className='secosub2' style={{ color: '#dc3545', marginBottom: '10px' }}>
          {language.languageType && language.languageType.toUpperCase()} 개발언어의 보안약점별 문제해결에 걸린 시간을
          다른 사용자와 비교해 보세요.
        </div>
      </div>

      <div style={{ textAlign: 'right' }}>
        {languageList?.map((item, index) => {
          return (
            <Button
              key={index}
              variant='contained'
              color='primary'
              onClick={() => handlerSelectLanguage(item.languageId)}
              style={{ marginRight: '10px' }}
            >
              <span className='secotext'>{item.languageType}</span>
            </Button>
          );
        })}
      </div>

      {language !== undefined && language.languageType !== undefined && (
        <div style={{ marginBottom: '80px', flex: '1', width: '0' }}>
          <div style={{ textAlign: 'left', fontSize: '1.5rem' }}>STEP1</div>
          <ResponsiveContainer width={960} height={300}>
            <ComposedChart
              data={elapsedTimeDatasByLanguage}
              margin={{ top: 20, right: 20, left: 0, bottom: 0 }}
              syncId='elapsedTime'
            >
              <CartesianGrid stroke='#f5f5f5' />
              <XAxis dataKey='secId' scale='auto' tick={customAxisTick} />
              <YAxis />
              <Tooltip content={<CustomTooltipFirst />} />
              <Bar dataKey='elapsedTimeFirstAvg' barSize={20} fill='#0073e6' background={{ fill: '#eee' }} />
              <Line dataKey='elapsedTimeFirst' type='monotone' stroke='#e60000' />
            </ComposedChart>
          </ResponsiveContainer>

          <div style={{ textAlign: 'left', fontSize: '1.5rem' }}>STEP2</div>
          <ResponsiveContainer width={960} height={300}>
            <ComposedChart
              data={elapsedTimeDatasByLanguage}
              margin={{ top: 20, right: 20, left: 0, bottom: 0 }}
              syncId='elapsedTime'
            >
              <CartesianGrid stroke='#f5f5f5' />
              <XAxis dataKey='secId' scale='auto' tick={customAxisTick} />
              <YAxis />
              <Tooltip content={<CustomTooltipSecond />} />
              <Legend verticalAlign='bottom' height={36} payload={legendElapsedTime} />
              <Bar dataKey='elapsedTimeSecondAvg' barSize={20} fill='#0044cc' background={{ fill: '#eee' }} />
              <Line dataKey='elapsedTimeSecond' type='monotone' stroke='#c70003' />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
      {language.languageType === undefined && (
        <div
          style={{
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '10px 10px 80px 50px',
          }}
        ></div>
      )}
    </Fragment>
  );
};

export default withRouter(MyElapsedTimeInfoStat);

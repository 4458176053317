import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/react';

import Mock from './state/mock';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import './index.scss';

import './assets/css/bootstrap.min.css';
import './assets/css/animate.css';
import './assets/fs/css/all.css';
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';
import ScrollToTop from './utilities/scrollToTop';
import App from './App';

Mock.onAny().passThrough();

import './state/database';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing({ tracingOrigins: ['*'] })],
  tracesSampleRate: 1.0,
  enabled: process.env.REACT_APP_MODE_PROD === 'prod' || process.env.REACT_APP_MODE === 'dev',
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </QueryClientProvider>
  </RecoilRoot>
);

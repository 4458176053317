import React from 'react';
import styled from 'styled-components';
import moment from 'moment/moment';
import { useLocation } from 'react-router';

export const PersonalResultWrapper = ({ title = '제목을 입력해주세요.', children }) => {
  return (
    <Wrapper>
      <h3>{title}</h3>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 10px 0 15px;
`;

/* ============================== 구분선 ============================== */

export const PersonalResultHeader = ({
  title = '페이지 제목을 입력해주세요.',
  userName = '-',
  display = 'block',
  groupName = '',
  timeStamp = new Date(),
  ...rest
}) => {
  const { pathname } = useLocation();
  const userId = pathname.split('/').at(-1) || '-';

  const formattedDate = moment(timeStamp).format('YYYY년 MM월 DD일');

  return (
    <HeaderWrapper display={display} id='ReportDetail' {...rest}>
      <Header>
        <img src='/logo-secolab.png' alt='SECOLAB' style={{ width: '150px' }} />

        <h3>{title}</h3>
      </Header>

      <ReportInfo>
        <div className='info-wrapper'>
          <div>
            <span className='title'>이름</span>
            <span>{userName}</span>
            <span className='title'>교육명</span>
            <span>{groupName}</span>
          </div>

          <div className='bottom'>
            <span className='title'>ID</span>
            <span>{userId}</span>
            <span className='title'>출력일</span>
            <span>{formattedDate}</span>
          </div>
        </div>
      </ReportInfo>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  display: ${(p) => p.display};
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: content-box;

  > img {
    justify-self: flex-end;
    margin-left: auto;
    transform: scale(0.8);
  }

  > h3 {
    font-size: 2.5rem;
    margin: 0;
    padding-top: 0px;
  }
`;

const ReportInfo = styled.div`
  width: 100%;
  padding: 20px 0 10px;

  > div.info-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div {
      box-sizing: border-box;
      width: 100%;
      display: flex;

      &.bottom {
        margin-top: -1px;
      }

      > span {
        display: inline-block;
        font-size: 1.5rem;
        padding: 5px 10px;
        flex: 3;
        max-width: 355px;
        text-align: center;
        border: 1px solid #333;
        box-sizing: border-box;
        margin-right: -1px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &.title {
          background-color: #eee;
          flex: 1;
          font-weight: bold;
        }
      }
    }
  }
`;

/* ============================== 구분선 ============================== */

export const PersonalResultFooter = ({ page = '' }) => {
  return (
    <Footer id='ReportFooter'>
      <p>이 보고서는 ㈜오픈이지의 SECOLAB 역량평가 시스템을 통해 출력되었습니다.</p>

      <div>
        <Logo>
          <img src='/assets/images/logo_openeg.png' alt='' />
        </Logo>

        <FooterContent>
          <p>안전한 소프트웨어를 만들기 위한 노력 주식회사오픈이지.</p>
          <p>서울시 중구 퇴계로 36길 2 동국대충무로영상센터 7층 http://openeg.co.kr</p>
        </FooterContent>

        <Page>페이지 {page}/2</Page>
      </div>
    </Footer>
  );
};

const Footer = styled.div`
  margin-top: 40px;
  display: none;

  > p {
    text-align: center;
    margin-bottom: 10px;
    color: #333;
  }

  > div {
    display: flex;
    margin-top: 20px;
  }
`;

const Logo = styled.div`
  width: 100%;
  flex: 0.5 !important;

  > img {
    width: 110px;
  }
`;

const FooterContent = styled.div`
  flex: 3 !important;
`;

const Page = styled.div`
  text-align: end;
  margin-top: auto;
  font-size: 1.8rem;
`;

/* ============================== 구분선 ============================== */

export const PersonalResultComment = () => {
  return (
    <PersonalResultWrapper title='역량평가 총평'>
      <CommentBox />
    </PersonalResultWrapper>
  );
};

const CommentBox = styled.div`
  width: 100%;
  height: 80px;
  border: 1px solid #000;
`;

import React from 'react';
import { useRecoilValue } from 'recoil';

import { useRedirectWithoutAuth } from 'hooks/redirect.hook.js';
import MypageIndex from './MypageIndex.js';
import MypageSub from './MypageSub.js';
import MypageOper from './MypageOper.js';
import { AuthAtom } from 'state';

const MyPage = () => {
  const { auth, type } = useRecoilValue(AuthAtom);

  useRedirectWithoutAuth();

  return (
    <>
      {auth && type === 1 && <MypageIndex />}
      {auth && type === 2 && <MypageSub />}
      {auth && type === 4 && <MypageOper />}
    </>
  );
};

export default MyPage;

import React from 'react';
import styled from 'styled-components';

const ReportFooter = ({ page = '' }) => {
  return (
    <Footer id='ReportFooter'>
      <p>이 보고서는 ㈜오픈이지의 SECOLAB 역량평가 시스템을 통해 출력되었습니다.</p>

      <div>
        <Logo>
          <img src='/assets/images/logo_openeg.png' alt='' />
        </Logo>

        <FooterContent>
          <p>안전한 소프트웨어를 만들기 위한 노력 주식회사오픈이지.</p>
          <p>서울시 중구 퇴계로 36길 2 동국대충무로영상센터 7층 http://openeg.co.kr</p>
        </FooterContent>

        <Page>페이지 {page}/2</Page>
      </div>
    </Footer>
  );
};

const Footer = styled.div`
  margin-top: 40px;
  display: none;

  > p {
    text-align: center;
    margin-bottom: 10px;
    color: #333;
  }

  > div {
    display: flex;
    margin-top: 20px;
  }
`;

const Logo = styled.div`
  width: 100%;
  flex: 0.5 !important;

  > img {
    width: 110px;
  }
`;

const FooterContent = styled.div`
  flex: 3 !important;
`;

const Page = styled.div`
  text-align: end;
  margin-top: auto;
  font-size: 1.8rem;
`;

export default ReportFooter;

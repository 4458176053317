import React, { useRef } from 'react';
import { withRouter } from 'react-router';
import { useRecoilValue } from 'recoil';
import Slider from 'react-slick';

import Layout from '../../layouts/Layout/Layout';
import { Button } from '../../elements';
import { MESSAGE } from 'utilities';
import { AuthAtom } from 'state';
import { errorToast, warningToast } from 'utilities/toast';
import styles from './Slider.module.scss';

export default withRouter(({ data = [], history }) => {
  const auth = useRecoilValue(AuthAtom);
  const { type, status } = auth;

  const handleSecurePage = (id) => {
    if (id === 'submittersignup') {
      history.push(`/submittersignup`);
    } else {
      if (auth.auth) {
        if (type !== 1) {
          errorToast(MESSAGE.SERVICE_STUDENT_ONLY);
        } else if (type === 1 && status !== 1 && id === 'capability') {
          history.push(`/student/${id}`);
        } else if (type === 1 && status !== 1) {
          errorToast(MESSAGE.STATUE_NOT_CONFIRMED);
        } else if (type === 1 && status === 1) {
          history.push(`/student/${id}`);
        }
      } else {
        warningToast(MESSAGE.SERVICE_AUTHENTICATED_ONLY);
      }
    }
  };

  let sliderRef = useRef(null);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slideList = data?.map((item, index) => (
    <article key={index}>
      <Layout
        style={{
          backgroundColor: '#f0f5f9 !important',
        }}
        col='1'
        mode='light'
      >
        <div className={styles.slide}>
          <div className={styles.iLeft}>
            <i className={'las la-angle-left'} onClick={() => sliderRef.slickPrev()} />
          </div>

          <div className={styles.intro}>
            <h3>{item.subtitle}</h3>
            <h1>{item.title}</h1>
            <img src={item.subImg} width='80%' alt='subImg' />
            <Button click={() => handleSecurePage(item.id)} type='outline-color-tw' hoverType='solid-color-tw'>
              <span className='secosub2'>바로가기</span>
            </Button>
            <p className='secotext'>{item.short}</p>
          </div>
          <div className={styles.slideRight}>
            <img src={item.mainImg} alt='mainImg' />
          </div>

          <div className={styles.iRight}>
            <i className={'las la-angle-right'} onClick={() => sliderRef.slickNext()} />
          </div>
        </div>
      </Layout>
    </article>
  ));

  return (
    <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
      {slideList}
    </Slider>
  );
});

import React from 'react';
import moment from 'moment/moment';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';

import { AuthAtom } from 'state';
import { LANGUAGE_ID } from 'constants/common';

const ExamInfoTable = ({ examInfo }) => {
  const location = useLocation();
  const auth = useRecoilValue(AuthAtom);
  const { userId, name } = auth;
  const languageId = location.state.languageId ?? location.pathname.split('/').at(-1);
  
  return (
    <Container>
      <StyledTable>
        <tbody>
          <tr>
            <td>교육명</td>
            <td>{examInfo?.groupCourse}</td>
            <td>차수</td>
            <td>{examInfo?.groupCourseCount}차</td>
          </tr>
          <tr>
            <td>평가 언어</td>
            <td>{LANGUAGE_ID[languageId]}</td>
            <td>과정 담당자</td>
            <td>{`${name} (${userId})`}</td>
          </tr>
          <tr>
            <td>교육 시작일</td>
            <td>{moment(examInfo?.groupStartDate).format('YYYY-MM-DD')}</td>
            <td>교육 종료일</td>
            <td>{moment(examInfo?.groupDueDate).format('YYYY-MM-DD')}</td>
          </tr>
        </tbody>
      </StyledTable>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 0 70px;
  font-size: 1.5rem;
  color: #fff;
  font-weight: bold;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 0 1px #7fadc9;

  tr {
    display: flex;
    border-bottom: 1px solid #7fadc9;

    &:last-child {
      border-bottom: none;
    }

    > td {
      padding: 10px 30px;

      &:nth-child(2n - 1) {
        flex: 0.7;
        background-color: #2e94d2;
      }

      &:nth-child(2n) {
        flex: 1;
        background-color: #fff;
        color: #333;
      }
    }
  }
`;

export default ExamInfoTable;

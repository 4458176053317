import { useQuery } from '@tanstack/react-query';

import { fetchStudentCountByMonth } from '../api/trainer';

export const useFetchStudentCountByMonth = ({ period }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchStudentCountByMonth', { period }],
    initialData: [],
    queryFn: () => fetchStudentCountByMonth({ period }),
    select: (res) => res && res.data,
    retry: 2,
    retryDelay: 1000,
  });
  return { ...queryReturns };
};

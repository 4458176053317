import React, { Fragment } from 'react';

import Layout from '../../layouts/Layout/Layout';
import THEME from '../../../state/theme';
import Button from '../../elements/Button/Button';
import { useCustomState } from 'state/state';
import styles from './Facts.module.scss';

const Facts = ({ data = [] }) => {
  const state = useCustomState()[0];
  
  const factsList = data?.map((item, index) => (
    <Fragment key={index}>
      <div>
        <div className={styles.card}>
          <p className='secosub2 secoblue'>{item.postTitle}</p>
          <h4 className='secosub2 secoblack'>{item.postContent}</h4>
          <i style={{ color: THEME.color }} className='las la-comment' />
          <p className='secotext'>{item.replyContent}</p>
        </div>
      </div>
    </Fragment>
  ));

  return (
    <Layout
      style={{
        background: 'url(' + state.data.header_bgs.facts + ') center/cover',
      }}
      mode='light'
      col='3'
      padding
      blur
    >
      <div className={'layout-header'}>
        <h2 className='secosub1 secowhite'>자주 묻는 질문</h2>
      </div>

      {factsList}

      <div className={`layout-footer ${styles.footer}`}>
        <Button type='outline-white-tw' hoverType='solid-color-tw' to='/customer/faq'>
          <span className='secotext'>더보기</span>
        </Button>
      </div>
    </Layout>
  );
};

export default Facts;

import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';

import { AuthAtom } from 'state';
import { useLocation } from 'react-router';

const OperatorGroupInfo = ({ groupInfo }) => {
  const { state } = useLocation();
  const auth = useRecoilValue(AuthAtom);
  const { userId, name } = auth;

  return (
    <Container>
      <StyledTable>
        <tbody>
          <tr>
            <td>교육명</td>
            <td>{groupInfo.groupCourse ?? state.groupName}</td>
            <td>차수</td>
            <td>{groupInfo.groupCourseCount ?? state.examOrder}차</td>
          </tr>
          <tr>
            <td>교육 운영자</td>
            <td>{`${userId} (${name})`}</td>
            <td>시험유무</td>
            <td>{groupInfo.groupExamYn === 'Y' ? '유' : '무'}</td>
          </tr>
          <tr>
            <td>시작일</td>
            <td>{groupInfo.groupStartDate ? moment(groupInfo.groupStartDate).format('YYYY-MM-DD') : '-'}</td>
            <td>종료일</td>
            <td>{groupInfo.groupStartDate ? moment(groupInfo.groupDueDate).format('YYYY-MM-DD') : '-'}</td>
          </tr>
        </tbody>
      </StyledTable>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 0 70px;
  font-size: 1.5rem;
  color: #fff;
  font-weight: bold;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 0 1px #7fadc9;

  tr {
    display: flex;
    border-bottom: 1px solid #7fadc9;

    &:last-child {
      border-bottom: none;
    }

    > td {
      padding: 10px 30px;

      &:nth-child(2n - 1) {
        flex: 0.7;
        background-color: #2e94d2;
      }

      &:nth-child(2n) {
        flex: 1;
        background-color: #fff;
        color: #333;
      }
    }
  }
`;

export default OperatorGroupInfo;

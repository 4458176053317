/* eslint-disable */

import React, { useEffect, useRef, Fragment, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';

import { useRedirectWithoutAuth } from '../hooks/redirect.hook';
import Layout from '../components/layouts/Layout/Layout';
import { AuthAtom } from '../state';
import { fetchMidibusCategories } from '../api/midibus';
import { getItem } from '../commons/localStorage';
import CourseDetailTop from '../components/widgets/Project/CourseDetailTop';
import CourseDetailContent from '../components/widgets/Project/CourseDetailContent';
import DetailAsideChapter from '../components/widgets/Project/DetailAsideChapter';
import { useFetchCourseIntro, useFetchCourseList, useFetchInstructorData } from '../hooks/student.hook';
import './Videomain.css';

const CourseDetail = ({ history }) => {
  const auth = useRecoilValue(AuthAtom);
  const ref = useRef(null);
  const { token } = auth;
  const midibusToken = getItem('midibusToken');

  useRedirectWithoutAuth();

  const { courseId, instId } = useParams();

  const [courseTotal, setCourseTotal] = useState([]);
  const [introData, setIntroData] = useState([]);
  const [instData, setInstData] = useState([]);
  const [currentCourseId, setCurrentCourseId] = useState();
  const [midibusCategory, setMidibusCategory] = useState([]);

  const courseCurrent = courseTotal?.find((item) => item.courseId.toString() === courseId);

  const { data: courseListData } = useFetchCourseList({ token });
  const { data: courseIntroData } = useFetchCourseIntro({ courseId });
  const { data: instructorData } = useFetchInstructorData({ instructorId: instId });

  const getCourseData = () => {
    if (!courseIntroData || !instructorData) return;

    setCourseTotal(courseListData?.data.courses);
    setCurrentCourseId(courseListData?.data.myCourseId);
    setIntroData(courseIntroData);
    setInstData(instructorData);
  };

  useEffect(() => {
    if (!(midibusToken || authAtom.midibusToken)) return;

    (async () => {
      try {
        const mediaCategoryResponse = await fetchMidibusCategories(midibusToken);
        setMidibusCategory(mediaCategoryResponse.data.category_list);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [auth, midibusToken]);

  useEffect(() => {
    getCourseData();
  }, [courseListData, courseIntroData, instructorData]);

  return (
    <Fragment>
      <div ref={ref}>
        <Layout
          style={{
            backgroundColor: '#fff',
          }}
          col='1'
        >
          <CourseDetailTop instData={instData} courseCurrent={courseCurrent} />

          <div className='viSection'>
            <DetailAsideChapter courseTotal={courseTotal} isCourseDetailAside={true} />

            <CourseDetailContent
              courseTotal={courseTotal}
              courseCurrent={courseCurrent}
              instData={instData}
              introData={introData}
              currentCourseId={currentCourseId}
              midibusCategory={midibusCategory}
            />
          </div>
        </Layout>
      </div>
    </Fragment>
  );
};

export default CourseDetail;

import React from 'react';

import styles from './Features.module.scss';
import Layout from '../../layouts/Layout/Layout';

const Features = ({ data = [] }) => {
  const priceList = data
    ?.map((item, index) => (
      <div className={styles.features} key={index}>
        <img src={item.icon} alt='features_icon' />
        <h4 className='secosub1'>{item.title}</h4>
        <p>{item.text}</p>
      </div>
    ))
    .slice(0, 3);

  return (
    <Layout
      style={{
        paddingTop: '5rem',
        paddingBottom: '1rem',
        background: 'rgb(255,255,255)',
      }}
      col='2'
      padding
    >
      <div className='layout-header'>
        <p className='secotext secoblue'>개인 학습자, 교육생 별로 가격 정책을 제공합니다.</p>
      </div>
      {priceList}
    </Layout>
  );
};

export default Features;

import React from 'react';
import { withRouter } from 'react-router';
import { useRecoilValue } from 'recoil';

import Layout from '../../layouts/Layout/Layout';
import { Button } from '../../elements';
import THEME from '../../../state/theme';
import { AuthAtom } from '../../../state';
import styles from './Features2.module.scss';

const Features2 = ({ data = [], history }) => {
  const auth = useRecoilValue(AuthAtom);

  const handleLink = () => {
    history.push('/signup');
  };

  const servicesList = data
    ?.map((item, index) => (
      <article key={index} className={styles.wrapper}>
        <div className={[styles.card, index === 1 && styles.main].join(' ')} style={{ borderColor: THEME.color }}>
          <i className={item.icon} />
          <h3 style={{ color: THEME.color }}>{item.title}</h3>
          <p>{item.text}</p>
          {!auth.auth && index === 1 && (
            <div className={styles.btn}>
              <Button
                before='&#xf067;'
                round
                type='outline-color-tb'
                hoverType='solid-color-tb'
                click={() => handleLink()}
              >
                지금바로 회원가입
              </Button>
            </div>
          )}
        </div>
      </article>
    ))
    .slice(0, 3);

  return (
    <Layout
      col='3'
      padding
      style={{
        background: 'white',
      }}
    >
      <div className={`layout-header ${styles.header}`}>
        <span className='subtitle' style={{ color: THEME.color }}>
          FEATURES
        </span>
        <h2>웹통합개발환경을 활용한 시큐어코딩 훈련시스템</h2>
        <p style={{ maxWidth: '480px', marginTop: '10px' }}>
          모든 소프트웨어 개발자들이 프로젝트 생성단계에서 부터 소스코드 보안 약점을 식별하고 제거한 시큐어한
          소프트웨어를 개발 할 수 있도록 훈련하는 시스템 입니다.
        </p>
      </div>
      {servicesList}
    </Layout>
  );
};

export default withRouter(Features2);

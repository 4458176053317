import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { Button, Card, CardContent, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ProjectContainer from '../../components/ProjectContainer';
import { api } from 'api/config';
import Loading from '../../../../components/Loading';
import CreateProject from './CreateProject';
import { AuthAtom } from 'state';
import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import { errorToast } from 'utilities/toast';
import '../../../../Training.css';
import './style.scss';

const SecureList = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

SecureList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#eee',
    display: 'flex',
    height: '80vh',
  },
  tabs: {
    borderRight: `1px solid #eee`,
  },
  gridList: {
    // paddingLeft: '4%',
    // paddingBottom: '10%',
    // width: '80%',
    margin: '5px',
    height: `${window.innerHeight}`,
  },
  createProject: {
    width: 280,
    height: 280,
    textAlign: 'center',
    // paddingBottom: "0 !important",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '40px',
    color: '#2f96eb',
    '&:hover': {
      backgroundColor: '#f0f5f9',
    },
  },
}));

export default function ListProjects({ history }) {
  const classes = useStyles();
  const auth = useRecoilValue(AuthAtom);
  const { userId } = auth;

  const [projects, setProjects] = useState([]);
  const [container, setContainer] = useState({});
  const [modalCreateProject, setModalCreateProject] = useState(false);

  const [loading, setLoading] = useState(true);
  const [isSleepLoading, setIsSleepLoading] = useState(false);

  useRedirectWithoutAuth();

  useEffect(() => {
    setTimeout(() => {
      api
        .get(`/users/projects`)
        .then((response) => {
          const { data } = response;

          setProjects(data);
          if (data.length !== 0) {
            // 컨테이너 정보를 가져오기 있을 때 가져오기
            api
              .get(`/users/containers`)
              .then((response) => {
                const { data } = response;

                if (!data) {
                  errorToast('컨테이너 생성 과정에 오류 발생하니 나중에 다시 시도해주세요.');
                } else {
                  setContainer(data);
                }
              })
              .catch((error) => {});
          } else {
            api
              .post(`/users/containers`, { projects })
              .then((response) => {
                const { data } = response;

                if (!data) {
                  errorToast('컨테이너 생성 과정에 오류 발생하니 나중에 다시 시도해주세요.');
                } else {
                  setContainer(data);
                }
              })
              .catch((error) => {});
          }
          setLoading(!loading);
        })
        .catch((error) => {});
    }, 300);
  }, []);

  // ?
  const closeModal = () => {
    setModalCreateProject(!modalCreateProject);

    api
      .get(`/users/projects`)
      .then((response) => {
        const { data } = response;
        // setProjects(data)
        setLoading(false);
      })
      .catch((error) => {});

    if (projects.length !== 0) {
      // 컨테이너 정보를 가져오기 있을 때 실행시켜줌

      api
        .get(`/users/containers`)
        .then((response) => {
          const { data } = response;
          // if(!data){
          //     alert("컨테이너 생성 과정에 오류 발생하니 나중에 다시 시도해주세요.")
          // }else{
          //     setContainer(data);
          // }
          setContainer(data);
        })
        .catch((error) => {});
    }
  };

  // 프로젝트 삭제
  const handleDeleteProject = (projectId) => {
    setLoading(true);
    const filterProject = projects.filter((element) => element.projectId !== projectId);
    setProjects(filterProject);
    setLoading(false);
  };

  // 웹 서비스 실행
  const handleMoveWebService = () => {
    api
      .get(`/users/webservice`)
      .then((response) => {
        const { data } = response;
        if (data !== 'fail') {
          window.open(`${process.env.REACT_APP_HTTP_IP}:${data}`, '_blank');
        } else {
          errorToast('페이지 접근 오류 발생했습니다. 서버가 정상적으로 작동되었는지 확인하세요. ');
        }
      })
      .catch((error) => {});
  };

  // 컨테이너 중지
  const handleSleepContainer = () => {
    if (isSleepLoading) return;

    setIsSleepLoading(true);
    api
      .post(`/users/stopcontainers`, {})
      .then((response) => {
        const { data } = response;
        if (data === 'success') {
          setContainer({ ...container, state: !container.state });
        }
        setIsSleepLoading(false);
      })
      .catch((error) => {
        setIsSleepLoading(false);
      });
  };

  // // 컨테이너 상태?
  // const handleContainerState = (container) =>{
  //     setContainer(container)
  // }

  // 컨테이너 정보 업데이트?
  const handleUpdateContainer = (projectId) => {
    api
      .get(`/users/containers`)
      .then((response) => {
        const { data } = response;

        if (!data) {
          errorToast('컨테이너 생성이 과정이 발생하니 나중에 다시 시도해주세요');
        } else setContainer(data);
      })
      .catch((error) => {});

    if (projects.length !== 0) {
      // 컨테이너 정보를 가져오기 있을 때 실행시켜줌

      api
        .get(`/users/containers`)
        .then((response) => {
          const { data } = response;
          // if(!data){
          //     alert("컨테이너 생성 과정에 오류 발생하니 나중에 다시 시도해주세요.")
          // }else{
          //     setContainer(data);
          // }
          setContainer(data);
        })
        .catch((error) => {});
    }
  };

  if (loading) {
    return <Loading type='spinningBubbles' color='#2F96EB' />;
  }

  const filterProject = projects.filter((project) => project.projectId === container.project_id);
  return (
    <div className='list-projects'>
      {projects.length !== 0 && filterProject.length !== 0 && (
        <div className='container-task'>
          <p className='container-info'>
            {container.state ? (
              <>
                &#8226; 현재 실행 중인 컨테이너: {filterProject[0].projectName} (컨테이너 Id:{' '}
                <span>{container.container_id}</span>)
              </>
            ) : (
              <>&#8226; 현재 컨테이너 중지된 상태 입니다.</>
            )}
          </p>
          <div className='list-projects__tasks'>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className='btn'
              onClick={() => handleMoveWebService()}
              disabled={!container.state}
            >
              웹 서비스 이동
            </Button>

            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className='btn'
              // className={classes.submit}
              onClick={() => handleSleepContainer(!modalCreateProject)}
              disabled={!container.state}
            >
              컨테이너 중지
            </Button>
          </div>
        </div>
      )}
      <div className='list-projects__container'>
        <div className='list-projects__container--content'>
          <div className='list-projects__container--createproject'>
            <Card
              className={classes.createProject}
              style={{ cursor: 'pointer', margin: '0 0 0 0px' }}
              onClick={() => setModalCreateProject(!modalCreateProject)}
            >
              <CardContent>
                <i className='fas fa-plus'></i>
                <p style={{ color: '#2f96eb', fontSize: '12px', marginTop: '10px' }}>프로젝트 생성</p>
              </CardContent>
            </Card>
          </div>
          {projects.length !== 0 &&
            projects?.map((project, index) => (
              <ProjectContainer
                key={index}
                container={container}
                // handleContainerState={handleContainerState}
                handleUpdateContainer={handleUpdateContainer}
                project={project}
                openModal={() => this.setModal(true)}
                handleDeleteProject={handleDeleteProject}
              />
            ))}
        </div>
      </div>
      {modalCreateProject && <CreateProject closeModal={closeModal} sampleName='' />}
    </div>
  );
}

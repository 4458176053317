import React from 'react';

import styles from './Layout.module.scss';

const Layout = ({ style, children, mode, col, padding, blur }) => {
  const colClassMap = {
    2: styles.col_2,
    3: styles.col_3,
    4: styles.col_4,
    5: styles.col_5,
  };

  const classNames = {
    [styles.outer]: true,
    [styles.dark]: mode === 'dark',
    [styles.light]: mode === 'light',
    [styles.padding]: padding,
    [styles.blur]: blur,
  };

  const computedClassName = Object.keys(classNames)
    .filter((key) => classNames[key])
    .join(' ');

  return (
    <div className={computedClassName} style={{ ...style }} id='Header'>
      <div className={styles.inner}>
        <div className={`${styles.wrapper} ${colClassMap[col] || ''}`}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;

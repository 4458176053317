import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CapabilityTest from './pages/CapabilityTest';
import TestPage from './pages/TestPage';
import TestResult from './pages/TestResult';
import SelectLanguage from './pages/SelectLanguage';
import Report from './pages/Report';

function CapabilityTestPage() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}/select`} component={SelectLanguage} />
      <Route exact path={`${match.url}/result`} component={TestResult} />
      <Route
        exact
        path={`${match.url}/:languageId/test`}
        render={(props) => {
          const languageId = props.match.params.languageId;

          return <TestPage languageId={languageId} />;
        }}
      />
      <Route
        exact
        path={`${match.path}/:languageId`}
        render={(props) => {
          const languageId = props.match.params.languageId;

          return <CapabilityTest languageId={languageId} />;
        }}
      />
      <Route exact path={`${match.url}/report/:languageId`} component={Report} />
    </Switch>
  );
}

export default CapabilityTestPage;

import { useQuery } from '@tanstack/react-query';

import { fetchUserExamStatus } from 'api/capability';

const useFetchUserExamInfo = ({ userId, languageId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchUserExamStatus', { userId, languageId }],
    queryFn: async () => await fetchUserExamStatus({ userId, languageId }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!(userId || languageId),
    keepPreviousData: true,
  });

  return { ...queryReturns };
};

export default useFetchUserExamInfo;

import React, { Fragment } from 'react';

import { Header, ProcessPrice, Features } from '../../widgets';
import { useCustomState } from 'state/state';

const AboutPrice = () => {
  const state = useCustomState()[0];

  return (
    <Fragment>
      <Header>가격 정보</Header>
      <Features data={state.data.price} />
      <ProcessPrice data={state.data.processPrice} />
    </Fragment>
  );
};

export default AboutPrice;

import React from 'react';

import styles from './Ranking.module.scss';
import THEME from '../../../state/theme';

export default ({ data = [], title, title_eng }) => {
  const servicesList = data
    ?.map((item, index) => (
      <div className='col-md-4' key={index}>
        <div className={styles.card} style={{ borderColor: THEME.color }}>
          <i className='las la-crown'></i>
          <p className='secosub2 secoblue'>No.{index + 1}</p>
          <span className='secotext secogray'>{item.secName}</span>
        </div>
      </div>
    ))
    .slice(0, 3);

  return (
    <div className='row' style={{ marginBottom: '40px' }}>
      <div className={`layout-header ${styles.header}`}>
        <span className='secosub1 secoblack'>{title}</span>
        <span className='secotext secoblue'>{title_eng}</span>
      </div>
      <>{servicesList}</>
    </div>
  );
};

import React from 'react';
import styled from 'styled-components';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

import ReportWrapper from './ReportWrapper';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, BarElement, PointElement, Title, Tooltip, Legend, LinearScale, BarController);

const ExamRiskResult = ({ correctIncorrectData, riskAvg }) => {
  const { firstExamReportData, secondExamReportData } = correctIncorrectData || {};

  const { firstRiskLevelCorrectRates: beforeAvg, secondRiskLevelCorrectRates: afterAvg } = riskAvg || {};

  const calculateAccuracyByRiskLevel = (data) => {
    if (!Array.isArray(data)) return;

    const riskLevels = ['HIGH', 'MEDIUM', 'LOW'];

    const summary = data.reduce((acc, item) => {
      const riskLevel = item.riskLevel;

      if (!acc[riskLevel]) {
        acc[riskLevel] = { total: 0, correct: 0 };
      }

      acc[riskLevel].total++;
      if (item.isCorrect) {
        acc[riskLevel].correct++;
      }

      return acc;
    }, {});

    const accuracy = riskLevels.reduce((acc, riskLevel) => {
      const total = summary[riskLevel]?.total || 0;
      const correct = summary[riskLevel]?.correct || 0;
      acc[riskLevel] = total > 0 ? (correct / total) * 100 : 0;
      return acc;
    }, {});

    return accuracy;
  };

  const beforeResult = calculateAccuracyByRiskLevel(firstExamReportData);
  const afterResult = calculateAccuracyByRiskLevel(secondExamReportData);

  const options = {
    indexAxis: 'y',
    animation: {
      duration: 0,
    },
    elements: {
      bar: {
        borderWidth: 1,
        borderRadius: 2,
      },
    },
    responsive: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        min: 0,
        max: 100,
        ticks: {
          color: '#000',
          font: {
            size: 12,
            weight: 'bold',
          },
        },
      },
      y: {
        ticks: {
          color: '#000',
          font: {
            size: 12,
            weight: 'bold',
          },
        },
      },
    },
  };

  const labels = ['상', '중', '하'];
  const data = {
    labels,
    datasets: [
      {
        barThickness: 10,
        data: Object.values(beforeResult || {}) || [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        barThickness: 10,
        data: Object.values(afterResult || {}) || [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  return (
    <ReportWrapper title='보안 위험도별 역량평가 결과'>
      <Notice>
        <p>
          보안약점 유형별 위험도는 상/중/하로 분류될 수 있으며, 위험도에 따라 해당 교육생의 정답률을 정리하였습니다.
        </p>
      </Notice>

      <Container>
        <TableWrapper>
          <table>
            <thead>
              <tr>
                <th rowSpan='2'>위험도</th>
                <th colSpan='2'>사전</th>
                <th colSpan='2'>사후</th>
              </tr>
              <tr>
                <th>정답률(%)</th>
                <th>평균(%)</th>
                <th>정답률(%)</th>
                <th>평균(%)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>상</td>
                <td>{Math.round(beforeResult?.HIGH) || '-'}</td>
                <td>{Math.round(beforeAvg?.HIGH) || '-'}</td>
                <td>{secondExamReportData?.length > 0 ? Math.round(afterResult?.HIGH) || '0' : '-'}</td>
                <td>{secondExamReportData?.length > 0 ? Math.round(afterAvg?.HIGH) || '0' : '-'}</td>
              </tr>
              <tr>
                <td>중</td>
                <td>{Math.round(beforeResult?.MEDIUM) || '-'}</td>
                <td>{Math.round(beforeAvg?.MEDIUM) || '-'}</td>
                <td>{secondExamReportData?.length > 0 ? Math.round(afterResult?.MEDIUM) || '0' : '-'}</td>
                <td>{secondExamReportData?.length > 0 ? Math.round(afterAvg?.MEDIUM) || '0' : '-'}</td>
              </tr>
              <tr>
                <td>하</td>
                <td>{Math.round(beforeResult?.LOW) || '-'}</td>
                <td>{Math.round(beforeAvg?.LOW) || '-'}</td>
                <td>{secondExamReportData?.length > 0 ? Math.round(afterResult?.LOW) || '0' : '-'}</td>
                <td>{secondExamReportData?.length > 0 ? Math.round(afterAvg?.LOW) || '0' : '-'}</td>
              </tr>
            </tbody>
          </table>
        </TableWrapper>

        <GraphWrapper>
          <Bar options={options} data={data} style={{ position: 'relative', width: '85%', height: '100%' }} />
        </GraphWrapper>
      </Container>
    </ReportWrapper>
  );
};

const Notice = styled.div`
  padding-bottom: 10px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  column-gap: 20px;

  > div {
    flex: 1;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  flex: 1.5 !important;

  table {
    width: 100%;
    height: 100%;
  }

  tr,
  th,
  td {
    padding: 5px 10px;
    border-collapse: collapse;
    border: 1px solid #a8a7a7;
  }

  th {
    background-color: #eee;
  }
`;

const GraphWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: 190px;
`;

export default ExamRiskResult;

import React from 'react';

import { Backdrop, LoginForm } from '..';
import { useCustomState } from '../../../state';
import styles from './ModalForm.module.scss';

const ModalForm = () => {
  const [state, actions] = useCustomState();
  return (
    <div className={`${styles.wrapper} ${state.modal ? styles.show : ''}`}>
      <div className={styles.modal}>
        <i onClick={() => actions.toggleModal()} className='las la-times' />
        <LoginForm />
      </div>
      <Backdrop />
    </div>
  );
};

export default ModalForm;

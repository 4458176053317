import React, { useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { AuthAtom } from '../../../state';
import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import { errorToast, successToast } from 'utilities/toast';
import { changeUserPassword, checkUserPrevPassword } from 'api/mypage';

const AccountPasswordChange = ({ useStyles }) => {
  const history = useHistory();
  const [auth] = useRecoilState(AuthAtom);
  const { userId } = auth;
  useRedirectWithoutAuth();

  const classes = useStyles();

  const [valuesPass, setValuesPass] = useState({
    userId,
    password: '',
    newpassword: '',
    confirmPassword: '',
  });

  const [error, setError] = useState({
    userIdError: '',
    emailError: '',
    emailCodeError: '',
    nameError: '',
    phoneError: '',
    pwdError: '',
    confirmPwd: '',
  });

  const [isAuthLoading, setIsAuthLoading] = useState(false);

  const isPwd = (pass) => {
    const pwdRegex = /^.*(?=.{6,20})(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@$!%*#?&]).*$/;
    return pwdRegex.test(pass);
  };

  const confirmPassword = (pass, confirmPass) => {
    return pass === confirmPass;
  };

  const onTextValidationPass = () => {
    let pwdError = '';
    let confirmPwd = '';

    if (!isPwd(valuesPass.newpassword)) pwdError = '비밀번호 조건을 만족 할 수 없습니다.';
    if (!confirmPassword(valuesPass.newpassword, valuesPass.confirmPassword))
      confirmPwd = '비밀번호가 일치하지 않습니다.';
    if (valuesPass.userId === valuesPass.newpassword) pwdError = '아이디를 비밀번호로 사용 할 수 없습니다.';

    setError({
      pwdError,
      confirmPwd,
    });

    if (pwdError || confirmPwd) return false;
    return true;
  };

  const handleChangeFormPass = (e) => {
    setValuesPass({ ...valuesPass, [e.target.name]: e.target.value });
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();

    if (isAuthLoading) return;

    const valid = onTextValidationPass();

    if (!valid) console.error('invalid');
    else {
      try {
        setIsAuthLoading(true);
        const response = await checkUserPrevPassword({
          userId,
          password: valuesPass.password,
        });
        if (response.data === 0) {
          errorToast('기존 비밀번호가 틀립니다.');
          setValuesPass({ password: '', newpassword: '', confirmPassword: '' });
        } else if (response.data === 1) {
          const response = await changeUserPassword({
            userId,
            password: valuesPass.newpassword,
          });
          if (response.data === 0) errorToast('인증에 실패 했습니다.');
          else if (response.data === 1) {
            successToast('비밀번호가 변경되었습니다.');
            setTimeout(() => {
              history.push('/information');
            }, 1000);
          } else errorToast('비밀번호 규정에 맞지 않습니다.');
        }
        setIsAuthLoading(false);
      } catch (error) {
        errorToast('서버 값을 받지 못했습니다.');
        setValuesPass({ password: '', newpassword: '', confirmPassword: '' });
        setIsAuthLoading(false);
      }
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <StyledForm className={classes.form} onSubmit={handleSubmitPassword}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              autoFocus
              autoComplete=''
              fullWidth
              label='기존 비밀번호를 입력하세요.'
              name='password'
              type='password'
              value={valuesPass.password}
              onChange={handleChangeFormPass}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              autoComplete=''
              fullWidth
              helperText='영문과 숫자, 특수문자(@$!%*#?&)를 조합하여 8-15자 사이로 입력해 주세요.'
              label='신규 비밀번호를 입력하세요.'
              name='newpassword'
              type='password'
              value={valuesPass.newpassword}
              onChange={handleChangeFormPass}
            />
            <div
              style={{
                color: 'red',
                fontSize: '12px',
                margin: '-5px 0 10px 15px',
                fontFamily: 'Noto Sans KR',
              }}
            >
              {error.pwdError}
            </div>
            <TextField
              variant='outlined'
              margin='normal'
              required
              autoComplete=''
              fullWidth
              label='비밀번호 다시한번 입력하세요.'
              name='confirmPassword'
              type='password'
              value={valuesPass.confirmPassword}
              onChange={handleChangeFormPass}
            />
            <div
              style={{
                color: 'red',
                fontSize: '12px',
                margin: '-5px 0 10px 15px',
                fontFamily: 'Noto Sans KR',
              }}
            >
              {error.confirmPwd}
            </div>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <div
                style={{
                  paddingTop: '10px',
                  borderBottom: '1px solid #e3e3e3',
                  margin: '10px 0',
                }}
              ></div>
            </div>
            <StyledButton type='submit' color='primary' className={classes.button}>
              <span className='secosub1'>비밀번호 변경</span>
            </StyledButton>
          </StyledForm>
        </Grid>
      </Grid>
    </>
  );
};

const StyledForm = styled.form`
  .MuiFormHelperText-root {
    font-size: 14px !important;
  }
`;

const StyledButton = styled.button`
  margin: 20px auto 0;
  padding: 10px 30px;
  border-radius: 50px;
`;

export default AccountPasswordChange;

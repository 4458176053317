import React from 'react';

import Layout from '../../layouts/Layout/Layout';

const Header = ({ children }) => {
  return (
    <Layout
      style={{
        backgroundColor: '#f0f5f9',
        marginTop: '110px',
        paddingBottom: '35px',
      }}
      col='1'
    >
      <div className='text-center' style={{ marginTop: '45px' }}>
        <h2 className='secomain secoblue' style={{ marginBlockStart: '0', markginBlockEnd: '0', marginBottom: '0' }}>
          {children}
        </h2>
      </div>
    </Layout>
  );
};

export default Header;

import React, { Fragment } from 'react';
import './coursestyle.css';

export default ({ data = [], sDatas = [], mediaCreated, mediaDuration }) => {
  let formattedDate =
    mediaCreated.substring(0, 4) + '-' + mediaCreated.substring(4, 6) + '-' + mediaCreated.substring(6, 8);

  return (
    <Fragment>
      <div className='videoDetailTitle'>
        <div className='detailcontentBox'>
          <div className='secosub1 secodgray col-md-2 detailLeft' style={{ textAlign: 'right' }}>
            강의명
          </div>
          <div className='secosub1 secoblue col-md-10 detailRight'>{sDatas.videoName}</div>
        </div>
        <div className='detailcontentBox'>
          <div className='secosub1 secodgray col-md-2 detailLeft' style={{ textAlign: 'right' }}>
            LastUpdate
          </div>
          <div className='secosub2 secoblack col-md-2 detailRight'>{formattedDate}</div>
          <div className='secosub1 secodgray col-md-2 detailLeft' style={{ textAlign: 'right' }}>
            강사
          </div>
          <div className='secosub2 secoblack col-md-2 detailRight'>{data.courseInst}</div>
          <div className='secosub1 secodgray col-md-2 detailLeft' style={{ textAlign: 'right' }}>
            time
          </div>
          <div className='secosub2 secoblack col-md-2 detailRight'>{mediaDuration}</div>
        </div>
      </div>
    </Fragment>
  );
};

/* eslint-disable */

import React, { useEffect, useState, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';

import Layout from '../components/layouts/Layout/Layout';
import ProjectVideo from '../components/widgets/Project/ProjectVideo';
import { useRedirectWithoutAuth } from '../hooks/redirect.hook';
import DetailAsideChapter from '../components/widgets/Project/DetailAsideChapter';
import styles from '../components/widgets/Project/Project.module.scss';
import THEME from '../state/theme';
import { useFetchCourseList } from '../hooks/student.hook';
import { AuthAtom } from '../state';

export default ({ history }) => {
  const params = useParams();
  const { courseType, courseId, instId, videoName, categoryId } = params;
  const { token } = useRecoilValue(AuthAtom);
  const [courseTotal, setCourseTotal] = useState([]);

  const { data: courseListData } = useFetchCourseList({ token });

  const courseCurrent = courseTotal.find((item) => item.courseId.toString() === courseId);

  useRedirectWithoutAuth();

  useEffect(() => {
    if (!courseListData) return;

    setCourseTotal(courseListData.data.courses);
  }, [courseListData]);

  return (
    <Fragment>
      <Layout
        style={{
          backgroundColor: '#fff',
          paddingBottom: '110px',
        }}
        col='1'
      >
        <Layout col='1'>
          <div className={styles.breadcrumbs} style={{ color: THEME.color }}>
            <Link to='/student/course' className='secosub1 secoblue'>
              학습실
            </Link>
            <span className='secosub1 secoblack'>
              &nbsp; &nbsp; <i className='fas fa-angle-right' />
              &nbsp; &nbsp;
            </span>
            <Link
              to={`/student/coursedetail/${courseType}/${courseId}/${instId}/${categoryId}`}
              className='secosub1 secoblue'
            >
              {/* courseCurrent props가 늦게 전달되는 문제에 대응 */}
              <span>{courseCurrent && courseCurrent.courseName}</span>
            </Link>
            <span className='secosub1 secoblack'>
              &nbsp; &nbsp; <i className='fas fa-angle-right' />
              &nbsp; &nbsp;{videoName}
            </span>
          </div>
        </Layout>

        <VideoPageWrapper>
          <DetailAsideChapter courseTotal={courseTotal} isCourseDetailAside={false} />
          <ProjectVideo courseTotal={courseTotal} />
        </VideoPageWrapper>
      </Layout>
    </Fragment>
  );
};

const VideoPageWrapper = styled.div`
  position: relative;
  width: 100%;
`;

import React from 'react';
import { Link } from 'react-router-dom';

import { warningToast } from '../utilities/toast';

const LanguageList = ({ languageType, languageId, disable }) => {
  const handlerClick = () => {
    warningToast(`${languageType.toUpperCase()} 개발언어의 보안약점 진단훈련을 이용할 수 없는 사용자입니다.`);
  };

  const imgUrl = `/assets/icons/${languageType}-icon.png`;

  return (
    <>
      {disable ? (
        <div className='mOver' onClick={handlerClick}>
          <div className='mBox' style={{ background: '#fff', opacity: '0.4' }}>
            <img src={imgUrl} alt='LanguageType' width='120' />
          </div>
        </div>
      ) : (
        <div className='mOver'>
          <Link to={`/student/seccategory/${languageType}/${languageId}`}>
            <div className='mBox'>
              <img src={imgUrl} alt='LanguageType' width='120' />
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

export default LanguageList;

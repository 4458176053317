import React from 'react';
import styled from 'styled-components';

import { PersonalResultWrapper } from './PersonalResultCommon';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  Filler,
  LineElement,
  PointElement,
  RadarController,
  RadialLinearScale,
  Title,
  Tooltip,
} from 'chart.js';

ChartJS.register(RadarController, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Title);

const PersonalResultTechnicalChart = ({ groupAvg, correctIncorrectData }) => {
  const { firstExamMiddleCorrectRates = {}, secondExamMiddleCorrectRates = {} } = groupAvg || {};

  const beforeExamAvg = Object.values(firstExamMiddleCorrectRates) || [];
  const lastIndex1 = beforeExamAvg.pop();
  beforeExamAvg.unshift(lastIndex1);

  const afterExamAvg = Object.values(secondExamMiddleCorrectRates) || [];
  const lastIndex2 = afterExamAvg.pop();
  afterExamAvg.unshift(lastIndex2);

  const { firstExamReportData, secondExamReportData } = correctIncorrectData || {};

  const calculateAccuracy = (data) => {
    if (!data || !data.length) return [];

    const categoryCounts = {};
    const correctCounts = {};

    data.map((item) => {
      const categoryName = item.middleCategoryName;

      if (!categoryCounts[categoryName]) {
        categoryCounts[categoryName] = 0;
        correctCounts[categoryName] = 0;
      }

      categoryCounts[categoryName]++;

      if (item.isCorrect) {
        correctCounts[categoryName]++;
      }
    });

    const categories = [
      '개요',
      '입력 데이터 검증 및 표현',
      '코드 오류',
      '보안 기능',
      '캡슐화',
      '에러 처리',
      '시간-상태',
      'API 오용',
    ];

    return categories.map((category) => (correctCounts[category] / categoryCounts[category]) * 100 || 0);
  };

  const beforeExamScore = calculateAccuracy(firstExamReportData);
  const afterExamScore = calculateAccuracy(secondExamReportData);

  const checkIsExamScoreZero = (scores) => {
    const array = [...scores];
    const zeroScores = array.filter((e) => e === 0);

    return zeroScores.length === scores.length;
  };

  const beforeExamTitle = {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: '사전 평가',
    },
    datalabels: {
      display: false,
    },
  };

  const afterExamTitle = {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: '사후 평가',
    },
    datalabels: {
      display: false,
    },
  };

  const commonOptions = {
    animation: {
      duration: 0,
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        pointLabels: {
          fontSize: 30,
        },
      },
    },
    scales: {
      r: {
        angleLines: {
          display: true,
        },
        ticks: {
          stepSize: 25,
        },
        suggestedMin: 0,
        suggestedMax: 100,
        pointLabels: {
          color: '#000',
          font: {
            size: 10,
            weight: 'bold',
          },
        },
      },
    },
  };

  const beforeExamOpt = {
    ...commonOptions,
    plugins: {
      ...beforeExamTitle,
    },
  };

  const afterExamOpt = {
    ...commonOptions,
    plugins: {
      ...afterExamTitle,
    },
  };

  const labels = [
    '개요',
    ['입력 데이터', '검증 및 표현'],
    '코드 오류',
    '보안 기능',
    '캡슐화',
    '에러 처리',
    '시간-상태',
    'API 오용',
  ];

  const beforeData = {
    labels: labels,
    datasets: [
      {
        borderDash: [5],
        fill: false,
        data: beforeExamAvg,
        backgroundColor: 'rgba(152,152,152,0.2)',
        borderColor: 'rgb(152,152,152)',
        borderStyle: 'dash',
        pointBackgroundColor: 'rgb(152,152,152)',
        pointHoverBorderColor: 'rgb(152,152,152)',
      },
      {
        data: beforeExamScore,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgb(255, 99, 132)',
        pointBackgroundColor: 'rgb(255, 99, 132)',
        pointHoverBorderColor: 'rgb(255, 99, 132)',
        pointStyle: checkIsExamScoreZero(beforeExamScore) ? 'line' : 'circle',
      },
    ],
  };

  const afterData = {
    labels: labels,
    datasets: [
      {
        borderDash: [5],
        fill: false,
        data: afterExamAvg,
        backgroundColor: 'rgba(152,152,152,0.2)',
        borderColor: 'rgb(152,152,152)',
        borderStyle: 'dash',
        pointBackgroundColor: 'rgb(152,152,152)',
        pointHoverBorderColor: 'rgb(152,152,152)',
      },
      {
        data: afterExamScore,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgb(54, 162, 235)',
        pointBackgroundColor: 'rgb(54, 162, 235)',
        pointHoverBorderColor: 'rgb(54, 162, 235)',
        pointStyle: checkIsExamScoreZero(afterExamScore) ? 'line' : 'circle',
      },
    ],
  };

  return (
    <PersonalResultWrapper title='기술요소별 역량평가 결과'>
      <Container>
        <div>
          <Radar data={beforeData} options={beforeExamOpt} width='50%' height='50%' />
        </div>

        <div>
          <Radar data={afterData} options={afterExamOpt} width='50%' height='50%' />
        </div>
      </Container>
    </PersonalResultWrapper>
  );
};

const Container = styled.div`
  display: flex;

  > div {
    flex: 1;
    max-width: 400px;
    height: 100%;
    margin: 0 auto;
  }
`;

export default PersonalResultTechnicalChart;

import axios from 'axios';

import { api } from './config';

export const fetchFaqData = async () => {
  return await axios.get(`${process.env.REACT_APP_IP}/users/faq/main`);
};

export const fetchLearningRate = async () => {
  return await axios.get(`${process.env.REACT_APP_IP}/common/learningRate`);
};

export const fetchDashBoardTimeInfo = async () => {
  return await api.get('/common/elapsedTimeInfoStat');
};

export const fetchDashBoard = async () => {
  return await api.get('/common/dashboard');
};

export const fetchDashBoardSolvedInfo = async (userId) => {
  return await api.get(`/users/my/solvedInfoStat/${userId}`);
};

export const fetchElapsedTimeInfoStat = async () => {
  return await api.get(`/common/elapsedTimeInfoStat`);
};

export const fetchProblemSolvingList = async ({ userId }) => {
  return await api.get(`/users/my/secureProblemSolvingList/${userId}`);
};

export const fetchProblemSolvingBest = async ({ userId }) => {
  return await api.get(`/users/my/secureProblemSolvingBest/${userId}`);
};

export const fetchProblemSolvingWorst = async ({ userId }) => {
  return await api.get(`/users/my/secureProblemSolvingWorst/${userId}`);
};

export const fetchLanguageData = async () => {
  return await api.get('/lan');
};

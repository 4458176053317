import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Grid, TextField } from '@mui/material';
import styled from 'styled-components';

import { Button } from '../../elements';
import { AuthAtom } from 'state';
import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import { fetchUserInfo, changeUserInfo, confirmEmail } from 'api/mypage';
import { userConfirmCode } from 'api/auth';
import { errorToast, successToast } from 'utilities/toast';
import styles from './AccountInfo.module.scss';

const AccountEdit = ({ useStyles }) => {
  const history = useHistory();
  const [auth, setAuth] = useRecoilState(AuthAtom);
  const { userId } = auth;

  useRedirectWithoutAuth();

  const [valuesDefault, setValuesDefault] = useState({
    userIdD: '',
    nameD: '',
    emailD: '',
    phoneD: '',
  });

  const [valuesEdit, setValuesEdit] = useState({
    userId: '',
    name: '',
    email: '',
    phone: '',
    confirmCode: '',
  });

  useEffect(() => {
    (async () => {
      try {
        const { data } = await fetchUserInfo();
        setValuesEdit({
          userId: data.userId,
          name: data.name,
          email: data.email,
          phone: data.phone,
        });
        setValuesDefault({
          userIdD: data.userId,
          nameD: data.name,
          emailD: data.email,
          phoneD: data.phone,
        });
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.status === 403) {
            errorToast('토큰이 만료되었습니다. 다시 로그인해주세요 :)');
            history.push('/');
          }
        }
      }
    })();
  }, []);

  const classes = useStyles();

  const [confirmStatusEmail, setConfirmStatusEmail] = useState('no');
  const [confirmStatusConfirmCode, setConfirmStatusConfirmCode] = useState('no');

  const [error, setError] = useState({
    userIdError: '',
    emailError: '',
    emailCodeError: '',
    nameError: '',
    phoneError: '',
    pwdError: '',
    confirmPwd: '',
  });

  const [isUsersLoading, setIsUsersLoading] = useState(false);

  const isEmail = (email) => {
    const emailRegex =
      /^(([^<>()\].,;:\s@"]+(\.[^<>()\].,;:\s@"]+)*)|(".+"))@(([^<>()¥[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    return emailRegex.test(email);
  };

  const isPhone = (phone) => {
    const phoneRegex = /^[0-9\b -]{0,13}$/;
    return phoneRegex.test(phone);
  };

  const onTextValidation = () => {
    let emailError = '';
    let emailCodeError = '';
    let nameError = '';
    let phoneError = '';

    if (valuesEdit.email === valuesDefault.emailD) {
      setConfirmStatusEmail(true);
      emailError = '';
      emailCodeError = '';
    } else {
      if (!isEmail(valuesEdit.email)) emailError = 'email 형식이 아닙니다.';
      if (!confirmStatusEmail || confirmStatusEmail === 'no') emailError = '이메일 중복확인 하세요';
    }
    if (valuesEdit.phone === valuesDefault.phoneD) {
      phoneError = '';
    } else {
      if (!isPhone(valuesEdit.phone)) phoneError = '휴대폰 형식이 아닙니다.';
    }
    if (valuesEdit.name === valuesDefault.nameD) {
      nameError = '';
    } else {
      if (valuesEdit.name.length === 0) nameError = '이름을 입력해주세요.';
    }
    if (!confirmStatusConfirmCode || confirmStatusConfirmCode === 'no') emailCodeError = '인증 번호를 확인 해주세요';
    setError({
      emailError,
      emailCodeError,
      nameError,
      phoneError,
    });

    if (emailError || emailCodeError || nameError || phoneError) return false;
    return true;
  };

  const handleChangeFormEdit = (e) => {
    setValuesEdit({ ...valuesEdit, [e.target.name]: e.target.value });
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (isUsersLoading) return;

    const valid = onTextValidation();
    if (!valid) console.error('invalid');
    else if (
      valuesEdit.email === valuesDefault.emailD &&
      valuesEdit.name === valuesDefault.nameD &&
      valuesEdit.phone === valuesDefault.phoneD
    ) {
      errorToast('변경하실 회원정보가 없습니다.');
    } else {
      try {
        setIsUsersLoading(true);

        await changeUserInfo({
          userId,
          name: valuesEdit.name,
          email: valuesEdit.email,
          phone: valuesEdit.phone,
        });

        setAuth({
          ...auth,
          name: valuesEdit.name,
          email: valuesEdit.email,
        });
        successToast('회원정보가 변경되었습니다.');
        setIsUsersLoading(false);
        history.push('/information');
      } catch (error) {
        setValuesEdit({
          userId: valuesDefault.userIdD,
          name: valuesDefault.nameD,
          email: valuesDefault.emailD,
          phone: valuesDefault.phoneD,
        });
        setIsUsersLoading(false);
      }
    }
  };

  const handleConfirmEmail = async () => {
    if (!valuesEdit.email) {
      errorToast('이메일을 입력하세요.');
    } else if (!isEmail(valuesEdit.email)) {
      errorToast(valuesEdit.email + '는 사용할 수 없는 형식입니다.');
    } else {
      try {
        setConfirmStatusEmail(false);
        const response = await confirmEmail({ email: valuesEdit.email });
        const { data } = response;
        if (data === 'success') {
          setConfirmStatusEmail(true);
          successToast(
            '입력하신 이메일 주소로 인증번호를 발송했습니다. 받은 편지함을 확인하셔서 전달된 인증번호를 입력해 주세요.'
          );
        } else if (data === 'sendMailErrror') {
          setConfirmStatusEmail(false);
          errorToast('인증번호를 발송할 수 없습니다. 이메일 주소를 확인하신 후 다시 시도해 주세요.');
        }
      } catch (error) {
        errorToast('서버와의 연결이 원할하지 않습니다. 잠시 후 다시 시도해 주세요.');
      }
    }
  };

  //  이메일 인증코드 확인
  const handleConfirmCode = async () => {
    if (confirmStatusEmail !== true) {
      errorToast('이메일 인증을 먼저 시도해 주세요.');
    } else if (!valuesEdit.confirmCode) {
      errorToast('인증번호를 입력하세요.');
    } else {
      try {
        setConfirmStatusConfirmCode(false);
        const response = await userConfirmCode({ email: valuesEdit.email, confirmCode: valuesEdit.confirmCode });
        const { data } = response;
        if (data === 'success') {
          setConfirmStatusConfirmCode(true);
          successToast('인증되었습니다. 계속해서 회원 정보 수정을 진행해 주세요.');
        } else {
          setConfirmStatusConfirmCode(false);
          errorToast(
            '인증번호 확인에 실패했습니다. 인증번호를 확인하신 후 다시 시도해 주세요. 인증제한시간(5분)이 초과한 경우 이메일 인증부터 다시 시도해 주세요.'
          );
        }
      } catch (error) {
        errorToast('서버와의 연결이 원할하지 않습니다. 잠시 후 다시 시도해 주세요.');
      }
    }
  };

  return (
    <>
      <StyledForm className={classes.form} onSubmit={handleSubmitEdit}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              helperText='※ ID 변경은 고객센터로 문의주시기 바랍니다.'
              disabled
              id='outlined-disabled'
              variant='outlined'
              margin='normal'
              label='아이디 (변경 불가능)'
              value={userId}
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              autoComplete='email'
              label='이메일'
              name='email'
              value={valuesEdit.email || ''}
              onChange={handleChangeFormEdit}
            />
            <div
              style={{
                color: 'red',
                fontSize: '12px',
                margin: '-5px 0 10px 15px',
                fontFamily: 'Noto Sans KR',
              }}
            >
              {error.emailError}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={styles.btn_acc}>
              <Button roundSmall className='relativeBtn' click={() => handleConfirmEmail()}>
                <span className='secotext'>이메일 인증</span>
              </Button>
            </div>
          </Grid>
          <Grid item xs={8}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              autoComplete='code'
              fullWidth
              label='이메일로 전달된 인증번호를 입력하세요.'
              name='confirmCode'
              value={valuesEdit.confirmCode || ''}
              onChange={handleChangeFormEdit}
            />
            <div
              style={{
                color: 'red',
                fontSize: '12px',
                margin: '-5px 0 10px 15px',
                fontFamily: 'Noto Sans KR',
              }}
            >
              {error.emailCodeError}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={styles.btn_acc}>
              <Button roundSmall className='relativeBtn' click={() => handleConfirmCode()}>
                <span className='secotext'>인증번호 확인</span>
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              autoComplete='name'
              fullWidth
              label='이름'
              name='name'
              value={valuesEdit.name || ''}
              onChange={handleChangeFormEdit}
            />
            <div
              style={{
                color: 'red',
                fontSize: '12px',
                margin: '-5px 0 10px 15px',
                fontFamily: 'Noto Sans KR',
              }}
            >
              {error.nameError}
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              margin='normal'
              autoComplete='phone'
              fullWidth
              label='전화번호'
              name='phone'
              value={valuesEdit.phone || ''}
              onChange={handleChangeFormEdit}
            />
            <div
              style={{
                color: 'red',
                fontSize: '12px',
                fontFamily: 'Noto Sans KR',
              }}
            >
              {error.phoneError}
            </div>
          </Grid>
        </Grid>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <div style={{ paddingTop: '10px', borderBottom: '1px solid #e3e3e3', margin: '10px 0' }}></div>
        </div>
        <StyledButton type='submit' color='primary' className={classes.button}>
          <span className='secosub1'>회원정보 변경</span>
        </StyledButton>
      </StyledForm>
    </>
  );
};

const StyledForm = styled.form`
  .MuiFormHelperText-root {
    font-size: 14px !important;
  }
`;

const StyledButton = styled.button`
  margin: 20px auto 0;
  padding: 10px 30px;
  border-radius: 50px;
`;

export default AccountEdit;

import React, { Fragment, useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { Header } from '../../widgets';
import { useCustomState } from 'state/state';
import { AuthAtom } from 'state';
import { getItem } from 'commons/localStorage';
import InfoFind from './InfoFind';

const AccountInfoFind = () => {
  const state = useCustomState()[0];
  const token = getItem('token');
  const auth = useRecoilValue(AuthAtom);
  const resetAuth = useResetRecoilState(AuthAtom);

  useEffect(() => {
    if (token && !auth.auth) {
      resetAuth();
    }
  }, [auth]);

  return (
    <Fragment>
      <Header img={state.data.header_bgs.signup}>아이디, 비밀번호 찾기</Header>
      <InfoFind />
    </Fragment>
  );
};

export default AccountInfoFind;

import React from 'react';

import { SignUpConfirm } from '../../ui';
import { useCustomState } from 'state/state';
import styles from './RequestForm.module.scss';

const RequestForm = () => {
  const [state, actions] = useCustomState();
  
  return (
    <div className={`${styles.wrapper} ${state.request.modal ? styles.show : ''}`}>
      <div className={styles.modal}>
        <i onClick={() => actions.toggleRequest()} className='las la-times' />

        <SignUpConfirm />
      </div>
    </div>
  );
};

export default RequestForm;

import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Menu, Fade } from '@mui/material';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

import { useCustomState, AuthAtom } from 'state';
import Layout from '../Layout/Layout';
import { Button, Logo } from '../../elements';
import { MESSAGE } from 'utilities';
import { warningToast } from '../../../utilities/toast';
import styles from './Header.module.scss';
import { logout } from 'api/auth';

const Header = ({ data = [], history }) => {
  const auth = useRecoilValue(AuthAtom);
  const resetAuthState = useResetRecoilState(AuthAtom);

  const userLogout = async () => {
    await logout();
  };

  // 내정보 팝업
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // 헤더 메뉴 sticky 설정
  const [sticky, setSticky] = useState(false);
  const actions = useCustomState()[1];

  const handleResize = () => {
    setSticky(window.pageYOffset > 200);
  };

  // 로그인 상태 정보
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    window.addEventListener('scroll', handleResize);
    return () => {
      window.removeEventListener('scroll', handleResize);
    };
  }, [auth]);

  const handleLogout = async () => {
    history.push('/');
    await userLogout();
    resetAuthState();
    setAnchorEl(null);
  };

  const handleMypage = () => {
    if (auth.auth) {
      history.push('/mypage');
    } else {
      warningToast(MESSAGE.SERVICE_AUTHENTICATED_ONLY);
      history.push('/');
    }
    handleClose();
  };

  // 메뉴 클릭 시 접근 권한 체크 후 이동
  const handleClick = (e) => {
    e.preventDefault();

    const { path, title } = e.target.dataset;

    if (!path) return;

    const authOnlyServices = [
      '/student', // 학습자
      '/student/Course', // 학습실
      '/student/securelist', // 보안약점 진단훈련
      '/student/codediagnosis', // 프로젝트 개발훈련
      '/common/dashboard', // 대시보드
      '/customer', // 고객센터 & 자료실
      '/customer/faq', // 자주묻는질문
      '/customer/review', // 사용자후기
      '/customer/contact', // 문의하기
      '/trainer', // 강사 메뉴
      '/trainer/monitoring', // 수강생 조회
    ];

    if (authOnlyServices.indexOf(path) !== -1 && !auth.auth) {
      warningToast(`"${title}" 메뉴는 ${MESSAGE.SERVICE_AUTHENTICATED_ONLY}`);
      return false;
    }

    if (path.indexOf('/student') === 0) {
      if (auth.type !== 1) {
        warningToast(`"${title}" 메뉴는 ${MESSAGE.SERVICE_STUDENT_ONLY}`);
        return false;
      } else if (auth.status !== 1) {
        warningToast(MESSAGE.STATUE_NOT_CONFIRMED);
        return false;
      }
    }

    if (path.indexOf('/trainer') === 0) {
      if (auth.type !== 2) {
        warningToast(`"${title}" 메뉴는 ${MESSAGE.SERVICE_TRAINER_ONLY}`);
        return false;
      } else if (auth.status !== 1) {
        warningToast(MESSAGE.STATUE_NOT_CONFIRMED);
        return false;
      }
    }

    history.push(path);
  };

  const menu = data?.map((item, index) => {
    if (!item.children) {
      return (
        <li key={index}>
          <a className='secosub2' data-path={item.url} data-title={item.name} href='#' onClick={handleClick}>
            {item.name}
          </a>
        </li>
      );
    } else {
      return (
        <li key={index}>
          <span>
            <a className='secosub2' data-path={item.url} data-title={item.name} href='#' onClick={handleClick}>
              {item.name} <i className='fa-solid fa-chevron-down' />
            </a>
          </span>
          <ul>
            {item.children?.map((subitem, subindex) => (
              <li
                key={subindex}
                style={{ display: auth.type !== 2 && subitem.name === '수강생 조회' ? 'none' : 'inherit' }}
              >
                <a className='secomin' data-path={subitem.url} data-title={subitem.name} href='#' onClick={handleClick}>
                  {subitem.name}
                </a>
              </li>
            ))}
          </ul>
        </li>
      );
    }
  });

  const header = (
    <Layout>
      <div className={styles.header} id='mainHeader'>
        <Logo dark />

        <ul className={styles.menu}>{menu}</ul>
        {!loading ? (
          auth.auth ? (
            <Fragment>
              <StyledButton className='btn btn-primary secotext' type='button' id='login' onClick={handleMenu}>
                <span>{auth.name}</span>
                <i className='fas fa-user'></i>
              </StyledButton>
              <Menu
                id='login'
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <div className={styles.dwUserBox}>
                  <div className={styles.uImg}>
                    <img src='/assets/icons/pf.png' alt='user' />
                  </div>
                  <div className={styles.uText}>
                    <p className='secotext secodgray'>{auth.name}</p>
                    <p className='secomin secogray'>{auth.email}</p>
                  </div>
                </div>
                <li role='separator' className={styles.divider} />
                <ButtonWrapper>
                  <StyledModalButton
                    className='btn btn-outline-primary secotext'
                    type='button'
                    id='button-addon2'
                    onClick={handleMypage}
                  >
                    <i className='far fa-address-book' />
                    <span>Mypage</span>
                  </StyledModalButton>
                  <StyledModalButton
                    className='btn btn-outline-danger secotext'
                    type='button'
                    id='button-addon2'
                    onClick={handleLogout}
                  >
                    <i className='fas fa-power-off' />
                    <span>Signout</span>
                  </StyledModalButton>
                </ButtonWrapper>
              </Menu>
            </Fragment>
          ) : (
            <Fragment>
              <div className={styles.btn_desktop}>
                <Button
                  after='&#xf107;'
                  type='outline-color-tw'
                  hoverType='solid-color-tb'
                  click={() => actions.toggleModal()}
                >
                  <span className='secosub2'>Login</span>
                </Button>
              </div>
              <div className={styles.btn_mobile} onClick={() => actions.toggleSidebar()}>
                <Button after='&#xf0c9;' type='solid-white-tb' hoverType='solid-gray-tb' />
              </div>
            </Fragment>
          )
        ) : (
          ''
        )}
      </div>
    </Layout>
  );

  return (
    <Fragment>
      <div className={styles.wrapper}>{header}</div>
      <div className={`${styles.wrapper} ${sticky ? styles.sticky : styles.hidden}`} id='StickyHeader'>
        {header}
      </div>
    </Fragment>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 5px;
`;

const StyledButton = styled.button`
  padding: 12px 35px;
  border-radius: 5px;

  i {
    margin-left: 10px;
  }
`;

const StyledModalButton = styled.button`
  padding: 5px 15px;
  border-radius: 5px;
`;

export default withRouter(Header);

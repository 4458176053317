import React, { useEffect, useRef, useState } from 'react';
import './TestTimer.css';

const padNumber = (num, length) => {
  return String(num).padStart(length, '0');
};

const TestTimer = (props) => {
  const [min, setMin] = useState(padNumber(0, 2));
  const [sec, setSec] = useState(padNumber(0, 2));
  let tmpTime = [{ jsonTime: props.questionData.examTimeLimit }];
  let timeString = tmpTime[0].jsonTime + '';
  let time = parseInt(timeString);
  // let time = 10000;
  const testContinue = props.questionData.isStarted;
  const interval = useRef(null);
  const timeLimit = useRef();

  function Timer() {
    interval.current = setInterval(() => {
      setMin(padNumber(parseInt(timeLimit.current / 60), 2));
      setSec(padNumber(timeLimit.current % 60, 2));
      timeLimit.current = timeLimit.current - 1;
    }, 1000);
    return () => clearInterval(interval.current);
  }

  function timeOut() {
    props.setTimeOut(true);
  }

  useEffect(() => {
    if (testContinue !== undefined) {
      timeLimit.current = time - 1;
      testContinue && Timer();
    }
  }, [testContinue]);

  useEffect(() => {
    if (timeLimit.current === 0) {
      clearInterval(interval.current);
      timeOut();
    }
    if (time <= 0) {
      clearInterval(interval.current);
      timeOut();
    }
  }, [sec]);

  return (
    <div style={{ marginTop: '-10px' }}>
      <p>남은시간</p>
      <div className='limitTime'>
        {min} : {sec}
      </div>
    </div>
  );
};

export default TestTimer;

import React, { Fragment, useRef } from 'react';
import { Link } from 'react-router-dom';

import { Header } from '../../widgets';
import AccountInfo from '../MypageMyInformation/AccountInfo.js';
import styles from './Mypage.module.scss';
import '../MyPage.css';

const MypageOper = () => {
  const ref = useRef(null);

  return (
    <Fragment>
      <Header>마이 페이지</Header>
      <div className={styles.mypage} ref={ref}>
        <section className={`${styles.wrapper} ${styles.with_sidebar} ${styles.left}`}>
          <aside className={styles.sidebar}>
            <div className='myPageLeftSide'>
              <ul>
                <li className={'mypageLeftsideActive'}>
                  <Link to='/mypage'>
                    <span className='secotext'>
                      <i className='far fa-address-book fa-2x'></i>
                      <br />내 정보 관리
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to='/operator'>
                    <span className='secotext'>
                      <i className='fas fa-bars fa-2x'></i>
                      <br />
                      수강 관리
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </aside>

          <article className='mypage'>
            <div className='mypageTable'>
              <AccountInfo />
            </div>
          </article>
        </section>
      </div>
    </Fragment>
  );
};

export default MypageOper;

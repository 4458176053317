import { TableRow, TableCell } from '@mui/material';
import { withStyles } from '@mui/styles';

export const StyledTableCellWithHead = withStyles((theme) => ({
  head: {
    backgroundColor: '#868686',
    color: '#fff',
    fontSize: '1.45rem',
    fontWeight: '600',
    textAlign: 'center',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export const ColorTableRow = withStyles(() => ({
  root: {
    backgroundColor: 'white',
  },
  selected: { color: 'black', backgroundColor: '#ffebd4 !important' },
}))(TableRow);

import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Header } from '../../widgets';
import { Layout } from '../../layouts';
import { useCustomState } from 'state/state';

const Term = () => {
  const location = useLocation();
  const state = useCustomState()[0];

  const path = location.pathname;
  const service = state.data.footerMenu.find((s) => s.url === path);

  return (
    <Fragment>
      <Header>{service.title}</Header>
      <Layout padding mode='light' style={{ background: 'rgb(255,255,255)', width: '100%' }}>
        <Wrapper>
          <TextBox>
            <h2>제 1조 목적</h2>
            <p>
              본 약관은 주식회사 오픈이지(이하 “회사”)가 운영하는 대표 사이트(secolab.kr) 에서 제공되는 인 터넷 관련
              서비스(이하 “서비스”) 이용과 관련하여 회사와 이용자의 권리/의무 및 책임사항을 규 정함을 목적으로 합니다.
            </p>
          </TextBox>

          <TextBox>
            <h2>제 2 조 정의</h2>
            <p>
              1. “서비스”란 접속 가능한 유/무선 정보통신기기의 종류와는 상관없이 이용 가능한 "회사"가 제공 하는 모든
              서비스를 의미합니다.
            </p>
            <p>
              2. “대표사이트(secolab.kr)”란 회사가 이용자에게 서비스를 제공하기 위하여 컴퓨터 등 정보통신설 비를
              이용하여 구성한 가상의 공간을 의미합니다.
            </p>
            <p>
              3. “이용자”란 “대표사이트”에 접속하여 이 약관에 따라 “대표사이트”의 서비스를 이용하는 자를 통칭합니다.
            </p>
            <p>
              4. 본 약관에서 사용하는 용어의 정의는 본 조에서 정하는 것을 제외하고는 관계법령 및 서비스 별 안내에서
              정하는 바에 따릅니다.
            </p>
          </TextBox>

          <TextBox>
            <h2>제 3 조 약관등의 명시와 설명 및 개정</h2>
            <p>
              1. 회사는 이 약관의 내용과 상호 및 영업소 소재지 주소, 전화번호, 사업자 등록번호, 통신판매업 신고번호 등을
              이용자가 알 수 있도록 대표사이트의 초기서비스 화면에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을
              통하여 볼 수 있도록 할 수 있습니다.
            </p>
            <p>
              2. 회사는 전자상거래등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자거래기본 법, 전자서명법,
              정보통신망이용촉진 및 정보보호등에 관한 법률, 소비자보호법 등 관련법령을 위 배하지 않는 범위에서 이 약관을
              개정할 수 있습니다.
            </p>
            <p>
              3. 회사는 필요할 경우 약관을 수정하거나 변경할 수 있습니다. 회사가 이 약관을 개정할 경우에 는 적용일자 및
              개정사유를 명시하여 현행약관과 함께 대표사이트 초기화면에 그 적용일자 7일이 전부터 적용일자 전일까지
              공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우 최 소한 30일 이상의 사전 유예기간을 두고
              공지합니다. 이 경우 “회사”는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록
              표시합니다.
            </p>
            <p>
              4. 회사가 약관을 개정할 경우에는 그 개정약관은 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미
              체결된 계약에 대해서는 개정전의 약관조항이 그대로 적용됩니다. 다만 이미 계 약을 체결한 이용자가 개정약관
              조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공 지기간 내에 “회사”에 송신하여 “회사”의 동의를
              받은 경우에는 개정약관 조항이 적용됩니다.
            </p>
            <p>
              5. 이용자가 변경된 약관에 동의하지 않는 경우, 회사 대표사이트에서 제공하는 서비스 이용이 제 한되거나
              정지될 수 있습니다.
            </p>
            <p>
              6. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래등에서의 소비자보 호에 관한 법률,
              약관의 규제등에 관한 법률, 공정거래위원회가 정하는 전자상거래등에서의 소비 자보호지침 및 관계법령 또는
              상관례에 따릅니다.
            </p>
          </TextBox>

          <TextBox>
            <h2>제 4 조 서비스 제공 및 이용제한</h2>
            <p>1. 회사는 이용자에게 서비스를 제공합니다.</p>
            <p>
              2. 본 이용 약관에 달리 명시되지 않는 한 이용자는 개인적이며 비상업적인 용도와 목적에 한정 하여 서비스를
              이용할 수 있으며 모든 서비스 또는 그 일부를 가공, 복제, 복사, 판매, 거래, 재판 매 또는 영리목적으로 이용할
              수 없습니다.
            </p>
            <p>
              3. 회사는 언제든지 자신의 독자적인 판단에 따라 사전 경고 또는 통지 없이 다음과 같은 조치를 할 수 있습니다.
              <p>ⓐ 서비스 변경 또는 서비스나 그 일부의 제공을 일시 중단 및/또는 종료</p>
              <p>
                ⓑ 서비스를 통해 이용자가 보내거나 받는 전송 횟수 또는 서비스나 그 일부를 이용자에 게 제공하는데 사용되는
                저장 공간의 크기 제한
              </p>
              <p>
                ⓒ 서비스를 통해 제공되는 일부 또는 모든 오디오 및 사운드 파일, 데이터 파일, 이미지, 음악, 사진,
                소프트웨어, 동영상 및 작성된 텍스트 (이하 "콘텐츠")를 사전 심사, 검토, 플래 그 표시, 필터링, 수정, 거부,
                거절, 액세스 차단 또는 제거
              </p>
            </p>
            <p>
              4. 이용자는 회사가 인터페이스를 통해 제공하는 것 이외의 다른 서비스를 이용할 수 없으며(이용 에는
              이용하려는 시도를 포함합니다. 이하 동일) 자동화된 도구(소프트웨어 및/또는 하드웨어 포 함), 기능, 서비스
              또는 기타 방식(스크립트 또는 웹 크롤러 포함)을 통해 서비스를 이용하지 않습 니다.
            </p>
            <p>
              5. 이용자는 서비스와 관련하여 회사가 이용자에게 제공하는 모든 지침을 준수하고 따르며 서비 스 또는 서비스에
              연결된 모든 서버, 네트워크 또는 기타 장비에 장애나 중단을 초래할 수 있는 행위에 관여하지 않습니다.
            </p>
            <p>
              6. 일부 서비스는 모바일 네트워크를 통해 이용할 수 있거나 모바일 네트워크를 통해 이용할 때 특히 유용합니다.
              이용자는 네트워크 공급자가 네트워크 액세스, 휴대폰/모바일 장치의 네트워크 연결 기간 및 서비스 이용을 위해
              사용되는 데이터 양에 대해 요금을 부과할 수 있다는 점을 알 고 있어야 합니다. 이와 관련하여 서비스 이용 전에
              이러한 요금이 적용될 수 있는지 네트워크 공급자에게 확인할 책임은 전적으로 이용자에게 있습니다.
            </p>
          </TextBox>

          <TextBox>
            <h2>제 5 조 광고</h2>
            <p>
              이용자는 회사가 서비스의 일부로 이용자에게 프로모션 목적의 광고, 프로모션 자료 또는 기타 콘텐츠 및 자료나
              제품을 배치하거나 제시한다는 데 동의합니다.
            </p>
          </TextBox>

          <TextBox>
            <h2>제 6 조 서비스에 제공되는 콘텐츠 및 콘텐츠 권한</h2>
            <p>
              1. 서비스에서 제공되거나 서비스 이용의 일부로 또는 서비스 이용을 통해 액세스할 수 있는 콘 텐츠는
              창작자에게 모든 책임과 권리가 있습니다. 콘텐츠에는 광고, 프로모션 자료 및 문서나 기 타 후원 대상 콘텐츠가
              포함될 수 있습니다.
            </p>
            <p>
              2. 콘텐츠는 제3자의 소유권 또는 지식 재산권에 따라 보호될 수 있습니다. 이용자는 콘텐츠의 전 부 또는 일부를
              수정, 대여, 임대, 임차, 판매, 배포 또는 이에 기반을 둔 파생물을 생성하거나 콘 텐츠에 대한 라이선스를
              허여할 수 없습니다.
            </p>
            <p>
              3. 이용자는 서비스를 이용함으로써 불쾌하거나 적절하지 못하거나 무례한 일부 콘텐츠와 마주칠 수 있는 위험에
              노출되며 이러한 노출과 관련하여 서비스 이용에 따른 위험은 전적으로 이용자 본인에게 있음을 이해합니다.
            </p>
          </TextBox>

          <TextBox>
            <h2>제 7 조 저작권의 귀속 및 이용 제한</h2>
            <p>1. 회사가 작성한 저작물에 대한 저작권 기타 지식재산권은 회사에 귀속합니다.</p>
            <p>
              2. 이용자는 대표사이트를 이용함으로써 얻는 정보 중 회사에게 지식재산권이 귀속된 정보를 회 사의 사전 승낙
              없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제 3자에게 이용하게 하여서는
              안됩니다.
            </p>
            <p>3. 회사는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보합니다.</p>
          </TextBox>

          <TextBox>
            <h2>제 8 조 상표</h2>
            <p>
              1. 회사는 이용자에게 회사가 정한 이용조건에 따라 콘텐츠 등 서비스를 이용할 수 있는 이용권 만을 부여합니다.
            </p>
            <p>
              2. 이용자는 서비스와 관련되거나 이에 첨부되어 있거나 포함되어 있는 회사 또는 제3자의 소유 권 표시, 기호,
              상표, 서비스 표시, 상호, 로고 또는 기타 표시를 제거하거나 가리거나 숨기거나 수 정하거나 다른 방식으로
              변경할 수 없습니다(저작권 및 상표 표시 포함).
            </p>
            <p>
              3. 이용자는 회사 또는 제3자의 기호, 상표, 서비스 표시, 상호, 로고 또는 기타 표시를 이러한 표 시, 이름 또는
              로고의 소유자, 라이선스 보유자 또는 권한 있는 이용자(경우에 따름)에 대해 다른 사람을 오도하거나 혼동을
              초래할 의도가 있거나 그러할 가능성이 있거나 그렇게 예상되는 방식 으로 사용할 수 없습니다.
            </p>
          </TextBox>

          <TextBox>
            <h2>제 9 조 회사의 의무</h2>
            <p>
              1. 회사는 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 본 약관이 정하 는 바에 따라
              지속적이고, 안정적으로 서비스를 제공하기 위해서 노력합니다.
            </p>
            <p>
              2. 회사는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 "이용자"의 개인정보 보호를 위 한 보안
              시스템을 구축합니다.
            </p>
            <p>3. 회사"는 이용자가 원하지 않는 영리 목적의 광고성 전자우편을 발송하지 않습니다.</p>
          </TextBox>

          <TextBox>
            <h2>제10조 이용자의 의무</h2>
            <p>
              1. 이용자는 다음 행위를 하여서는 안됩니다.
              <p>ⓐ 신청 또는 변경 시 허위 내용의 등록</p>
              <p>ⓑ 타인의 정보 도용</p>
              <p>ⓒ 회사에 게시된 정보의 변경</p>
              <p>ⓓ 회사가 정한 정보 이외의 정보 (컴퓨터 프로그램 등)의 송신 또는 게시</p>
              <p>ⓔ 기타 제 3자의 저작권 등 지식재산권에 대한 침해</p>
              <p>ⓕ 기타 제 3자의 명예를 손상시키거나 업무를 방해하는 행위</p>
              <p>
                ⓖ 외설, 욕설, 비속어 또는 폭력적인 메시지, 화상, 음성 기타 공서양속에 반하는 정보를 [대표사이트]에 공개
                또는 게시하는 행위
              </p>
              <p>ⓗ 영리를 목적으로 하는 광고의 게시</p>
            </p>
            <p>
              2. 회사는 이용자가 제1항을 위반한 경우로서 다음 각호에 해당함이 명백한 때에는 사전 통지 없 이 해당
              게시물을 삭제할 수 있습니다.
              <p>
                ⓐ 정보 도용, 저작권 등 권리 침해 및 명예훼손, 업무방해 등의 사실이 명백하거나 해당 피해자 등이 회사에
                합리적인 근거를 제시하며 피해사실을 소명한 경우
              </p>
              <p>ⓑ 관련 법령상 금지되거나 형사처벌의 대상이 되는 행위</p>
            </p>
          </TextBox>

          <TextBox>
            <h2>제 11 조 분쟁의 해결</h2>
            <p>
              1. "회사"는 "이용자"가 제기하는 의견이나 불만에 대하여 적절하고 신속하게 처리하고, 그 결과를 통지합니다.
              다만, 신속한 처리가 곤란한 경우에 "회사"는 "이용자"에게 그 사유와 처리일정을 통 보합니다.
            </p>
            <p>
              2. "회사"는 "이용자"가 제기한 의견 등이 정당하지 않음을 이유로 처리하지 않은 경우 이의 사유 를 통보합니다.
            </p>
            <p>
              3. "회사"와 "이용자" 사이에 분쟁이 발생한 경우 "회사" 또는 "이용자"는 「콘텐츠산업진흥법」 제 29조에서
              정하고 있는 콘텐츠분쟁조정위원회에 분쟁조정을 신청할 수 있습니다.
            </p>
          </TextBox>

          <TextBox>
            <h2>제 12 조 재판권 및 준거법</h2>
            <p>
              1. "회사"와 "이용자"간의 "콘텐츠" 이용계약에 관한 소송은 제소 당시의 "이용자"의 주소에 의하 고, 주소가
              없는 경우 거소를 관할하는 지방법원의 전속관할로 합니다.
            </p>
            <p>
              2. 제소 당시 "이용자"의 주소 또는 거소가 분명하지 아니한 경우에 "회사"와 "이용자"간의 "콘텐 츠" 이용계약에
              관한 소송에 관한 관할법원은 「민사소송법」에 따라 정합니다.
            </p>
          </TextBox>

          <EndText>[부칙] 본 약관은 2023 년 8월 1일부터 적용됩니다.</EndText>
        </Wrapper>
      </Layout>
    </Fragment>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 900px;
`;

const TextBox = styled.div`
  padding: 20px 0;
  text-align: left;

  h2 {
    padding-bottom: 5px;
    font-size: 18px;
  }

  p {
    color: #333;
    font-size: 1.5em;
    line-height: 1.8;
    padding-bottom: 10px;

    > p {
      font-size: 1em;
      padding-left: 20px;
      padding-bottom: 10px;
    }
  }
`;

const EndText = styled.p`
  text-align: right;
`;

export default Term;

/* 역량평가 객관식 지문 순서 섞기 */
export const shuffleProblemOption = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

/* 로컬스토리지에서 객관식 지문 조회 */
export const getOrdersFromLocalStorage = (languageName) => {
  const value = localStorage.getItem(`questionOrder${languageName}`);
  return value ? JSON.parse(value) : {};
};

/* 로컬스토리지에 섞은 문제 저장 */
export const setOrdersToLocalStorage = (languageName, orders) => {
  localStorage.setItem(`questionOrder${languageName}`, JSON.stringify(orders));
};

/* 로컬스토리지에서 index에 해당하는 섞은 객관식 지문 순서 조회 */
export const getQuestionOrder = (languageName, idx, optionsLength) => {
  const orders = getOrdersFromLocalStorage(languageName);
  if (orders[idx]) {
    return orders[idx];
  } else {
    const order = shuffleProblemOption([...Array(optionsLength).keys()]);
    orders[idx] = order;
    setOrdersToLocalStorage(languageName, orders);
    return order;
  }
};

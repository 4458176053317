import React, { Fragment, useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useRecoilValue } from 'recoil';
import { withRouter } from 'react-router';
import { Button } from '@mui/material';

import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import { AuthAtom } from 'state';
import { fetchDashBoardSolvedInfo } from 'api/common';

const MySolvedInfoStat = () => {
  const auth = useRecoilValue(AuthAtom);
  const [solvedDatas, setSolvedDatas] = useState([]);
  const [languageSolvedDatas, setLanguageSolvedDatas] = useState([]);
  const [language, setLanguage] = useState({});
  const [solvedTotal, setSolvedTotal] = useState(0);
  const [scodeTotal, setScodeTotal] = useState(0);
  const [languageList, setLanguageList] = useState([]);

  useRedirectWithoutAuth();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchDashBoardSolvedInfo(auth.userId);
        setSolvedDatas(response.data);

        const languageList = response.data.reduce(function (res, cur) {
          if (res.findIndex(({ languageId }) => languageId === cur.languageId) === -1) {
            res.push({ languageId: cur.languageId, languageType: cur.languageType });
          }
          return res;
        }, []);
        setLanguageList(languageList);

        const langId = languageList[1].languageId;
        const regeneratedSet = response.data
          .filter((o) => o.languageId === langId)
          ?.map((o) => {
            return { ...o, scodeCnt: o.scodeCnt - o.solvedCnt };
          });

        setLanguage(response.data.find((o) => o.languageId === langId));
        setLanguageSolvedDatas(regeneratedSet);

        setScodeTotal(
          regeneratedSet.reduce((sum, o) => {
            return sum + o.scodeCnt + o.solvedCnt;
          }, 0)
        );
        setSolvedTotal(
          regeneratedSet.reduce((sum, o) => {
            return sum + o.solvedCnt;
          }, 0)
        );
      } catch (error) {}
    })();
  }, []);

  const handlerSelectLanguage = (langId) => {
    const regeneratedSet = solvedDatas
      .filter((o) => o.languageId === langId)
      ?.map((o) => {
        return { ...o, scodeCnt: o.scodeCnt - o.solvedCnt };
      });

    setLanguage(solvedDatas.find((o) => o.languageId === langId));
    setLanguageSolvedDatas(regeneratedSet);

    setScodeTotal(
      regeneratedSet.reduce((sum, o) => {
        return sum + o.scodeCnt + o.solvedCnt;
      }, 0)
    );
    setSolvedTotal(
      regeneratedSet.reduce((sum, o) => {
        return sum + o.solvedCnt;
      }, 0)
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const solvedCnt = payload.find((o) => o.dataKey === 'solvedCnt');
      const scodeCnt = payload.find((o) => o.dataKey === 'scodeCnt');

      if (solvedCnt !== undefined && scodeCnt !== undefined) {
        return (
          <div
            style={{
              background: '#eee',
              border: '1px solid #ccc',
              borderRadius: '5px',
              padding: '10px',
              color: '#000',
            }}
          >
            <p>보안약점 유형: {scodeCnt.payload.scategoryName}</p>
            <p>보안약점 명: {scodeCnt.payload.secName}</p>
            <p>
              {solvedCnt.value + scodeCnt.value} 문제 중 {solvedCnt.value} 문제를 해결했습니다.
            </p>
          </div>
        );
      }
    }
    return null;
  };

  // x축 라벨 기울여서 출력하기
  const customAxisTick = ({ x, y, value, payload }) => {
    if (payload.value === 'auto') value = 1;
    else value = payload.value;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={13} textAnchor='start' fill='#666' transform='rotate(45)'>
          {value + 1}
        </text>
        ;
      </g>
    );
  };

  // const customAxisTick = ({ x, y, width, value, payload }) => {
  //   if (payload.value === "auto") value = 1;
  //   else value = payload.value;
  //   return <text x={x - 12} y={y + 10} fill="#666" textAnchor="bottom">{`SEC${value + 1}`}</text>;
  // };

  const legendPayload = [
    { color: '#82ca9d', type: 'rect', value: '해결한 문제' },
    { color: '#999999', type: 'rect', value: '해결하지 못한 문제' },
  ];

  return (
    <Fragment>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <p className='secosub1 secoblack'>개발언어별 보안약점 문제풀이 현황</p>
        {language !== undefined && language.languageType !== undefined && (
          <div>
            <div className='secosub2' style={{ color: '#dc3545', marginBottom: '10px' }}>
              {((solvedTotal / scodeTotal) * 100).toFixed(2)}%
            </div>
            <div className='secotext' style={{ marginBottom: '20px' }}>
              {language.languageType.toUpperCase()} 개발언어, {languageSolvedDatas.length}개의 보안약점으로 구성된{' '}
              {scodeTotal} 문제 중 {solvedTotal} 문제를 해결했습니다.
            </div>
          </div>
        )}
      </div>
      <div style={{ textAlign: 'right' }}>
        {languageList?.map((item, index) => {
          return (
            <Button
              key={index}
              variant='contained'
              color='primary'
              onClick={() => handlerSelectLanguage(item.languageId)}
              style={{ marginRight: '10px' }}
            >
              <span className='secotext'>{item.languageType}</span>
            </Button>
          );
        })}
      </div>

      {language !== undefined && language.languageType !== undefined && (
        <BarChart
          width={960}
          height={450}
          data={languageSolvedDatas}
          margin={{ top: 20, right: 20, left: 0, bottom: 80 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis tick={customAxisTick} />
          <YAxis type='number' domain={[0, 'dataMax + 1']} allowDecimals={false} />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign='bottom' height={36} payload={legendPayload} />
          <Bar stackId='a' fill='#82ca9d' dataKey='solvedCnt' />
          <Bar stackId='a' fill='#999999' dataKey='scodeCnt' />
        </BarChart>
      )}
      {language === undefined ||
        (language.languageType === undefined && (
          <div
            style={{
              height: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '10px 10px 80px 50px',
            }}
          ></div>
        ))}
    </Fragment>
  );
};

export default withRouter(MySolvedInfoStat);

import React, { Fragment, useRef } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import { Header } from '../../widgets';
import { useRedirectWithoutAuth } from 'hooks/redirect.hook';
import styles from './CustomerIndex.module.scss';
import '../MyPage.css';

const CustomerLayout = (props) => {
  const ref = useRef(null);
  const { children } = props;

  // 로그인 정보 없을 때 홈으로 리다이렉트
  useRedirectWithoutAuth();

  const info = document.location.href.split('/');
  let sTitle = info[4];
  if (!sTitle) {
    sTitle = 'library';
  }

  let mainTitle = '';
  if (sTitle.indexOf('library') >= 0) {
    mainTitle = '자료실';
  } else if (sTitle.indexOf('faq') >= 0) {
    mainTitle = '자주 묻는 질문';
  } else if (sTitle.indexOf('review') >= 0) {
    mainTitle = '사용자 후기';
  } else if (sTitle.indexOf('contact') >= 0) {
    mainTitle = '문의하기';
  }

  `${styles.wrapper} ${styles.with_sidebar} ${styles.left}`;

  return (
    <Fragment>
      <Header>{mainTitle}</Header>
      <div className={styles.mypage} ref={ref} style={{ margin: '0 0 60px 0' }}>
        <section className={`${styles.wrapper} ${styles.with_sidebar} ${styles.left}`}>
          <aside className={styles.sidebar}>
            <div className='myPageLeftSide'>
              <ul>
                <li className='mypageLeftsideActive'>
                  <Link to='/customer'>
                    <span className='secotext'>
                      <i className='las la-book fa-2x'></i>
                      <br />
                      자료실
                    </span>
                  </Link>
                </li>
                <li className='mypageLeftsideActive'>
                  <Link to='/customer/faq'>
                    <span className='secotext'>
                      <i className='las la-comment fa-2x'></i>
                      <br />
                      FAQ
                    </span>
                  </Link>
                </li>
                <li className='mypageLeftsideActive'>
                  <Link to='/customer/review'>
                    <span className='secotext'>
                      <i className='las la-smile fa-2x'></i>
                      <br />
                      사용자 후기
                    </span>
                  </Link>
                </li>
                <li className='mypageLeftsideActive'>
                  <Link to='/customer/contact'>
                    <span className='secotext'>
                      <i className='las la-envelope-open fa-2x'></i>
                      <br />
                      문의하기
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </aside>

          <article className='mypage'>
            <div>{children}</div>
          </article>
        </section>
      </div>
    </Fragment>
  );
};

export default withRouter(CustomerLayout);

import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Header } from '../../widgets';
import { Layout } from '../../layouts';
import { useCustomState } from 'state/state';

const Privacy = () => {
  const location = useLocation();
  const state = useCustomState()[0];

  const path = location.pathname;
  const service = state.data.footerMenu.find((s) => s.url === path);

  return (
    <Fragment>
      <Header>{service.title}</Header>
      <Layout padding mode='light' style={{ background: 'rgb(255,255,255)' }}>
        <Wrapper>
          <StartText>
            <OpenegText>‘주식회사 오픈이지’</OpenegText>는 개인정보보호법에 따라 고객님의 개인정보와 그 권리를
            보호하고자 다음과 같은 처리방침을 두고 있습니다.
          </StartText>
          <TextBox>
            <h2>제 1조 개인정보의 처리 목적</h2>
            <p>
              <OpenegText>‘주식회사 오픈이지’</OpenegText>는 다음의 목적을 위해 개인정보를 처리합니다.
            </p>
            <p>처리한 개인정보는 아래 목적 이외의 용도로는 사용되지 않습니다.</p>
            <p>만일 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.</p>
            <p>
              ᆞ제공 서비스: 고객문의에 대한 답변제공 및 현황관리, 부정제보 민원처리 및 결과 회신 등을 목적으 로
              개인정보를 처리합니다.
            </p>
          </TextBox>

          <TextBox>
            <h2>제 2조 개인정보 수집현황</h2>
            <p>수집하는 개인정보는 아래와 같습니다.</p>
            <p>
              ᆞ개인정보 항목 :<p>– (필수항목) 이메일, 이름, 휴대전화번호</p>
              <p>– (선택항목) 없음</p>
            </p>
            <p>ᆞ수집방법 : 홈페이지, 서면양식, 이메일</p>
          </TextBox>

          <TextBox>
            <h2>제 3조 개인정보의 처리 및 보유 기간</h2>
            <p>
              <OpenegText>‘주식회사 오픈이지’</OpenegText>는 법령에 따라 정보주체로부터 개인정보를 수집/처리합니다.
            </p>
            <p>개인정보는 처리목적달성이 완료되거나 정보주체의 파기요청이 있을 경우 지체없이 파기됩니다.</p>
          </TextBox>

          <TextBox>
            <h2>제 4조 개인정보의 제3자 제공에 관한 사항</h2>
            <p>
              <OpenegText>‘주식회사 오픈이지’</OpenegText>는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법
              제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
            </p>
          </TextBox>

          <TextBox>
            <h2>제 5조 정보주체의 권리,의무 및 그 행사방법</h2>
            <p>
              ① 고객님은 <OpenegText>‘주식회사 오픈이지’</OpenegText>에 대해 언제든지 아래의 개인정보 보호관련 권리를
              행사하실 수 있 습니다.
              <p>ᆞ 개인정보 열람요구</p>
              <p>ᆞ 오류 등이 있을 경우 정정 요구</p>
              <p>ᆞ 삭제요구</p>
              <p>ᆞ 처리정지 요구</p>
            </p>
            <p>
              ② 제1항에 따른 권리행사는 개인정보보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, 모사 전송(FAX)
              등을 통하여 하실 수 있습니다. <OpenegText>‘주식회사 오픈이지’</OpenegText>는 지체없이 조치하겠습니다.
              <p>ᆞ팩스 :050-8090-9633, hello@openeg.co.kr</p>
            </p>
            <p>
              ③ 고객님께서 개인정보의 정정 또는 삭제를 요구하신 경우, <OpenegText>‘주식회사 오픈이지’</OpenegText>에서
              정정 또는 삭제를 완료할 때까지 개인정보를 이용하거나 제공하지 않습니다.
            </p>
            <p>④ 고객님의 권리 행사는 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.</p>
            <p></p>
          </TextBox>

          <TextBox>
            <h2>제 6조 개인정보의 파기</h2>
            <p>
              <OpenegText>‘주식회사 오픈이지’</OpenegText>는 개인정보 처리목적이 달성된 경우나 정보주체가 요구하는
              경우에는 지체없이 해당 개인정보를 파기하며 그 방법은 다음과 같습니다.
              <p>
                ᆞ파기방법: 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다. 종이에 출력된
                개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
              </p>
            </p>
          </TextBox>

          <TextBox>
            <h2>제 7조 개인정보의 안전성 확보 조치</h2>
            <p>
              <OpenegText>‘주식회사 오픈이지’</OpenegText>는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에
              필요한 기술적/관 리적 및 물리적 조치를 하고 있습니다.
            </p>
            <p>
              ① 개인정보 취급 직원의 최소화 및 교육
              <p>개인정보 취급직원을 최소화 하였으며, 개인정보관리대책을 시행하고 있습니다.</p>
            </p>
            <p>
              ② 내부관리계획의 수립 및 시행
              <p>개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</p>
            </p>
            <p>
              ③ 해킹 등에 대비한 기술적 대책
              <p>
                <OpenegText>‘주식회사 오픈이지’</OpenegText>는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및
                훼손을 막기 위하여 보 안프로그램을 설치, 업데이트를 하고 있습니다.
              </p>
            </p>
            <p>
              ④ 개인정보의 암호화
              <p>고객님의 개인정보는 암호화 되어 저장 및 관리되고 있습니다.</p>
              <p>
                중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기 능을
                사용하고 있습니다.
              </p>
            </p>
          </TextBox>

          <TextBox>
            <h2>제 8조 개인정보의 권리침해 신고상담</h2>
            <p>
              <OpenegText>‘주식회사 오픈이지’</OpenegText>의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지
              못하셨거나 자세한 도움이 필요하실 경우 아래 기관에 문의하여 주식기 바랍니다. 아래의 기관은{' '}
              <OpenegText>‘주식회사 오픈이지’</OpenegText>와는 별개의 기관입니다.
              <p>
                ① 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (
                <a href='http://www.kopico.go.kr' target='_blank'>
                  www.kopico.go.kr
                </a>
                )
              </p>
              <p>
                ② 개인정보침해신고센터 : (국번없이) 118 ({' '}
                <a href='http://www.privacy.kisa.or.kr' target='_blank'>
                  www.privacy.kisa.or.kr
                </a>
                )
              </p>
              <p>
                ③ 대검찰청 : (국번없이) 1301 (
                <a href='http://www.spo.go.kr' target='_blank'>
                  www.spo.go.kr
                </a>
                )
              </p>
              <p>
                ④ 경찰청 : (국번없이) 182 (
                <a href='https://www.police.go.kr' target='_blank'>
                  www.police.go.kr
                </a>
                )
              </p>
            </p>
          </TextBox>

          <TextBox>
            <h2>제 9조 개인정보 처리방침 변경</h2>
            <p>이 개인정보처리방침은 시행일로부터 적용됩니다.</p>
            <p>
              법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공 지사항을
              통하여 고지할 것입니다.
            </p>
          </TextBox>

          <EndText>○ 본 방침은 2023년 8월 1일부터 시행됩니다.</EndText>
        </Wrapper>
      </Layout>
    </Fragment>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 900px;
`;

const TextBox = styled.div`
  padding: 20px 0;
  text-align: left;

  h2 {
    padding-bottom: 5px;
    font-size: 18px;
  }

  p {
    color: #333;
    font-size: 1.5em;
    line-height: 1.8;
    padding-bottom: 10px;

    > p {
      font-size: 1em;
      padding-left: 20px;
      padding-bottom: 10px;
    }
  }
`;

const StartText = styled.p`
  font-size: 1.5em;
  color: #333;
  padding-bottom: 40px;
`;

const EndText = styled.p`
  text-align: right;
`;

const OpenegText = styled.span`
  color: #2e94d2;
  font-weight: bold;
  font-size: 1em;
`;

export default Privacy;

import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { InputLabel, MenuItem, FormControl, Select, Tooltip } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

import DescAccordionProblem from './AccordionProblem';
import { errorToast } from '../utilities/toast';
import { fetchSecureCategories, fetchSecureList, fetchSecureNames } from 'api/student';
import './secureCoding.css';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'aqua',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '1px 1px #eee',
    fontSize: 11,
  },
  arrow: {
    color: 'aqua',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: '7px',
    minWidth: 350,
  },
  formControlSec: {
    margin: '7px',
    minWidth: 350,
    marginTop: -2,
  },
  formControlSecOpacity: {
    margin: '7px',
    minWidth: 350,
    opacity: 0.2,
    marginTop: -2,
  },
  selectEmpty: {
    marginTop: '7px',
  },
  select: {
    label: { color: '#d3d3d3' },
  },
}));

const ScategorySelect = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [sel, setCategory] = useState('');
  const [sid, setSecId] = useState('');
  const [secCategoryDatas, setSecCategoryData] = useState([]);
  const [proCategoryDatas, setProcategoryDatas] = useState([]);
  //const [ sDatas, setSdatas ] = React.useState([]);

  const [lists, setLists] = useState([]);
  const [problemNumber, setProblemNumber] = useState(props.problemNumber);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchSecureCategories();
        setSecCategoryData(response.data);
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            errorToast('토큰이 유효하지 않습니다! 로그아웃됩니다.');
            history.push('/');
          } else if (error.response.status === 403) {
            errorToast('토큰이 만료되었습니다. 다시 로그인해주세요 :)');
            history.push('/');
          }
        }
      }
    })();
  }, []);

  // 기존 index1.js 에서 문제 선택리스트에 해당하는 처리 내용 index1.js 376-492
  // 취약점 > 보안약점 선택 value값에 맞춰 문제리스트를 state 형식으로 lists 배열에 저장처리
  const getLists = async (secId) => {
    let form = new URLSearchParams();
    form.append('secId', parseInt(secId));
    form.append('languageId', props.languageId);

    try {
      const response = await fetchSecureList(form);
      setLists(response.data);

      // 최근에 풀고 있던 문제부터 불러오기
      // 문제리스트가 1개인 경우
      if (response.data.length === 1) {
        let url_code = `${process.env.REACT_APP_IP}/users/sec/code/${response.data[0].scodeId}`;
      }
      //문제가 여러개라면
      else if (response.data.length > 1) {
        //lastTryDate가 가장 최근인 index를 problemNumber에 담는다
        var lastTryDate = response.data[0].lastTryDate;
        var problemNumber = 1;
        for (let i = 1; i < response.data.length; i++) {
          if (response.data[i].lastTryDate !== null) {
            if (lastTryDate >= response.data[i].lastTryDate) {
            } else {
              lastTryDate = response.data[i].lastTryDate;
              problemNumber = i + 1;
            }
          }
        }
        setProblemNumber({ problemNumber: problemNumber });
        let url_code = `${process.env.REACT_APP_IP}/users/sec/code/${response.data[problemNumber - 1].scodeId}`;
      } // 최근에 푼 문제 불러오기 끝
    } catch (error) {
      errorToast('잘못된 접근입니다. 다시 로그인 해주세요');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('examYn');
      history.push('/');
    }
  };

  const getSecNames = async (categoryId) => {
    try {
      const response = await fetchSecureNames({ categoryId });
      setProcategoryDatas(response.data);
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status === 401) {
          errorToast('토큰이 유효하지 않습니다! 로그아웃됩니다.');
          history.push('/');
        } else if (error.response.status === 403) {
          errorToast('토큰이 만료되었습니다. 다시 로그인해주세요 :)');
          history.push('/');
        }
      }
    }
  };

  //취약점 선택 선택후 링크 처리
  const handleChange = (event) => {
    setCategory(event.target.value);
    getSecNames(event.target.value);
    props.bringScategoryId(event.target.value);
    props.bringSecId('');
    setSecId('');
  };

  const handleChangeSid = (event) => {
    setSecId(event.target.value);
    props.bringSecId(event.target.value);
    getLists(event.target.value);
  };

  return (
    <div>
      <FormControl variant='outlined' className={classes.formControl}>
        <InputLabel id='demo-simple-select-outlined-label'>보안약점유형 선택</InputLabel>
        <LightTooltip title='취약점을 선택하세요.' placement='right' arrow>
          <Select
            labelId='demo-simple-select-outlined-label'
            id='demo-simple-select-outlined'
            defaultValue={''}
            value={sel}
            onChange={handleChange}
            label='취약점 카테고리를 선택하세요'
          >
            {secCategoryDatas?.map((s) => {
              return (
                <MenuItem key={s.scategoryId} value={s.scategoryId}>
                  {s.scategoryName}
                </MenuItem>
              );
            })}
          </Select>
        </LightTooltip>
      </FormControl>
      <br />
      <br />
      {sel > 0 ? (
        <FormControl variant='outlined' className={classes.formControlSec}>
          <InputLabel id='demo-simple-select-outlined-label'>보안약점 선택</InputLabel>
          <LightTooltip title='보안약점을 선택하세요.' placement='right' arrow>
            <Select
              variant='outlined'
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              value={sid}
              defaultValue={''}
              onChange={handleChangeSid}
              label='보안약점 카테고리를 선택하세요'
            >
              {proCategoryDatas?.map((s, idx) => {
                return (
                  <MenuItem key={idx} value={s.secId}>
                    {s.secName}
                  </MenuItem>
                );
              })}
            </Select>
          </LightTooltip>
        </FormControl>
      ) : (
        <FormControl variant='outlined' className={classes.formControlSecOpacity} disabled>
          <InputLabel id='demo-simple-select-outlined-label' className='sdisable'>
            보안약점 선택
          </InputLabel>

          <Select
            variant='outlined'
            labelId='demo-simple-select-outlined-label'
            id='demo-simple-select-outlined'
            value={''}
            defaultValue={''}
            label='취약점 카테고리를 선택하세요'
          ></Select>
        </FormControl>
      )}

      {sid > 0 ? (
        <Fragment>
          <DescAccordionProblem
            language={props.language}
            languageId={props.languageId}
            secId={sid}
            scategoryId={sel}
            handleSelectProblem={props.handleSelectProblem}
            problemNumber={props.problemNumber}
            lists={lists}
          />
        </Fragment>
      ) : (
        ''
      )}
    </div>
  );
};

export default ScategorySelect;

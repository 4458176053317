import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { fetchExamReport } from 'api/capability';
import { ExamReport } from '../../constants';

const useFetchExamReport = ({ languageId }) => {
  const [personalScore, setPersonalScore] = useState({});

  const queryReturns = useQuery({
    queryKey: ['fetchExamReport', { languageId }],
    queryFn: async () => {
      const response = await fetchExamReport({ languageId });
      const data = response.data.data;

      if (response.status === 200) {
        const { firstExamReportData = {}, secondExamReportData = {} } = data;

        const groupDataByCategory = (data) => {
          const groupedData = {};

          data.forEach((item) => {
            const categoryName = item.middleCategoryName;
            if (!groupedData[categoryName]) {
              groupedData[categoryName] = { correct: 0, total: 0 };
            }
            groupedData[categoryName].total += 1;
            if (item.isCorrect) {
              groupedData[categoryName].correct += 1;
            }
          });

          return groupedData;
        };

        const preGroupedData = groupDataByCategory(firstExamReportData);
        const postGroupedData = groupDataByCategory(secondExamReportData);

        const calculateCorrectRate = (groupedData) => {
          const calculatedData = {};

          for (const category in groupedData) {
            const data = groupedData[category];
            calculatedData[category] = (data.correct / data.total) * 100;
          }

          return calculatedData;
        };

        const preCorrectRates = calculateCorrectRate(preGroupedData);
        const postCorrectRates = calculateCorrectRate(postGroupedData);

        const resultData = Object.keys(ExamReport.MIDDLE_CATEGORIES).map((code) => {
          const type = ExamReport.MIDDLE_CATEGORIES[code];
          const preCorrectRate = preCorrectRates[type] || 0;
          const postCorrectRate = postCorrectRates[type] || 0;

          return {
            code: code,
            type: type,
            beforeCorrectRate: `${preCorrectRate === 0 ? 0 : preCorrectRate}`,
            afterCorrectRate: `${postCorrectRate === 0 ? 0 : postCorrectRate}`,
          };
        });

        setPersonalScore(resultData);
      }

      return data;
    },
    retry: 2,
    retryDelay: 1000,
    enabled: !!languageId,
  });

  return { ...queryReturns, personalScore };
};

export default useFetchExamReport;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { warningToast } from 'utilities/toast';

const LanguageList = ({ languageType, disable }) => {
  const [mouseOn, setMouseOn] = useState(false);

  const handlerUnableClick = () => {
    warningToast(`${languageType.toUpperCase()} 개발언어의 프로젝트 개발훈련을 이용할 수 없는 사용자입니다.`);
  };

  const handlerClick = (lan_type) => {
    localStorage.setItem('lan_type', lan_type);
  };

  const mouseOnHandler = () => setMouseOn(!mouseOn);

  const imgUrl = `/assets/icons/${languageType}-icon.png`;
  const isCommonLanguage = languageType !== 'common';

  const supportedLanguages = ['python', 'java', 'nodejs'];

  return (
    <>
      {/* 이용 가능 + 서비스 중인 언어 */}
      {isCommonLanguage && !disable && supportedLanguages.includes(languageType) && (
        <div className=' mOver' onClick={() => handlerClick(languageType)}>
          <Link to={'/student/codediagnosis/listproblems'}>
            <div className='mBox'>
              <img src={imgUrl} alt='LanguageType' width='120' />
            </div>
          </Link>
        </div>
      )}
      {/* 이용 불가 + 서비스 중인 언어 */}
      {isCommonLanguage &&
        disable &&
        (languageType === 'python' || languageType === 'java' || languageType === 'nodejs') && (
          <div className=' mOver' onClick={handlerUnableClick}>
            <div className='mBox' style={{ background: '#fff', opacity: '0.4' }}>
              <img src={imgUrl} alt='LanguageType' width='120' />
            </div>
          </div>
        )}
      {/* 이용 가능 + 서비스하지 않는 언어 */} {/* 이용 불가 + 서비스하지 않는 언어 */}
      {isCommonLanguage && !(languageType === 'python' || languageType === 'java' || languageType === 'nodejs') && (
        <div className=' mOver' onMouseEnter={mouseOnHandler} onMouseLeave={mouseOnHandler}>
          {mouseOn ? (
            <div className='mBox' style={{ height: '100%', background: '#fff', opacity: '0.4', position: 'relative' }}>
              <img src={imgUrl} alt='LanguageType' width='120' />
              <p style={{ position: 'absolute', top: '70%', left: '50%', transform: 'translate(-50%,-0%)' }}>
                서비스 준비 중
              </p>
            </div>
          ) : (
            <div className='mBox' style={{ background: '#fff', opacity: '0.4' }}>
              <img src={imgUrl} alt='LanguageType' width='120' />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default LanguageList;

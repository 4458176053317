import { api } from './config';
import { getItem } from '../commons/localStorage';

/* 학습 영상 조회 */
export const fetchTrainVideoList = async ({ courseId, courseType }) => {
  const { data } = await api.get(`/users/video/${courseId}/${courseType}`);

  return { data };
};

/* 영상 학습 등록 */
export const createUserTrainVideo = async ({ courseId }) => {
  const response = await api.post(`/users/video/train`, {
    courseId,
  });

  return response.data;
};

/* 동영상 강의 진행률 업데이트 */
export const updateVideoProgress = async ({ videoId, mediaLastPosition, viewArray }) => {
  const response = await api.put(`/users/video/train`, {
    videoId,
    mediaLastPosition,
    viewArray,
  });

  return response.data;
};

/* 코스트 리스트 조회 */
export const fetchCourseList = async () => {
  const { data } = await api.get(`/users/course`);

  return data;
};

/* 강의 과정 소개 조회 */
export const fetchCourseIntro = async ({ courseId }) => {
  const { data } = await api.get(`/users/intro/${courseId}`);

  return data;
};

/* 강사 소개 조회 */
export const fetchInstructorData = async ({ instructorId }) => {
  const { data } = await api.get(`/users/inst/${instructorId}`);

  return data;
};

/* 강사 이미지 조회 */
export const fetchInstructorImage = async ({ instId }) => {
  return await api.get(`/users/inst/image/${instId}`);
};

/* 보안약점 유형 조회 */
export const fetchSecureCategories = async () => {
  return await api.get(`/common/category`);
};

/* 보안약점 조회 */
export const fetchSecureList = async (form) => {
  const token = getItem('token');
  return await api.post(`/users/sec/list`, form, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
      Authentication: `${token}`,
    },
  });
};

/* 보안약점 이름, ID 조회 */
export const fetchSecureNames = async ({ categoryId }) => {
  return await api.get(`/common/sv/${categoryId}`);
};

export const fetchCodeDiagnosisExam = async ({ questId }) => {
  return await api.get(`/users/quests/projectExam/get/${questId}`);
};

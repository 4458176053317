import { api } from './config';

export const fetchUserStudyStatus = async () => {
  return await api.get(`/common/my/study-status`);
};

export const fetchUserStudyStatusByCourseId = async ({ courseId }) => {
  return await api.get(`/common/my/study-status/${courseId}`);
};

export const fetchUserInfo = async () => {
  return await api.get(`/users/info`);
};

export const changeUserInfo = async ({ userId, name, email, phone }) => {
  return await api.put(`/users`, {
    userId,
    name,
    email,
    phone,
  });
};

export const confirmEmail = async ({ email }) => {
  return await api.get(`/users/email/sendEmailConfirm/${email}`);
};

export const checkUserPrevPassword = async ({ userId, password }) => {
  return await api.post(`/users/sign/auth`, {
    userId,
    password,
  });
};

export const changeUserPassword = async ({ userId, password }) => {
  return await api.put(`/users/sign/pw`, {
    userId,
    password,
  });
};

export const fetchMyQuestionList = async () => {
  return await api.get(`/common/my/questionList`);
};

export const fetchMyQuestionDetail = async ({ id }) => {
  return await api.get(`/common/my/question/${id}`);
};

import React from 'react';
import ReportWrapper from './ReportWrapper';
import styled from 'styled-components';

const ExamComment = () => {
  return (
    <ReportWrapper title='역량평가 총평'>
      <CommentBox />
    </ReportWrapper>
  );
};

const CommentBox = styled.div`
  width: 100%;
  height: 80px;
  border: 1px solid #000;
`;

export default ExamComment;

import React, { useEffect, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Header, Sidebar, Footer } from './components/layouts';
import { ModalForm, RequestForm } from './components/ui';
import {
  Home,
  About,
  AboutPrice,
  SignUp,
  Mypage,
  MypageMyInformation,
  MypageMyQandA,
  MyQandADetail,
  AccountInfoFind,
  Contact,
  Dashboard,
} from './components/pages';
import {
  OperatorGroupList,
  OperatorGroup,
  CategoryResult,
  ExamUserList,
  Monitoring,
  PersonalTestResult,
  LearningResult,
} from './components/pages/Operator';
import { Terms, Privacy, Email, Sitemap } from './components/pages/Footer';

import AxiosConfig from './utilities/Axios.js';
import Customer from './components/pages/Customer/index';
import Student from './Student/App.js';
import NotFound from './NotFound';
import { useCustomState } from './state';
import { InstructorRoute, OperatorRoute } from './PrivateRoute';
import { TrainerMonitoring } from './components/pages/Trainer';
import { useVersionCheck } from './hooks';

const App = () => {
  const [state, actions] = useCustomState();

  /* 해시 체크 후 새로운 버전이 확인되면 새로고침 */
  const { data: latestHash } = useVersionCheck();

  useEffect(() => {
    if (latestHash) {
      const currentHash = localStorage.getItem('app-hash');

      if (currentHash && currentHash !== latestHash.trim()) {
        window.location.reload();
        localStorage.setItem('app-hash', latestHash.trim());
      } else {
        localStorage.setItem('app-hash', latestHash.trim());
      }
    }
  }, [latestHash]);

  useEffect(() => {
    actions.fetch();
  }, [actions]);

  let app;

  if (state.data) {
    app = (
      <Fragment>
        <ToastContainer style={{ fontSize: '1.3rem', width: '85%' }} autoClose='2000' position='top-center' />
        {/*<Sidebar data={state.data.menu} />*/}
        <RequestForm />
        <AxiosConfig />
        <ModalForm />

        <Header data={state.data.menu} />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/aboutUser' exact component={About} />
          <Route path='/aboutPrice' exact component={AboutPrice} />
          <Route path='/signup' exact component={SignUp} />
          <Route path='/mypage' exact component={Mypage} />

          {/* 오퍼레이터 */}
          <OperatorRoute path='/operator' exact component={OperatorGroupList} />
          <OperatorRoute path='/operator/group' exact component={OperatorGroup} />
          <OperatorRoute path='/operator/exam-users/:languageId' component={ExamUserList} />
          <OperatorRoute path='/operator/monitoring' component={Monitoring} />
          <OperatorRoute path='/operator/learning-result' component={LearningResult} />
          <OperatorRoute path='/operator/personal-result/:userId' component={PersonalTestResult} />
          <OperatorRoute path='/operator/category-result/:languageId' component={CategoryResult} />

          {/* 교강사 */}
          <InstructorRoute path='/trainer/monitoring' exact component={TrainerMonitoring} />

          <Route path='/information' exact component={MypageMyInformation} />
          <Route path='/question' exact component={MypageMyQandA} />
          <Route path='/question/:id' exact component={MyQandADetail} />
          <Route path='/accountinfofind' exact component={AccountInfoFind} />
          <Route path='/student' component={Student} />
          <Route path='/common/dashboard' component={Dashboard} />
          <Route path='/customer' component={Customer} />
          <Route path='/contact' component={Contact} />

          {/* Footer */}
          <Route path='/footer/terms' component={Terms} />
          <Route path='/footer/privacy' component={Privacy} />
          <Route path='/footer/email' component={Email} />
          <Route path='/footer/sitemap' component={Sitemap} />

          <Route component={NotFound} />
        </Switch>
        <Footer />
      </Fragment>
    );
  }

  return <Fragment>{app}</Fragment>;
};

export default App;

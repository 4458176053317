import React, { useState } from 'react';
import './listProblems.css';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function MyPdf({ pdfPath }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document file={`${process.env.REACT_APP_IP}${pdfPath}`} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>

      <a
        style={{ float: 'right' }}
        className='btn btn-outline-primary secotext1'
        href={`${process.env.REACT_APP_IP}${pdfPath}`}
        target='_black'
        download
      >
        PDF Download <i className='fas fa-file-download'></i>
      </a>

      <div className='pagination'>
        <button
          className='btn btn-info btn-circle pagenationChild'
          onClick={() => (pageNumber > 1 ? setPageNumber(pageNumber - 1) : null)}
        >
          <i className='fas fa-arrow-left'></i>
        </button>

        <div className='pagenationChild'>
          <p
            style={{
              color: 'grey',
              fontSize: '2rem',
              fontWeight: '500',
              fontFamily: 'Noto Sans KR',
            }}
          >
            {pageNumber} / {numPages}
          </p>
        </div>

        <button
          className='btn btn-info btn-circle pagenationChild'
          onClick={() => (pageNumber < numPages ? setPageNumber(pageNumber + 1) : null)}
        >
          <i className='fas fa-arrow-right'></i>
        </button>
      </div>
    </div>
  );
}

/* eslint-disable */
import React, { Fragment, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useCustomState } from '../state';
import { Services4 } from '../components/widgets';
import { AuthAtom } from '../state';
import { errorToast } from '../utilities/toast';
import './Training.css';

export default ({ history }) => {
  const state = useCustomState()[0];
  const auth = useRecoilValue(AuthAtom);

  useEffect(() => {
    if (!auth.auth) {
      errorToast('다시 로그인해 주세요.');
      history.push('/');
    } else {
      if (auth.type !== 1) {
        errorToast('해당 아이디는 학습자 계정이 아닙니다.');
        history.push('/');
      } else if (auth.status === 0) {
        errorToast('신청 대기 중입니다.');
        history.push('/');
      }
    }
  }, [auth]);

  return (
    <Fragment>
      <Services4 data={state.data.services} />
    </Fragment>
  );
};

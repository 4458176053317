import { useQuery } from '@tanstack/react-query';

import { fetchExamReportAvg } from 'api/capability';

const useFetchExamReportAvg = ({ languageId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchExamReportAvg', { languageId }],
    queryFn: async () => await fetchExamReportAvg({ languageId }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!languageId,
  });

  return { ...queryReturns };
};

export default useFetchExamReportAvg;

import React, { Fragment, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';

import { useCustomState } from 'state/state';
import { setItem } from 'commons/localStorage';
import { errorToast, warningToast } from 'utilities/toast';
import { parseJwtToken } from 'utilities/parseToken';
import { AuthAtom } from 'state/AuthAtom';
import { login } from 'api/auth';
import { api } from 'api/config';
import './Login.css';

const LoginForm = () => {
  const [state, actions] = useCustomState();

  const [authAtom, setAuthAtom] = useRecoilState(AuthAtom);

  const hideContent = () => {
    state.sidebar && actions.toggleSidebar();
    state.modal && actions.toggleModal();
    state.signconfirm && actions.toggleSignConfirm();
    state.request.modal && actions.toggleRequest();
    state.video && actions.toggleVideo();
  };

  const [values, setValues] = useState({
    userId: '',
    password: '',
    type: 1,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;

    if (values.userId.length === 0 || values.password.length === 0) {
      errorToast('아이디와 패스워드를 입력해 주세요.');
    } else {
      setIsLoading(true);
      try {
        const loginUserResponse = await login({ userId: values.userId, password: values.password });
        const { allowLanguages, freeYn, status, userEmail, userName, userType, userId } = parseJwtToken(
          loginUserResponse.headers.authorization
        );

        api.defaults.headers.common['Authorization'] = loginUserResponse.headers.authorization;

        setAuthAtom({
          ...authAtom,
          auth: true,
          userId: userId,
          name: userName,
          type: userType,
          email: userEmail,
          status,
          token: loginUserResponse.headers.authorization,
          refreshToken: loginUserResponse.headers.refreshtoken,
          freeYn,
          allowLanguages,
          examYn: loginUserResponse.data.data,
        });

        setItem('token', loginUserResponse.headers.authorization);
        setItem('refreshToken', loginUserResponse.headers.refreshtoken);
        setItem('examYn', loginUserResponse.data.data);

        setIsLoading(false);
        actions.removeModal();
      } catch (error) {
        errorToast('아이디 혹은 패스워드가 틀렸습니다. 혹은 회원가입 여부를 확인해 주세요.');
        setValues({ userId: '', password: '' });
        setIsLoading(false);
      }
    }
  };

  return (
    <Fragment>
      <div className='loginModal'>
        <div className='d-flex justify-content-center'>
          <p className='secomain' style={{ marginTop: '20px', marginBottom: '40px' }}>
            LOGIN
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className='form-group' style={{ width: '100%' }}>
            <input
              className='form-control form-control-lg'
              style={{
                fontSize: '17.5px',
                letterSpacing: '-0.175px',
                paddingLeft: '10px',
                paddingTop: '15px',
                paddingBottom: '15px',
              }}
              id='userId'
              required=''
              placeholder='아이디'
              name='userId'
              value={values.userId}
              onChange={handleChange}
            />
          </div>
          <div className='form-group' style={{ width: '100%' }}>
            <input
              className='form-control form-control-lg'
              style={{
                fontSize: '17.5px',
                letterSpacing: '-0.175px',
                paddingLeft: '10px',
                paddingTop: '15px',
                paddingBottom: '15px',
              }}
              type='password'
              required=''
              id='password'
              placeholder='패스워드'
              name='password'
              value={values.password}
              onChange={handleChange}
              formNoValidate
            />
          </div>
          <div
            className='form-group text-center'
            style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
          >
            <button
              className='btn btn-dark'
              style={{ fontSize: '1.4rem', padding: '10px', width: '155px', fontFamily: 'Noto Sans KR' }}
              type='button'
              data-dismiss='modal'
              onClick={hideContent}
            >
              취소
            </button>
            <button
              className='btn btn-primary'
              style={{ fontSize: '1.4rem', padding: '10px', width: '155px', fontFamily: 'Noto Sans KR' }}
              onClick={(e) => handleSubmit(e)}
              type='submit'
            >
              로그인
            </button>
          </div>
          <div className='form-group text-center' style={{ fontSize: '1.2rem', width: '100%' }}>
            <Link
              onClick={() =>
                warningToast(
                  '현재 아이디/비밀번호 찾기를 허용하지 않고 있습니다. 소속 단체/법인의 교육담당자에게 문의해 주시기를 바랍니다.'
                )
              }
              to=''
              style={{ marginRight: '20px', fontFamily: 'Noto Sans KR' }}
              data-dismiss='modal'
            >
              <span className='secotext'>아이디 혹은 비밀번호를 잊으셨나요?</span>
            </Link>
          </div>
          <div style={{ paddingTop: '10px', borderBottom: '1px solid #e3e3e3' }}></div>
        </form>

        <div style={{ paddingTop: '10px', borderBottom: '1px solid #e3e3e3', marginBottom: '40px' }}></div>
      </div>
    </Fragment>
  );
};

export default LoginForm;

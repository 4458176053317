import { useQuery } from '@tanstack/react-query';

import { fetchExamResultInfo } from 'api/capability';

const useFetchExamResultInfo = ({ languageId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchExamResultInfo', { languageId }],
    queryFn: async () => await fetchExamResultInfo({ languageId }),
    select: (res) => res.data.data,
    retry: 2,
    retryDelay: 1000,
    enabled: !!languageId,
  });

  return { ...queryReturns };
};

export default useFetchExamResultInfo;

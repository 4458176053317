export const LANGUAGE_ID = {
  0: 'common',
  1: 'python',
  2: 'java',
  3: 'c',
  4: 'c++',
  5: 'csharp',
  6: 'nodejs',
  7: 'javascript',
};

export const EXAM_ORDER = {
  1: '사전',
  2: '사후',
  3: '재',
};

import React, { useEffect, useState, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ProjectContainer from '../../components/ProjectContainer';
import Loading from '../../../../components/Loading';
import { api } from 'api/config';
import { AuthAtom } from 'state';
import { errorToast } from 'utilities/toast';
import './style.scss';
import '../../../../Training.css';

function SecureList(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

SecureList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',
    display: 'flex',
    height: '80vh',
  },
  tabs: {
    borderRight: `1px solid #eee`,
  },
  gridList: {
    margin: '5px',
    height: `${window.innerHeight}`,
  },
  createProject: {
    width: 280,
    height: 280,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '40px',
    color: '#2f96eb',
    '&:hover': {
      backgroundColor: '#f0f5f9',
    },
  },
}));

export default function ListProjects({ history }) {
  const classes = useStyles();
  const auth = useRecoilValue(AuthAtom);
  const { userId } = auth;
  const [projects, setProjects] = useState([]);
  const [container, setContainer] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalCreateProject, setModalCreateProject] = useState(false);
  const runningRef = useRef(null);

  // lanId로 해당 언어명 매칭
  const lanObj = {
    2: 'java',
  };

  useEffect(() => {
    if (!auth.auth) {
      errorToast('로그인이 필요합니다.');
      history.push('/');
    }
  }, [auth]);

  useEffect(() => {
    setTimeout(() => {
      api
        .get(`/users/quests/projects`)
        .then((response) => {
          const { data } = response;
          setProjects(data);
          if (data.length !== 0) {
            // 컨테이너 정보를 가져오기 있을 때 가져오기
            api.get(`/users/quests/containers`).then((response) => {
              const { data } = response;
              if (!data) {
                errorToast('컨테이너 생성 과정에 오류 발생하니 나중에 다시 시도해주세요.');
              } else {
                setContainer(data);
              }
            });
          } else {
            api
              .post(`/users/quests/containers`, { projects })
              .then((response) => {
                const { data } = response;
                setContainer(data);
              })
              .catch((error) => {});
          }
          setLoading(!loading);
        })
        .catch((error) => {});
    }, 300);
  }, []);

  // ?
  const closeModal = () => {
    setModalCreateProject(!modalCreateProject);

    api
      .get(`/users/quests/projects`)
      .then((response) => {
        const { data } = response;
        // setProjects(data)
        setLoading(false);
      })
      .catch((error) => {});

    if (projects.length !== 0) {
      // 컨테이너 정보를 가져오기 있을 때 실행시켜줌
      api
        .get(`/users/quests/containers`)
        .then((response) => {
          const { data } = response;
          setContainer(data);
        })
        .catch((error) => {});
    }
  };

  // 프로젝트 삭제
  const handleDeleteProject = (questId) => {
    setLoading(true);
    const filterProject = projects.filter((element) => element.questId !== questId);
    setProjects(filterProject);
    setLoading(false);
  };

  // 컨테이너 정보 업데이트?
  const handleUpdateContainer = () => {
    api
      .get(`/users/quests/containers`)
      .then((response) => {
        const { data } = response;
        if (!data) {
          errorToast('컨테이너 생성이 과정이 발생하니 나중에 다시 시도해주세요');
        } else setContainer(data);
      })
      .catch((error) => {
        setContainer({});
      });
  };

  if (loading) {
    return <Loading type='spinningBubbles' color='#2F96EB' />;
  }
  return (
    <div className='list-projects'>
      <div className='list-projects__container'>
        <div className='list-projects__container--content'>
          {projects.length !== 0 &&
            projects?.map((project, index) => {
              if (project.questLanguageType === lanObj[Number(auth.allowLanguages)]) {
                return (
                  <div key={index}>
                    <ProjectContainer
                      key={index}
                      ref={project.questId === container.project_id ? runningRef : null}
                      container={container}
                      setContainer={setContainer}
                      setLoading={setLoading}
                      handleUpdateContainer={handleUpdateContainer}
                      project={project}
                      openModal={() => this.setModal(true)}
                      handleDeleteProject={handleDeleteProject}
                    />
                  </div>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
}

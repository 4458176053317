import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMore from '@mui/icons-material/ExpandMore';

import Loading from '../../../../components/Loading';
import CreateProject from '../ListProjects/CreateProject';
import MyPdf from './pdf';
import { api } from 'api/config';
import { AuthAtom } from 'state';
import { errorToast } from 'utilities/toast';
import './listProblems.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    height: '30px',
    padding: '10px 0 10px 10px',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#1E88E5',
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    padding: '10px 0 10px 0px',
    fontSize: '1.4rem',
    color: '#333',
  },
  contents: {
    padding: '0px 0px 20px 10px',
    fontSize: '1.2rem',
  },
  barBox: {
    marginBottom: '-2px',
  },
  title01: {
    width: 'fit-content',
    padding: '5px 30px 5px 10px',
    margin: '20px 0 10px 0',
    borderRadius: '5px 15px 15px 5px',
    color: 'white',
    fontSize: '1.4rem',
    backgroundColor: '#f1ba28',
  },
  title02: {
    margin: '20px 0 10px 20px',
    fontSize: '1.7rem',
    color: '#27A0F1',
  },
  content01: {
    fontSize: '1.4rem',
    marginLeft: '20px',
  },
}));

export default function ListProblems({ setActive }) {
  const history = useHistory();
  const { userId } = useRecoilValue(AuthAtom);

  const [questLevel, setQuestLevel] = useState('1');
  const [problemDatas, setProblemDatas] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectQuestId, setProjectQuestId] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalCreateProject, setModalCreateProject] = useState(false);
  const [levelActive, setLevelActive] = useState(1);
  const [quest, setQuest] = useState({});
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [buttonText, setButtonText] = useState('학습중');
  const lan_type = localStorage.getItem('lan_type');

  const handelClick = (e) => {
    setLevelActive(e);
    setQuestLevel(e);
    api
      .get(`/users/quests/projectExam/${e}/${lan_type}`)
      .then((response) => {
        setProblemDatas(response.data);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            errorToast('토큰이 유효하지 않습니다! 로그아웃됩니다.');
            history.push('/');
          } else if (error.response.status === 403) {
            errorToast('토큰이 만료되었습니다. 다시 로그인해주세요 :)');
            history.push('/');
          }
        }
      });
  };

  const closeModal = () => {
    setModalCreateProject(!modalCreateProject);

    api
      .get(`/users/quests/projects`)
      .then((response) => {
        const { data } = response;
        setProjects(data);
        setLoading(false);
      })
      .catch((error) => {});
  };

  const loadProject = (questId) => {
    setLoading(true);

    api
      .post(`/users/quests/containers`, {
        questId,
      })
      .then((response) => {
        const { data } = response;
        if (data === 'success') {
          setActive(2);
          setLoading(false);
          history.push('/student/codediagnosis/listprojects');
        } else {
          setLoading(false);
          errorToast('컨테이너 재시작 오류 발생하니 다시 시도 해주세요.');
        }
      })
      .catch((error) => {
        errorToast('컨테이너 재시작 오류 발생하니 다시 시도 해주세요.');
      });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    api
      .get(`/users/quests/projectExam/${questLevel}/${lan_type}`)
      .then((response) => {
        setProblemDatas(response.data);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 401) {
            history.push('/');
          } else if (error.response.status === 403) {
            errorToast('토큰이 만료되었습니다. 다시 로그인해주세요 :)');
            history.push('/');
          }
        }
      });

    api
      .get(`/users/quests/projects`)
      .then((response) => {
        let { data } = response;
        setLoading(!loading);
        setProjects(data);
        setProjectQuestId(data?.map((element) => element.questId));
      })
      .catch((error) => {});
  }, []);

  if (loading) {
    return <Loading type='spinningBubbles' color='#2F96EB' />;
  }
  return (
    <>
      <div className='problemList'>
        <div className='levelList'>
          <div className='row'>
            <div className='col-12'>
              <ul>
                <li className={levelActive === 1 ? 'levelListActive' : ''}>
                  <Link to='#' onClick={() => handelClick(1)}>
                    <p className='levelTitle secosub2'>레벨 1</p>
                    <p className='levelIcon'>
                      <i className='fas fa-star'></i>
                    </p>
                  </Link>
                </li>
                <li className={levelActive === 2 ? 'levelListActive' : ''}>
                  <Link to='#' onClick={() => handelClick(2)}>
                    <p className='levelTitle secosub2'>레벨 2</p>
                    <p className='levelIcon'>
                      <i className='fas fa-star'></i>
                      <i className='fas fa-star'></i>
                    </p>
                  </Link>
                </li>
                <li className={levelActive === 3 ? 'levelListActive' : ''}>
                  <Link to='#' onClick={() => handelClick(3)}>
                    <p className='levelTitle secosub2'>레벨 3</p>
                    <p className='levelIcon'>
                      <i className='fas fa-star'></i>
                      <i className='fas fa-star'></i>
                      <i className='fas fa-star'></i>
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='problemmain'>
          <div className='row'>
            <div className='col-12'>
              <div className={classes.root}>
                {problemDatas?.map((problem) => {
                  return (
                    <div key={problem.questId}>
                      <Accordion
                        className={classes.barBox}
                        expanded={expanded === problem.questId}
                        onChange={handleChange(problem.questId)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls={problem.questName}
                          id={problem.questId}
                        >
                          <Typography className={classes.heading}>{problem.questName}</Typography>
                          <Typography className={classes.secondaryHeading}>{problem.questSummary}</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <MyPdf pdfPath={problem.questGuideName} />
                        </AccordionDetails>
                        <Grid item xs={12}>
                          <div
                            style={{
                              paddingTop: '3em',
                              borderBottom: '1px solid #e3e3e3',
                              margin: '20px 30px',
                              fontFamily: 'Noto Sans KR',
                            }}
                          ></div>
                        </Grid>

                        {projectQuestId.includes(problem.questId) ? (
                          <>
                            <div
                              style={{ position: 'relative', left: '42%' }}
                              onMouseEnter={() => {
                                setButtonText('내 프로젝트로 이동');
                              }}
                              onMouseLeave={() => {
                                setButtonText('학습중');
                              }}
                            >
                              <button
                                style={{
                                  cursor: 'pointer',
                                  padding: '20px 40px',
                                  marginBottom: '20px',
                                  borderRadius: '10px',
                                  backgroundColor: '#3498db',
                                  color: 'white',
                                  border: 'thin solid white',
                                  fontSize: '1.2rem',
                                  fontWeight: '500',
                                  fontFamily: 'Noto Sans KR',
                                }}
                                onClick={() => {
                                  loadProject(problem.questId);
                                }}
                              >
                                <i className='fas fa-plus'></i>&nbsp;&nbsp;{buttonText}
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ position: 'relative', left: '42%' }}>
                              <button
                                style={{
                                  cursor: 'pointer',
                                  padding: '20px 40px',
                                  marginBottom: '20px',
                                  borderRadius: '10px',
                                  backgroundColor: '#3498db',
                                  color: 'white',
                                  border: 'thin solid white',
                                  fontSize: '1.2rem',
                                  fontWeight: '500',
                                  fontFamily: 'Noto Sans KR',
                                }}
                                onClick={() => {
                                  setQuest({
                                    id: problem.questId,
                                    name: problem.questName,
                                  });
                                  setModalCreateProject(!modalCreateProject);
                                }}
                              >
                                <i className='fas fa-plus'></i>&nbsp;&nbsp;학습 시작
                              </button>
                            </div>
                          </>
                        )}
                      </Accordion>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalCreateProject && (
        <CreateProject
          closeModal={closeModal}
          questId={quest.id}
          questName={quest.name}
          questLanguageType={lan_type}
          setActive={setActive}
          history={history}
        />
      )}
    </>
  );
}

import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import { useFetchGroupUsersExamResult } from 'hooks';
import { Header } from '../../../widgets';
import { EXAM_ORDER } from 'constants/common';
import ExamInfoTable from './ExamInfoTable';
import StudentScoreGraph from './StudentScoreGraph';
import ExamUserTable from './ExamUserTable';

const ExamUserList = () => {
  const { state } = useLocation();

  const { groupExamInfo, groupId, languageId } = state || {};

  const { data: userExamInfo } = useFetchGroupUsersExamResult({
    groupId,
    languageId,
  });
  const { userExamResult } = userExamInfo || {};

  return (
    <>
      <Header>{`<${groupExamInfo.courseName}> ${EXAM_ORDER[groupExamInfo.groupCourseCount]}평가 시험 결과`}</Header>

      <Container>
        {/* 해당 시험 정보 테이블 */}
        <ExamInfoTable examInfo={userExamInfo} />

        {/* 전체 교육생 점푸 그래프 */}
        <StudentScoreGraph userExamResult={userExamResult} />

        {/* 전체 교육생 리스트 테이블 */}
        <ExamUserTable userExamResult={userExamResult} />
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 1140px;
  min-height: 433px;
  display: flex;
  flex-direction: column;
  margin: 30px auto;
`;

export default ExamUserList;

import React, { Fragment } from 'react';

import { Services3, Slider, Facts, Counter, Reviews } from '../../widgets';
import { useCustomState } from 'state/state';
import { useFetchHomeData } from '../../../hooks';

const Home = () => {
  const state = useCustomState()[0];

  const { faqData, rateData } = useFetchHomeData();
  const faq = faqData?.data || [];
  const rate = rateData?.data || [];

  return (
    <Fragment>
      <Slider data={state.data.slides} />
      <Services3 data={state.data.services} />

      {rate.length > 0 && <Counter data={rate} />}

      {faq.length > 0 && <Facts data={faq} />}

      {/*   홈 화면에 불필요한 내용 출력이 되지 않도록 처리
      <Features2 data={state.data.features2} />
      <Team data={state.data.team} />
      <Reviews data={state.data.reviews} />
      <Features data={state.data.price} />
      <Facts data={faqData} /> */}
    </Fragment>
  );
};

export default Home;

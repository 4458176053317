import React from 'react';
import styled from 'styled-components';

import { PersonalResultWrapper } from './PersonalResultCommon';
import moment from 'moment/moment';

const PersonalResultSummary = ({ summaryResult, riskAvg, tableColor = '#f2f2f2' }) => {
  const beforeExam = { ...summaryResult.filter((e) => e.examOrder === 1)[0] } || {};
  const afterExam = { ...summaryResult.filter((e) => e.examOrder === 2)[0] } || {};

  const { firstRiskLevelCorrectRates: beforeAvg = {}, secondRiskLevelCorrectRates: afterAvg = {} } = riskAvg || {};

  const formatedSubmitDate = (time) => {
    if (!time) return null;

    return moment(time).format('YYYY-MM-DD');
  };

  const getExamDuration = (startTime, submitTime) => {
    if (!startTime || !submitTime) return '-';

    const start = new Date(startTime);
    const submit = new Date(submitTime);

    const durationInMilliseconds = submit - start;

    const totalSeconds = Math.floor(durationInMilliseconds / 1000);

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return `${minutes}분 ${seconds}초`;
  };

  return (
    <PersonalResultWrapper title='개인 역량평가 종합 결과'>
      <Container tableColor={tableColor}>
        <table>
          <thead>
            <tr>
              <th rowSpan='2'>구분</th>
              <th rowSpan='2'>평가일자</th>
              <th rowSpan='2'>평가소요시간</th>
              <th rowSpan='2'>목표 점수</th>
              <th rowSpan='2'>전체 평균</th>
              <th rowSpan='2'>개인 점수</th>
              <th colSpan='3'>위험도별 정답률(%)</th>
              <th rowSpan='2'>
                순위
                <br />
                (본인/전체)
              </th>
            </tr>
            <tr>
              <th>상</th>
              <th>중</th>
              <th>하</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>사전</td>
              <td>{formatedSubmitDate(beforeExam.submitDateTime) || '-'}</td>
              <td>{getExamDuration(beforeExam.startDateTime, beforeExam.submitDateTime)}</td>
              {/* 목표 점수 80으로 하드코딩(240724 회의) */}
              <td>80</td>
              <td>{beforeExam.examStatus === '시험 종료' ? Math.round(beforeExam.groupAvgScore) : '-'}</td>
              <td>{beforeExam.userScore || '-'}</td>
              <td>{beforeExam.examStatus === '시험 종료' ? Math.round(beforeAvg.HIGH || 0) : '-'}</td>
              <td>{beforeExam.examStatus === '시험 종료' ? Math.round(beforeAvg.MEDIUM || 0) : '-'}</td>
              <td>{beforeExam.examStatus === '시험 종료' ? Math.round(beforeAvg.LOW || 0) : '-'}</td>
              <td>
                <span>
                  {beforeExam.examStatus === '시험 종료'
                    ? !(beforeExam.userRank || beforeExam.userScore)
                      ? '미응시'
                      : `${beforeExam.userRank} / ${beforeExam.examTakerCount}`
                    : '-'}
                </span>
              </td>
            </tr>
            <tr>
              <td>사후</td>
              <td>{formatedSubmitDate(afterExam.submitDateTime) || '-'}</td>
              <td>{getExamDuration(afterExam.startDateTime, afterExam.submitDateTime) || '-'}</td>
              {/* 목표 점수 80으로 하드코딩(240724 회의) */}
              <td>80</td>
              <td>{afterExam.examStatus === '시험 종료' ? Math.round(afterExam.groupAvgScore) : '-'}</td>
              <td>{afterExam.userScore || '-'}</td>
              <td>{afterExam.examStatus === '시험 종료' ? Math.round(afterAvg.HIGH || 0) : '-'}</td>
              <td>{afterExam.examStatus === '시험 종료' ? Math.round(afterAvg.MEDIUM || 0) : '-'}</td>
              <td>{afterExam.examStatus === '시험 종료' ? Math.round(afterAvg.LOW || 0) : '-'}</td>
              <td>
                <span>
                  {afterExam.examStatus === '시험 종료'
                    ? !(afterExam.userRank || afterExam.userScore)
                      ? '미응시'
                      : `${afterExam.userRank} / ${afterExam.examTakerCount}`
                    : '-'}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </Container>
    </PersonalResultWrapper>
  );
};

const Container = styled.div`
  width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 1.5rem;
    text-align: center;
  }

  th,
  td {
    padding: 3px 7px;
    border: 1px solid #ddd;
  }

  thead {
    background-color: ${(p) => p.tableColor};
    color: ${(p) => (p.tableColor !== '#f2f2f2' ? '#fff' : '#000')};
  }
`;

export default PersonalResultSummary;

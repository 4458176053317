import photo from '../assets/placeholders/photo.jpg';
import photo01 from '../assets/images/securecoding01.jpg';
import photo02 from '../assets/images/securecoding02.jpg';
import photo03 from '../assets/images/securecoding03.jpg';
import photo04 from '../assets/images/securecoding04.jpg';
// import logotype from '../assets/placeholders/logotype.png';
// import icon from '../assets/placeholders/icon.svg';
import icon01 from '../assets/icons/cat4.png';
import icon02 from '../assets/icons/cat11.png';
import icon03 from '../assets/icons/cat22.png';
import icon04 from '../assets/icons/cat33.png';

import price01 from '../assets/icons/price01.png';
import price02 from '../assets/icons/price02.png';
// import price03 from '../assets/icons/price03.png';
import slideSub01 from '../assets/images/logo-secolab.png';

import slideMain01 from '../assets/images/head.png';
import slideMain02 from '../assets/images/support.png';
import slideMain03 from '../assets/images/write.png';
import Mock from './mock';
// import slideMain04 from '../assets/images/head.png';
// import slideMain05 from '../assets/images/support.png';

const database = {
  categories: [
    {
      id: '0',
      title: 'Product',
    },
    {
      id: '1',
      title: 'Project',
    },
    {
      id: '2',
      title: 'Review',
    },
    {
      id: '3',
      title: 'Article',
    },
  ],
  facts: [
    {
      text: '현재는 BtoB 서비스만 제공하고 있기 때문에 개인이 온라인으로 가입할 수 없습니다.',
      title: '개인이 온라인으로 회원가입이 가능한가요?',
    },
    {
      text: '월구독료를 결재하시면 시코랩의 모든 기능을 무제한 사용할 수 있습니다.',
      title: '월구독은 뭔가요?',
    },
    {
      text: 'AI봇을 통해 Q&A 서비스를 제공하고 있습니다. AI선생님께 질문해 보세요. 원하는 답을 얻을 수 있습니다.',
      title: '문제를 풀다가 해결이 되지 않는 문제는 어떻게 해결할 수 있나요? ',
    },
    {
      text: '넵. 월구독권을 구입하시면 모든 동영상 교육을 무료로 수강할 수 있습니다.',
      title: '동영상 교육은 모든 과목을 다 수강할 수 있나요?',
    },
  ],
  fast_links: [
    {
      title: '훈련장 이용 안내',
      url: '/aboutUser',
    },
    {
      title: '가격 정보',
      url: '/aboutPrice',
    },
  ],
  footerMenu: [
    {
      title: '이용약관',
      subTitle: 'Terms of service',
      url: '/footer/terms',
    },
    {
      title: '개인정보처리방침',
      subTitle: 'Privacy Policy',
      url: '/footer/privacy',
    },
    // {
    //   title: '이메일무단수집거부',
    //   subTitle: 'Refusal of unauthorized collection of e-mails',
    //   url: '/footer/email',
    // },
    {
      title: '사이트맵',
      subTitle: 'Site map',
      url: '/footer/sitemap',
    },
  ],

  price: [
    {
      icon: price01,
      id: 'price_0',
      text: '',
      // "1인 준비중/달",
      title: '개인 학습자',
    },
    {
      icon: price02,
      id: 'price_1',
      text: '',
      // "관련 교육 신청 시 무료 1인 50,000원/달",
      title: '교육생',
    },
  ],
  features2: [
    {
      icon: 'las la-archive',
      text: '소스코드에 존재하는 잠재적인 보안약점 식별하고 제거하는 훈련',
      title: 'SW개발보안 진단 및 대응훈련',
    },
    {
      icon: 'las la-business-time',
      text: '개발자는 안전하게 동작되는 프로그램을 만들 책임이 있습니다.여러분의 코딩 기술 향상을 위해 시큐어코딩 훈련 시스템에 도전해 보세요.',
      title: '시큐어코딩 훈련시스템',
    },
    {
      icon: 'las la-file-invoice-dollar',
      text: '웹기반 통합개발환경을 활용하여 시큐어한 소프트웨어 개발 훈련',
      title: '시큐어한 SW개발 프로젝트 훈련',
    },
  ],
  header_bgs: {
    about: photo,
    about_video_poster: photo,
    blog: photo,
    contacts: photo,
    contacts_w: photo,
    portfolio: photo,
    services: photo,
    team: photo04,
    team_w: photo04,
    facts: photo01,
    subscribe: photo,
    list: photo,
    signup: photo02,
    mypage: photo03,
    mypageinformation: photo04,
    mypageqanda: photo01,
    accountinfo: photo02,
    training: photo01,
  },
  menu: [
    {
      children: [
        {
          name: '훈련장 이용 안내',
          url: '/aboutUser',
        },
        {
          name: '가격 정보',
          url: '/aboutPrice',
        },
      ],
      name: '이용 안내',
      url: '/aboutUser',
    },
    {
      children: [
        {
          name: '강의실',
          url: '/student/Course',
        },
        {
          name: '보안약점 진단훈련',
          url: '/student/securelist',
        },
        {
          name: '프로젝트 개발훈련',
          url: '/student/codediagnosis/',
        },
        {
          name: '역량평가',
          url: '/student/capability/select',
        },
        {
          name: '대시보드',
          url: '/common/dashboard',
        },
        {
          name: '수강생 조회',
          url: '/trainer/monitoring',
        },
      ],
      name: '학습실',
      url: '/student',
    },
    {
      children: [
        {
          name: '자료실',
          url: '/customer',
        },
        {
          name: '자주묻는 질문',
          url: '/customer/faq',
        },
        {
          name: '사용자후기',
          url: '/customer/review',
        },
        {
          name: '문의하기',
          url: '/customer/contact',
        },
      ],
      name: '고객센터',
      url: '/customer',
    },
  ],

  slides: [
    {
      title: '시큐어코딩 훈련시스템',
      subtitle: '안전한 소프트웨어를 만들기 위한 노력',
      subImg: slideSub01,
      short:
        '개발자는 안전하게 동작되는 프로그램을 만들 책임이 있습니다. 코딩 기술 향상을 위해 시큐어코딩 훈련 시스템에 도전해 보세요.',
      mainImg: slideMain01,
      id: 'select',
    },
    {
      title: '보안약점 진단훈련',
      subtitle: '안전한 소프트웨어를 만들기 위한 노력',
      subImg: slideSub01,
      short:
        '시코랩 보안약점 진단 훈련과정은 소스 코드에 존재하는 보안 약점을 식별하고 시큐어 코딩 적용 방법을 학습합니다.',
      mainImg: slideMain02,
      id: 'securelist',
    },
    {
      title: '학습실',
      subtitle: '안전한 소프트웨어를 만들기 위한 노력',
      subImg: slideSub01,
      short:
        '시코랩 학습실에서는 SW보안약점과 취약점에 관련 공부를 위한 과정들이 동영상으로 배울수 있도록 준비되어 있습니다.',
      mainImg: slideMain03,
      id: 'Course',
    },
    {
      title: '프로젝트 개발훈련',
      subtitle: '안전한 소프트웨어를 만들기 위한 노력',
      subImg: slideSub01,
      short:
        '시코랩 프로젝트 개발훈련에서는 시큐어한 문제점등을 프로젝트 기반으로한 소프트웨어 개발을 시큐어하게 수행하는 방법을 학습합니다.',
      mainImg: slideMain01,
      id: 'codediagnosis',
    },
    {
      title: '역량평가',
      subtitle: '안전한 소프트웨어를 만들기 위한 노력',
      subImg: slideSub01,
      short:
        '시코랩 역량평가에서는 교육생의 사전 역량과 사후 이해도를 측정하여 학습 성취도를 객관적인 지표로 확인할 수 있도록 합니다.',
      mainImg: slideMain02,
      id: 'capability',
    },
  ],

  process: [
    {
      text: '회원가입 페이지에서 개인정보를 입력하고 회원가입 합니다.',
      title: '회원가입',
    },
    {
      text: '회원가입 시 결제정보를 등록하고 결제를 해야 학습자로 신청이 가능합니다.',
      title: '결제',
    },
    {
      text: '회원가입 시 입력한 아이디와 비밀번호를 이용하여 로그인합니다.',
      title: '로그인',
    },
    {
      text: '훈련장은 개발/보안 동영상 학습, 보안약점 진단훈련, 시큐어코딩 개발훈련 세개의 트랙으로 구성되어 있습니다.',
      title: '훈련장 이용',
    },
    {
      text: '마이페이지는 학습현황, 회원정보 수정하기 로 구성되어 있습니다.',
      title: '마이페이지 확인',
    },
  ],
  processPrice: [
    {
      text: '신청서를 다운로드 받아 작성합니다.',
      title: '신청서 다운로드',
    },
    {
      text: '작성한 신청서를 첨부하여 hello@openeg.co.kr로 보냅니다.',
      title: '신청 메일 발송',
    },
    {
      text: '신청 및 등록 절차가 완료되면 교육생 계정을 발급해 드립니다.',
      title: '교육생 계정 발급',
    },
    {
      text: '발급받은 교육생 계정으로 로그인하여 훈련장을 이용합니다.',
      title: '훈련장 이용',
    },
  ],
  reviews: [
    {
      author: '정 * 빈',
      id: 0,
      img: photo,
      status: '교육생 SK인포섹 K디지털 교육',
      text: '시코랩을 통해 개발자도 보안을 고려한 소프트웨어를 설계해야하고 구현단계에 취약점의 원인이 되는 보안약점을 선제적으로 제거하도록 코딩 습관을 들여야 한다는 것을 배웠습니다.',
      title: '개발자도 보안을 고려한 소프트웨어 설계',
    },
    {
      author: '송 * 주',
      id: 1,
      img: photo,
      status: '교육생 SK인포섹 4차산업선도인력양성과정',
      text: '코드리뷰를 통해 코드를 통해 보안약점을 식별할 수 있다는것을 배웠습니다. 그리고 어떻게 그 보안약점을 제거할 수 있는지도 학습 할 수 있었습니다.',
      title: '코드리뷰를 통해 보안약점을 식별',
    },
    {
      author: '이 * 주',
      id: 2,
      img: photo,
      status: '시큐어코딩 적용은 개발자의 의무라고 생각합니다.',
      text: '시큐어코딩 적용은 개발자의 의무라고 생각합니다. 보안사고를 선제적으로 방어할 수 있는 가장 효율적인 보안 활동이 시큐어코딩이라는것을 시코랩학습을 통해 배우게 되었습니다<',
      title: '시큐어코딩 적용은 개발자의 의무',
    },
    {
      author: '정 * 빈',
      id: 3,
      img: photo,
      status: '교육생 SK인포섹 K디지털 교육',
      text: '시코랩을 통해 개발자도 보안을 고려한 소프트웨어를 설계해야하고 구현단계에 취약점의 원인이 되는 보안약점을 선제적으로 제거하도록 코딩 습관을 들여야 한다는 것을 배웠습니다.',
      title: '개발자도 보안을 고려한 소프트웨어 설계',
    },
    {
      author: '송 * 주',
      id: 4,
      img: photo,
      status: '교육생 SK인포섹 4차산업선도인력양성과정',
      text: '코드리뷰를 통해 코드를 통해 보안약점을 식별할 수 있다는것을 배웠습니다. 그리고 어떻게 그 보안약점을 제거할 수 있는지도 학습 할 수 있었습니다.',
      title: '코드리뷰를 통해 보안약점을 식별',
    },
    {
      author: '이 * 주',
      id: 5,
      img: photo,
      status: '시큐어코딩 적용은 개발자의 의무라고 생각합니다.',
      text: '시큐어코딩 적용은 개발자의 의무라고 생각합니다. 보안사고를 선제적으로 방어할 수 있는 가장 효율적인 보안 활동이 시큐어코딩이라는것을 시코랩학습을 통해 배우게 되었습니다<',
      title: '시큐어코딩 적용은 개발자의 의무',
    },
  ],
  servicesIntro: [
    {
      icon: icon01,
      id: 'Course',
      img: icon02,
      subtitle: '개발/보안 동영상 학습',
      text01: '학습 동영상은 소프트웨어 개발보안, 정보보안, 프로그래밍 언어 총 세개의 카테고리로 구성되어 있습니다.',
      text02: '각 카테고리별로 원하는 과정을 선택하여 수강하면 됩니다.',
      text03: '',
      text04: '',
      text05: '',
    },
    {
      icon: icon02,
      id: 'securelist',
      img: icon02,
      subtitle: '보안약점 진단훈련',
      text01: '훈련하고 싶은 보안약점을 선택하고, 해당 보안약점에 출제된 문제를 풀어봅니다.',
      text02:
        '문제는 1단계, 2단계로 나뉘어져 있으며 1단계는 취약한 라인을 선택하면 통과되고, 2단계는 조건에 맞게 안전한 코드를 작성하면 통과됩니다.',
      text03: '문제 풀이 시 어려움을 겪을 경우 상단에 힌트 버튼을 통해 힌트를 열람합니다.',
      text04: '',
      text05: '',
    },
    {
      icon: icon03,
      id: 'codediagnosis',
      img: icon03,
      subtitle: '시큐어코딩 개발훈련',
      text01: '웹 브라우저에서 프로젝트를 생성하여 원하는 프로그램을 개발할 수 있습니다.',
      text02: '문제 목록에 있는 문제를 보고 조건에 맞게 프로젝트를 생성하여 개발할 수 있습니다.',
      text03: '프로젝트 목록에서 개인 프로젝트를 생성하여 개발할 수 있습니다.',
      text04: '개발을 완료하면 해당 프로젝트에 어떤 취약점이 있는지 진단하고, 진단 결과를 레포트로 받아볼 수 있습니다.',
      text05: '',
    },
  ],
  services: [
    {
      full: 'Continually incubate standards compliant customer service rather than professional initiatives. Intrinsicly whiteboard web-enabled potentialities via team building supply chains. Collaboratively integrate granular innovation and performance based intellectual capital. Compellingly redefine multimedia based paradigms for high-quality portals. Quickly build out-of-the-box e-tailers for customer directed opportunities.',
      icon: icon01,
      id: 'Course',
      img: icon01,
      steps: [
        {
          text: 'Competently benchmark multifunctional data with cross-unit               synergy. Synergistically incubate user-centric content rather than               2.0 paradigms. Interactively enable optimal scenarios with effective channels.',
          title: 'Construction project planning',
        },
        {
          text: 'Competently benchmark multifunctional data with cross-unit               synergy. Synergistically incubate user-centric content rather than               2.0 paradigms. Interactively enable optimal scenarios with effective channels.',
          title: '학습실',
        },
        {
          text: 'Competently benchmark multifunctional data with cross-unit               synergy. Synergistically incubate user-centric content rather than               2.0 paradigms. Interactively enable optimal scenarios with effective channels.',
          title: 'Post construction phase',
        },
      ],
      subtitle: '학습실',
      text: 'SW보안약점과 취약점을 동영상으로 제작된 교육을 통해 학습합니다.',
      title: 'Construction',
    },
    {
      featured: 'yes',
      full: ' ',
      icon: icon02,
      id: 'securelist',
      img: photo,

      subtitle: '보안약점 진단훈련',
      text: '소스코드에 존재하는 보안 약점을 식별하고 시큐어코딩 적용 방법을 학습합니다.',
      title: '',
    },
    {
      full: '',
      icon: icon03,
      id: 'codediagnosis',
      img: photo,

      subtitle: '프로젝트 개발훈련',
      text: '프로젝트기반의 소프트웨어 개발을 시큐어하게 수행하는 방법을 학습합니다.',
      title: '',
    },
    {
      full: '',
      icon: icon04,
      id: 'capability',
      img: photo,

      subtitle: '역량평가',
      text: '교육생의 사전 역량과 사후 이해도를 평가하여 학습 성취도를 확인합니다.',
      title: '',
    },
  ],
  sitemap: [
    {
      title: '이용안내',
      subMenu: [
        {
          title: '훈련장 이용 안내',
          link: '../aboutUser',
        },
        {
          title: '가격 정보',
          link: '../aboutPrice',
        },
      ],
    },
    {
      title: '학습실',
      subMenu: [
        {
          title: '강의실',
          link: '../student/Course',
        },
        {
          title: '보안약점 진단훈련',
          link: '../student/securelist',
        },
        {
          title: '프로젝트 개발훈련',
          link: '/student/codediagnosis',
        },
        {
          title: '역량평가',
          link: '../student/capability/select',
        },
        {
          title: '대시보드',
          link: '../common/dashboard',
        },
      ],
    },
    {
      title: '고객센터',
      subMenu: [
        {
          title: '자료실',
          link: '../customer/library',
        },
        {
          title: '자주 묻는 질문',
          link: '../customer/faq',
        },
        {
          title: '사용자 후기',
          link: '../customer/review',
        },
        {
          title: '문의하기',
          link: '../customer/contact',
        },
      ],
    },
  ],
  sitemap2: [
    {
      title: '사용자 메뉴',
      subMenu: [
        {
          title: '로그인',
          link: '../',
        },
        // TODO: 안되는 기능이기에 주석 처리. 추후에 기능 살리면 다시 주석 해제
        // {
        //   title: '회원가입',
        //   link: '../customer/faq',
        // },
        // {
        //   title: '아이디 및 비밀번호 찾기',
        //   link: '../customer/review',
        // },
        {
          title: '마이페이지',
          link: '../mypage',
        },
      ],
    },
    {
      title: '홈페이지 도우미',
      subMenu: [
        {
          title: '이용약관',
          link: '../footer/terms',
        },
        {
          title: '개인정보처리방침',
          link: '../footer/privacy',
        },
        // {
        //   title: '이메일무단수집거부',
        //   link: '../footer/email',
        // },
        {
          title: '사이트맵',
          link: '../footer/sitemap',
        },
      ],
    },
  ],
};

Mock.onGet('/api/data').reply(() => {
  return [200, database];
});

import { useQuery } from '@tanstack/react-query';

import { fetchUserReportLanguage } from 'api/capability';

const useFetchUserReportLanguage = () => {
  const queryReturns = useQuery({
    queryKey: ['fetchUserReportLanguage'],
    queryFn: async () => await fetchUserReportLanguage(),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
  });

  return { ...queryReturns };
};

export default useFetchUserReportLanguage;
